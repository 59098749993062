import React, { useEffect, useState, Fragment } from "react";
import { useTranslation } from 'react-i18next';
import { format, parseISO } from 'date-fns';
import { useNavigate, Link } from 'react-router-dom';
import {
  Row,
  Col,
  Card,
  CardBody
} from "reactstrap";
import $ from 'jquery';
import './EditableTable.css';
import { CSVLink } from 'react-csv';

import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../../components/Loaders/DnaLoader";
import "../../../assets/custom css/loadoverlay.scss";

import Alerts from "../../../components/Alerts/alerts";

import CustomerService from "./Customer.service";
import AuthService from "../../Authentication/auth.service";

import DataTable from 'react-data-table-component';
import { Tooltip } from "@mui/material";

import CustomerFilter from "../../../components/Table Filters/InvoiceFilter/CustomerFilter";
import AlertService from "../../../components/Alerts/alerts";
import moment from "moment";



const CustomersList = () => {
  const [isHovered, setIsHovered] = useState(false);

  const handleHover = () => {
    setIsHovered(!isHovered);
  };


  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [pdfModalIsOpen, setPDFModalIsOpen] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");
  const [filterInvoice_StartDate, setFilterInvoice_StartDate] = useState('');
  const [filterInvoice_EndDate, setFilterInvoice_EndDate] = useState('');
  const [filterDue_StartDate, setFilterDue_StartDate] = useState('');
  const [filterDue_EndDate, setFilterDue_EndDate] = useState('');
  const [search_text, setSearchText] = useState('');

  const [loading, setLoading] = useState(false);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [csvData, setCSVData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState("");
  const [searchName, setsearchName] = useState('');
  const [searchRegNo, setsearchRegNo] = useState('');
  const [searchEmail, setsearchEmail] = useState('');
  const [filterText1, setFilterText1] = useState('');
  const [filterText2, setFilterText2] = useState('');
  const [filterText3, setFilterText3] = useState('');

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    fetchCustomers(0, pageLimit);
  }, [pageLimit]);
  function setFilterTextFtn(itemrec, param) {
    console.log(itemrec)
    console.log('Value of key1:', itemrec);
    if (param === 'users') {
      setFilterText1(itemrec);
    }
    if (param === 'orders') {
      setFilterText2(itemrec);
    }
  };
  const [value, onChange] = useState(new Date());



  function checkenterkey(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      setResetPaginationToggle(!resetPaginationToggle);
      var page = 0
      handlePageChange(page++)
    }
  };




  function fetchCustomers(search_text, page_index, page_limit, filterText1, filterText2,
    filterInvoice_StartDate, filterInvoice_EndDate,
    filterDue_StartDate, filterDue_EndDate) {
    var filter = {};
    console.log(filterText1);
    if (filterText1) {
      console.log("filterText1");
      filter["customer_id"] = filterText1
    }
    if (filterText2) {
      console.log("filterText1");
      filter["status"] = filterText2
    } if (search_text) {
      console.log("search_text");
      filter["search_text"] = search_text
    }
    if (filterDue_StartDate) {
      console.log("filterDue_StartDate");
      filter["filterDue_StartDate"] = filterDue_StartDate
    } if (filterDue_EndDate) {
      console.log("filterDue_EndDate");
      filter["filterDue_EndDate"] = filterDue_EndDate
    } if (filterInvoice_StartDate) {
      console.log("filterInvoice_StartDate");
      filter["filterInvoice_StartDate"] = filterInvoice_StartDate
    } if (filterInvoice_EndDate) {
      filter["filterInvoice_EndDate"] = filterInvoice_EndDate
    }
    var offset = (page_index - 1) * page_limit;
    if (offset < 1) {
      offset = 0;
    }

    setLoading(true);

    CustomerService.getAllInvoices(offset, page_limit, filterText1,
      filterText2, filterInvoice_StartDate, filterInvoice_EndDate, filterDue_StartDate, filterDue_EndDate, search_text).then((response) => {
        setLoading(false);
        console.log("Get Customers response:-", response)
        if (response.data.message === "Unauthorized") {
          AuthService.logout();
          navigate("/login");
          window.location.reload();
        }
        else {
          console.log(response.data);
          setCustomers(response.data.data);
          console.log(response.data)
          setTotalRows(response.data.count.count);
          const mappedData = response.data.data.map(customer => ({
            invoice_date: moment(customer.invoice_date).format('DD-MM-YYYY'),
            invoice_number: customer.invoice_number,
            order_no: customer.order_no,
            pic_name: customer.user_details?.pic_name ?? '',
            company_name: customer.user_details?.company_name ?? '',
            status: customer.status,
            due_date: moment(customer.due_date).format(`DD-MM-YYYY`),
            total_amount: customer.total_amount,
          }));
          setCSVData(mappedData)
        }
      },
        (error) => {
          setLoading(false);
          const errContent =
            (error.response && error.response.data) || error.message || error.toString();
          console.log("Net:-", errContent)
          if (errContent) {
            Alerts.swalErrorAlert(errContent)
          }
          else {
            Alerts.swalErrorAlert(t("Server Error, Please try again later."))
          }
        })
  };
  function handleClear(item) {
    if (item === 'users') {
      $("#colfilter").val('');
      setFilterText1('');
      setFilterText2('');
      setResetPaginationToggle(!resetPaginationToggle);
      var page = 0
      handlePageChange(page++)
    }
    if (item === 'action') {
      // $("#modfilter").val('');
      $("#actfilter").val('');
      setFilterText2('');
      setResetPaginationToggle(!resetPaginationToggle);
      var page = 0
      handlePageChange(page++)
    }
    if (item === 'function') {
      $("#funcfilter").val('');
      setFilterText3('');
      setResetPaginationToggle(!resetPaginationToggle);
      var page = 0
      handlePageChange(page++)
    }
  };

  function handlePageChange(page_index) {
    console.log("Page index:-", page_index)
    setCurrentPage(page_index)
    fetchCustomers(page_index, pageLimit);
  }

  async function handlePerRowsChange(newPageLimit, page_index) {
    setPageLimit(newPageLimit);
  }

  function nameFilter(e) {
    console.log(e.target.value);
    setsearchName(e.target.value)
  }
  function regFilter(e) {
    console.log(e.target.value);
    setsearchRegNo(e.target.value)
  }
  function emailFilter(e) {
    console.log(e.target.value);
    setsearchEmail(e.target.value)
  }


  function resetFilters() {
    setResetPaginationToggle(!resetPaginationToggle);
    setFilterText1('');
    setFilterInvoice_StartDate('');
    setFilterInvoice_EndDate('');
    setFilterDue_StartDate('');
    setFilterDue_EndDate('');
    setFilterText2('');
    fetchCustomers(0, pageLimit);

  }
  const downloadCSV = () => {
    var getData = {
      functionName: "GetInvoices",
      sort: { _id: -1 }
    }
    setLoading(true);
    CustomerService.getInvoices(getData).then((response) => {
      setLoading(false);
      console.log(response.data);
      if (response.status === 200) {
        // Extract column headers from the first data row
        const data = response.data.data;
        if (data.length > 0) {
          // Define the columns to exclude
          const columnsToExclude = ['_id', 'customer_id', 'lastLogin_datetime', 'datetime_modified', 'doctypeData'];

          // Map header columns and modify it to include 'pic_name' and 'email' from 'user_details'
          const header = Object.keys(data[0]).map(column => {
            if (column === 'user_details') {
              return 'pic_name'; // Rename 'user_details' to 'pic_name'
            } else if (column === 'user_details') {
              return 'email'; // Rename 'user_details' to 'email'
            } else if (column === 'item') {
              return 'item_details'; // Rename 'item' to 'item_details'
            } else if (!columnsToExclude.includes(column)) {
              return column;
            }
          }).flat(); // Flatten the array

          // Filter the data to include 'pic_name' and 'email' from 'user_details' and format 'item' details in a single cell
          const filteredData = data.map(row => {
            const filteredRow = {};
            for (const key in row) {
              if (key === 'user_details' && row[key]) {
                filteredRow['pic_name'] = row[key].pic_name;
                filteredRow['email'] = row[key].email;
              } else if (key === 'item' && Array.isArray(row[key]) && row[key].length > 0) {
                // Format 'item' details in a single cell
                const itemDetails = row[key].map(item => {
                  return Object.keys(item).map(itemKey => `${itemKey}: ${item[itemKey]}`).join(', ');
                }).join('\t'); // Combine all item details into a single cell with tabs
                filteredRow['item_details'] = itemDetails;
              } else if (!columnsToExclude.includes(key)) {
                filteredRow[key] = row[key];
              }
            }
            return filteredRow;
          });

          // Create a CSV content with filtered headers and data
          const csv = [header.join('\t'), ...filteredData.map(row => header.map(fieldName => row[fieldName]).join('\t'))].join('\n');

          // Create a Blob with the CSV data
          const blob = new Blob([csv], { type: 'text/csv' });

          // Create a download link
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = 'data.csv';

          // Trigger the download
          a.click();

          // Clean up
          window.URL.revokeObjectURL(url);
        } else {
          console.log("No data to download.");
        }
      } else if (response.status === 401) {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      } else {
        console.log("Response status: " + response.status);
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent);
        if (errContent) {
          Alerts.swalErrorAlert(errContent);
        } else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."));
        }
      });
  }








  function searchCustomers() {
    setResetPaginationToggle(!resetPaginationToggle);
    const page = 0;

    // Filter values
    const filteredText1 = filterText1 || "";
    const filteredText2 = filterText2 || "";
    const search_texts = search_text || "";

    const FilterInvoice_StartDate1 = filterInvoice_StartDate || "";
    const FilterInvoice_EndDate2 = filterInvoice_EndDate || "";
    const FilterDue_StartDate3 = filterDue_StartDate || "";
    const FilterDue_EndDate4 = filterDue_EndDate || "";
    console.log(FilterDue_StartDate3)
    console.log(FilterDue_EndDate4)
    fetchCustomers(search_texts, 0, pageLimit, filteredText1, filteredText2, FilterInvoice_StartDate1,
      FilterInvoice_EndDate2, FilterDue_StartDate3, FilterDue_EndDate4);
  }


  function confirmDeleteCustomer(_id, order_no, status) {
    if (status === 'generated') {
      let msg = t("Once deleted, you will not be able to recover this Invoice!")
      Alerts.swalConfirmDeleteAlert(msg).then(res => {
        console.log("Resp:-", res)
        if (res === true) {
          sendDelete(_id, order_no)
        }
      })
    } else {
      AlertService.swalErrorAlert("Invoice status should be Generated")
    }
  };


  function sendDelete(id, order_no) {
    setLoading(true);
    CustomerService.deleteInvoice(id).then((response) => {
      setLoading(false);
      console.log("Delete customer response:-", response)
      if (response.data.status) {
        fetchCustomers(currentPage, pageLimit)
        if (response.data.message !== '') {
          Alerts.swalSuccessAlert(response.data.message)
        }
        else {
          var payload = {
            functionName: "UpdateOrder",
            postData: {
              _id: order_no,
              invoice_status: "false"
            },
          }
          console.log(payload);
          CustomerService.UpdateOrderStatus(payload).then((response) => {
            console.log(response);
            setLoading(false);
            Alerts.swalSuccessAlert(t("Invoice Deleted Successfully."))
          });

        }
        console.log(response.data.message)
      }
      else {
        if (response.data.message !== '') {
          if (response.data.message === "Unauthorized") {
            AuthService.logout();
            navigate("/login");
            window.location.reload();
          }
          else {
            Alerts.swalErrorAlert(response.data.message);
          }
        }
        else {
          Alerts.swalErrorAlert(t("Error: Can't delete the customer at the moment."))
        }
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        Alerts.swalErrorAlert(t("Delete operation failed"))
      })
  };

  const mycolumns = [
    {
      name: "Invoice Date",
      selector: "invoice_date",
      sortable: true,
      cell: (row) => format(parseISO(row.invoice_date), 'dd-MM-yyyy'),
    },
    {
      name: "Invoice No.",
      selector: "invoice_number",
      sortable: true,
    },
    {
      name: "Customer Name",
      selector: (row) => {
        if (row.user_details) {
          return <div >{row.user_details.company_name}</div>
        } else {
          return;
        }
      },
      sortable: true,
    },
    {
      name: "Order No.",
      selector: "order_no",
      sortable: true,
    },
    {
      name: t('Status'),
      width: '15%',
      selector: (row) => {
        return (
          <div>
            {row.status === "partial paid" ?
              <button type="button" style={{ borderColor: '#F39026', backgroundColor: '#F39026' }}
                className="btn btn-success btn-hover-shine me-3 btn">{row.status}
              </button>
              :
              row.status === "cancelled" ?
                <button type="button"
                  className="btn btn-danger btn-hover-shine me-3 btn">{row.status}
                </button>
                :
                row.status === "generated" ?
                  <button type="button" style={{ borderColor: '#1BD35A', backgroundColor: '#1BD35A' }}
                    className="btn btn-c btn-hover-shine me-3 btn">{row.status}
                  </button>
                  :
                  row.status === "paid" ?
                    <button type="button"
                      className="btn btn-success btn-hover-shine me-3 btn">{row.status}
                    </button>

                    :
                    row.status === "unpaid" ?
                      <button type="button" style={{ borderColor: '#609FFC', backgroundColor: '#609FFC' }}
                        className="btn btn-info btn-hover-shine me-3 btn">{row.status}
                      </button>
                      :
                      <button type="button"
                        className="btn btn-default btn-hover-shine me-3 btn">{row.status}
                      </button>
            }
          </div>
        );
      }
    },
    {
      name: "Due Date",
      selector: "due_date",
      sortable: true,
      cell: (row) => format(parseISO(row.due_date), 'dd-MM-yyyy'),
    },
    {
      name: "Amount",
      sortable: true,
      selector: (row) => (
        <div >{`${row.currency}${parseFloat(row.total_amount).toFixed(2)}`} </div>
      )
    },
  ];
  const headers = [
    { label: "Invoice Date", key: "invoice_date", style: { fontWeight: "bold", backgroundColor: "lightgray", color: "blue" } },
    { label: "Invoice Number", key: "invoice_number", style: { fontWeight: "bold", backgroundColor: "lightgray", color: "blue" } },
    { label: "Order No.", key: "order_no", style: { fontWeight: "bold", backgroundColor: "lightgray", color: "blue" } },
    { label: "Customer Name", key: "pic_name", style: { fontWeight: "bold", backgroundColor: "lightgray", color: "blue" } },
    { label: "Company Name", key: "company_name", style: { fontWeight: "bold", backgroundColor: "lightgray", color: "blue" } },
    { label: "Status", key: "status", style: { fontWeight: "bold", backgroundColor: "lightgray", color: "blue" } },
    { label: "Due Date", key: "due_date", style: { fontWeight: "bold", backgroundColor: "lightgray", color: "blue" } },
    { label: "Amount", key: "total_amount", style: { fontWeight: "bold", backgroundColor: "lightgray", color: "blue" } },
  ];
  const linkStyle = {
    textDecoration: 'none',
    color: isHovered ? 'black' : 'white',
  };
  return (
    <Fragment>
      <div className="app-main__outer">
        <div className="app-main__inner">
          <div className=" bg-light-gray bg-animation ">
            <div className="d-flex  justify-content-center align-items-center">
              <Col md="12" className="mx-auto app-login-box">
                <div className="app-page-title">
                  <div className="page-title-wrapper">
                    <div className="page-title-heading fw-bold">
                      <div className="page-title-icon">
                        <i className="pe-7s-news-paper icon-gradient bg-tempting-azure" />
                      </div>
                      <div>
                        Invoice Reports
                        <div className="page-title-subheading">
                          {t('View & Download Reports of Invoices.')}
                        </div>
                      </div>
                    </div>
                    <div className="page-title-actions">
                      <button type="button" onClick={() => navigate(-1)} className="btn btn-outline-success btn-hover-shine me-3">
                        Back
                      </button>
                    </div>
                  </div>
                </div>
                <Row>
                  <Col >
                    <Card className="main-card mb-3">
                      <CardBody>
                        <LoadingOverlay tag="div" active={loading}
                          styles={{ overlay: (base) => ({ ...base }) }}
                          spinner={<DNALoader />}>
                          <CustomerFilter
                            handleHover={handleHover}
                            linkStyle={linkStyle}
                            csvData={csvData}
                            search_text={search_text}
                            onSearch_text={(e) => {
                              setSearchText(e.target.value);
                            }}
                            onFilterInvoice_StartDate={setFilterInvoice_StartDate}
                            onFilterInvoice_EndDate={setFilterInvoice_EndDate}

                            onFilterDue_StartDate={setFilterDue_StartDate}
                            onFilterDue_EndDate={setFilterDue_EndDate}
                            searchCustomers={searchCustomers}
                            resetFilters={resetFilters}
                            onFilter1={(e) => {
                              console.log(e.target.value);
                              setFilterTextFtn(e.target.value, 'users');
                            }}

                            onFilter2={(e) => {
                              console.log(e.target.value);
                              setFilterText2(e.target.value, 'users');
                            }}
                            handleKeyDown={(event) => checkenterkey(event)}
                            filterText1={filterText1}
                            filterText2={filterText2}
                            filterInvoice_StartDate={filterInvoice_StartDate}
                            filterInvoice_EndDate={filterInvoice_EndDate}
                            filterDue_StartDate={filterDue_StartDate}
                            filterDue_EndDate={filterDue_EndDate}

                            onClear={() => handleClear('users')}
                          />

                          <DataTable
                            data={customers}
                            columns={mycolumns}
                            pagination
                            fixedHeader
                            fixedHeaderScrollHeight="400px"
                            paginationServer
                            paginationTotalRows={totalRows}
                            onChangePage={handlePageChange}
                            onChangeRowsPerPage={handlePerRowsChange}
                            paginationResetDefaultPage={resetPaginationToggle}
                          />
                        </LoadingOverlay>

                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </div>
          </div>
        </div>
      </div>

    </Fragment>

  );
};

export default CustomersList;