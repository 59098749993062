import React, { Fragment } from "react";
import { Navigate } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody
} from "reactstrap";
import DataTable from 'react-data-table-component';

// import ServicesService from "../services.service";
import AuthService from "../../../../Authentication/auth.service";

import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../../../../components/Loaders/DnaLoader";
import "../../../../../assets/custom css/loadoverlay.scss";

import Alerts from "../../../../../components/Alerts/alerts";
import CustomerService from "../../Customer.service";
import ServicesService from "../../../Services Management/services.service";

import AddOrderItem from "../../Customer Modals/Customer Order Modals/AddOrderItem";
import UpdateOrderItem from "../../Customer Modals/Customer Order Modals/UpdateOrderItem";
import AddOrderMedia from "../../Customer Modals/Customer Order Modals/AddOrderMedia";
import ViewOrderMedia from "../../Customer Modals/Customer Order Modals/ViewOrderMedia";




export default class OrderMedia extends React.Component {

  componentDidMount() {
    this.customerSingleOrderMedia();
  }
  constructor(props) {
    super(props);
    console.log("Received Prop in Order Media :-", props);
    this.state = {
      loading: false,
      loginNavigate: false,
      mediaTabupdated: 'false',
      orderMedia: [],
      // images: [
      //   {
      //     img: "https://mypallets.com.my/wp-content/uploads/2020/03/malaysia-pallet-supplier-2-way-forklift-pallet.jpg"
      //   },
      //   {
      //     img: "https://5.imimg.com/data5/SELLER/Default/2022/1/UN/JK/FF/62539552/plastic-drums.jpg"
      //   }
      // ]
    };
    this.customerSingleOrderMedia = this.customerSingleOrderMedia.bind(this)
    this.sendDelete = this.sendDelete.bind(this)
    this.confirmDeleteSingleOrderMedia = this.confirmDeleteSingleOrderMedia.bind(this)
    this.handleSuccess = this.handleSuccess.bind(this)
    this.goBack = this.goBack.bind(this)
  }

  // this.props.onSuccess();

  customerSingleOrderMedia() {
    var getData = {
      functionName: "GetOrdersMedia",
      postData: {
        order_id: this.props.data._id
      },
    }
    this.setState({
      loading: !this.state.loading,
    });
    CustomerService.getSingleOrderMedia(getData).then((response) => {
      this.setState({
        loading: !this.state.loading,
      });
      console.log("Get Customer Order Media response:-", response)
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        this.setState({ loginNavigate: true });
        window.location.reload();
      }
      else {
        this.setState({
          orderMedia: response.data.data
        });
      }
    },
      (error) => {
        this.setState({
          loading: !this.state.loading,
        });
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent)
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert("Server Error, Please try again later.")
        }
      })
  }

  handleSuccess() {
    this.setState({
      mediaTabupdated: 'true',
    });
    this.customerSingleOrderMedia();
  };

  goBack() {
    this.props.OnEditMedia(this.state.mediaTabupdated)
  }


  confirmDeleteSingleOrderMedia(_id, order_id, user_id) {
    let msg = "Once deleted, you will not be able to recover this item!"
    Alerts.swalConfirmDeleteAlert(msg).then(res => {
      console.log("Resp:-", res)
      if (res === true) {
        this.sendDelete(_id, order_id, user_id)
      }
    })
  };

  sendDelete(id, orderid, userid) {
    this.setState({
      loading: !this.state.loading,
    });
    CustomerService.deleteSingleOrderMedia(id, orderid, userid).then((response) => {
      this.setState({
        loading: !this.state.loading,
      });
      console.log("Delete customer order media details response:-", response)
      if (response.data.status) {
        this.setState({
          mediaTabupdated: 'true',
        });
        this.customerSingleOrderMedia();
        if (response.data.message !== '') {
          Alerts.swalSuccessAlert(response.data.message)
        }
        else {
          Alerts.swalSuccessAlert("Customer Order Media Deleted Successfully.")
        }
        console.log(response.data.message)
      }
      else {
        if (response.data.message !== '') {
          if (response.data.message === "Unauthorized") {
            AuthService.logout();
            this.setState({ loginNavigate: true });
            window.location.reload();
          }
          else {
            Alerts.swalErrorAlert(response.data.message);
          }
        }
        else {
          Alerts.swalErrorAlert("Error: Can't delete the media at the moment.")
        }
      }
    },
      (error) => {
        this.setState({
          loading: !this.state.loading,
        });
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        Alerts.swalErrorAlert("Delete operation failed")
      })
  };


  render() {
    {
      this.state.loginNavigate &&
        <Navigate to={'/login'} />
    }
    return (
      <Fragment>
        <div className="form-wizard-content">
          <Card>
            <CardBody className="mb-5">
              <LoadingOverlay tag="div" active={this.state.loading}
                styles={{ overlay: (base) => ({ ...base }) }}
                spinner={<DNALoader />}>
                <Row>
                  <Col md="10" className="text-end">
                    {/* <button className="btn-icon btn btn-success ms-5" onClick={this.goBack}>
                      <i className="pe-7s-up-arrow btn-icon-wrapper" > </i>
                      Back
                    </button> */}
                  </Col>
                  <Col md="2" className="text-center">
                    <AddOrderMedia data={this.props.data} onSuccess={this.handleSuccess} />
                  </Col>
                </Row>
                <Row>
                  {this.state.orderMedia !== 0 &&
                    this.state.orderMedia.map((media, index) => (
                      <Col md="6">
                        <Row className="mt-2 mb-2 ps-1 pe-2">
                          <Card >
                            <CardBody className="text-center">
                              <Row>
                                {media.file.includes('data:application/pdf') ?
                                  <iframe src={media.file} width={300} height={300} style={{ objectFit: "fit" }}></iframe>
                                  :
                                  <img src={media.file} width={300} height={300} style={{ objectFit: "fit" }}></img>
                                }
                              </Row>
                              <Row className="mt-2">
                                <Col md="6" className="text-end pe-1">
                                  <ViewOrderMedia data={media.file} />
                                </Col>
                                <Col md="6" className="text-start ps-1">
                                  <a style={{ cursor: "pointer" }} onClick={() => this.confirmDeleteSingleOrderMedia(media._id, media.order_id, media.user_id)}>
                                    <button className="btn-icon btn-icon-only btn-outline-2x btn btn-outline-danger">
                                      <i className="pe-7s-trash btn-icon-wrapper"> </i>
                                    </button>
                                  </a>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        </Row>
                      </Col>
                    ))
                  }
                </Row>
                <Row>
                  {this.state.orderMedia.length === 0 &&
                    <Col md="12">
                      <Row className="mt-2 mb-2 ps-1 pe-2">
                        <Card >
                          <CardBody className="text-center">
                            <h6>There are no Media items to display.</h6>
                          </CardBody>
                        </Card>
                      </Row>
                    </Col>
                  }
                </Row>
              </LoadingOverlay>
            </CardBody>
          </Card>
        </div>
      </Fragment >
    );
  }
}
