import React, { Fragment, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Input,
  FormGroup,
  Label,
} from "reactstrap";
import moment from "moment";
import { format, subDays } from "date-fns";

import CustomerService from "./Invoices.service";
import SettingsIcon from '@mui/icons-material/Settings';
import Icon from '@mui/material/Icon';
import Select from 'react-select';
import './EditableTable.css'; // Import your CSS file

import { Form, Field } from "react-final-form";

import FleetService from "./Customer.service";
import AuthService from "../../Authentication/auth.service";
import { useTranslation } from 'react-i18next';

import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../../components/Loaders/DnaLoader";
import "../../../assets/custom css/loadoverlay.scss";

import Alerts from "../../../components/Alerts/alerts";
import { Divider, Tooltip } from "@mui/material";


const AddFleet = () => {

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state.state;
  // const { check } = location.state;
  const check = location;

  console.log(state);
  console.log(check);

  console.log(state);
  const [loading, setLoading] = useState(false);
  const [rows, setrows] = useState([]);
  const [models, setModels] = useState([]);
  const [total_amount, setTotal_amount] = useState(0);
  const [subtotal, setSubtotal] = useState(0);
  const getCurrentDate = () => {
    return format(new Date(), "yyyy-MM-dd");
  };

  const getDefaultDueDate = (days) => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + days);
    return format(currentDate, "yyyy-MM-dd");
  };
  const [invoiceDate, setInvoiceDate] = useState(state.invoice_date ? moment(state.invoice_date).format('YYYY-MM-DDTHH:mm') : moment().format('YYYY-MM-DDTHH:mm'));
  const [DueDate, setDueDate] = useState(state.due_date ? moment(state.due_date).format('YYYY-MM-DD') : getDefaultDueDate(parseInt(localStorage.getItem("due_date"))));
  const [discount_Amount, setDiscount_Amount] = useState(0);
  const [amount, setAmount] = useState(0);
  const [consignment, setConsignment] = useState(state?.consignment);
  const [remarks, setRemarks] = useState(state?.remarks);
  const [po_no, setPo_no] = useState(state?.po_no);
  const [discount, setDiscount] = useState(state.discount ? state.discount : 0);
  const [selectedItem, setSelectedItem] = useState(null);
  const [newItem, setNewItem] = useState("");

  const [brands, setbrands] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedOrders, setSelectedOrder] = useState({
    value: state.order_no,
    label: state.order_no,
  });
  const [Orders, setOrders] = useState([]);
  const [orderDetails, setOrderDetails] = useState([]);
  const [Customers, setCustomers] = useState([]);
  const [Services, setServices] = useState([]);
  const [Packaging, setPackaging] = useState([]);
  // const [Packaging_size, setPackaging_size] = useState([]);

  const [selectedbrand, setselectedbrand] = useState("");
  const [generatedOrderNumber, setGeneratedOrderNumber] = useState('');


  const handleGenerateNumberOrder = () => {
    const uniqueNumber = generateUniqueNumberOrder();
    setGeneratedOrderNumber(uniqueNumber);
  };
  const generateUniqueNumberOrder = () => {
    const prefix = 'ORDER-';
    const randomNum = Math.floor(1000 + Math.random() * 9000); // Generate a 4-digit random number
    return `${prefix}${randomNum}`;
  };

  useEffect(() => {
    const foundCustomer = Customers.find(customer => customer.value === state.customer_id);
    if (foundCustomer) {
      setSelectedCustomer(foundCustomer);
    }
  }, [Customers]);


  const [generatedNumber, setGeneratedNumber] = useState(state.invoice_number ? state.invoice_number : 'INV-' + Math.floor(1000 + Math.random() * 9000));
  const generateUniqueNumber = () => {
    const prefix = 'INV-';
    const randomNum = Math.floor(1000 + Math.random() * 9000); // Generate a 4-digit random number
    return `${prefix}${randomNum}`;
  };
  const handleGenerateNumber = () => {
    const uniqueNumber = generateUniqueNumber();
    setGeneratedNumber(uniqueNumber);
  };

  const [data, setData] = useState(state.item ? state.item : []);
  // Define your select options
  useEffect(() => {
    // getOrderDetails();
  }, [selectedOrders, selectedCustomer]);

  const renderSelectOrders = ({ input, meta }) => (
    <div>
      <Select
        {...input}
        options={Orders}
        value={selectedOrders}
        onChange={(selectedOption) => {
          input.onChange(selectedOption);
          setSelectedOrder(selectedOption);
        }}
        isSearchable={true}
        placeholder="Select or Add an Order"
      />
      {meta.error && meta.touched && <span className="text-danger">{meta.error}</span>}
    </div>
  );
  const renderSelect = ({ input, meta }) => (
    <div>
      <Select
        {...input}
        options={Customers}
        value={selectedCustomer}
        onChange={(selectedOption) => {
          input.onChange(selectedOption);
          setSelectedCustomer(selectedOption);
          fetchOrders(selectedOption.value);
          fetchServices(selectedOption.value);
          fetchPackaging(selectedOption.value)
        }}
        isSearchable={true}
        placeholder="Select or Add a Customer"
        isDisabled={!selectedCustomer}
      />
      {meta.error && meta.touched && <span className="text-danger">{meta.error}</span>}
    </div>
  );


  const [editableRowIndex, setEditableRowIndex] = useState(null);

  const handleEditClick = (index) => {
    setEditableRowIndex(index);
  };

  const handleSaveClick = (index) => {
    setEditableRowIndex(null);
  };


  const handleAddRow = () => {
    const newData = [...data];
    const id = data.length + 1;
    newData.push({
      id, item_name: '', package: '',
      //  package_size: '',
      quantity: 1, weight: 1
    });
    setData(newData);

    // Set editableRowIndex to the index of the newly added item
    setEditableRowIndex(newData.length - 1);
  };

  const handleDeleteClick = (index) => {
    const newData = [...data];
    newData.splice(index, 1); // Remove the item at the specified index
    setData(newData);
    setEditableRowIndex(null); // Clear the editable row if deleted
  };

  const handleInputChange = (e, field, index) => {
    const newData = [...data];
    newData[index][field] = e.target.value;

    // Calculate the new tax amount
    newData[index]["tax"] = (parseFloat(newData[index].quantity) * parseFloat(newData[index].weight) * parseFloat(newData[index].rate) * parseFloat(localStorage.getItem('tax_percentage')) / 100);

    // Update the amount field
    newData[index]["amount"] = (parseFloat(newData[index].rate) * parseFloat(newData[index].weight) * parseFloat(newData[index].quantity)) + parseFloat(newData[index].tax);

    setData(newData);
  };

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    fetchCustomers();
    // fetchInvoiceDetails(state._id);
    fetchServices(state.customer_id);
    fetchPackaging(state.customer_id)
    fetchOrders(state.customer_id);
    // fetchPackaging_size();
    // handleGenerateNumber();
    // handleGenerateNumberOrder();
    getTotal();
  }, []);

  useEffect(() => {
    getTotal();
  }, [data, discount]);


  const [customerNotes, setCustomerNotes] = useState(state.comments ? state.comments : localStorage.getItem('default_notes'));

  function getOrderDetails() {
    var getData = {
      functionName: "GetOrder",
      postData: {
        "_id": selectedOrders.value,
        "sort": { _id: -1 },
        "offset": 0,
        "limit": 1000,
        "filter": {}
      },
    }
    CustomerService.getOrderDetails(getData).then((response) => {
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      } else {
        if (response.data && response.data.data) {
          console.log(response.data.data);
          const orderDetailsData = response.data.data;

          const result = orderDetailsData.map((order, orderIndex) => {
            return order.orderDetails.map((item, itemIndex) => ({
              item_name: order.services_details[itemIndex] ? order.services_details[itemIndex].name : '',
              package: order.packaging_details[itemIndex] ? order.packaging_details[itemIndex].name : '',
              // package_size: order.packaging_size_details[itemIndex] ? order.packaging_size_details[itemIndex].name : '',
              weight: item.weight ? item.weight : 0,
              quantity: item.quantity ? item.quantity : 0,
              rate: item.price ? item.price : 0,
              tax: (parseInt(item.quantity) * parseInt(item.weight) * parseInt(item.price) * parseInt(localStorage.getItem('tax_percentage')) / 100),
              amount: (parseInt(item.price) * parseInt(item.weight) * parseInt(item.quantity)) + parseInt((parseInt(item.quantity) * parseInt(item.price) * parseInt(localStorage.getItem('tax_percentage')) / 100))
            }));
          });


          // Flatten the nested arrays
          const flattenedResult = result.flat();

          console.log(flattenedResult);

          setData(flattenedResult);
          setOrderDetails(orderDetailsData); // Set the orderDetails here
        }
      }
    }, (error) => {
      const errContent =
        (error.response && error.response.data) || error.message || error.toString();
      if (errContent) {
        Alerts.swalErrorAlert(errContent);
      } else {
        Alerts.swalErrorAlert(t("Server Error, Please try again later."));
      }
    });
  }
  const handleCustomerNotesChange = (event) => {
    // Update the state variable when the user types in the textarea
    setCustomerNotes(event.target.value);
  };
  function getTotal() {
    const totalAmount = data.reduce((total, invoice) => {
      const amount = parseFloat(invoice.amount);
      return !isNaN(amount) ? total + amount : total;
    }, 0);
    // Parse the discount value to a float (assuming it's a percentage)
    const discountPercentage = parseFloat(discount) / 100;
    // Calculate the discount amount
    const discountAmount = totalAmount * discountPercentage;
    // Calculate the final amount after applying the discount
    const finalAmount = totalAmount - discountAmount;
    setDiscount_Amount(discountAmount);
    setTotal_amount(finalAmount);
    setSubtotal(parseFloat(totalAmount).toFixed(2));
  }
  function fetchServices(selectedCustomer) {
    var getData = {
      functionName: "getCustomerServices",
      postData: {
        "sort": { _id: -1 },
        "offset": 0,
        "limit": 1000,
        "filter": {},
        customer_id: selectedCustomer

      },
    }
    CustomerService.getCustomerServices(getData).then((response) => {
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        if (response.data) {
          // const data = transformDataForServices(response.data.data);
          setServices(response.data.data);
        }
      }
    },
      (error) => {
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  };
  function fetchPackaging(selectedCustomer) {
    var getData = {
      functionName: "GetCustomerPackaging",
      postData: {
        "sort": { _id: -1 },
        "offset": 0,
        "limit": 1000,
        "filter": {},
        customer_id: selectedCustomer

      },
    }
    CustomerService.GetCustomerPackaging(getData).then((response) => {
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        if (response.data) {
          // const data = transformDataForServices(response.data.data);
          setPackaging(response.data.data);
        }
      }
    },
      (error) => {
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  };

  function confirmBack() {
    let msg = t("Once Back, you will not be able to revert this Invoice!")
    Alerts.swalConfirmGeneralAlert(msg).then(res => {
      console.log("Resp:-", res)
      if (res === true) {
        navigate(-1)
      }
    })
  };


  // function fetchPackaging_size() {
  //   var getData = {
  //     functionName: "GetSizes",
  //     postData: {
  //       "sort": { _id: -1 },
  //       "offset": 0,
  //       "limit": 1000,
  //       "filter": {}
  //     },
  //   }
  //   CustomerService.getPackaging_size(getData).then((response) => {
  //     if (response.data.message === "Unauthorized") {
  //       AuthService.logout();
  //       navigate("/login");
  //       window.location.reload();
  //     }
  //     else {
  //       if (response.data) {
  //         // const data = transformDataForServices(response.data.data);
  //         setPackaging_size(response.data.data);
  //       }
  //     }
  //   },
  //     (error) => {
  //       const errContent =
  //         (error.response && error.response.data) || error.message || error.toString();
  //       if (errContent) {
  //         Alerts.swalErrorAlert(errContent)
  //       }
  //       else {
  //         Alerts.swalErrorAlert(t("Server Error, Please try again later."))
  //       }
  //     })
  // };

  function fetchInvoiceDetails(_id) {
    var getData = {
      functionName: "GetInvoice",
      postData: {
        "sort": { _id: -1 },
        "offset": 0,
        "limit": 1000,
        "filter": {}
      },
    }
    CustomerService.getAllCustomers(getData).then((response) => {
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        if (response.data) {
          const data = transformDataForSelect(response.data.data);
          setCustomers(data);
          data.map((item) => {
            if (item.value === state.customer_id) {
              setSelectedCustomer(item);
            }
          });
        }
      }
    },
      (error) => {
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  };
  function fetchCustomers() {
    var getData = {
      functionName: "GetCustomers",
      postData: {
        "sort": { _id: -1 },
        "offset": 0,
        "limit": 1000,
        "filter": {}
      },
    }
    CustomerService.getAllCustomers(getData).then((response) => {
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        if (response.data) {
          const data = transformDataForSelect(response.data.data);
          setCustomers(data);
        }
      }
    },
      (error) => {
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  };
  function fetchOrders(selectedCustomer) {
    var getData = {
      functionName: "getCustomerOrders",
      postData: {
        "sort": { _id: -1 },
        "offset": 0,
        "limit": 1000,
        "filter": {
          invoice_status: "false",
          user_id: selectedCustomer.value
        }
      },
    }
    CustomerService.getCustomerOrders(getData).then((response) => {
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        if (response.data) {
          console.log(response.data);
          const data = transformDataForSelectOrders(response.data.data);
          setOrders(data);
        }
      }
    },
      (error) => {
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  };
  const transformDataForSelect = (data) => {
    return data.map((item) => ({
      label: item.company_name,
      value: item._id,
    }));
  };
  const transformDataForSelectOrders = (data) => {
    return data.map((item) => ({
      label: item.order_no,
      value: item._id,
    }));
  };


  const onSubmit = values => {
    console.log(values);
    var payloadData = {
      functionName: "UpdateInvoice",
      _id: state._id,
      customer_id: selectedCustomer.value,
      order_no: selectedOrders.value,
      invoice_number: generatedNumber,
      invoice_date: invoiceDate,
      due_date: DueDate,
      item: data,
      sub_total: parseFloat(subtotal).toFixed(2),
      discount: discount,
      amount: parseFloat(amount).toFixed(2),
      total_amount: parseFloat(total_amount).toFixed(2),
      discount_amount: parseFloat(discount_Amount).toFixed(2),
      comments: customerNotes,
      currency: localStorage.getItem('currency'),
      paymentMethod: localStorage.getItem('payment_method'),
      po_no: po_no,
      consignment: consignment,
      remarks: remarks

    }
    console.log("Payload:-", payloadData)
    setLoading(true);
    CustomerService.UpdateInvoice(payloadData).then((response) => {
      console.log("API Response:-", response);
      setLoading(false);
      if (response.data.status) {
        if (check) {
          localStorage.setItem('tab5', "5");
        }
        navigate(-1)
        if (response.data.message !== '') {
          Alerts.swalSuccessAlert(response.data.message)
        }
        else {
          var payload = {
            functionName: "UpdateOrder",
            postData: {
              _id: selectedOrders.value,
              invoice_status: "true"
            },
          }
          console.log(payload);
          CustomerService.UpdateOrderStatus(payload).then((response) => {
            Alerts.swalSuccessAlert(t("Invoice updated successfully."));

          });
        }
      }
      else {
        if (response.data.message !== '') {
          if (response.data.message === "Unauthorized") {
            AuthService.logout();
            navigate("/login");
            window.location.reload();
          }
          else {
            Alerts.swalErrorAlert(response.data.message);
          }
        }
        else {
          Alerts.swalErrorAlert(t("Error: Can't add fleet at the moment. Please try again later."))
        }
      }
    },
      (error) => {
        setLoading(false);
        const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        Alerts.swalErrorAlert(t("Network Operation failed."))
      });
  }

  const validateFtn = (values) => {
    // console.log("values:-", values.brand_id)
    const errors = {};
    if (!selectedCustomer) {
      errors.customer_name = "Customer Required.";
    }
    // if (!discount) {
    //   errors.discount = "Discount is Required.";
    // }
    return errors;
  };



  return (
    <Fragment>
      <div className="app-main__outer">
        <div className="app-main__inner">
          <div className=" bg-light-gray bg-animation">
            <div className="d-flex  justify-content-center align-items-center">
              <Col md="12" className="mx-auto app-login-box">

                <div className="app-page-title">
                  <div className="page-title-wrapper">
                    <div className="page-title-heading fw-bold">
                      <div className="page-title-icon">
                        <i className="pe-7s-news-paper icon-gradient bg-tempting-azure" />
                      </div>
                      <div>
                        Edit Invoice
                        <div className="page-title-subheading">
                          {t('Update details of already Invoice against an order')}
                        </div>


                      </div>
                    </div>
                    <div className="page-title-actions">
                      <button type="button" onClick={() => {
                        if (check) {
                          localStorage.setItem('tab5', "5");
                        }
                        navigate(-1)
                      }} className="btn btn-outline-success btn-hover-shine me-3">
                        Back
                      </button>
                    </div>
                  </div>
                </div>

                <Card className="main-card mb-3">
                  <CardBody>
                    <Row>
                      <Col md="12" className="pt-3">
                        <LoadingOverlay tag="div" active={loading}
                          styles={{ overlay: (base) => ({ ...base }) }}
                          spinner={<DNALoader />}>
                          <Form onSubmit={onSubmit}
                            validate={validateFtn}
                            render={({ handleSubmit, values, submitting, validating, valid }) => (
                              <form onSubmit={handleSubmit}>
                                <Row>
                                  <Col md="12" >

                                    <Row style={{ marginBottom: '70px' }} className="align-items-center">
                                      <Col md="2"><Label className="pb-1">Customer Name</Label></Col>
                                      <Col md="8">
                                        <FormGroup>
                                          <Field name="customer_name" component={renderSelect}
                                            selectOptions={Customers.value} />
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                  </Col>

                                  <Col md="6">
                                    <Row className="align-items-center">
                                      <Col md="4"><Label className="pb-1">Invoice No. </Label></Col>
                                      <Col md="8">
                                        <FormGroup className="d-flex align-items-center">
                                          <Input type="text" value={generatedNumber} readOnly />
                                        </FormGroup>
                                      </Col>

                                    </Row>




                                  </Col>
                                  <Col md="4">

                                    <Row className="align-items-center">
                                      <Col md="4"><Label className="pb-1">Select Order </Label></Col>
                                      <Col md="8">
                                        <FormGroup className="d-flex align-items-center">
                                          <Field
                                            name="order"
                                            component={renderSelectOrders}
                                            selectOptions={Orders.value}
                                          />
                                        </FormGroup>
                                        {/* <Input type="text" value={generatedOrderNumber} readOnly /> */}
                                        {/* <SettingsIcon
                                            sx={{ cursor: 'pointer' }}
                                            onClick={handleGenerateNumberOrder}
                                            style={{ marginLeft: '10px' }}
                                          /> */}
                                      </Col>

                                    </Row>


                                  </Col>
                                  <Col md="6" >

                                    <Row className="align-items-center">
                                      <Col md="4"><Label className="pb-1">Invoice Date</Label></Col>
                                      <Col md="8">
                                        <FormGroup>
                                          <Field name="invoice_date">
                                            {({ input, meta }) => (
                                              <div>
                                                <Input
                                                  {...input}
                                                  type="datetime-local"
                                                  invalid={meta.error && meta.touched}
                                                  value={invoiceDate}
                                                  onChange={(e) => {
                                                    setInvoiceDate(e.target.value);
                                                    input.onChange(e);
                                                  }}
                                                />
                                                {meta.error && meta.touched && (
                                                  <span className="text-danger">{meta.error}</span>
                                                )}
                                              </div>
                                            )}
                                          </Field>
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                  </Col>

                                  <Col md="4" >
                                    <Row className="align-items-center">
                                      <Col md="4"><Label className="pb-1">Due Date</Label></Col>
                                      <Col md="8">
                                        <FormGroup>
                                          <Field name="due_date">
                                            {({ input, meta }) => (
                                              <div>
                                                <Input
                                                  {...input}
                                                  onChange={(e) => {
                                                    setDueDate(e.target.value);
                                                    input.onChange(e);
                                                  }}
                                                  type="date"
                                                  invalid={meta.error && meta.touched}
                                                  min={getCurrentDate()}
                                                  value={DueDate}
                                                />
                                                {meta.error && meta.touched && (
                                                  <span className="text-danger">{meta.error}</span>
                                                )}
                                              </div>
                                            )}
                                          </Field>
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col md="6" >
                                    <Row className="align-items-center">
                                      <Col md="4"><Label className="pb-1">Consignment No.</Label></Col>
                                      <Col md="8">
                                        <FormGroup>
                                          <Field name="consignment">
                                            {({ input, meta }) => (
                                              <div>
                                                <Input
                                                  {...input}
                                                  type="text"
                                                  invalid={meta.error && meta.touched}
                                                  value={consignment}
                                                  onChange={(e) => {
                                                    setConsignment(e.target.value);
                                                  }}
                                                />
                                                {meta.error && meta.touched && (
                                                  <span className="text-danger">{meta.error}</span>
                                                )}
                                              </div>
                                            )}
                                          </Field>
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col md="4" >
                                    <Row className="align-items-center">
                                      <Col md="4"><Label className="pb-1">PO No.</Label></Col>
                                      <Col md="8">
                                        <FormGroup>
                                          <Field name="po_no">
                                            {({ input, meta }) => (
                                              <div>
                                                <Input
                                                  {...input}
                                                  onChange={(e) => {
                                                    setPo_no(e.target.value);
                                                  }}
                                                  type="text"
                                                  invalid={meta.error && meta.touched}
                                                  value={po_no}
                                                />
                                                {meta.error && meta.touched && (
                                                  <span className="text-danger">{meta.error}</span>
                                                )}
                                              </div>
                                            )}
                                          </Field>
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col md="6" >
                                    <Row className="align-items-center">
                                      <Col md="4"><Label className="pb-1">Remarks</Label></Col>
                                      <Col md="8">
                                        <FormGroup>
                                          <Field name="remarks">
                                            {({ input, meta }) => (
                                              <div>
                                                <Input
                                                  {...input}
                                                  type="text"
                                                  invalid={meta.error && meta.touched}
                                                  value={remarks}
                                                  onChange={(e) => {
                                                    setRemarks(e.target.value);
                                                  }}
                                                />
                                                {meta.error && meta.touched && (
                                                  <span className="text-danger">{meta.error}</span>
                                                )}
                                              </div>
                                            )}
                                          </Field>
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                  </Col>

                                  <Divider sx={{ marginBottom: "20px", marginTop: "10px" }} />

                                  <div>
                                    <table className="custom-table">
                                      <thead>
                                        <tr>
                                          <th>Item</th>
                                          <th>Item Details</th>
                                          <th>Weight(KG)</th>
                                          <th>Quantity</th>
                                          <th>Price Per Unit</th>
                                          <th>{`Tax (${localStorage.getItem('tax_percentage')}%)`}</th>
                                          <th>Amount</th>
                                          <th>Actions</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {data.map((item, index) => (
                                          <tr key={item.id}>
                                            {/* <td>{item.id}</td> */}
                                            <td style={{ width: "15%" }}>
                                              {editableRowIndex === index ? (
                                                <FormGroup>
                                                  <Field name="item_name">
                                                    {({ input, meta }) => (
                                                      <div>
                                                        <>
                                                          <Input
                                                            type="text"
                                                            {...input}
                                                            value={item.item_name}
                                                            autoComplete="off"
                                                            placeholder="Select Service"
                                                            onChange={(e) => handleInputChange(e, 'item_name', index)}
                                                            list="servicesList"
                                                          />

                                                          <datalist id="servicesList">
                                                            {Services.map((option) => (
                                                              <option key={option.label} value={option.label}>
                                                                {option.name}
                                                              </option>
                                                            ))}
                                                          </datalist>
                                                        </>
                                                        {meta.error && meta.touched && (
                                                          <span className="text-danger">{meta.error}</span>
                                                        )}
                                                      </div>
                                                    )}
                                                  </Field>
                                                </FormGroup>

                                                // <Field name="item_name" component={renderSelectItem} selectOptions={Services.value} />

                                              ) : (
                                                item.item_name
                                              )}
                                            </td>
                                            <td style={{ width: "15%" }}>
                                              {editableRowIndex === index ? (
                                                <div>
                                                  <FormGroup style={{ width: "100%" }}>
                                                    <Field name="item_name">
                                                      {({ input, meta }) => (
                                                        <div>
                                                          <>
                                                            <Input
                                                              type="text"
                                                              {...input}
                                                              value={item.package}
                                                              autoComplete="off"
                                                              placeholder="Select package"
                                                              onChange={(e) => handleInputChange(e, 'package', index)}
                                                              list="packageList"
                                                            />

                                                            <datalist id="packageList">
                                                              {Packaging.map((option) => (
                                                                <option key={option.label} value={option.label}>
                                                                  {option.name}
                                                                </option>
                                                              ))}
                                                            </datalist>
                                                          </>
                                                          {meta.error && meta.touched && (
                                                            <span className="text-danger">{meta.error}</span>
                                                          )}
                                                        </div>
                                                      )}
                                                    </Field>
                                                  </FormGroup>
                                                  {/* <FormGroup style={{ width: "100%" }} >
                                                    <Field name="item_name">
                                                      {({ input, meta }) => (
                                                        <div>
                                                          <>
                                                            <Input
                                                              type="text"
                                                              {...input}
                                                              value={item.package_size}
                                                              autoComplete="off"
                                                              placeholder="Select package Size"
                                                              onChange={(e) => handleInputChange(e, 'package_size', index)}
                                                              list="package_size_List"
                                                            />

                                                            <datalist id="package_size_List">
                                                              {Packaging_size.map((option) => (
                                                                <option key={option.label} value={option.label}>
                                                                  {option.name}
                                                                </option>
                                                              ))}
                                                            </datalist>
                                                          </>
                                                          {meta.error && meta.touched && (
                                                            <span className="text-danger">{meta.error}</span>
                                                          )}
                                                        </div>
                                                      )}
                                                    </Field>
                                                  </FormGroup> */}
                                                </div>

                                              ) : (
                                                <div style={{ width: "100%" }}>
                                                  {`${item.package}`}
                                                </div>

                                              )}
                                            </td>
                                            <td>
                                              {editableRowIndex === index ? (
                                                <input
                                                  type="select"
                                                  value={item.weight}
                                                  onChange={(e) => handleInputChange(e, 'weight', index)}
                                                />
                                              ) : (
                                                item.weight
                                              )}
                                            </td>
                                            <td>
                                              {editableRowIndex === index ? (
                                                <input
                                                  type="select"
                                                  value={item.quantity}
                                                  onChange={(e) => handleInputChange(e, 'quantity', index)}
                                                />
                                              ) : (
                                                item.quantity
                                              )}
                                            </td>
                                            <td>
                                              {editableRowIndex === index ? (
                                                <input
                                                  type="number"
                                                  value={parseFloat(item.rate).toFixed(2)}
                                                  onChange={(e) => handleInputChange(e, 'rate', index)}
                                                />
                                              ) : (
                                                parseFloat(item.rate).toFixed(2)
                                              )}
                                            </td>
                                            <td>
                                              {editableRowIndex === index ? (
                                                <input
                                                  type="number"
                                                  value={parseFloat(item.tax).toFixed(2)}
                                                  onChange={(e) => handleInputChange(e, 'tax', index)}
                                                />
                                              ) : (
                                                parseFloat(item.tax).toFixed(2)
                                              )}
                                            </td>
                                            <td>
                                              {editableRowIndex === index ? (
                                                <input
                                                  disabled
                                                  type="number"
                                                  value={parseFloat(item.amount).toFixed(2)}
                                                  onChange={(e) => handleInputChange(e, 'amount', index)}
                                                />
                                              ) : (
                                                parseFloat(item.amount).toFixed(2)
                                              )}
                                            </td>
                                            <td>
                                              {editableRowIndex === index ? (
                                                <Tooltip placement="top" title="Save" arrow>
                                                  <i onClick={() => handleSaveClick(index)} className="pe-7s-edit btn-icon-wrapper text-info"> </i>
                                                </Tooltip>
                                              ) : (
                                                <>
                                                  <Tooltip placement="top" title="Edit" arrow>
                                                    <i onClick={() => handleEditClick(index)} className="pe-7s-pen btn-icon-wrapper text-info"> </i>
                                                  </Tooltip>
                                                  <Tooltip placement="top" title="Delete" arrow>
                                                    <i onClick={() => handleDeleteClick(index)} className="pe-7s-trash text-danger btn-icon-wrapper"> </i>
                                                  </Tooltip>
                                                </>)}
                                            </td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    </table>
                                  </div>

                                  <Col md="12" >
                                    <Row className="align-items-center">
                                      <Col md="6">
                                        <button type="button" onClick={handleAddRow} className="btn btn-secondary btn-lg btn-hover-shine me-3">
                                          Add New Item
                                        </button>
                                      </Col>
                                    </Row>
                                  </Col>




                                  <Col md="12" >
                                    <Row className="align-items-center">
                                      <Col md="8">
                                        <Label style={{ marginLeft: '15px', marginTop: '20px', fontSize: "16px", fontWeight: "700" }} className="pb-1">Subtotal</Label>
                                      </Col>

                                      <Col style={{ fontSize: "16px", fontWeight: "700" }} md="3"><Label className="pb-1">{parseFloat(subtotal).toFixed(2)}</Label></Col>
                                    </Row>
                                  </Col>
                                  <Col md="12" >
                                    <Row className="align-items-center">
                                      <Col md="6">
                                        <Label style={{ marginLeft: '15px', fontSize: "16px", fontWeight: "700" }} className="pb-1">Discount</Label>
                                      </Col>


                                      <Col style={{ fontSize: "16px", fontWeight: "700" }} md="2">
                                        <FormGroup>
                                          <Field name="discount">
                                            {({ input, meta }) => (
                                              <div>
                                                <Input
                                                  {...input}
                                                  type="text"

                                                  value={discount} // Set the value from the state variable
                                                  onChange={(e) => {
                                                    setDiscount(e.target.value); // Update the state variable
                                                    input.onChange(e); // Notify the Field component of the change
                                                  }}
                                                  invalid={meta.error && meta.touched}
                                                />
                                                {meta.error && meta.touched && (
                                                  <span className="text-danger">{meta.error}</span>
                                                )}
                                              </div>
                                            )}
                                          </Field>
                                        </FormGroup>

                                      </Col>
                                      <Col style={{ fontSize: "16px", fontWeight: "700" }} md="3"><Label className="pb-1">{parseFloat(discount_Amount).toFixed(2)}</Label></Col>
                                    </Row>

                                  </Col>



                                  <Col md="12" >
                                    <Row className="align-items-center">
                                      <Col style={{ fontSize: "16px", fontWeight: "700" }} md="9">
                                        <Divider style={{ marginTop: "5px", marginBottom: "15px" }} />
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                                <Col md="12" >
                                  <Row className="align-items-center">
                                    <Col style={{ fontSize: "16px", fontWeight: "700" }} md="8"><Label className="pb-1">Total (${localStorage.getItem('currency')})</Label>
                                    </Col>

                                    <Col style={{ fontSize: "16px", fontWeight: "700" }} md="1"><Label className="pb-1">{parseFloat(total_amount).toFixed(2)}</Label></Col>
                                  </Row>

                                </Col>
                                <Col md="12" >
                                  <Row className="align-items-center">
                                    <Col style={{ fontSize: "16px", fontWeight: "700" }} md="9">
                                      <Divider style={{ marginTop: "5px", marginBottom: "15px" }} />
                                    </Col>
                                  </Row>
                                </Col>
                                <Col md="12" >
                                  <Row className="align-items-center">
                                    <Col md="10">
                                      <Label style={{ marginTop: '20px' }} className="pb-1">Customers Notes</Label>
                                      <FormGroup  >
                                        <textarea
                                          name="customer_notes"
                                          style={{ width: '90%', height: '90px' }}
                                          placeholder="Customer Notes"
                                          value={customerNotes} // Bind the value to the state variable
                                          onChange={handleCustomerNotesChange} // Update the state when the user types
                                        />
                                        {/* You can display an error message if needed
                                          {meta.error && meta.touched && (
                                            <span className="text-danger">{meta.error}</span>
                                          )} */}
                                      </FormGroup>
                                      <Label className="pb-1">Will be Displayed on Invoice</Label>
                                    </Col>
                                  </Row>
                                </Col>

                                <Row style={{ marginTop: "20px" }}>
                                  <FormGroup>
                                    <div className="text-center">
                                      <Button type="submit" className="btn btn-secondary btn-lg btn-hover-shine me-3">
                                        Update
                                      </Button>
                                      <button type="button" onClick={() => {
                                        if (check) {
                                          localStorage.setItem('tab5', "5");
                                        }
                                        confirmBack();
                                      }} className="btn btn-outline-success btn-lg btn-hover-shine me-3">
                                        Cancel
                                      </button>
                                    </div>
                                  </FormGroup>
                                </Row>
                              </form>
                            )}
                          />
                        </LoadingOverlay>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </div>

          </div>
        </div>
      </div>
    </Fragment >
  );

};


export default AddFleet;


// import React, { Fragment, useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
// import {
//   Row,
//   Col,
//   Card,
//   CardBody,
//   Input,
//   FormGroup,
//   Label,
// } from "reactstrap";
// import CustomerService from "../../../pages/Edsha/Invoices/Invoices.service";
// import SettingsIcon from '@mui/icons-material/Settings';
// import Icon from '@mui/material/Icon';
// import Select from 'react-select';
// import './EditableTable.css'; // Import your CSS file

// import { Form, Field } from "react-final-form";

// import FleetService from "./Customer.service";
// import AuthService from "../../Authentication/auth.service";
// import { useTranslation } from 'react-i18next';

// import LoadingOverlay from "react-loading-overlay-ts";
// import DNALoader from "../../../components/Loaders/DnaLoader";
// import "../../../assets/custom css/loadoverlay.scss";

// import Alerts from "../../../components/Alerts/alerts";
// import { Divider } from "@mui/material";


// const AddFleet = () => {

//   const { t, i18n } = useTranslation();
//   const navigate = useNavigate();
//   const [loading, setLoading] = useState(false);
//   const [rows, setrows] = useState([]);
//   const [models, setModels] = useState([]);
//   const [brands, setbrands] = useState([]);
//   const [Customers, setCustomers] = useState([]);
//   const [selectedbrand, setselectedbrand] = useState("");
//   const [generatedNumber, setGeneratedNumber] = useState('');
//   const generateUniqueNumber = () => {
//     const prefix = 'INV-';
//     const randomNum = Math.floor(1000 + Math.random() * 9000); // Generate a 4-digit random number
//     return `${prefix}${randomNum}`;
//   };
//   const handleGenerateNumber = () => {
//     const uniqueNumber = generateUniqueNumber();
//     setGeneratedNumber(uniqueNumber);
//   };

//   const [data, setData] = useState([
//     { id: 1, name: 'E-Waste', item_details: 'Large Drum', quantity: "2.0", rate: '1200', tax: '5%', amount: '2400' },
//     { id: 2, name: 'New Item', item_details: 'Item Details here', quantity: "2.0", rate: '1200', tax: '5%', amount: '2400' },
//     { id: 2, name: 'New Item 2', item_details: 'Item Details here', quantity: "2.0", rate: '1200', tax: '5%', amount: '2400' },
//     { id: 2, name: 'New Item 3', item_details: 'Item Details here', quantity: "2.0", rate: '1200', tax: '5%', amount: '2400' },

//   ]);
//   // Define your select options
//   const selectOptions = [
//     { v: 'option1', pic_name: 'Ihtesham Mansoor' },
//     { v: 'option2', pic_name: 'Hassam' },
//     { v: 'option3', pic_name: 'Arsalan' },
//     // Add more options as needed
//   ];

//   const renderSelect = ({ input, meta }) => (
//     <div>
//       <Select
//         {...input}
//         options={Customers}
//         value={Customers.value}
//         isSearchable={true}
//         placeholder="Select or Add a Customer"
//       />
//       {meta.error && meta.touched && <span className="text-danger">{meta.error}</span>}
//     </div>
//   );

//   console.log(data);
//   const [editableRowIndex, setEditableRowIndex] = useState(null);

//   const handleEditClick = (index) => {
//     setEditableRowIndex(index);
//   };

//   const handleSaveClick = (index) => {
//     setEditableRowIndex(null);
//   };

//   const handleAddRow = () => {
//     const newData = [...data];
//     const id = data.length + 1;
//     newData.push({ id, name: 'New Item', description: 'New Description' });
//     setData(newData);
//   };

//   const handleInputChange = (e, field, index) => {
//     const newData = [...data];
//     newData[index][field] = e.target.value;
//     setData(newData);
//   };

//   useEffect(() => {
//     i18n.changeLanguage(localStorage.getItem('language'));
//     fetchCustomers();
//     handleGenerateNumber();
//     // getModels();
//     // getBrands();
//   }, []);

//   function fetchCustomers() {
//     var getData = {
//       functionName: "GetCustomers",
//       postData: {
//         "sort": { _id: -1 },
//         "offset": 0,
//         "limit": 1000,
//         "filter": {}
//       },
//     }
//     CustomerService.getAllCustomers(getData).then((response) => {
//       if (response.data.message === "Unauthorized") {
//         AuthService.logout();
//         navigate("/login");
//         window.location.reload();
//       }
//       else {
//         console.log(response.data.data);
//         if (response.data) {
//           const data = transformDataForSelect(response.data.data);
//           console.log(data);
//           setCustomers(data);
//         }
//       }
//     },
//       (error) => {
//         const errContent =
//           (error.response && error.response.data) || error.message || error.toString();
//         if (errContent) {
//           Alerts.swalErrorAlert(errContent)
//         }
//         else {
//           Alerts.swalErrorAlert(t("Server Error, Please try again later."))
//         }
//       })
//   };

//   const transformDataForSelect = (data) => {
//     return data.map((item) => ({
//       label: item.pic_name,
//       value: item._id,
//     }));
//   };

//   function getBrands() {
//     setLoading(true);
//     FleetService.getBrands().then((response) => {
//       setLoading(false);
//       console.log("Get Brands response:-", response)
//       if (response.data.message === "Unauthorized") {
//         AuthService.logout();
//         navigate("/login");
//         window.location.reload();
//       }
//       else {
//         setbrands(response.data.data);
//       }
//     },
//       (error) => {
//         setLoading(false);
//         const errContent =
//           (error.response && error.response.data) || error.message || error.toString();
//         console.log("Net:-", errContent)
//         if (errContent) {
//           Alerts.swalErrorAlert(errContent)
//         }
//         else {
//           Alerts.swalErrorAlert(t("Server Error, Please try again later."))
//         }
//       })
//   };


//   function gotBrand(e) {
//     console.log(e.target.value);
//     setselectedbrand(e.target.value)
//     getModels(e.target.value)
//   }


//   function getModels(brand_id) {
//     setLoading(true);
//     FleetService.getModels(brand_id).then((response) => {
//       setLoading(false);
//       console.log("Get Models response:-", response)
//       if (response.data.message === "Unauthorized") {
//         AuthService.logout();
//         navigate("/login");
//         window.location.reload();
//       }
//       else {
//         setModels(response.data.data);
//       }
//     },
//       (error) => {
//         setLoading(false);
//         const errContent =
//           (error.response && error.response.data) || error.message || error.toString();
//         console.log("Net:-", errContent)
//         if (errContent) {
//           Alerts.swalErrorAlert(errContent)
//         }
//         else {
//           Alerts.swalErrorAlert(t("Server Error, Please try again later."))
//         }
//       })
//   };


//   const onSubmit = values => {
//     console.log(values);
//     var payloadData = {
//       functionName: "AddFleet",
//       postData: {
//         name: values.name,
//         brand_id: selectedbrand,
//         model_id: values.model_id,
//         manufacturing_year: values.manufacturing_year,
//         reg_no: values.reg_no,
//         color: values.color,
//         width: values.width,
//         length: values.length,
//         height: values.height,
//         loadcap: values.loadcap
//       }
//     }
//     console.log("Payload:-", payloadData)
//     setLoading(true);
//     FleetService.addFleet(payloadData).then((response) => {
//       console.log("API Response:-", response);
//       setLoading(false);
//       if (response.data.status) {
//         navigate("/fleet-list")
//         if (response.data.message !== '') {
//           Alerts.swalSuccessAlert(response.data.message)
//         }
//         else {
//           Alerts.swalSuccessAlert(t("Fleet added successfully."));
//         }
//       }
//       else {
//         if (response.data.message !== '') {
//           if (response.data.message === "Unauthorized") {
//             AuthService.logout();
//             navigate("/login");
//             window.location.reload();
//           }
//           else {
//             Alerts.swalErrorAlert(response.data.message);
//           }
//         }
//         else {
//           Alerts.swalErrorAlert(t("Error: Can't add fleet at the moment. Please try again later."))
//         }
//       }
//     },
//       (error) => {
//         setLoading(false);
//         const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
//         Alerts.swalErrorAlert(t("Network Operation failed."))
//       });
//   }

//   const validateFtn = (values) => {
//     // console.log("values:-", values.brand_id)
//     const errors = {};
//     if (!values.name) {
//       errors.name = "Fleet Name Required.";
//     }
//     if (!values.model_id) {
//       errors.model_id = "Please select Model.";
//     }
//     if (!selectedbrand) {
//       errors.brand_id = "Please select Brand.";
//     }
//     if (!values.color) {
//       errors.color = "Color Required.";
//     }
//     if (!values.width) {
//       errors.width = "Container Width Required.";
//     }
//     if (!values.reg_no) {
//       errors.reg_no = "Registration No Required.";
//     }
//     if (!values.height) {
//       errors.height = "Container Height Required.";
//     }
//     if (!values.manufacturing_year) {
//       errors.manufacturing_year = "Manufacturing Year Required.";
//     }
//     if (!values.length) {
//       errors.length = "Container Length Required.";
//     }
//     if (!values.loadcap) {
//       errors.loadcap = "Loading Capacity Required.";
//     }
//     return errors;
//   };



//   return (
//     <Fragment>
//       <div className="app-main__outer">
//         <div className="app-main__inner">
//           <div className=" bg-light-gray bg-animation">
//             <div className="d-flex  justify-content-center align-items-center">
//               <Col md="12" className="mx-auto app-login-box">

//                 <div className="app-page-title">
//                   <div className="page-title-wrapper">
//                     <div className="page-title-heading fw-bold">
//                       <div className="page-title-icon">
//                         <i className="lnr-bus icon-gradient bg-tempting-azure" />
//                       </div>
//                       <div>
//                         New Invoice
//                       </div>
//                     </div>
//                     <div className="page-title-actions">
//                       <button type="button" onClick={() => navigate(-1)} className="btn btn-outline-success btn-hover-shine me-3">
//                         Back
//                       </button>
//                     </div>
//                   </div>
//                 </div>

//                 <Card className="main-card mb-3">
//                   <CardBody>
//                     <Row>
//                       <Col md="12" className="pt-3">
//                         <LoadingOverlay tag="div" active={loading}
//                           styles={{ overlay: (base) => ({ ...base }) }}
//                           spinner={<DNALoader />}>
//                           <Form onSubmit={onSubmit}
//                             validate={validateFtn}
//                             render={({ handleSubmit, values, submitting, validating, valid }) => (
//                               <form onSubmit={handleSubmit}>
//                                 <Row>
//                                   <Col md="12" >

//                                     <Row style={{ marginBottom: '70px' }} className="align-items-center">
//                                       <Col md="2"><Label className="pb-1">Customer Name</Label></Col>
//                                       <Col md="8">
//                                         <FormGroup>
//                                           <Field name="name" component={renderSelect} selectOptions={Customers.value} />
//                                           {/* <Field name="name">
//                                             {({ input, meta }) => (
//                                               <div>
//                                                 <Input {...input} type="select" placeholder="Select or Add a Customer"
//                                                   invalid={meta.error && meta.touched}
//                                                 />
//                                                 {meta.error && meta.touched &&
//                                                   <span className="text-danger">{meta.error}</span>}
//                                               </div>
//                                             )}
//                                           </Field> */}
//                                         </FormGroup>
//                                       </Col>
//                                     </Row>
//                                   </Col>

//                                   <Col md="12">


//                                     {/* onChange={event => gotBrand(event)} */}
//                                     <Row className="align-items-center">
//                                       <Col md="2"><Label className="pb-1">Invoice # </Label></Col>
//                                       <Col md="4">
//                                         <FormGroup className="d-flex align-items-center">
//                                           <Input type="text" value={generatedNumber} readOnly />
//                                           <SettingsIcon
//                                             sx={{ cursor: 'pointer' }}
//                                             onClick={handleGenerateNumber}
//                                             style={{ marginLeft: '10px' }}
//                                           />
//                                         </FormGroup>
//                                       </Col>

//                                     </Row>

//                                     {/* <Field name="brand_id" >
//                                             {({ input, meta }) => (
//                                               <div>
//                                                 <Input {...input} type="text"
//                                                   invalid={meta.error && meta.touched}
//                                                   onChange={event => gotBrand(event)}
//                                                 >
//                                                   <option disabled={false} value="">Brand</option>
//                                                   {brands &&
//                                                     brands.map((brand, inde) => (
//                                                       <option key={inde} value={brand._id}>{brand.name}</option>
//                                                     ))
//                                                   } 
//                                                 </Input>
//                                                 {meta.error && meta.touched &&
//                                                   <span className="text-danger">{meta.error}</span>}
//                                               </div>
//                                             )}
//                                           </Field> */}


//                                     <Row className="align-items-center">
//                                       <Col md="2"><Label className="pb-1">Order No.</Label></Col>
//                                       <Col md="4">
//                                         <FormGroup>
//                                           <Field name="color">
//                                             {({ input, meta }) => (
//                                               <div>
//                                                 <Input {...input} type="text"
//                                                   invalid={meta.error && meta.touched}
//                                                 />
//                                                 {meta.error && meta.touched &&
//                                                   <span className="text-danger">{meta.error}</span>}
//                                               </div>
//                                             )}
//                                           </Field>
//                                         </FormGroup>
//                                       </Col>
//                                     </Row>
//                                   </Col>

//                                   <Col md="6" >

//                                     <Row className="align-items-center">
//                                       <Col md="4"><Label className="pb-1">Invoice Date</Label></Col>
//                                       <Col md="8">
//                                         <FormGroup>
//                                           <Field name="width">
//                                             {({ input, meta }) => (
//                                               <div>
//                                                 <Input {...input} type="date"
//                                                   invalid={meta.error && meta.touched}
//                                                 />
//                                                 {meta.error && meta.touched &&
//                                                   <span className="text-danger">{meta.error}</span>}
//                                               </div>
//                                             )}
//                                           </Field>
//                                         </FormGroup>
//                                       </Col>
//                                     </Row>
//                                   </Col>
//                                   {/* <Col md="3" >
//                                     <Row className="align-items-center">
//                                       <Col md="4"><Label className="pb-1">Terms</Label></Col>
//                                       <Col md="8">
//                                         <FormGroup>
//                                           <Field name="height">
//                                             {({ input, meta }) => (
//                                               <div>
//                                                 <Input {...input} type="text"
//                                                   invalid={meta.error && meta.touched}
//                                                 />
//                                                 {meta.error && meta.touched &&
//                                                   <span className="text-danger">{meta.error}</span>}
//                                               </div>
//                                             )}
//                                           </Field>
//                                         </FormGroup>
//                                       </Col> */}
//                                     {/* </Row> */}
//                                   {/* </Col> */}

//                                   <Col md="4" >
//                                     <Row className="align-items-center">
//                                       <Col md="4"><Label className="pb-1">Due Date</Label></Col>
//                                       <Col md="8">
//                                         <FormGroup>
//                                           <Field name="height">
//                                             {({ input, meta }) => (
//                                               <div>
//                                                 <Input {...input} type="date"
//                                                   invalid={meta.error && meta.touched}
//                                                 />
//                                                 {meta.error && meta.touched &&
//                                                   <span className="text-danger">{meta.error}</span>}
//                                               </div>
//                                             )}
//                                           </Field>
//                                         </FormGroup>
//                                       </Col>
//                                     </Row>
//                                   </Col>
//                                   <Divider sx={{ marginBottom: "20px", marginTop: "10px" }} />

//                                   <div>
//                                     <table className="custom-table">
//                                       <thead>
//                                         <tr>
//                                           <th>Item</th>
//                                           <th>Item Details</th>
//                                           <th>Quantity</th>
//                                           <th>Price Per Unit</th>
//                                           <th>Tax (%)</th>
//                                           <th>Amount</th>
//                                           <th>Actions</th>
//                                         </tr>
//                                       </thead>
//                                       <tbody>
//                                         {data.map((item, index) => (
//                                           <tr key={item.id}>
//                                             {/* <td>{item.id}</td> */}
//                                             <td>
//                                               {editableRowIndex === index ? (
//                                                 <input
//                                                   type="text"
//                                                   value={item.name}
//                                                   onChange={(e) => handleInputChange(e, 'name', index)}
//                                                 />
//                                               ) : (
//                                                 item.name
//                                               )}
//                                             </td>
//                                             <td>
//                                               {editableRowIndex === index ? (
//                                                 <input
//                                                   type="text"
//                                                   value={item.item_details}
//                                                   onChange={(e) => handleInputChange(e, 'item_details', index)}
//                                                 />
//                                               ) : (
//                                                 item.item_details
//                                               )}
//                                             </td>

//                                             <td>
//                                               {editableRowIndex === index ? (
//                                                 <input
//                                                   type="text"
//                                                   value={item.quantity}
//                                                   onChange={(e) => handleInputChange(e, 'quantity', index)}
//                                                 />
//                                               ) : (
//                                                 item.quantity
//                                               )}
//                                             </td>
//                                             <td>
//                                               {editableRowIndex === index ? (
//                                                 <input
//                                                   type="text"
//                                                   value={item.rate}
//                                                   onChange={(e) => handleInputChange(e, 'rate', index)}
//                                                 />
//                                               ) : (
//                                                 item.rate
//                                               )}
//                                             </td>
//                                             <td>
//                                               {editableRowIndex === index ? (
//                                                 <input
//                                                   type="text"
//                                                   value={item.tax}
//                                                   onChange={(e) => handleInputChange(e, 'tax', index)}
//                                                 />
//                                               ) : (
//                                                 item.tax
//                                               )}
//                                             </td>
//                                             <td>
//                                               {editableRowIndex === index ? (
//                                                 <input
//                                                   type="text"
//                                                   value={item.amount}
//                                                   onChange={(e) => handleInputChange(e, 'amount', index)}
//                                                 />
//                                               ) : (
//                                                 item.amount
//                                               )}
//                                             </td>
//                                             <td>
//                                               {editableRowIndex === index ? (
//                                                 <button onClick={() => handleSaveClick(index)}>Save</button>
//                                               ) : (
//                                                 <button onClick={() => handleEditClick(index)}>Edit</button>
//                                               )}
//                                             </td>
//                                           </tr>
//                                         ))}
//                                       </tbody>
//                                     </table>
//                                   </div>

//                                   <Col md="12" >
//                                     <Row className="align-items-center">
//                                       <Col md="6">
//                                         <button onClick={handleAddRow} className="btn btn-secondary btn-lg btn-hover-shine me-3">
//                                           Add New Item </button>
//                                       </Col>

//                                       <Col style={{ fontSize: "16px", fontWeight: "700" }} md="5"><Label className="pb-1">Subtotal</Label></Col>
//                                       <Col style={{ fontSize: "16px", fontWeight: "700" }} md="1"><Label className="pb-1">1000</Label></Col>
//                                     </Row>
//                                   </Col>

//                                   <Col md="12" >
//                                     <Row className="align-items-center">
//                                       <Col md="6">
//                                       </Col>

//                                       <Col style={{ fontSize: "16px", fontWeight: "700" }} md="2"><Label className="pb-1">Discount</Label>
//                                       </Col>

//                                       <Col style={{ fontSize: "16px", fontWeight: "700" }} md="2">
//                                         <FormGroup>
//                                           <Field name="loadcap">
//                                             {({ input, meta }) => (
//                                               <div>
//                                                 <Input {...input} type="text"
//                                                   invalid={meta.error && meta.touched}
//                                                 />
//                                                 {meta.error && meta.touched &&
//                                                   <span className="text-danger">{meta.error}</span>}
//                                               </div>
//                                             )}
//                                           </Field>
//                                         </FormGroup>

//                                       </Col>
//                                       <Col style={{ marginLeft: "105px", fontSize: "16px", fontWeight: "700" }} md="1"><Label className="pb-1">200</Label></Col>
//                                     </Row>

//                                   </Col>



//                                   <Col md="12" >
//                                     <Row className="align-items-center">
//                                       <Col md="6">
//                                       </Col>
//                                       <Col style={{ fontSize: "16px", fontWeight: "700" }} md="6">
//                                         <Divider style={{ marginTop: "5px", marginBottom: "15px" }} />
//                                       </Col>
//                                     </Row>
//                                   </Col>
//                                 </Row>
//                                 <Col md="12" >
//                                   <Row className="align-items-center">
//                                     <Col md="6">
//                                     </Col>

//                                     <Col style={{ fontSize: "16px", fontWeight: "700" }} md="4"><Label className="pb-1">Total (MYR)</Label>
//                                     </Col>
//                                     <Col style={{ marginLeft: "105px", fontSize: "16px", fontWeight: "700" }} md="1"><Label className="pb-1">21000</Label></Col>
//                                   </Row>

//                                 </Col>


//                                 <Col md="12" >
//                                   <Row className="align-items-center">
//                                     <Col md="10">
//                                       <Label className="pb-1">Customers Notes</Label>
//                                       <FormGroup  >
//                                         <textarea style={{ width: '90%', height: "90px" }} name="loadcap">
//                                           {({ input, meta }) => (
//                                             <div>
//                                               <Input {...input} type="text"
//                                                 invalid={meta.error && meta.touched}
//                                                 placeholder="Customer Notes"
//                                               />
//                                               {meta.error && meta.touched &&
//                                                 <span className="text-danger">{meta.error}</span>}
//                                             </div>
//                                           )}
//                                         </textarea>
//                                       </FormGroup>
//                                       <Label className="pb-1">Will be Displayed on Invoice</Label>
//                                     </Col>
//                                   </Row>
//                                 </Col>

//                                 <Row style={{ marginTop: "20px" }}>
//                                   <FormGroup>
//                                     <div className="text-center">
//                                       <button type="submit" className="btn btn-secondary btn-lg btn-hover-shine me-3">
//                                         Save </button>
//                                       <button type="button" onClick={() => navigate(-1)} className="btn btn-outline-success btn-lg btn-hover-shine me-3">
//                                         Cancel
//                                       </button>
//                                     </div>
//                                   </FormGroup>
//                                 </Row>
//                               </form>
//                             )}
//                           />
//                         </LoadingOverlay>
//                       </Col>
//                     </Row>
//                   </CardBody>
//                 </Card>
//               </Col>
//             </div>

//           </div>
//         </div>
//       </div>
//     </Fragment>
//   );

// };


// export default AddFleet;
