import React, { useEffect, useState, Fragment } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate, Link } from "react-router-dom";
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Row, Col, Card, CardBody } from "reactstrap";
import Tooltip from '@mui/material/Tooltip';

import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import "../../assets/custom css/loadoverlay.scss";

import GenFilter from "../../components/Table Filters/GenFilter";
import DataTable from 'react-data-table-component';

import Alerts from "../../components/Alerts/alerts";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faLockOpen, faLock, faKey } from "@fortawesome/free-solid-svg-icons";

import UserService from "../../pages/Users Management/users.service";
import AuthService from "../Authentication/auth.service";
import RoleService from "../Roles/roles.service";

library.add(
  faLockOpen,
  faLock,
  faKey
);


const UsersList = () => {

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);


  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    getusers();
    getroles();
  }, [])

  function getusers() {
    setLoading(true);
    UserService.getAllUsers().then((response) => {
      setLoading(false);
      console.log("Get Users response:-", response)
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        setUsers(response.data.data);
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      },
    )
  };

  const getroles = async () => {
    try {
      const response = await RoleService.getAll();
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        const data = await response.data.data;
        setRoles(data);
        console.log("Roles:-", data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const TBcolumns = [
    {
      name: t('Users'),
      width: '17%',
      selector: row => row.name,
    },
    {
      name: t('Role'),
      width: '13%',
      selector: (row) => {
        for (let i = 0; i < roles.length; i++) {
          if (row.role_id === roles[i]._id) {
            return (
              <div>
                {roles[i].name}
              </div>
            );
          }
        }
      }
    },
    {
      name: t('Email'),
      width: '25%',
      selector: row => row.email,
    },
    {
      name: t('Contact #'),
      width: '15%',
      selector: row => row.contact,
    },
    {
      name: t('Current Status'),
      width: '15%',
      selector: (row) => {
        return (
          <div>
            {row.status === "1" &&
              <button type="button"
                className="btn btn-success btn-hover-shine me-3 btn">{t('Active')}
              </button>
            }
            {row.status === "0" &&
              <button type="button"
                className="btn btn-danger btn-hover-shine me-3 btn">{t('Deactivated')}
              </button>
            }
          </div>
        );
      }
    },
    {
      name: t('Actions'),
      width: '15%',
      selector: (row) => {
        return (
          <div>
            <Tooltip placement="top" title="Edit" arrow>
              <Link to="/update-user" state={row} className="EditData">
                <i className="pe-7s-pen icon-gradient bg-arielle-smile btn-lg px-1"> </i>
              </Link>
            </Tooltip >

            {row.status === "1" &&
              <Tooltip placement="top" title="Change Status" arrow>
                <a onClick={() => changestatus(row)} style={{ cursor: "pointer" }} className="text-info btn-lg px-1">
                  <FontAwesomeIcon icon={["fas", "lock-open"]} style={{ marginBottom: "2px" }} />
                </a>
              </Tooltip >
            }
            {row.status === "0" &&
              <Tooltip placement="top" title="Change Status" arrow>
                <a onClick={() => changestatus(row)} style={{ cursor: "pointer" }} className=" text-danger btn-lg px-1">
                  <FontAwesomeIcon icon={["fas", "lock"]} style={{ marginBottom: "2px" }} />
                </a>
              </Tooltip >
            }
            <Tooltip placement="top" title="Delete" arrow>
              <a className="DeletetData" onClick={() => confirmDelete(row._id)} style={{ cursor: "pointer" }}>
                <i className="pe-7s-trash icon-gradient bg-ripe-malin btn-lg px-1"> </i>

              </a>
            </Tooltip >

            <Tooltip placement="top" title="Change Password" arrow>

              <a onClick={() => regeneratePassword(row._id)} style={{ cursor: "pointer" }} className="text-warning btn-sm px-1 py-0">
                {/* <i className="pe-7s-key icon-gradient bg-grow-early btn-lg px-1 py-0"> </i> */}
                <FontAwesomeIcon icon={["fas", "key"]} style={{ marginBottom: "2px" }} />
              </a>
            </Tooltip >

          </div>
        );
      }
    },
  ];

  const [filterText, setFilterText] = React.useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  const filteredItems = users.filter(
    item => item.name && item.name.toLowerCase().includes(filterText.toLowerCase()),
  );
  // document.getElementById("search").focus(); 
  const handleSearch = (searchText) => {
    setFilterText(searchText);
  };

  const handleClear = () => {
    if (filterText) {
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterText('');
    }
  };

  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <GenFilter
        onSearch={handleSearch}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle])

  function changestatus(_user) {
    console.log("User data:-", _user)
    let msg = "Are you sure you want to change the current status of this User?"
    Alerts.swalConfirmGeneralAlert(msg).then(res => {
      console.log("Resp:-", res)
      if (res === true) {
        sendUpdatedStatus(_user)
      }
    })
  };

  function sendUpdatedStatus(userdata) {
    var getData = {
      functionName: "UpdateUser",
      postData: {
        name: userdata.name,
        email: userdata.email,
        contact: userdata.contact,
        role_id: userdata.role_id,
        status: "",
        _id: userdata._id
      },
    }
    if (userdata.status === "1") {
      getData.postData.status = "0"
    }
    else {
      getData.postData.status = "1"
    }
    setLoading(true);
    UserService.updateUser(getData).then((response) => {
      console.log("API Response:-", response);
      setLoading(false);
      getusers();
      if (response.data.status) {
        if (response.data.message !== '') {
          Alerts.swalSuccessAlert(response.data.message)
        }
        else {
          Alerts.swalSuccessAlert(t("Status Updated Successfully."));
        }
      }
      else {
        if (response.data.message !== '') {
          if (response.data.message === "Unauthorized") {
            AuthService.logout();
            navigate("/login");
            window.location.reload();
          }
          else {
            Alerts.swalErrorAlert(response.data.message);
          }
        }
        else {
          Alerts.swalErrorAlert(t("Error: Can't update status at the moment."))
        }
      }
    },
      (error) => {
        setLoading(false);
        const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        Alerts.swalErrorAlert(t("Operation failed."))
      });
  }

  function confirmDelete(_id) {
    let msg = t("Once deleted, you will not be able to recover this user!")
    Alerts.swalConfirmDeleteAlert(msg).then(res => {
      console.log("Resp:-", res)
      if (res === true) {
        sendDelete(_id)
      }
    })
  };

  function sendDelete(id) {
    setLoading(true);
    UserService.deleteUser(id).then((response) => {
      console.log("Delete User response:-", response)
      setLoading(false);
      getusers();
      if (response.data.status) {
        if (response.data.message !== '') {
          Alerts.swalSuccessAlert(response.data.message)
        }
        else {
          Alerts.swalSuccessAlert(t("User Deleted Successfully."))
        }
        console.log(response.data.message)
      }
      else {
        if (response.data.message !== '') {
          if (response.data.message === "Unauthorized") {
            AuthService.logout();
            navigate("/login");
            window.location.reload();
          }
          else {
            Alerts.swalErrorAlert(response.data.message);
          }
        }
        else {
          Alerts.swalErrorAlert(t("Error: Can't delete the User."))
        }
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        Alerts.swalErrorAlert(t("Delete operation failed"))
      })
  };

  function regeneratePassword(_id) {
    let msg = t("Are you sure you want to regenerate password for this user?")
    Alerts.swalConfirmGeneralAlert(msg).then(res => {
      console.log("Resp:-", res)
      if (res === true) {
        sendPassword(_id)
      }
    })
  };

  function sendPassword(id) {
    setLoading(true);
    UserService.sendPassword(id).then((response) => {
      console.log("Send Password response:-", response)
      setLoading(false);
      getusers();
      if (response.data.status) {
        if (response.data.message !== '') {
          Alerts.swalSuccessAlert(response.data.message)
        }
        else {
          Alerts.swalSuccessAlert(t("Password emailed Successfully."))
        }
        console.log(response.data.message)
      }
      else {
        if (response.data.message !== '') {
          if (response.data.message === "Unauthorized") {
            AuthService.logout();
            navigate("/login");
            window.location.reload();
          }
          else {
            Alerts.swalErrorAlert(response.data.message);
          }
        }
        else {
          Alerts.swalErrorAlert(t("Error: Can't send the Password."))
        }
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        Alerts.swalErrorAlert(t("Operation failed"))
      })
  };


  return (
    <Fragment>
      <div className="app-main__outer">
        <div className="app-main__inner">
          <TransitionGroup>
            <CSSTransition component="div" classNames="TabsAnimation" appear={true}
              timeout={1500} enter={false} exit={false}>
              <div>
                <div className="app-page-title">
                  <div className="page-title-wrapper">
                    <div className="page-title-heading fw-bold">
                      <div className="page-title-icon">
                        <i className="pe-7s-users icon-gradient bg-happy-green" />
                      </div>
                      <div>
                        {t('Users Management')}
                        <div className="page-title-subheading">
                          {t('View all Users, Create, Edit and Delete a User.')}
                        </div>
                      </div>
                    </div>
                    <div className="page-title-actions">
                      <button type="button" onClick={() => navigate(-1)} className="btn btn-outline-success btn-hover-shine me-3">
                        {t('Back')}
                      </button>
                    </div>
                  </div>
                </div>
                <Row>
                  <Col md="12">
                    <Card className="main-card mb-3">
                      <CardBody>
                        <LoadingOverlay tag="div" active={loading}
                          styles={{ overlay: (base) => ({ ...base }) }}
                          spinner={<DNALoader />}>
                          <Col md="6" style={{ float: "left", position: "absolute", zIndex: "1" }}>
                            {subHeaderComponentMemo}
                          </Col>
                          <DataTable
                            columns={TBcolumns}
                            data={filteredItems}
                            pagination
                            paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                            subHeader
                            subHeaderComponent={<a href="/add-user" className="AddData">
                              <button type="button" className="btn btn-custom-color btn-hover-shine me-3 btn">{t('Add New')}</button>
                            </a>
                            }
                            selectableRowDisabled={true}
                            persistTableHead
                          />
                        </LoadingOverlay>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </CSSTransition>
          </TransitionGroup>
        </div>
      </div>
    </Fragment>
  );
}

export default UsersList;















// function confirmDelete(_id) {
//   confirmAlert({
//     customUI: ({ onClose }) => {
//       return (
//         <div className="react-confirm-alert-body">
//           <div className='custom-ui'>
//             <div className="logo_div">
//               <img
//               src={"assets/images/trash.png"}
//               alt="logo-small"
//               className="logo-sm logo-dark delete_icon"
//               />
//             </div>
//             <h4 className="react-confirm-alert-body-h4">Delete User?</h4>
//             <p className="react-confirm-alert-body-p">Do you really want to delete this user? You will not be able to recover it.</p>
//             <div className="react-confirm-alert-button-group">
//               <button className="btn btn-soft-secondary btn-block me-2" onClick={onClose}>Cancel</button>
//               <button className="btn btn-danger btn-block"
//                 onClick={() => {
//                   sendDelete(_id)
//                   onClose();
//                 }}
//               >
//                 Yes, delete it
//               </button>
//             </div>
//           </div>
//         </div>
//       );
//     }
//   });
// }

{/* <table className="table table-hover mb-0 square border">
                    <thead className="thead-light">
                      <tr>
                        <th>Users</th>
                        <th>Role</th>
                        <th>Email</th>
                        <th>Contact No</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                    {users && users.map((user, index) => (
                      <tr key = {index}>
                        <td>
                          <img 
                            src="assets/images/users/user-8.jpg"
                            alt='image'
                            className="rounded-circle thumb-xs me-1"
                          />
                          {user.name}
                        </td>
                        <td>Plumber</td>
                        <td>{user.email}</td>
                        <td>{user.contact}</td>
                        <td>
                          <span className="badge badge-soft-success" >{user.status == '1' && "Active"}</span>
                          <span className="badge badge-soft-danger" >{user.status == '0' && "Inactive"}</span>
                        </td>
                        <td>
                          <Link to='/add-user' state= {user} >
                            <i className="las la-pen text-secondary font-16" />
                          </Link>
                          <a onClick={() => deleteuser(user._id)} style={{cursor: "pointer"}}>
                            <i className="las la-trash-alt text-secondary font-16" />
                          </a>
                        </td>
                      </tr>
                    ))}
                    </tbody>
</table> */}
