import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import styled from "styled-components";
import CustomerService from "../../Edsha/Invoices/Invoices.service.js";
import AuthService from "../../Authentication/auth.service";
import Alerts from "../../../components/Alerts/alerts.js";
import { Row, Col } from 'react-bootstrap';


const Input = styled.select.attrs(props => ({
    type: "select",
    size: props.small ? 5 : undefined
}))`
  height: 32px;
  width: 100%;
  border-radius: 6px;
  
  background: white;
`;

const Label = styled.label`
  display: block;
  font-weight: bold;
`;


const CollectionFilter = ({ filterText1, filterText2,
    handleKeyDown, onFilter1, onFilter2, onFilterInvoice_StartDate, filterDue_EndDate,
    onFilterInvoice_EndDate, onFilterDue_StartDate, onFilterDue_EndDate,
    searchCustomers, resetFilters,
    filterDue_StartDate, filterInvoice_StartDate, filterInvoice_EndDate }) => {
    const { t, i18n } = useTranslation();
    const [collection, setCollection] = useState([]);
    const navigate = useNavigate();
    useEffect(() => {
        i18n.changeLanguage(localStorage.getItem('language'));
        fetchcollections();
    }, []);

    function fetchcollections() {
        var getData = {
            functionName: "GetCustomers",
            postData: {
                "sort": { _id: -1 },
                "offset": 0,
                "limit": 1000,
                "filter": {}
            },
        }
        CustomerService.getAllCustomers(getData).then((response) => {
            if (response.data.message === "Unauthorized") {
                AuthService.logout();
                navigate("/login");
                window.location.reload();
            }
            else {
                console.log(response.data.data);
                if (response.data) {
                    setCollection(response.data.data);
                }
            }
        },
            (error) => {
                const errContent =
                    (error.response && error.response.data) || error.message || error.toString();
                if (errContent) {
                    Alerts.swalErrorAlert(errContent)
                }
                else {
                    Alerts.swalErrorAlert(t("Server Error, Please try again later."))
                }
            })
    };

    console.log(filterText1);

  
    return (
        <>
            <Row style={{ marginBottom: '20px' }}>
                <Col md="2">
                    <Label>{t("Name")}</Label>
                    <Input
                        type="text"
                        placeholder={t("Name")}
                        value={filterText1}
                        onChange={onFilter1}
                        id="colfilter"
                    >
                    </Input>
                </Col>
                <Col md="2">
                    <Label>{t("Email")}</Label>
                    <Input
                        type="text"
                        placeholder={t("Email")}
                        value={filterText2}
                        onChange={onFilter2}
                        id="colfilter"
                    >
                    </Input>
                </Col>
                <Col md="2">
                    <Label>{t("Phone")}</Label>
                    <Input
                        type="number"
                        placeholder={t("Phone")}
                        value={filterText2}
                        onChange={onFilter2}
                        id="colfilter"
                    >
                    </Input>
                </Col>

                <Col md="2">
                    <Label>{t("Reg No.")}</Label>
                    <Input
                        type="text"
                        placeholder={t("Reg No.")}
                        value={filterText2}
                        onChange={onFilter2}
                        id="colfilter"
                    >
                    </Input>
                </Col>
                <Col style={{ marginTop: '20px' }} md="4">
                    <button type="button" className="btn btn-custom-color btn-hover-shine ms-1"
                        onClick={() => searchCustomers()}
                    >Search</button>
                    <button style={{ marginLeft: '10px' }} type="button"
                        className="btn btn-custom-color btn-hover-shine"
                        onClick={() => resetFilters()}
                    >Reset</button>
                    <button to='/add-invoice' state={{ check: '1' }} className="btn btn-custom-color btn-hover-shine float-end ml-50 mb-20">
                        Download CSV
                    </button>
                </Col>
            </Row>
        </>

    );
};

export default CollectionFilter;
