import React, { useEffect, useState, Fragment, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
} from "reactstrap";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
// import { Form, Field } from "react-final-form";
import ServicesService from "../../../Services Management/services.service.js";

import AuthService from "../../../../Authentication/auth.service";
import { useTranslation } from 'react-i18next';

import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../../../../components/Loaders/DnaLoader";
import "../../../../../assets/custom css/loadoverlay.scss";
import "../../../../../assets/custom css/modalwidths.scss";
import Alerts from "../../../../../components/Alerts/alerts";

import CustomerService from "../../Customer.service";

import AlertService from "../../../../../components/Alerts/alerts";


const AddOrderItem = ({ data, services, packagings, onBack, onSuccess }) => {

  const navigate = useNavigate();
  const form = useRef();
  console.log(data);
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const { state } = location;
  console.log("Received Object:-", state);

  const [rows, setrows] = useState([]);
  const [orderDetails, setOrderDetails] = useState([]);
  const [Services, setServices] = useState([]);

  const [fservice_id, setfservice_id] = useState("");
  const [fpackaging_id, setfpackaging_id] = useState("");
  // const [fpackaging_size_id, setfpackaging_size_id] = useState("");
  const [myDataPopObj, setmyDataPopObj] = useState([]);
  const [priceidx, setpriceidx] = useState(0);
  const [SinglePriceObj, setSinglePriceObj] = useState([]);
  const [fquantity, setfquantity] = useState("");
  const [fweight, setfweight] = useState("");


  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    setOrderDetails([]);
    addRow1();
  }, []);

  useEffect(() => {
    if (orderDetails[priceidx]) {
      if (orderDetails[priceidx].packaging
        // && orderDetails[priceidx].packagingsize 
        && orderDetails[priceidx].services
      ) {
        SearchPrice();
      }
    }
  }, [fservice_id, fpackaging_id
    // , fpackaging_size_id
  ]);

  function SearchPrice() {
    var getData = {
      functionName: "GetPricing",
      postData: {
        "user_id": state?._id ? state._id : data.customer_id,
        "service_id": orderDetails[priceidx].services,
        "packaging_id": orderDetails[priceidx].packaging,
        // "packaging_size_id": orderDetails[priceidx].packagingsize
      },
    }
    setLoading(true);
    CustomerService.getCustomerPricing(getData).then((response) => {
      setLoading(false);
      console.log("Get customer pricing response:-", response)
      if (response.data.status) {
        var updatedPopObj = [...myDataPopObj];
        var singlePriceObj = [...SinglePriceObj];
        if (response.data.data.length !== 0) {
          // var popDataObj = {
          //   [`price${ind}`]: response.data.data[0]?.price
          // };
          singlePriceObj[priceidx] = parseFloat(response.data.data[0]?.price).toFixed(2);
          updatedPopObj[priceidx] = (parseFloat((isNaN(orderDetails[priceidx].quantity) ? 1 : orderDetails[priceidx].quantity) * (isNaN(orderDetails[priceidx].weight) ? 1 : orderDetails[priceidx].weight) * (response.data.data[0]?.price)).toFixed(2));
          setmyDataPopObj(updatedPopObj);
          setSinglePriceObj(singlePriceObj);

          // setfservice_id("");
          // setfpackaging_id("");
          // setfpackaging_size_id("");
          console.log("Final Obj:-", updatedPopObj)
          console.log("single Price Obj:-", singlePriceObj)
          // setmyDataPopObj("");
        }
        else {
          // setfservice_id("");
          // setfpackaging_id("");
          // setfpackaging_size_id("");
          singlePriceObj[priceidx] = ("");
          updatedPopObj[priceidx] = ("");
          setSinglePriceObj(singlePriceObj);
          setmyDataPopObj(updatedPopObj);
        }

      }
      else {
        if (response.data.message === "Unauthorized") {
          AuthService.logout();
          navigate("/login");
          window.location.reload();
        }
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent)
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  }



  function addRow1() {
    setrows(1);
  };

  function addRow() {
    if (orderDetails[priceidx] === undefined) {
      AlertService.swalErrorAlert("Please Fill Existing Rows First")
    } else if (orderDetails[priceidx]) {
      if (!orderDetails[priceidx].packaging
        // || !orderDetails[priceidx].packagingsize 
        || !orderDetails[priceidx].services) {
        AlertService.swalErrorAlert("Please Fill existing Rows First")
      } else {
        var saddRow = rows;
        saddRow = saddRow + 1;
        setrows(saddRow);

      }
    } else {
      var saddRow = rows;
      saddRow = saddRow + 1;
      setrows(saddRow);
    }
  }

  function remove_option(index) {
    if (index !== 0) {
      document.getElementById('parent' + index).remove();
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    var formvalid0 = 'false';
    var formvalid1 = 'false';
    // var formvalid2 = 'false';
    var formvalid3 = 'false';
    var formvalid4 = 'false';
    var formvalid5 = 'false';
    var formvalid6 = 'false';
    const elements0 = Array.from(
      document.getElementsByClassName('serviceclass')
    );
    var services_ids = [];
    for (let i = 0; i < elements0.length; i++) {
      if (elements0[i].value) {
        services_ids.push(elements0[i].value)
        formvalid0 = 'true'
      }
      else {
        formvalid0 = 'false'
        break;
      }
    }

    const elements1 = Array.from(
      document.getElementsByClassName('packclass')
    );
    var pack = [];
    for (let i = 0; i < elements1.length; i++) {
      if (elements1[i].value) {
        pack.push(elements1[i].value)
        formvalid1 = 'true'
      }
      else {
        formvalid1 = 'false'
        break;
      }
    }


    // const elements2 = Array.from(
    //   document.getElementsByClassName('sizeclass')
    // );
    // var sizes = [];
    // for (let i = 0; i < elements2.length; i++) {
    //   if (elements2[i].value) {
    //     sizes.push(elements2[i].value)
    //     formvalid2 = 'true'
    //   }
    //   else {
    //     formvalid2 = 'false'
    //     break;
    //   }
    // }

    const elements3 = Array.from(
      document.getElementsByClassName('qtyclass')
    );
    var quantities = [];
    for (let i = 0; i < elements3.length; i++) {
      if (elements3[i].value) {
        quantities.push(elements3[i].value)
        formvalid3 = 'true'
      }
      else {
        formvalid3 = 'false'
        break;
      }
    }

    const elements4 = Array.from(
      document.getElementsByClassName('weightclass')
    );
    var weights = [];
    for (let i = 0; i < elements4.length; i++) {
      if (elements4[i].value) {
        weights.push(elements4[i].value)
        formvalid4 = 'true'
      }
      else {
        formvalid4 = 'false'
        break;
      }
    }

    const elements5 = Array.from(
      document.getElementsByClassName('priceclass')
    );
    var prices = [];
    for (let i = 0; i < elements5.length; i++) {
      if (elements5[i].value) {
        prices.push(parseFloat(elements5[i].value).toFixed(2))
        formvalid5 = 'true'
      }
      else {
        formvalid5 = 'false'
        break;
      }
    }
    const elements6 = Array.from(
      document.getElementsByClassName('totalpriceclass')
    );
    var total_prices = [];
    for (let i = 0; i < elements6.length; i++) {
      if (elements6[i].value) {
        total_prices.push(parseFloat(elements6[i].value).toFixed(2))
        formvalid6 = 'true'
      }
      else {
        formvalid6 = 'false'
        break;
      }
    }
    if (formvalid0 === 'false' || formvalid1 === 'false'
      // || formvalid2 === 'false' 
      || formvalid3 === 'false' || formvalid4 === 'false' || formvalid5 === 'false' || formvalid6 === 'false') {
      Alerts.swalErrorAlert("Please fill all the fields.")
    }
    else {
      var payloadData = {
        functionName: "AddOrderDetails",
        postData: {
          user_id: data.customer_id,
          order_id: data._id,
          service_id: services_ids,
          packaging_id: pack,
          // packaging_size_id: sizes,
          quantity: quantities,
          weight: weights,
          price: prices,
          total_price: total_prices,
        }
      }
      setLoading(true);
      CustomerService.addCustomerOrderItems(payloadData).then((response) => {
        console.log("API Response:-", response);
        setLoading(false);
        if (response.data.status) {
          if (response.data.message !== '') {
            Alerts.swalSuccessAlert(response.data.message).then(res => {
              onSuccess();
            })
          }
          else {
            Alerts.swalSuccessAlert(t("Customer order items added successfully.")).then(res => {
              onSuccess();
            })
          }
        }
        else {
          if (response.data.message !== '') {
            if (response.data.message === "Unauthorized") {
              AuthService.logout();
              navigate("/login");
              window.location.reload();
            }
            else {
              Alerts.swalErrorAlert(response.data.message);
            }
          }
          else {
            Alerts.swalErrorAlert(t("Error: Can't add Customer order items at the moment. Please try again later."))
          }
        }
      },
        (error) => {
          setLoading(false);
          const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          Alerts.swalErrorAlert(t("Network Operation failed."))
        });
    }
  }

  function selectChanged(type, ind, ev) {
    console.log("type:-", type)
    console.log("index:-", ind)
    console.log("value:-", ev.target.value)
    setpriceidx(ind)
    const updatedOrderDetails = [...orderDetails];
    const newValue = { [type]: ev.target.value };
    updatedOrderDetails[ind] = {
      ...updatedOrderDetails[ind],
      ...newValue,
    };
    if (type === "price") {
      console.log("price", ev.target.value)
      if (ev.target.value) {
        var updatedPopObj = [...myDataPopObj];
        var singlePriceObj = [...SinglePriceObj];
        singlePriceObj[ind] = parseFloat(ev.target.value);
        updatedPopObj[ind] = (parseFloat((isNaN(orderDetails[ind].quantity) ? 1 : orderDetails[ind].quantity) * (isNaN(orderDetails[ind].weight) ? 1 : orderDetails[ind].weight) * (ev.target.value)).toFixed(2));
        setmyDataPopObj(updatedPopObj);
        setSinglePriceObj(singlePriceObj);
      }
    }

    setOrderDetails(updatedOrderDetails);
    if (type === "services") {
      setfservice_id(ev.target.value)
      if (orderDetails[ind]) {
        if (orderDetails[ind].packaging
          //  && orderDetails[priceidx].packagingsize 
          && orderDetails[ind].services
        ) {
          SearchPrice();
        }

      }
    }
    if (type === "packaging") {
      setfpackaging_id(ev.target.value)
      if (orderDetails[ind]) {
        if (orderDetails[ind].packaging
          //  && orderDetails[priceidx].packagingsize 
          && orderDetails[ind].services
        ) {
          SearchPrice();
        }

      }
    }
    // if (type === "packagingsize") {
    //   setfpackaging_size_id(ev.target.value)
    // }
    if (type === "weight") {
      // setfweight(ev.target.value)

      var updatedPo = [...myDataPopObj];
      console.log("updatedPopObj[priceidx]", updatedPo[ind])

      // setfquantity(ev.target.value)
      updatedPo[ind] = (parseFloat((parseFloat(ev.target.value)) * (isNaN(orderDetails[ind].quantity) ? 1 :
        parseFloat(orderDetails[ind].quantity)) *
        parseFloat(SinglePriceObj[ind])).toFixed(2));
      console.log("updatedPop[ind]", updatedPo[ind])
      setmyDataPopObj(updatedPo);
    }
    if (type === "quantity") {
      console.log("ev.target.value", ev.target.value)
      console.log("orderDetails[ind].weight", orderDetails[ind].weight)
      console.log("isNAN", isNaN(orderDetails[ind].weight))
      console.log("SinglePriceObj[ind].price", SinglePriceObj[ind])
      var updatedPop = [...myDataPopObj];
      console.log("updatedPopObj[priceidx]", updatedPop[ind])

      // setfquantity(ev.target.value)
      updatedPop[ind] = (parseFloat((parseFloat(ev.target.value)) * (isNaN(orderDetails[ind].weight) ? 1 :
        parseFloat(orderDetails[ind].weight)) *
        parseFloat(SinglePriceObj[ind])).toFixed(2));
      console.log("updatedPop[ind]", updatedPop[ind])
      setmyDataPopObj(updatedPop);

    }
  }

  function onCancel() {
    onBack()
  }

  return (
    <Row>
      <Col md="12">
        <Card className="main-card mb-3">
          <CardBody>
            <LoadingOverlay tag="div" active={loading}
              styles={{ overlay: (base) => ({ ...base }) }}
              spinner={<DNALoader />}>
              <Form onSubmit={handleSubmit} ref={form} default>
                <Row>
                  <h4 className="fw-bold mb-1">Add Order</h4>
                </Row>
                {Array.from({ length: rows }, (_, index) => {
                  var preprice = "";
                  var singleprice = "";
                  if (index < myDataPopObj.length) {
                    preprice = myDataPopObj[index];
                    singleprice = SinglePriceObj[index];
                  } return (
                    <div>
                      <Row id={"parent" + index} className="mt-4">
                        <Col md="2">
                          <div className="form-group">
                            <select type="select" className="form-select serviceclass" name="service_id" onChange={(ev) => {
                              selectChanged("services", index, ev)
                            }}
                            >
                              <option disabled={false} value="">Service</option>
                              {services &&
                                services.map((serv, indexx) => (
                                  <option key={indexx} value={serv._id}>{serv.name}</option>
                                ))
                              }
                            </select>
                          </div>
                        </Col>
                        <Col md="2">
                          <div className="form-group">
                            <select type="select" className="form-select packclass" name="packaging_id" onChange={(ev) => {
                              selectChanged("packaging", index, ev)
                            }}
                            >
                              <option disabled={false} value="">Packaging</option>
                              {packagings &&
                                packagings.map((pack, inde) => (
                                  <option key={inde} value={pack._id}>{pack.name}</option>
                                ))
                              }
                            </select>
                          </div>
                        </Col>
                        {/* <Col md="1">
                          <div className="form-group">
                            <select type="select" className="form-select sizeclass" name="packaging_size_id" onChange={(ev) => {
                              selectChanged("packagingsize", index, ev)
                            }}
                            >
                              <option disabled={false} value="">Size</option>
                              {packagingSizes &&
                                packagingSizes.map((size, ind) => (
                                  <option key={ind} value={size._id}>{size.name}</option>
                                ))
                              }
                            </select>
                          </div>
                        </Col > */}
                        <Col md="2">
                          <div className="form-group">
                            <Input type="text" placeholder="Quantity" onChange={(ev) => {
                              selectChanged("quantity", index, ev)
                            }}
                              className="form-control qtyclass" name="quantity"
                            />
                          </div>
                        </Col>
                        <Col md="1">
                          <div className="form-group">
                            <Input type="text" placeholder="Weight" onChange={(ev) => {
                              selectChanged("weight", index, ev)
                            }} className="form-control weightclass" name="weight"
                            />
                          </div>
                        </Col>
                        <Col md="2" >
                          <div className="form-group">
                            <Input type="number" onChange={(ev) => {
                              selectChanged("price", index, ev)
                            }}
                              placeholder="Price" className="form-control priceclass" value={singleprice} name="price"
                            />
                          </div>
                        </Col>
                        <Col md="2" >
                          <div className="form-group">
                            <Input type="number" disabled placeholder="Total Price" className="form-control totalpriceclass" value={preprice} name="totalprice"
                            />
                          </div>
                        </Col>
                        <Col md="1" className="pt-1">
                          <i onClick={() => addRow()} className="lnr-plus-circle h3 fw-bold"> </i>
                          {rows !== 1 &&
                            <i onClick={() => remove_option(index)} className="lnr-cross-circle h3 fw-bold"> </i>
                          }
                        </Col>
                      </Row>
                    </div>
                  )
                })}
                {/* <Row className="mt-2">
                              <Col md="2">
                                <AssignOrderManual onSuccess={handleSuccess} />
                              </Col>
                              <Col md="2">
                                <Switch checked={switchtoggle} onChange={event => handleSwitch(event)} onColor="#86d3ff"
                                  onHandleColor="#2693e6" handleDiameter={20} uncheckedIcon={false} checkedIcon={false}
                                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)" activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                  height={14} width={36} className="me-2 mb-3" id="material-switch"
                                />
                              </Col>
                            </Row> */}
                <Row className="mt-5">
                  <div className="text-center form-group">
                    <button type="submit" className="btn btn-secondary btn-lg btn-hover-shine me-3">
                      Save </button>
                    <button type="button" onClick={() => onCancel()} className="btn btn-outline-success btn-lg btn-hover-shine me-3">
                      Cancel
                    </button>
                  </div>

                </Row>
              </Form>
            </LoadingOverlay>
          </CardBody>
        </Card>
      </Col>
    </Row>

  )

};
export default AddOrderItem;









// import React from "react";
// import { Translation } from "react-i18next";
// import { Navigate } from "react-router-dom";
// import { Row, Col, FormGroup, Button, Label, Modal, ModalHeader, ModalBody, ModalFooter, Card, CardBody } from "reactstrap";
// // import { Form, Field } from "react-final-form";
// import Form from "react-validation/build/form";
// import Input from "react-validation/build/input";

// import LoadingOverlay from "react-loading-overlay-ts";
// import DNALoader from "../../../../../components/Loaders/DnaLoader";
// import "../../../../../assets/custom css/loadoverlay.scss";
// import "../../../../../assets/custom css/modalwidths.scss";
// import Alerts from "../../../../../components/Alerts/alerts";

// import CustomerService from "../../Customer.service";
// import AuthService from "../../../../Authentication/auth.service";


// class AddOrderItem extends React.Component {

//   componentDidMount() {
//     this.addRow1();
//     this.clearFields();
//   }

//   constructor(props) {
//     super(props);
//     console.log("Received Prop:-", props);
//     this.form = React.createRef();
//     this.state = {
//       modal: false,
//       backdrop: true,
//       loading: false,
//       loginNavigate: false,
//       rows: [],
//       fservice_id: "",
//       fpackaging_id: "",
//       fpackaging_size_id: "",
//       myDataPopObj: [],
//     };
//     this.toggle = this.toggle.bind(this);
//     this.handleSubmit = this.handleSubmit.bind(this);
//     this.selectChanged = this.selectChanged.bind(this);
//     this.searchPrice = this.searchPrice.bind(this)
//     this.addRow1 = this.addRow1.bind(this);
//     this.addRow = this.addRow.bind(this);
//     this.remove_option = this.remove_option.bind(this);
//     this.clearFields = this.clearFields.bind(this)
//   }

//   toggle() {
//     this.setState({
//       modal: !this.state.modal,
//     },() => {
//      this.clearFields()
//     });
//   }

//   addRow1() {
//     this.setState({
//       rows: 1,
//     });
//   }

//   addRow() {
//     var saddRow = this.state.rows;
//     saddRow = saddRow + 1;
//     this.setState({
//       rows: saddRow,
//     });
//   }

//   remove_option = index => {
//     if (index !== 0) {
//       document.getElementById('parent' + index).remove();
//     }
//   }

//   searchPrice() {
//     var getData = {
//       functionName: "GetPricing",
//       postData: {
//         "user_id": this.props.data.customer_id,
//         "service_id": this.state.fservice_id,
//         "packaging_id": this.state.fpackaging_id,
//         "packaging_size_id": this.state.fpackaging_size_id
//       },
//     }
//     this.setState({
//       loading: !this.state.loading,
//     });
//     CustomerService.getCustomerPricing(getData).then((response) => {
//       this.setState({
//         loading: !this.state.loading,
//       });
//       console.log("Get customer pricing response:-", response)
//       if (response.data.status) {
//         var updatedPopObj = [...this.state.myDataPopObj];
//         if (response.data.data.length !== 0) {
//           updatedPopObj.push(response.data.data[0]?.price);
//           this.setState({
//             myDataPopObj: updatedPopObj,
//           });
//           this.setState({
//             fservice_id: "",
//           });
//           this.setState({
//             fpackaging_id: "",
//           });
//           this.setState({
//             fpackaging_size_id: "",
//           });
//           console.log("Final Obj:-", this.state.myDataPopObj)
//         }
//         else {
//           this.setState({
//             fservice_id: "",
//           });
//           this.setState({
//             fpackaging_id: "",
//           });
//           this.setState({
//             fpackaging_size_id: "",
//           });
//           updatedPopObj.push("");
//           this.setState({
//             myDataPopObj: updatedPopObj,
//           });
//         }
//       }
//       else {
//         if (response.data.message === "Unauthorized") {
//           AuthService.logout();
//           this.setState({ loginNavigate: true });
//           window.location.reload();
//         }
//       }
//     },
//       (error) => {
//         this.setState({
//           loading: !this.state.loading,
//         });
//         const errContent =
//           (error.response && error.response.data) || error.message || error.toString();
//         console.log("Net:-", errContent)
//         if (errContent) {
//           Alerts.swalErrorAlert(errContent)
//         }
//         else {
//           Alerts.swalErrorAlert("Server Error, Please try again later.")
//         }
//       })
//   }

//   handleSubmit = (e) => {
//     e.preventDefault();
//     var formvalid0 = 'false';
//     var formvalid1 = 'false';
//     var formvalid2 = 'false';
//     var formvalid3 = 'false';
//     var formvalid4 = 'false';
//     var formvalid5 = 'false';
//     const elements0 = Array.from(
//       document.getElementsByClassName('serviceclass')
//     );
//     var services_ids = [];
//     for (let i = 0; i < elements0.length; i++) {
//       if (elements0[i].value) {
//         services_ids.push(elements0[i].value)
//         formvalid0 = 'true'
//       }
//       else {
//         formvalid0 = 'false'
//         break;
//       }
//     }

//     const elements1 = Array.from(
//       document.getElementsByClassName('packclass')
//     );
//     var pack = [];
//     for (let i = 0; i < elements1.length; i++) {
//       if (elements1[i].value) {
//         pack.push(elements1[i].value)
//         formvalid1 = 'true'
//       }
//       else {
//         formvalid1 = 'false'
//         break;
//       }
//     }

//     const elements2 = Array.from(
//       document.getElementsByClassName('sizeclass')
//     );
//     var sizes = [];
//     for (let i = 0; i < elements2.length; i++) {
//       if (elements2[i].value) {
//         sizes.push(elements2[i].value)
//         formvalid2 = 'true'
//       }
//       else {
//         formvalid2 = 'false'
//         break;
//       }
//     }

//     const elements3 = Array.from(
//       document.getElementsByClassName('qtyclass')
//     );
//     var quantities = [];
//     for (let i = 0; i < elements3.length; i++) {
//       if (elements3[i].value) {
//         quantities.push(elements3[i].value)
//         formvalid3 = 'true'
//       }
//       else {
//         formvalid3 = 'false'
//         break;
//       }
//     }

//     const elements4 = Array.from(
//       document.getElementsByClassName('weightclass')
//     );
//     var weights = [];
//     for (let i = 0; i < elements4.length; i++) {
//       if (elements4[i].value) {
//         weights.push(elements4[i].value)
//         formvalid4 = 'true'
//       }
//       else {
//         formvalid4 = 'false'
//         break;
//       }
//     }

//     const elements5 = Array.from(
//       document.getElementsByClassName('priceclass')
//     );
//     var prices = [];
//     for (let i = 0; i < elements5.length; i++) {
//       if (elements5[i].value) {
//         prices.push(elements5[i].value)
//         formvalid5 = 'true'
//       }
//       else {
//         formvalid5 = 'false'
//         break;
//       }
//     }

//     if (formvalid0 === 'false' || formvalid1 === 'false' || formvalid2 === 'false' || formvalid3 === 'false' || formvalid4 === 'false' || formvalid5 === 'false') {
//       Alerts.swalErrorAlert("Please fill all the fields.")
//     }
//     else {
//       var payloadData = {
//         functionName: "AddOrderDetails",
//         postData: {
//           user_id: this.props.data.customer_id,
//           order_id: this.props.data._id,
//           service_id: services_ids,
//           packaging_id: pack,
//           packaging_size_id: sizes,
//           quantity: quantities,
//           weight: weights,
//           price: prices
//         }
//       }
//       this.setState({
//         loading: !this.state.loading,
//       });
//       CustomerService.addCustomerOrderItems(payloadData).then((response) => {
//         console.log("API Response:-", response);
//         this.setState({
//           loading: !this.state.loading,
//         });
//         if (response.data.status) {
//           this.props.onSuccess();
//           this.clearFields();
//           if (response.data.message !== '') {
//             Alerts.swalSuccessAlert(response.data.message).then(res => {
//               console.log("Resp:-", res);
//               this.toggle();
//             })
//           }
//           else {
//             Alerts.swalSuccessAlert("Customer order items added successfully.").then(res => {
//               console.log("Resp:-", res);
//               this.toggle();
//             });
//           }
//         }
//         else {
//           if (response.data.message !== '') {
//             if (response.data.message === "Unauthorized") {
//               AuthService.logout();
//               this.setState({ loginNavigate: true });
//               window.location.reload();
//             }
//             else {
//               Alerts.swalErrorAlert(response.data.message);
//             }
//           }
//           else {
//             Alerts.swalErrorAlert("Error: Can't add Customer order at the moment. Please try again later.")
//           }
//         }
//       },
//         (error) => {
//           this.setState({
//             loading: !this.state.loading,
//           });
//           const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
//           Alerts.swalErrorAlert("Network Operation failed.")
//         });
//     }
//   }

//   selectChanged = (type, ind, ev) => {
//     var index = ind
//     console.log("type:-", type)
//     console.log("index:-", ind)
//     console.log("value:-", ev.target.value)

//     if (type === "services") {
//       this.setState({
//         fservice_id: ev.target.value,
//       // },() => {
//       //   console.log("service Id:-", this.state.fservice_id)
//       //   console.log("pacakging Id:-", this.state.fpackaging_id)
//       //   console.log("Packaging Size Id:-", this.state.fpackaging_size_id)
//       //   if (this.state.fservice_id !== "" && this.state.fpackaging_id !== "" && this.state.fpackaging_size_id !== "") {
//       //     this.searchPrice();
//       //   }
//       });
//     }
//     if (type === "packaging") {
//       this.setState({
//         fpackaging_id: ev.target.value,
//       // },() => {
//       //   console.log("service Id:-", this.state.fservice_id)
//       //   console.log("pacakging Id:-", this.state.fpackaging_id)
//       //   console.log("Packaging Size Id:-", this.state.fpackaging_size_id)
//       //   if (this.state.fservice_id !== "" && this.state.fpackaging_id !== "" && this.state.fpackaging_size_id !== "") {
//       //     this.searchPrice();
//       //   }
//       });
//     }
//     if (type === "packagingsize") {
//       this.setState({
//         fpackaging_size_id: ev.target.value,
//       },() => {
//         console.log("service Id:-", this.state.fservice_id)
//         console.log("pacakging Id:-", this.state.fpackaging_id)
//         console.log("Packaging Size Id:-", this.state.fpackaging_size_id)
//         // if (this.state.fservice_id !== "" && this.state.fpackaging_id !== "" && this.state.fpackaging_size_id !== "") {
//           this.searchPrice();
//         // }
//       });
//     }
//   }

//   clearFields() {
//     this.setState({
//       myDataPopObj: [],
//     });
//     this.setState({
//       fservice_id: "",
//     });
//     this.setState({
//       fpackaging_id: "",
//     });
//     this.setState({
//       fpackaging_size_id: "",
//     });
//     this.setState({
//       rows: 1,
//     });
//   }

//   render() {
//     {
//       this.state.loginNavigate &&
//         <Navigate to={'/login'} />
//     }
//     return (
//       <Translation>
//         {(t) => (
//           <span className="d-inline-block mb-2">
//             <a onClick={this.toggle} className="btn btn-custom-color btn-hover-shine">
//               Add New</a>
//             <Modal isOpen={this.state.modal} toggle={this.toggle} className="large-modal" backdrop={true}>
//               <LoadingOverlay tag="div" active={this.state.loading}
//                 styles={{ overlay: (base) => ({ ...base }) }}
//                 spinner={<DNALoader />}>
//                 <Card>
//                   <CardBody>
//                     <Form onSubmit={this.handleSubmit} ref={this.form} default>
//                       <Row>
//                         <h4 className="fw-bold mb-1">Add Order</h4>
//                       </Row>
//                       {Array.from({ length: this.state.rows }, (_, index) => {
//                         var preprice = "";
//                         console.log(this.state.myDataPopObj.length);
//                         if (index < this.state.myDataPopObj.length) {
//                           preprice = this.state.myDataPopObj[index];
//                           console.log("preprice:-", preprice)
//                         }
//                         return (
//                           <div>
//                             <Row id={"parent" + index} className="mt-4">
//                               <Col md="2">
//                                 <div className="form-group">
//                                   <select type="select" className="form-select serviceclass" name="service_id" onChange={(ev) => {
//                                     this.selectChanged("services", index, ev)
//                                   }}
//                                   >
//                                     <option disabled={false} value="">Service</option>
//                                     {this.props.services &&
//                                       this.props.services.map((serv, indexx) => (
//                                         <option key={indexx} value={serv._id}>{serv.name}</option>
//                                       ))
//                                     }
//                                   </select>
//                                 </div>
//                               </Col>
//                               <Col md="2">
//                                 <div className="form-group">
//                                   <select type="select" className="form-select packclass" name="packaging_id" onChange={(ev) => {
//                                     this.selectChanged("packaging", index, ev)
//                                   }}
//                                   >
//                                     <option disabled={false} value="">Packaging</option>
//                                     {this.props.packagings &&
//                                       this.props.packagings.map((pack, inde) => (
//                                         <option key={inde} value={pack._id}>{pack.name}</option>
//                                       ))
//                                     }
//                                   </select>
//                                 </div>
//                               </Col>
//                               <Col md="2">
//                                 <div className="form-group">
//                                   <select type="select" className="form-select sizeclass" name="packaging_size_id" onChange={(ev) => {
//                                     this.selectChanged("packagingsize", index, ev)
//                                   }}
//                                   >
//                                     <option disabled={false} value="">Size</option>
//                                     {this.props.packagingSizes &&
//                                       this.props.packagingSizes.map((size, ind) => (
//                                         <option key={ind} value={size._id}>{size.name}</option>
//                                       ))
//                                     }
//                                   </select>
//                                 </div>
//                               </Col >
//                               <Col md="2">
//                                 <div className="form-group">
//                                   <Input type="text" placeholder="Quantity" className="form-control qtyclass" name="quantity"
//                                   />
//                                 </div>
//                               </Col>
//                               <Col md="2">
//                                 <div className="form-group">
//                                   <Input type="text" placeholder="Weight" className="form-control weightclass" name="weight"
//                                   />
//                                 </div>
//                               </Col>
//                               <Col md="1" >
//                                 <div className="form-group">
//                                   <Input type="text" placeholder="Price" className="form-control priceclass" value={preprice} name="price"
//                                   />
//                                 </div>
//                               </Col>
//                               <Col md="1" className="pt-1">
//                                 <i onClick={() => this.addRow()} className="lnr-plus-circle h3 fw-bold"> </i>
//                                 {this.state.rows !== 1 &&
//                                   <i onClick={() => this.remove_option(index)} className="lnr-cross-circle h3 fw-bold"> </i>
//                                 }
//                               </Col>
//                             </Row>
//                           </div>
//                         )
//                       })}
//                       {/* <Row className="mt-2">
//                               <Col md="2">
//                                 <AssignOrderManual onSuccess={handleSuccess} />
//                               </Col>
//                               <Col md="2">
//                                 <Switch checked={switchtoggle} onChange={event => handleSwitch(event)} onColor="#86d3ff"
//                                   onHandleColor="#2693e6" handleDiameter={20} uncheckedIcon={false} checkedIcon={false}
//                                   boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)" activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
//                                   height={14} width={36} className="me-2 mb-3" id="material-switch"
//                                 />
//                               </Col>
//                             </Row> */}
//                       <Row className="mt-5">
//                         <div className="text-center form-group">
//                           <button type="submit" className="btn btn-secondary btn-lg btn-hover-shine me-3">
//                             Save </button>
//                           <button type="button" onClick={this.toggle} className="btn btn-outline-success btn-lg btn-hover-shine me-3">
//                             Cancel
//                           </button>
//                         </div>

//                       </Row>
//                     </Form>
//                   </CardBody>
//                 </Card>
//               </LoadingOverlay>
//             </Modal>
//           </span>
//         )
//         }
//       </Translation>
//     );
//   };


// };

// export default AddOrderItem;