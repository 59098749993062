import React, { Fragment, useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  Input,
  FormGroup,
  Label,
} from "reactstrap";
import { Translation } from "react-i18next";
import { Form, Field } from "react-final-form";
import moment from 'moment';

import CustomerService from "../../Customer.service";
import AuthService from "../../../../Authentication/auth.service";
import FleetService from "../../../Fleet Management/fleet.service";
import DriverService from "../../../Drivers/Driver.service";

import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../../../../components/Loaders/DnaLoader";
import "../../../../../assets/custom css/loadoverlay.scss";
import Alerts from "../../../../../components/Alerts/alerts";
import UserService from "../../../../Users Management/users.service";


export default class OrderDetails extends React.Component {

  componentDidMount() {
    this.getUsers();
    // this.getFleets();
    // this.getDrivers();
  }

  constructor(props) {
    super(props);
    console.log("Received Prop in OrderDetails:-", props);
    this.state = {
      loading: false,
      loginNavigate: false,
      users: [],
      initialvalues: this.props.data,
      edited: "false",
      pick_datetime: this.props.data?.pick_datetime
      // fleets: [],
      // drivers: []
    };
    this.validateFtn = this.validateFtn.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.getUsers = this.getUsers.bind(this);
    this.changeDateFormat = this.changeDateFormat.bind(this);
    this.revealUser = this.revealUser.bind(this);
    this.getSingleOrder = this.getSingleOrder.bind(this);
    this.goBack = this.goBack.bind(this)
    // this.getFleets = this.getFleets.bind(this);
    // this.getDrivers = this.getDrivers.bind(this);
  }

  getUsers() {
    var getData = {
      functionName: "GetUsers",
      postData: {
      },
    }
    UserService.getAllUsers(getData).then((response) => {
      console.log("Get Users response:-", response)
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        this.setState({ loginNavigate: true });
        window.location.reload();
      }
      else {
        this.setState({
          users: response.data.data,
        });
      }
    },
      (error) => {
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent)
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert("Server Error, Please try again later.")
        }
      })
  }

  getSingleOrder() {
    var getData = {
      functionName: "GetOrder",
      postData: {
        _id: this.props.order_id
      },
    }
    CustomerService.getSingleOrder(getData).then((response) => {
      console.log("Get Order response:-", response)
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        this.setState({ loginNavigate: true });
        window.location.reload();
      }
      else {
        this.setState({
          initialvalues: response.data.data,
        });
      }
    },
      (error) => {
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent)
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert("Server Error, Please try again later.")
        }
      })
  }

  // getFleets() {
  //   var getData = {
  //     functionName: "GetFleets",
  //     postData: {
  //     },
  //   }
  //   FleetService.getAllFleets(getData).then((response) => {
  //     console.log("Get Fleets response:-", response)
  //     if (response.data.message === "Unauthorized") {
  //       AuthService.logout();
  //       this.setState({ loginNavigate: true });
  //       window.location.reload();
  //     }
  //     else {
  //       this.setState({
  //         fleets: response.data.data,
  //       });
  //     }
  //   },
  //     (error) => {
  //       const errContent =
  //         (error.response && error.response.data) || error.message || error.toString();
  //       console.log("Net:-", errContent)
  //       if (errContent) {
  //         Alerts.swalErrorAlert(errContent)
  //       }
  //       else {
  //         Alerts.swalErrorAlert("Server Error, Please try again later.")
  //       }
  //     })
  // }

  // getDrivers() {
  //   var getData = {
  //     functionName: "GetDrivers",
  //     postData: {
  //     },
  //   }
  //   DriverService.getAllDrivers(getData).then((response) => {
  //     console.log("Get Driver response:-", response)
  //     if (response.data.message === "Unauthorized") {
  //       AuthService.logout();
  //       this.setState({ loginNavigate: true });
  //       window.location.reload();
  //     }
  //     else {
  //       this.setState({
  //         drivers: response.data.data,
  //       });
  //     }
  //   },
  //     (error) => {
  //       const errContent =
  //         (error.response && error.response.data) || error.message || error.toString();
  //       console.log("Net:-", errContent)
  //       if (errContent) {
  //         Alerts.swalErrorAlert(errContent)
  //       }
  //       else {
  //         Alerts.swalErrorAlert("Server Error, Please try again later.")
  //       }
  //     })
  // }

  validateFtn = values => {
    const errors = {};
    if (!values.created_at) {
      errors.created_at = "Order Date Time Required.";
    }
    if (!values.order_status) {
      errors.order_status = "Order Status Required.";
    }
    // if (!values.customer_id) {
    //   errors.customer_id = "Customer Name Required.";
    // }
    // if (!values.driver_id) {
    //   errors.driver_id = "Driver Name Required.";
    // }
    // if (!values.fleet_id) {
    //   errors.fleet_id = "Vehicle Number Required.";
    // }
    if (!values.created_by) {
      errors.created_by = "Order Placed By Required.";
    }
    // if (!values.pick_datetime) {
    //   errors.pick_datetime = "Pickup Date Time Required.";
    // }
    // if (!values.arrived_at) {
    //   errors.arrived_at = "Arrival Date Time Required.";
    // }
    return errors;
  };

  onSubmit = values => {
    console.log(values);
    let check = false;
    if (values.order_status === 'Confirmed') {
      if (values.pick_datetime) {
        check = false;
      } else {
        check = true;
      }
    }
    if (check) {
      Alerts.swalErrorAlert('Pickup Date Required for Order Status Confirmed');
    } else {
      var payloadData = {
        functionName: "UpdateOrder",
        postData: {
          created_at: values.created_at,
          order_status: values.order_status,
          driver_id: values.driver_id,
          fleet_id: values.fleet_id,
          remarks: values.remarks,
          po_no: values.po_no,
          consignment: values.consignment,
          created_by: values.created_by,
          pick_datetime: values.pick_datetime,
          arrived_at: values.arrived_at,
          customer_id: this.props.data.customer_id,
          _id: this.props.data._id
        }
      }
      this.setState({
        loading: !this.state.loading,
      });
      CustomerService.updateCustomerOrderDetails(payloadData).then((response) => {
        console.log("API Response:-", response);
        this.setState({
          loading: !this.state.loading,
        });
        if (response.data.status) {
          this.setState({
            edited: "true",
          });
          if (response.data.message !== '') {
            this.getSingleOrder();
            Alerts.swalSuccessAlert(response.data.message).then(res => {
              console.log("Resp:-", res)
              // this.props.onSuccess();
            })
          }
          else {
            Alerts.swalSuccessAlert("Customer Order Details Updated successfully.").then(res => {
              console.log("Resp:-", res)
              // this.props.onSuccess();
            })
          }
        }
        else {
          if (response.data.message !== '') {
            if (response.data.message === "Unauthorized") {
              AuthService.logout();
              this.setState({ loginNavigate: true });
              window.location.reload();
            }
            else {
              Alerts.swalErrorAlert(response.data.message).then(res => {
                console.log("Resp:-", res)
              })
            }
          }
          else {
            Alerts.swalErrorAlert("Error: Can't update details at the moment. Please try again later.")
          }
        }
      },
        (error) => {
          this.setState({
            loading: !this.state.loading,
          });
          const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          Alerts.swalErrorAlert("Network Operation failed.")
        });
    }
  }

  changeDateFormat = value => {
    return (
      moment(value).format("DD-MM-YYYY HH:mm:ss A")
    )
  }

  revealUser = value => {
    for (let i = 0; i < this.state.users.length; i++) {
      if (value === this.state.users[i]._id) {
        return (
          this.state.users[i].name
        );
      }
    }
  }

  goBack() {
    this.props.OnEditDetails(this.state.edited)
  }


  render() {
    {
      this.state.loginNavigate &&
        <Navigate to={'/login'} />
    }
    return (
      <Translation>
        {(t) => (

          <Fragment>
            <div className="form-wizard-content">
              <Row>
                <Col md="12" className="pt-1">
                  <LoadingOverlay tag="div" active={this.state.loading}
                    styles={{ overlay: (base) => ({ ...base }) }}
                    spinner={<DNALoader />}>
                    <Form onSubmit={this.onSubmit}
                      initialValues={this.state.initialvalues}
                      validate={this.validateFtn}
                      render={({ handleSubmit, values, submitting, validating, valid }) => (
                        <form onSubmit={handleSubmit}>
                          <Row>
                            <Col md="6">
                              <Row className="align-items-center">
                                <Col md="4" ><Label className="pb-2">{t('Order Date Time')}</Label></Col>
                                <Col md="8">
                                  <FormGroup>
                                    <Field name="created_at">
                                      {({ input, meta }) => (
                                        <div>
                                          <Input {...input} type="datetime" placeholder={t('Order Date Time')} value={this.changeDateFormat(this.props.data.created_at)}
                                            invalid={meta.error && meta.touched} readOnly
                                          />
                                          {meta.error && meta.touched &&
                                            <span className="text-danger">{meta.error}</span>}
                                        </div>
                                      )}
                                    </Field>
                                  </FormGroup>
                                </Col>

                              </Row>
                            </Col>
                            {/* Translate 'Status' */}
                            <Col md="6">
                              <Row className="align-items-center">
                                <Col md="4" ><Label className="pb-2">{t('Status')}</Label></Col>
                                <Col md="8">
                                  <FormGroup>
                                    <Field name="order_status">
                                      {({ input, meta }) => (
                                        <div>
                                          <Input {...input} type="select" placeholder={t('Status')}
                                            invalid={meta.error && meta.touched}
                                          >
                                            <option disabled={false} value="">{t('--Choose an option--')}</option>
                                            <option value="Quoted">{t('Quoted')}</option>
                                            <option value="Confirmed">{t('Confirmed')}</option>
                                            <option value="Postponed">{t('Postponed')}</option>
                                            <option value="Invoiced">{t('Invoiced')}</option>
                                            <option value="Assigned">{t('Assigned')}</option>
                                            <option value="Completed">{t('Completed')}</option>
                                            <option value="Cancelled">{t('Cancelled')}</option>
                                          </Input>
                                          {meta.error && meta.touched &&
                                            <span className="text-danger">{meta.error}</span>}
                                        </div>
                                      )}
                                    </Field>
                                  </FormGroup>
                                </Col>
                              </Row>
                            </Col>
                            <Col md="6">
                              <Row className="align-items-center">
                                <Col md="4" ><Label className="pb-2">{t('Driver Name')}</Label></Col>
                                <Col md="8">
                                  <FormGroup>
                                    <Field name="driver_id">
                                      {({ input, meta }) => (
                                        <div>
                                          <Input {...input} type="select" placeholder={t('Driver Name')}
                                            invalid={meta.error && meta.touched}
                                          >
                                            <option disabled={false} value="">{t('--Choose an option--')}</option>
                                            {this.props.drivers &&
                                              this.props.drivers.map((driver, inde) => (
                                                <option key={inde} value={driver._id}>{driver.name}</option>
                                              ))
                                            }
                                          </Input>
                                          {meta.error && meta.touched &&
                                            <span className="text-danger">{meta.error}</span>}
                                        </div>
                                      )}
                                    </Field>
                                  </FormGroup>
                                </Col>
                              </Row>
                            </Col>
                            {/* Translate 'Customer Name' */}
                            <Col md="6">
                              <Row className="align-items-center">
                                <Col md="4" ><Label className="pb-2">{t('Customer Name')}</Label></Col>
                                <Col md="8">
                                  <FormGroup>
                                    <Field name="customer_id">
                                      {({ input, meta }) => (
                                        <div>
                                          <Input {...input} type="text" placeholder={t('Customer Name')}
                                            value={this.props.data?.userDetails[0]?.company_name}
                                            invalid={meta.error && meta.touched}
                                          />
                                          {meta.error && meta.touched &&
                                            <span className="text-danger">{meta.error}</span>}
                                        </div>
                                      )}
                                    </Field>
                                  </FormGroup>
                                </Col>
                              </Row>
                            </Col>
                            {/* Translate 'Order Placed By' */}
                            <Col md="6">
                              <Row className="align-items-center">
                                <Col md="4" ><Label className="pb-2">{t('Order Placed By')}</Label></Col>
                                <Col md="8">
                                  <FormGroup>
                                    <Field name="created_by">
                                      {({ input, meta }) => (
                                        <div>
                                          <Input {...input} type="text" placeholder={t('Order Placed By')} readOnly value={this.revealUser(this.props.data.created_by)}
                                            invalid={meta.error && meta.touched}
                                          />
                                          {meta.error && meta.touched &&
                                            <span className="text-danger">{meta.error}</span>}
                                        </div>
                                      )}
                                    </Field>
                                  </FormGroup>
                                </Col>
                              </Row>
                            </Col>
                            {/* Translate 'Pickup Date Time' */}
                            <Col md="6">
                              <Row className="align-items-center">
                                <Col md="4" ><Label className="pb-2">{t('Pickup Date Time')}</Label></Col>
                                <Col md="8">
                                  <FormGroup>
                                    <Field name="pick_datetime" initialValue={moment(this.props.data?.pick_datetime).format('YYYY-MM-DDTHH:mm')}>
                                      {({ input, meta }) => (
                                        <div>
                                          <Input {...input}
                                            type="datetime-local" placeholder={t('Pickup Date Time')}
                                            invalid={meta.error && meta.touched}
                                          />
                                          {meta.error && meta.touched &&
                                            <span className="text-danger">{meta.error}</span>}
                                        </div>
                                      )}
                                    </Field>
                                  </FormGroup>
                                </Col>
                              </Row>
                            </Col>
                            {/* Translate 'Arrival Date Time' */}
                            <Col md="6">
                              <Row className="align-items-center">
                                <Col md="4" ><Label className="pb-2">{t('Arrival Date Time')}</Label></Col>
                                <Col md="8">
                                  <FormGroup>
                                    <Field name="arrived_at">
                                      {({ input, meta }) => (
                                        <div>
                                          <Input {...input} type="datetime-local" placeholder={t('Arrival Date Time')}
                                            invalid={meta.error && meta.touched}
                                          />
                                          {meta.error && meta.touched &&
                                            <span className="text-danger">{meta.error}</span>}
                                        </div>
                                      )}
                                    </Field>
                                  </FormGroup>
                                </Col>
                              </Row>
                            </Col>
                            {/* Translate 'Vehicle Number' */}
                            <Col md="6">
                              <Row className="align-items-center">
                                <Col md="4" ><Label className="pb-2">{t('Vehicle Number')}</Label></Col>
                                <Col md="8">
                                  <FormGroup>
                                    <Field name="fleet_id">
                                      {({ input, meta }) => (
                                        <div>
                                          <Input {...input} type="select" placeholder={t('Vehicle Number')}
                                            invalid={meta.error && meta.touched}
                                          >
                                            <option disabled={false} value="">{t('--Choose an option--')}</option>
                                            {this.props.fleets &&
                                              this.props.fleets.map((fleet, inde) => (
                                                <option key={inde} value={fleet._id}>{fleet.reg_no}</option>
                                              ))
                                            }
                                          </Input>
                                          {meta.error && meta.touched &&
                                            <span className="text-danger">{meta.error}</span>}
                                        </div>
                                      )}
                                    </Field>
                                  </FormGroup>
                                </Col>
                              </Row>
                            </Col>
                            {/* Translate 'Remarks' */}
                            <Col md="6">
                              <Row className="align-items-center">
                                <Col md="4" ><Label className="pb-2">{t('Remarks')}</Label></Col>
                                <Col md="8">
                                  <FormGroup>
                                    <Field name="remarks">
                                      {({ input, meta }) => (
                                        <div>
                                          <Input {...input} type="text" placeholder={t('Remarks')}
                                            invalid={meta.error && meta.touched}
                                          />
                                          {meta.error && meta.touched &&
                                            <span className="text-danger">{meta.error}</span>}
                                        </div>
                                      )}
                                    </Field>
                                  </FormGroup>
                                </Col>
                              </Row>
                            </Col>
                            <Col md="6">
                              <Row className="align-items-center">
                                <Col md="4" ><Label className="pb-2">{t('PO No.')}</Label></Col>
                                <Col md="8">
                                  <FormGroup>
                                    <Field name="po_no">
                                      {({ input, meta }) => (
                                        <div>
                                          <Input {...input} type="text" placeholder={t('PO No.')}
                                            invalid={meta.error && meta.touched}
                                          />
                                          {meta.error && meta.touched &&
                                            <span className="text-danger">{meta.error}</span>}
                                        </div>
                                      )}
                                    </Field>
                                  </FormGroup>
                                </Col>
                              </Row>
                            </Col>
                            <Col md="6">
                              <Row className="align-items-center">
                                <Col md="4" ><Label className="pb-2">{t('Consignment No.')}</Label></Col>
                                <Col md="8">
                                  <FormGroup>
                                    <Field name="consignment">
                                      {({ input, meta }) => (
                                        <div>
                                          <Input {...input} type="text" placeholder={t('Consignment No.')}
                                            invalid={meta.error && meta.touched}
                                          />
                                          {meta.error && meta.touched &&
                                            <span className="text-danger">{meta.error}</span>}
                                        </div>
                                      )}
                                    </Field>
                                  </FormGroup>
                                </Col>
                              </Row>
                            </Col>
                            {/* Translate buttons */}
                            <div className="text-center mt-3">
                              <FormGroup>
                                <div className="text-center">
                                  <button type="submit" className="btn btn-secondary btn-lg btn-hover-shine me-3">
                                    {t('Update')} </button>
                                  <button type="button" className="btn btn-outline-success btn-lg btn-hover-shine me-3" onClick={this.goBack}>
                                    {t('Back')}
                                  </button>
                                </div>
                              </FormGroup>
                            </div>
                          </Row>
                        </form>
                      )}
                    />
                  </LoadingOverlay>
                </Col>
              </Row>
            </div>
          </Fragment>
        )}
      </Translation>

    );
  }

}
