import React, { Fragment, useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import DataTable from 'react-data-table-component';
import Tooltip from '@mui/material/Tooltip';

import {
  Row,
  Col,
  Input, CardBody, Card
} from "reactstrap";

import ServicesService from "./services.service";
import AuthService from "../../Authentication/auth.service";
import { useTranslation } from 'react-i18next';

import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../../components/Loaders/DnaLoader";
import "../../../assets/custom css/loadoverlay.scss";

import Alerts from "../../../components/Alerts/alerts";

import EditService from "./Services Modals/EditService";


const ServicesList = () => {

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [services, setServices] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState("");
  const [searchName, setsearchName] = useState('');
  const [searchCode, setsearchCode] = useState('');
  const [searchDesc, setsearchDesc] = useState('');

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    fetchServices(0, pageLimit);
  }, [pageLimit]);


  function fetchServices(page_index, page_limit) {
    var filter = {};
    if (searchName !== "") {
      console.log("Search name:-", searchName)
      filter["name"] = searchName
    }
    if (searchCode !== "") {
      console.log("Search Code:-", searchCode)
      filter["code"] = searchCode
    }
    if (searchDesc !== "") {
      filter["description"] = searchDesc
    }

    var offset = (page_index - 1) * page_limit;
    if (offset < 1) {
      offset = 0;
    }
    var getData = {
      functionName: "GetServices",
      postData: {
        "sort": { _id: -1 },
        "offset": offset,
        "limit": page_limit,
        "filter": filter
      },
    }
    setLoading(true);
    ServicesService.getAllServices(getData).then((response) => {
      setLoading(false);
      console.log("Get Services response:-", response)
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        setServices(response.data.data);
        setTotalRows(response.data.count.count);
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent)
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  };

  function handlePageChange(page_index) {
    console.log("Page index:-", page_index)
    setCurrentPage(page_index)
    fetchServices(page_index, pageLimit);
  }

  async function handlePerRowsChange(newPageLimit, page_index) {
    setPageLimit(newPageLimit);
  }

  function nameFilter(e) {
    console.log(e.target.value);
    setsearchName(e.target.value)
  }
  function codeFilter(e) {
    console.log(e.target.value);
    setsearchCode(e.target.value)
  }
  function descFilter(e) {
    console.log(e.target.value);
    setsearchDesc(e.target.value)
  }


  function resetFilters() {
    setResetPaginationToggle(!resetPaginationToggle);
    setsearchName('');
    setsearchCode('');
    setsearchDesc('');
    var offset = 0;
    var getData = {
      functionName: "GetServices",
      postData: {
        "sort": { _id: -1 },
        "offset": offset,
        "limit": pageLimit,
        "filter": {}
      },
    }
    setLoading(true);
    ServicesService.getAllServices(getData).then((response) => {
      setLoading(false);
      console.log("Get Services response:-", response)
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        setServices(response.data.data);
        setTotalRows(response.data.count.count);
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent)
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  }

  function searchServices() {
    setResetPaginationToggle(!resetPaginationToggle);
    var page = 0
    handlePageChange(page++)
  }


  function confirmDeleteService(_id) {
    let msg = t("Once deleted, you will not be able to recover this service!")
    Alerts.swalConfirmDeleteAlert(msg).then(res => {
      console.log("Resp:-", res)
      if (res === true) {
        sendDelete(_id)
      }
    })
  };


  function sendDelete(id) {
    setLoading(true);
    ServicesService.deleteService(id).then((response) => {
      setLoading(false);
      console.log("Delete service response:-", response)
      if (response.data.status) {
        fetchServices(currentPage, pageLimit)
        if (response.data.message !== '') {
          Alerts.swalSuccessAlert(response.data.message)
        }
        else {
          Alerts.swalSuccessAlert(t("Service Deleted Successfully."))
        }
        console.log(response.data.message)
      }
      else {
        if (response.data.message !== '') {
          if (response.data.message === "Unauthorized") {
            AuthService.logout();
            navigate("/login");
            window.location.reload();
          }
          else {
            Alerts.swalErrorAlert(response.data.message);
          }
        }
        else {
          Alerts.swalErrorAlert(t("Error: Can't delete the service at the moment."))
        }
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        Alerts.swalErrorAlert(t("Delete operation failed"))
      })
  };

  const mycolumns = [
    {
      name: "Name",
      selector: row => row.name,
    },
    {
      name: "Code",
      selector: row => row.code,
    }, {
      name: "Description",
      selector: row => row.description,
    },
    {
      name: 'Actions',
      selector: (row) => {
        return (
          <div>
            <button className="mt-2 mb-2 me-2 btn-icon btn">
              <Tooltip placement="top" title="Master Pricing" arrow>
                <Link to="/service-details" state={row}>
                  <i className="pe-7s-note2 btn-icon-wrapper text-info"> </i>
                </Link>
              </Tooltip>
              <Tooltip placement="top" title="Delete" arrow>
                <i onClick={() => confirmDeleteService(row._id)} className="pe-7s-trash text-danger btn-icon-wrapper"> </i>
              </Tooltip>
              <Tooltip placement="top" title="Edit" arrow>
                <i>
                  <EditService data={row} />
                </i>
              </Tooltip>
            </button>
          </div>
        );
      }
    },
  ];


  return (
    <Fragment>
      <div className="app-main__outer">
        <div className="app-main__inner">
          <div className=" bg-light-gray bg-animation ">
            <div className="d-flex  justify-content-center align-items-center">
              <Col md="12" className="mx-auto app-login-box">
                <div className="app-page-title">
                  <div className="page-title-wrapper">
                    <div className="page-title-heading fw-bold">
                      <div className="page-title-icon">
                        <i className="lnr-cog icon-gradient bg-tempting-azure" />
                      </div>
                      <div>
                        Services Management
                        <div className="page-title-subheading">
                          {t('Add, Update, Delete & View Services with basic details.')}
                        </div>
                      </div>
                    </div>
                    <div className="page-title-actions">
                      <button type="button" onClick={() => navigate(-1)} className="btn btn-outline-success btn-hover-shine me-3">
                        Back
                      </button>
                    </div>
                  </div>
                </div>
                <Row>
                  <Fragment>
                    <Row>
                      <Col md="12">
                        <Card className="main-card mb-3">
                          <CardBody>

                            <LoadingOverlay tag="div" active={loading}
                              styles={{ overlay: (base) => ({ ...base }) }}
                              spinner={<DNALoader />}>
                              <Row>

                                <Col md="3">
                                  <Input type="text" name="fname" value={searchName} placeholder="Name" onChange={event => nameFilter(event)}></Input>
                                </Col>
                                <Col md="2">
                                  <Input type="text" name="fcode" value={searchCode} placeholder="Code" onChange={event => codeFilter(event)}></Input>
                                </Col>
                                <Col md="3">
                                  <Input type="text" name="fdesc" value={searchDesc} placeholder="Description" onChange={event => descFilter(event)}></Input>
                                </Col>
                                <Col md="1">
                                  <button type="button" className="btn btn-custom-color btn-hover-shine ms-1" onClick={() => searchServices()}>Search</button>
                                </Col>
                                <Col md="1">
                                  <button type="button" className="btn btn-custom-color btn-hover-shine ms-1" onClick={() => resetFilters()}>Reset</button>
                                </Col>
                                <Col md="2">
                                  <a href="/add-service" className="btn btn-custom-color btn-hover-shine ms-1">
                                    Add New</a>
                                </Col>
                              </Row>

                              <Col md="12" className="pt-3">
                                <DataTable
                                  data={services}
                                  columns={mycolumns}
                                  pagination
                                  fixedHeader
                                  fixedHeaderScrollHeight="400px"
                                  paginationServer
                                  paginationTotalRows={totalRows}
                                  onChangePage={handlePageChange}
                                  onChangeRowsPerPage={handlePerRowsChange}
                                  paginationResetDefaultPage={resetPaginationToggle}
                                />
                              </Col>

                            </LoadingOverlay>

                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Fragment>
                </Row>
              </Col>
            </div>
          </div>
        </div>
      </div >

    </Fragment >
  );
};


export default ServicesList;
