import React, { Fragment } from "react";
import { Navigate } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  Modal
} from "reactstrap";
import DataTable from 'react-data-table-component';
import { Translation } from "react-i18next";
import Tooltip from '@mui/material/Tooltip';

// import ServicesService from "../services.service";
import AuthService from "../../../../Authentication/auth.service";

import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../../../../components/Loaders/DnaLoader";
import "../../../../../assets/custom css/loadoverlay.scss";

import Alerts from "../../../../../components/Alerts/alerts";
import CustomerService from "../../Customer.service";
import ServicesService from "../../../Services Management/services.service";

import AddOrderItem from "../../Customer Modals/Customer Order Modals/AddOrderItem";
import UpdateOrderItem from "../../Customer Modals/Customer Order Modals/UpdateOrderItem";




export default class OrderItems extends React.Component {

  async componentDidMount() {
    await this.Services();
    await this.Packaging();
    this.customerSingleOrderItems();
  }
  constructor(props) {
    super(props);
    console.log("Received Prop in OrderItems:-", props);
    this.state = {
      loading: false,
      loginNavigate: false,
      itemsTabupdated: 'false',
      orderitems: [],
      CustomerServices: [],
      CustomerPackaging: [],
      ismodalopen: false
    };
    // this.setData = this.setData.bind(this)
    this.confirmDeleteSingleOrderItem = this.confirmDeleteSingleOrderItem.bind(this)
    this.sendDelete = this.sendDelete.bind(this)
    this.customerSingleOrderItems = this.customerSingleOrderItems.bind(this)
    this.Services = this.Services.bind(this)
    this.Packaging = this.Packaging.bind(this)
    this.handleSuccess = this.handleSuccess.bind(this)
    this.goBack = this.goBack.bind(this)
    this.openModal = this.openModal.bind(this)
    this.handleBack = this.handleBack.bind(this)
    this.handleSuccessUpdateSuccess = this.handleSuccessUpdateSuccess.bind(this)
  }

  // this.props.onSuccess();

  // setData() {
  //   this.setState({
  //     orderitems: this.props.data.orderDetails,
  //   });
  // }

  customerSingleOrderItems() {
    var getData = {
      functionName: "GetOrderDetails",
      postData: {
        order_id: this.props.data._id,
        sort: { "created_at": -1 }
      },
    }
    this.setState({
      loading: !this.state.loading,
    });
    CustomerService.getCustomerOrders(getData).then((response) => {
      this.setState({
        loading: !this.state.loading,
      });
      console.log("Get Customer Orders response:-", response)
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        this.setState({ loginNavigate: true });
        window.location.reload();
      }
      else {
        this.setState({
          orderitems: response.data.data,
        });
      }
    },
      (error) => {
        this.setState({
          loading: !this.state.loading,
        });
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent)
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert("Server Error, Please try again later.")
        }
      })
  }
  async Services() {
    var getData = {
      functionName: "getCustomerServices",
      postData: {
        "sort": { _id: -1 },
        "offset": 0,
        "limit": 1000,
        "filter": {},
        customer_id: this.props.data.customer_id

      },
    }
    this.setState({
      loading: !this.state.loading,
    });
    await ServicesService.getAllServices(getData).then((response) => {
      this.setState({
        loading: !this.state.loading,
      });
      console.log("Get Customer Orders response:-", response)
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        this.setState({ loginNavigate: true });
        window.location.reload();
      }
      else {
        console.log(response.data.data);
        this.setState({
          CustomerServices: response.data.data,
        });
      }
    },
      (error) => {
        this.setState({
          loading: !this.state.loading,
        });
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent)
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert("Server Error, Please try again later.")
        }
      })
  }
  async Packaging() {
    var getData = {
      functionName: "GetCustomerPackaging",
      postData: {
        "sort": { _id: -1 },
        "offset": 0,
        "limit": 1000,
        "filter": {},
        customer_id: this.props.data.customer_id

      },
    }
    this.setState({
      loading: !this.state.loading,
    });
    await ServicesService.GetCustomerPackaging(getData).then((response) => {
      this.setState({
        loading: !this.state.loading,
      });
      console.log("Get Customer Orders response:-", response)
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        this.setState({ loginNavigate: true });
        window.location.reload();
      }
      else {
        console.log(response.data.data);
        this.setState({
          CustomerPackaging: response.data.data,
        });
      }
    },
      (error) => {
        this.setState({
          loading: !this.state.loading,
        });
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent)
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert("Server Error, Please try again later.")
        }
      })
  }
  handleSuccess() {
    this.setState({
      ismodalopen: !this.state.ismodalopen
    })
    this.setState({
      itemsTabupdated: 'true',
    });
    this.customerSingleOrderItems();
  };

  handleSuccessUpdateSuccess() {
    this.customerSingleOrderItems();
  }

  goBack() {
    this.props.OnEditItems(this.state.itemsTabupdated)
  }
  // console.log(this.CustomerServices);
  gotcolumns = (t) => [
    {
      name: t('Service'),
      width: '20%',
      selector: row => {
        if (row.services.length > 0) {
          return (
            <div>
              {row.services?.[0]?.name}
            </div>
          );
        }
      }
    },
    {
      name: t('Packaging'),
      width: '20%',
      selector: row => {
        if (row.packaging.length > 0) {
          return (
            <div>
              {row.packaging?.[0]?.name}
            </div>
          );
        }
      }
    },
    // Other columns...
    {
      name: t('Quantity'),
      width: '10%',
      selector: row => row.quantity
    },
    {
      name: t('Weight'),
      width: '10%',
      selector: row => row.weight
    },
    {
      name: t('Price'),
      width: '10%',
      selector: row => row.price
    },
    {
      name: t('Total Price'),
      width: '10%',
      selector: row => {
        return (
          <div>
            {parseFloat(row.total_price).toFixed(2)}
          </div>
        );
      }
    },
    {
      name: t('Actions'),
      width: '10%',
      selector: (row) => {
        return (
          <div>
            <button className="mt-2 mb-2 btn-icon btn">
              <Tooltip placement="top" title={t('Update')} arrow>
                <i>
                  <UpdateOrderItem data={row} services={this.state.CustomerServices} packagings={this.state.CustomerPackaging}
                    //  packagingSizes={this.props.packagingSizes} 
                    onSuccess={this.handleSuccessUpdateSuccess} />
                </i>
              </Tooltip>
              <Tooltip placement="top" title={t('Delete')} arrow>
                <i onClick={() => this.confirmDeleteSingleOrderItem(row._id, row.order_id, row.user_id)} className="pe-7s-trash text-danger btn-icon-wrapper"> </i>
              </Tooltip>
            </button>
          </div>
        );
      }
    },
  ];


  confirmDeleteSingleOrderItem(_id, order_id, user_id) {
    let msg = "Once deleted, you will not be able to recover this item!"
    Alerts.swalConfirmDeleteAlert(msg).then(res => {
      console.log("Resp:-", res)
      if (res === true) {
        this.sendDelete(_id, order_id, user_id)
      }
    })
  };

  sendDelete(id, orderid, userid) {
    this.setState({
      loading: !this.state.loading,
    });
    CustomerService.deleteSingleOrderItem(id, orderid, userid).then((response) => {
      this.setState({
        loading: !this.state.loading,
      });
      console.log("Delete customer order item details response:-", response)
      if (response.data.status) {
        this.setState({
          itemsTabupdated: 'true',
        });
        this.customerSingleOrderItems();
        if (response.data.message !== '') {
          Alerts.swalSuccessAlert(response.data.message)
        }
        else {
          Alerts.swalSuccessAlert("Customer Order item Deleted Successfully.")
        }
        console.log(response.data.message)
      }
      else {
        if (response.data.message !== '') {
          if (response.data.message === "Unauthorized") {
            AuthService.logout();
            this.setState({ loginNavigate: true });
            window.location.reload();
          }
          else {
            Alerts.swalErrorAlert(response.data.message);
          }
        }
        else {
          Alerts.swalErrorAlert("Error: Can't delete the item at the moment.")
        }
      }
    },
      (error) => {
        this.setState({
          loading: !this.state.loading,
        });
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        Alerts.swalErrorAlert("Delete operation failed")
      })
  };

  openModal() {
    this.setState({
      ismodalopen: !this.state.ismodalopen
    })
  }

  handleBack() {
    this.setState({
      ismodalopen: !this.state.ismodalopen
    })
  }



  render() {
    {
      this.state.loginNavigate &&
        <Navigate to={'/login'} />
    }

    return (
      <Translation>
        {(t) => (
          <Fragment>
            <div className="form-wizard-content">
              <Row>
                <Col md="10" className="text-end">
                  <button className="btn-icon btn btn-success ms-5" onClick={this.goBack}>
                    <i className="pe-7s-up-arrow btn-icon-wrapper">
                      {/* {t('Back')} */}
                    </i>
                    {t('Back')}
                  </button>
                </Col>
                <Col md="2" className="text-center">
                  <a onClick={this.openModal} className="btn btn-custom-color btn-hover-shine">
                    {t('Add New')}
                  </a>
                  <Modal isOpen={this.state.ismodalopen} className="large-modal" backdrop={true}>
                    <AddOrderItem data={this.props.data} services={this.state.CustomerServices} packagings={this.state.CustomerPackaging}
                      //  packagingSizes={this.props.packagingSizes}
                      onSuccess={this.handleSuccess} onBack={this.handleBack} />
                  </Modal>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <Card>
                    <CardBody>
                      <DataTable
                        data={this.state.orderitems}
                        columns={this.gotcolumns(t)}
                        pagination
                        fixedHeader
                        fixedHeaderScrollHeight="400px"
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </Fragment>
        )}
      </Translation>

    );
  }

}
