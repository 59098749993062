import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { Fragment } from "react";
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import {  useLocation, useNavigate } from 'react-router-dom';
import cx from "classnames";
import PerfectScrollbar from "react-perfect-scrollbar";
import MetisMenu from "react-metismenu";
import CommonService from "../../pages/common.service.js";
import AuthService from "../../pages/Authentication/auth.service.js";
import $ from 'jquery';
import axios from "axios";
const API_URL  = process.env.REACT_APP_BASEURL;

const Leftbar =  () => {

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [modulelist, setmodulelist] = useState([]);
  const [modules_arr, setmModulearr] = useState([]);
  // const [userData, setUserData] = useState("");

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    checkUserData();
  }, []);

  useEffect(() => {
    if(modulelist.length === 0 ){
      // localStorage.setItem('super_access', true);
      console.log("super_access")
    }else{
      console.log("no super_access")
      // localStorage.setItem('super_access', false);

      const matchingModules = modules_arr.reduce((acc, module) => {
        const matchingModule = modulelist.find((listItem) => listItem._id === module);
        if (matchingModule) {
          acc.push(matchingModule.route); 
        }
        return acc;
      }, []);  
      const matchingSubModules = modules_arr.reduce((acc, moduleId) => {
        modulelist.forEach((listItem) => {
          const matchingChild = listItem.children.find((child) => child.id === moduleId);
          if (matchingChild) {
            acc.push(matchingChild.route);
          }
        });
        return acc;
      }, []);     
       const combinedArray = matchingModules.concat(matchingSubModules);
       console.log(combinedArray);
       localStorage.setItem('access_pages' , combinedArray)
       }
  }, [modulelist , modules_arr]);
 
  function checkUserData() {
    // var modules_arr = [];
    if(localStorage.getItem("user")){
      // setUserData(JSON.parse(localStorage.getItem("user")));
      var userData = JSON.parse(localStorage.getItem("user"));
      if(userData.role_details){
        if(userData.role_details.role_modules){
          var user_modules = userData.role_details.role_modules;
          setmModulearr(user_modules.map(x => x.module_id)); 
        }
      }
      if(userData.super_admin !==1){
        restrict_functions_access()
      }
      fetchModulesData();
    }
  };

  const fetchModulesData = async () => {
    try {
      const response = await  GetModules();
      if(response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        const data = await response.data.data;
        setmodulelist(data);
      }
    } catch (error) {
        console.log("error", error);
    }
  };


  const GetModules = () => {
    var userData = JSON.parse(localStorage.getItem("user"));
    const headers =  { 
      'Content-Type': 'application/json',
      'token': localStorage.getItem("session-id")
    }
    if(userData.super_admin===1){
      var pdata = {
        status:1
      }
    }else{
      var pdata = {
        menu_status:1,
        status:1
      }
    }
    var payload = {
      functionName: "GetModules",
      postData: pdata
    }
    return axios.post(API_URL, payload, { headers });
  };

  function inArray(needle, haystack) {
    var length = haystack.length;
    for(var i = 0; i < length; i++) {
        if(haystack[i] === needle) return true;
    }
    return false;
  };

  function restrict_functions_access(){
    const mypathname = location.pathname.replace("/", "");
    var filter = {"route":mypathname};
    CommonService.fetchModuleswithfunctions(filter).then((response) => {
      var storeduser = JSON.parse(localStorage.getItem("user"));
      var role_modules = storeduser.role_details.role_modules;
      console.log("res:-", response.data)
      if(response.data.data.length !== 0) {
        var dataobj = response.data.data[0];
        var module_id = dataobj._id;
        var modulefunctions =  role_modules.filter(function(dataobj) {
          return dataobj.module_id == module_id;
        });
        hide_functions_if_exists(modulefunctions[0])  
      }
    },
    (error) => {
     console.log("Error: " + error)
    })

  }

  function hide_functions_if_exists(functions_arr){
    console.log('functions:-', JSON.stringify(functions_arr));
    if(JSON.stringify(functions_arr) !== undefined) {
      var functions = Object.keys(functions_arr);
      console.log('functions'+functions);

      setInterval(function () { 
        functions.forEach(function(func){
          if(functions_arr[func] ===0 ){
            $('.'+func).remove();
            //console.log(func+'-removed')
          }else{
            //console.log(func+'-allowed')
          }
        });
      }, 1000);
    }
    else {
      navigate("/restricted-access");
      window.location.reload();
    } 
  }

  var userData = JSON.parse(localStorage.getItem("user"));
  const navi=[];
  for(var i = 0;i < modulelist.length;i++){
    var item = modulelist[i];
    if(userData.super_admin===1){
      var is_assigned =  1;
    }else{
      var is_assigned =  inArray(item._id,modules_arr);
    }
    
    if(is_assigned){
      var child_arr = item.children;
      if(child_arr.length > 0 ){

        var content = [];
        child_arr.forEach(childitem => {
          if(userData.super_admin===1){
            var is_assignedchild =  1;
          }else{
            var is_assignedchild =  inArray(childitem.child_id,modules_arr);
          }
          
          if(is_assignedchild){
            content.push({ 
              label: childitem.name,
              to: "/"+childitem.route
            })
          }
        });
        navi.push({
          icon: item.icon,
          label: item.name,
          content: content
        })
      }else{

        navi.push({
          icon: item.icon,
          label: item.name,
          to: "/"+item.route,
        })
        
      }
    }
    
  }
  
  // const navi=[
  //   {
  //     icon: 'pe-7s-display2',
  //     label: "Dashboard",
  //     to: '/home',
  //   },
  //   {
  //     icon: 'pe-7s-shopbag',
  //     label: "Customers",
  //     to: '/customers-list',
  //   },
  //   {
  //     icon: 'pe-7s-users',
  //     label: "System Users",
  //     to: '/users-list',
  //   },
  //   {
  //     icon: 'pe-7s-config',
  //     label: "System Settings",
  //     content: [
  //       {
  //         label: "Configuration",
  //         to: '/configuration',
  //       },
  //       {
  //         label: "Activity Logs",
  //         to: '/activity-logs',
  //       },
  //       {
  //         label: "Email Templates",
  //         to: '/email-templates',
  //       },
  //       {
  //         label: "Entities",
  //         to: '/entities-list',
  //       },
  //       {
  //         label: "Attributes",
  //         to: '/attributes-list',
  //       },
  //       {
  //         label: "Users Roles",
  //         to: '/roles-list',
  //       },
  //       {
  //         label: "Products",
  //         to: "/viewdata/63e4da2712e6990017b28353",
  //       },
  //       {
  //         label: "Product Serials",
  //         to: "/viewdata/63ee227f998aebf01c0e3339",
  //       },
  //       {
  //         label: "NTK Inspection",
  //         to: "/viewdata/63f458acd7e84ec3b4b22771",
  //       },
        
  //     ],
  //   },
  // ];

  return(
    <Fragment>
      <TransitionGroup>
        <CSSTransition component="div"
          className={cx("app-sidebar sidebar-shadow")}
            appear={true} enter={false} exit={false} timeout={500}>
          <div>
            <PerfectScrollbar>
              <div className="app-sidebar__inner">
                <h5 className="app-sidebar__heading">{t('Menu')}</h5>
                <MetisMenu 
                  activeLinkFromLocation
                  className="vertical-nav-menu " 
                  iconNamePrefix="" 
                  //classNameStateIcon="pe-7s-angle-down"
                  content={navi}
                  />
              </div>
            </PerfectScrollbar>
          </div>
        </CSSTransition>
      </TransitionGroup>
    </Fragment>
  );

};

export default Leftbar;
















 
//   return (
//     <div className="leftbar-tab-menu">
//       <div className="main-menu-inner" style={{display: "block"}}>

//         <div className="topbar-left">
//           <a href={baseURL} className="logo">
//             <span>
//               <img
//                 src={baseURL + "../assets/images/basesystemslogo.svg"}
//                 alt="logo-large"
//                 className="logo-lg logo-dark"
//               />
//             </span>
//           </a>
//         </div>

//         {show_collapse &&
//           <div className="menu-body navbar-vertical">
//           <div aria-labelledby="dasboard-tab">
//             <ul className="nav flex-column navbar-nav">
//               {menu_items && menu_items.map((item, index) => {
//                 var child_arr = item.children;
//                 if(child_arr.length > 0 ){
//                   return (
//                     <li className="nav-item" key={index}>
//                       <a
//                         className="nav-link"
//                         data-bs-toggle="collapse"
//                         role="button"
//                         aria-expanded="true"
//                         aria-controls="sidebarAnalytics"
//                         data-bs-target="#sidebarAnalytics"
//                       >
//                         <i width={18} data-feather="check-square" />
//                         {item.name}
//                       </a>
//                       <div className="collapse show" id="sidebarAnalytics">
//                       {child_arr && child_arr.map((childitem, childindex) => {
//                         var active = "";
//                         if(location.pathname === "/"+childitem.route){
//                           active ="active"
//                         }
//                           return (
//                             <ul className="nav flex-column" key={"ch"+childindex}>
//                               <li className="nav-item">
//                                 <a href={"/"+childitem.route} className={"nav-link "+active}>
//                                 {childitem.name}
//                                 </a>
//                               </li>
//                             </ul>
//                           )
//                       })}
//                       </div>
//                     </li>
//                   )
//                 }
//                 else{ 
//                   var active = "";
//                   if(location.pathname === "/"+item.route){
//                     active ="active"
//                   }
//                   return (
//                     <li className="nav-item" key={index}>
//                       <a className={"nav-link "+active} href={"/"+item.route} >
//                         <i width={18} data-feather="grid" />
//                         {item.name}
//                       </a>
//                     </li>
//                   )
//                 }
//               })}
//             </ul>
//           </div>
//           </div>
//         }

//         {!show_collapse &&
//           <div className="menu-body navbar-vertical">
//           <div aria-labelledby="dasboard-tab">
//             <ul className="nav flex-column navbar-nav">
//               {menu_items && menu_items.map((item, index) => {
//                 var child_arr = item.children;
//                 if(child_arr.length > 0 ){
//                   return (
//                     <li className="nav-item" key={index}>
//                       <a
//                         className="nav-link collapsed"
                        
//                         data-bs-toggle="collapse"
//                         role="button"
//                         aria-expanded="false"
//                         // aria-controls="sidebarAnalytics"
//                         data-bs-target="#sidebarAnalytics"
//                       >
//                         <i width={18} data-feather="check-square" />
//                         {item.name}
//                       </a>
//                       <div className="collapse" id="sidebarAnalytics">
//                       {child_arr && child_arr.map((childitem, childindex) => {
//                         var active = "";
//                         if(location.pathname === "/"+childitem.route){
//                           active ="active"
//                         }
//                           return (
//                             <ul className="nav flex-column" key={"ch"+childindex}>
//                               <li className="nav-item">
//                                 <a href={"/"+childitem.route} className={"nav-link "+active}>
//                                 {childitem.name}
//                                 </a>
//                               </li>
//                             </ul>
//                           )
//                       })}
//                       </div>
//                     </li>
//                   )
//                 }
//                 else{ 
//                   var active = "";
//                   if(location.pathname === "/"+item.route){
//                     active ="active"
//                   }
//                   return (
//                     <li className="nav-item" key={index}>
//                       <a className={"nav-link "+active} href={"/"+item.route} >
//                         <i width={18} data-feather="grid" />
//                         {item.name}
//                       </a>
//                     </li>
//                   )
//                 }
//               })}
//             </ul>
//           </div>
//           </div>
//         }

        

//       </div>
//     </div>
//   );





