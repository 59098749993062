import React, { useState, useRef, useEffect } from "react";
import { Translation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  Row,
  Col,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  Card,
  CardBody,
} from "reactstrap";
import { useTranslation } from 'react-i18next';

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import ServicesService from "../../Services Management/services.service";
import AuthService from "../../../Authentication/auth.service";

import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../../../components/Loaders/DnaLoader";
import "../../../../assets/custom css/loadoverlay.scss";

import Alerts from "../../../../components/Alerts/alerts";
import CustomerService from "../Customer.service";

const PricingAddCustomer = (props) => {
  const [modal, setModal] = useState(false);
  const form = useRef();
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const [rows, setrows] = useState([]);
  const [orderDetails, setOrderDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loginNavigate, setLoginNavigate] = useState(false);
  const [modalclosed, setModalClosed] = useState("");
  const [services, setServices] = useState([]);
  const [packagings, setPackagings] = useState([]);
  // const [packagingSizes, setPackagingSizes] = useState([]);
  const [myDataPopObj, setmyDataPopObj] = useState([]);
  const [SinglePriceObj, setSinglePriceObj] = useState([]);

  useEffect(() => {
    getServices();
    getPackaging();
    // getPackagingSizes();
  }, []);
  useEffect(() => {
    setOrderDetails([]);
    addRow1();
  }, []);

  function onCancel() {
    setModal(!modal);
  }
  const toggle = () => {
    addRow1();
    setModal(!modal);
  };

  const getServices = () => {
    var getData = {
      functionName: "GetServices",
      postData: {},
    };
    ServicesService.getAllServices(getData).then(
      (response) => {
        console.log("Get Services response:-", response);
        setServices(response.data.data);
      },
      (error) => {
        handleServiceError(error);
      }
    );
  };

  const getPackaging = () => {
    ServicesService.getPackaging().then(
      (response) => {
        console.log("Get Packagings response:-", response);
        setPackagings(response.data.data);
      },
      (error) => {
        handleServiceError(error);
      }
    );
  };
  function addRow1() {
    setrows(1);
  };
  function selectChanged() {

  }
  const handleSubmit = (e) => {
    e.preventDefault();
    var formvalid0 = 'false';
    var formvalid1 = 'false';
    var formvalid2 = 'false';
    const elements0 = Array.from(
      document.getElementsByClassName('serviceclass')
    );
    var services_ids = [];
    for (let i = 0; i < elements0.length; i++) {
      if (elements0[i].value) {
        services_ids.push(elements0[i].value)
        formvalid0 = 'true'
      }
      else {
        formvalid0 = 'false'
        break;
      }
    }

    const elements1 = Array.from(
      document.getElementsByClassName('packclass')
    );
    var pack = [];
    for (let i = 0; i < elements1.length; i++) {
      if (elements1[i].value) {
        pack.push(elements1[i].value)
        formvalid1 = 'true'
      }
      else {
        formvalid1 = 'false'
        break;
      }
    }







    const elements5 = Array.from(
      document.getElementsByClassName('priceclass')
    );
    var prices = [];
    for (let i = 0; i < elements5.length; i++) {
      if (elements5[i].value) {
        prices.push(parseFloat(elements5[i].value).toFixed(2))
        formvalid2 = 'true'
      }
      else {
        formvalid2 = 'false'
        break;
      }
    }

    if (formvalid0 === 'false' || formvalid1 === 'false' || formvalid2 === 'false') {
      Alerts.swalErrorAlert("Please fill all the fields.")
    }
    else {
      var payloadData = {
        functionName: "AddCustomerPricing",
        postData: {
          service_id: services_ids,
          packaging_id: pack,
          price: prices,
          user_id: props.data,
        },
      }
      setLoading(true);
      CustomerService.addCustomerPricing(payloadData)
        .then((response) => {
          console.log("API Response:-", response);
          setLoading(false);
          if (response.data.status) {
            if (response.data.message !== '') {
              Alerts.swalSuccessAlert(response.data.message).then(res => {
                setModal(!modal);
                props.onSuccess();
              })
            }
            else {
              Alerts.swalSuccessAlert("Customer Pricing added successfully.").then(res => {
                setModal(!modal);
                props.onSuccess();
              })
            }
          }
          else {
            if (response.data.message !== '') {
              if (response.data.message === "Unauthorized") {
                AuthService.logout();
                navigate("/login");
                window.location.reload();
              }
              else {
                Alerts.swalErrorAlert(response.data.message);
              }
            }
            else {
              Alerts.swalErrorAlert(t("Error: Can't add Customer order items at the moment. Please try again later."))
            }
          }
        },
          (error) => {
            setLoading(false);
            const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            Alerts.swalErrorAlert(t("Network Operation failed."))
          });
    }
  }

  function addRow() {
    // if (orderDetails[priceidx] === undefined) {
    //   AlertService.swalErrorAlert("Please Fill Existing Rows First")
    // } else if (orderDetails[priceidx]) {
    //   if (!orderDetails[priceidx].packaging
    //     // || !orderDetails[priceidx].packagingsize 
    //     || !orderDetails[priceidx].services) {
    //     AlertService.swalErrorAlert("Please Fill existing Rows First")
    //   } else {
    var saddRow = rows;
    saddRow = saddRow + 1;
    setrows(saddRow);

    //   }
    // } else {
    //   var saddRow = rows;
    //   saddRow = saddRow + 1;
    //   setrows(saddRow);
    // }
  }
  function remove_option(index) {
    if (index !== 0) {
      document.getElementById('parent' + index).remove();
    }
  }

  const handleServiceError = (error) => {
    const errContent =
      (error.response && error.response.data) || error.message || error.toString();
    console.log("Net:-", errContent);
    if (errContent) {
      Alerts.swalErrorAlert(errContent);
    } else {
      Alerts.swalErrorAlert("Server Error, Please try again later.");
    }
  };

  const validateFtn = (values) => {
    const errors = {};
    if (!values.service_id) {
      errors.service_id = "Service Name Required.";
    }
    if (!values.packaging_id) {
      errors.packaging_id = "Packaging Name Required.";
    }
    // if (!values.packaging_size_id) {
    //   errors.packaging_size_id = "Packaging Size Required.";
    // }
    if (!values.price) {
      errors.price = "Price Required.";
    }
    return errors;
  };


  return (
    <Translation>
      {(t) => (
        <span className="d-inline-block float-end mb-2 me-2">
          <a onClick={toggle} className="btn btn-custom-color btn-hover-shine ms-1">
            {t("Add New")}
          </a>
          <Modal isOpen={modal} toggle={toggle} className="custom_modal_width" backdrop={true}>
            <Row>
              <Col md="12">
                <Card className="main-card mb-3">
                  <CardBody>
                    <LoadingOverlay tag="div" active={loading}
                      styles={{ overlay: (base) => ({ ...base }) }}
                      spinner={<DNALoader />}>
                      <Form onSubmit={handleSubmit} ref={form} default>
                        <Row>
                          <h4 className="fw-bold mb-1">{t("Add Customer Pricing")}</h4>
                        </Row>

                        {Array.from({ length: rows }, (_, index) => {
                          if (index < myDataPopObj.length) {
                          } return (
                            <div>
                              <Row id={"parent" + index} className="mt-4">
                                <Col md="3">
                                  <div className="form-group">
                                    <select type="select" className="form-select serviceclass" name="service_id" onChange={(ev) => {
                                      selectChanged("services", index, ev)
                                    }}
                                    >
                                      <option disabled={false} value="">{t("Service")}</option>
                                      {services &&
                                        services.map((serv, indexx) => (
                                          <option key={indexx} value={serv._id}>{serv.name}</option>
                                        ))
                                      }
                                    </select>
                                  </div>
                                </Col>
                                <Col md="3">
                                  <div className="form-group">
                                    <select type="select" className="form-select packclass" name="packaging_id" onChange={(ev) => {
                                      selectChanged("packaging", index, ev)
                                    }}
                                    >
                                      <option disabled={false} value="">{t("Packaging")}</option>
                                      {packagings &&
                                        packagings.map((pack, inde) => (
                                          <option key={inde} value={pack._id}>{pack.name}</option>
                                        ))
                                      }
                                    </select>
                                  </div>
                                </Col>

                                <Col md="3" >
                                  <div className="form-group">
                                    <Input type="number" onChange={(ev) => {
                                      selectChanged("price", index, ev)
                                    }}
                                      placeholder={t("Price")} className="form-control priceclass" name="price"
                                    />
                                  </div>
                                </Col>
                                <Col md="3" className="pt-1">
                                  <i onClick={() => addRow()} className="lnr-plus-circle h3 fw-bold"> </i>
                                  {rows !== 1 &&
                                    <i onClick={() => remove_option(index)} className="lnr-cross-circle h3 fw-bold"> </i>
                                  }
                                </Col>
                              </Row>
                            </div>
                          )
                        })}
                        <Row className="mt-5">
                          <div className="text-center form-group">
                            <button type="submit" className="btn btn-secondary btn-lg btn-hover-shine me-3">
                              {t("Save")} </button>
                            <button type="button" onClick={() => onCancel()} className="btn btn-outline-success btn-lg btn-hover-shine me-3">
                              {t("Cancel")}
                            </button>
                          </div>

                        </Row>
                      </Form>
                    </LoadingOverlay>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Modal >
        </span >
      )}
    </Translation >
  );
};

export default PricingAddCustomer;
// import React, { useState, useEffect } from "react";
// import { Translation } from "react-i18next";
// import { Navigate } from "react-router-dom";
// import {
//   Row,
//   Col,
//   FormGroup,
//   Input,
//   Modal,
//   ModalHeader,
//   ModalBody,
//   Card,
//   CardBody,
// } from "reactstrap";
// import { Form, Field } from "react-final-form";
// import ServicesService from "../../Services Management/services.service";
// import AuthService from "../../../Authentication/auth.service";
// import AlertService from "../../../../components/Alerts/alerts";

// import LoadingOverlay from "react-loading-overlay-ts";
// import DNALoader from "../../../../components/Loaders/DnaLoader";
// import "../../../../assets/custom css/loadoverlay.scss";

// import Alerts from "../../../../components/Alerts/alerts";
// import CustomerService from "../Customer.service";

// const PricingAddCustomer = (props) => {
//   const [modal, setModal] = useState(false);
//   const [loading, setLoading] = useState(false);
//   const [loginNavigate, setLoginNavigate] = useState(false);
//   const [modalclosed, setModalClosed] = useState("");
//   const [services, setServices] = useState([]);
//   const [packagings, setPackagings] = useState([]);
//   const [packagingSizes, setPackagingSizes] = useState([]);
//   const [rows, setrows] = useState([]);
//   const [orderDetails, setOrderDetails] = useState([]);

//   const [fservice_id, setfservice_id] = useState("");
//   const [fpackaging_id, setfpackaging_id] = useState("");
//   const [fpackaging_size_id, setfpackaging_size_id] = useState("");
//   const [myDataPopObj, setmyDataPopObj] = useState([]);
//   const [priceidx, setpriceidx] = useState(0);

//   useEffect(() => {
//     getServices();
//     getPackaging();
//     getPackagingSizes();
//   }, []);

//   const toggle = () => {
//     setModal(!modal);
//   };

//   const getServices = () => {
//     var getData = {
//       functionName: "GetServices",
//       postData: {},
//     };
//     ServicesService.getAllServices(getData).then(
//       (response) => {
//         console.log("Get Services response:-", response);
//         setServices(response.data.data);
//       },
//       (error) => {
//         handleServiceError(error);
//       }
//     );
//   };

//   function addRow1() {
//     setrows(1);
//   };

//   const getPackaging = () => {
//     ServicesService.getPackaging().then(
//       (response) => {
//         console.log("Get Packagings response:-", response);
//         setPackagings(response.data.data);
//       },
//       (error) => {
//         handleServiceError(error);
//       }
//     );
//   };

//   const getPackagingSizes = () => {
//     ServicesService.getPackagingSizes().then(
//       (response) => {
//         console.log("Get PackagingSizes response:-", response);
//         setPackagingSizes(response.data.data);
//       },
//       (error) => {
//         handleServiceError(error);
//       }
//     );
//   };

//   function selectChanged(type, ind, ev) {
//     console.log("type:-", type)
//     console.log("index:-", ind)
//     console.log("value:-", ev.target.value)
//     setpriceidx(ind)
//     const updatedOrderDetails = [...orderDetails];
//     const newValue = { [type]: ev.target.value };
//     updatedOrderDetails[ind] = {
//       ...updatedOrderDetails[ind],
//       ...newValue,
//     };
//     setOrderDetails(updatedOrderDetails);
//     if (type === "services") {
//       setfservice_id(ev.target.value)
//     }
//     if (type === "packaging") {
//       setfpackaging_id(ev.target.value)
//     }
//     if (type === "packagingsize") {
//       setfpackaging_size_id(ev.target.value)
//     }
//   }
//   const handleServiceError = (error) => {
//     const errContent =
//       (error.response && error.response.data) || error.message || error.toString();
//     console.log("Net:-", errContent);
//     if (errContent) {
//       Alerts.swalErrorAlert(errContent);
//     } else {
//       Alerts.swalErrorAlert("Server Error, Please try again later.");
//     }
//   };
//   function addRow() {
//     if (orderDetails[priceidx] === undefined) {
//       AlertService.swalErrorAlert("Please Fill Existing Rows First")
//     } else if (orderDetails[priceidx]) {
//       if (!orderDetails[priceidx].packaging || !orderDetails[priceidx].packagingsize || !orderDetails[priceidx].services) {
//         AlertService.swalErrorAlert("Please Fill existing Rows First")
//       } else {
//         var saddRow = rows;
//         saddRow = saddRow + 1;
//         setrows(saddRow);

//       }
//     } else {
//       var saddRow = rows;
//       saddRow = saddRow + 1;
//       setrows(saddRow);
//     }
//   }

//   function remove_option(index) {
//     if (index !== 0) {
//       document.getElementById('parent' + index).remove();
//     }
//   }
//   const validateFtn = (values) => {
//     const errors = {};
//     if (!values.service_id) {
//       errors.service_id = "Service Name Required.";
//     }
//     if (!values.packaging_id) {
//       errors.packaging_id = "Packaging Name Required.";
//     }
//     if (!values.packaging_size_id) {
//       errors.packaging_size_id = "Packaging Size Required.";
//     }
//     if (!values.price) {
//       errors.price = "Price Required.";
//     }
//     return errors;
//   };

//   const onSubmit = (values) => {
//     console.log(values);
//     var payloadData = {
//       functionName: "AddCustomerPricing",
//       postData: {
//         service_id: values.service_id,
//         packaging_id: values.packaging_id,
//         packaging_size_id: values.packaging_size_id,
//         price: values.price,
//         user_id: props.data,
//       },
//     };

//     setLoading(true);
//     CustomerService.addCustomerPricing(payloadData)
//       .then((response) => {
//         console.log("API Response:-", response);
//         setLoading(false);
//         if (response.data.status) {
//           props.onSuccess();
//           if (response.data.message !== "") {
//             Alerts.swalSuccessAlert(response.data.message).then((res) => {
//               console.log("Resp:-", res);
//               setModalClosed("true");
//               toggle();
//             });
//           } else {
//             Alerts.swalSuccessAlert("Customer Pricing Added successfully.").then(
//               (res) => {
//                 console.log("Resp:-", res);
//                 toggle();
//               }
//             );
//           }
//         } else {
//           if (response.data.message !== "") {
//             if (response.data.message === "Unauthorized") {
//               AuthService.logout();
//               setLoginNavigate(true);
//               window.location.reload();
//             } else {
//               Alerts.swalErrorAlert(response.data.message).then((res) => {
//                 console.log("Resp:-", res);
//               });
//             }
//           } else {
//             Alerts.swalErrorAlert(
//               "Error: Can't Add Customer Pricing at the moment. Please try again later."
//             );
//           }
//         }
//       })
//       .catch((error) => {
//         setLoading(false);
//         const resMessage =
//           (error.response && error.response.data && error.response.data.message) ||
//           error.message ||
//           error.toString();
//         Alerts.swalErrorAlert("Network Operation failed.");
//       });
//   };

//   return (
//     <Translation>
//       {(t) => (
//         <span className="d-inline-block float-end mb-2 me-2">
//           <a onClick={toggle} className="btn btn-custom-color btn-hover-shine ms-1">
//             Add New
//           </a>
//           <Modal isOpen={modal} toggle={toggle} className="custom_modal_width" backdrop={true}>
//             <LoadingOverlay
//               tag="div"
//               active={loading}
//               styles={{ overlay: (base) => ({ ...base }) }}
//               spinner={<DNALoader />}
//             >
//               <ModalHeader>{t("Add Customer Pricing")}</ModalHeader>
//               <ModalBody>
//                 <Card className="main-card mb-3">
//                   <CardBody>
//                     <Row>
//                       <Col md="12" className="pt-3">
//                         <Form
//                           onSubmit={onSubmit}
//                           validate={validateFtn}
//                           render={({ handleSubmit, values, submitting, validating, valid }) => (
//                             <form onSubmit={handleSubmit}>
//                                                       {Array.from({ length: rows }, (_, index) => {
//                           var preprice = "";
//                           if (index < myDataPopObj.length) {
//                             preprice = myDataPopObj[index];
//                           }
//                           return (
//                             <div>
//                               <Row id={"parent" + index} className="mt-4">
//                                 <Col md="2">
//                                   <div className="form-group">
//                                     <select type="select" className="form-select serviceclass" name="service_id" onChange={(ev) => {
//                                       selectChanged("services", index, ev)
//                                     }}
//                                     >
//                                       <option disabled={false} value="">Service</option>
//                                       {services &&
//                                         services.map((serv, indexx) => (
//                                           <option key={indexx} value={serv._id}>{serv.name}</option>
//                                         ))
//                                       }
//                                     </select>
//                                   </div>
//                                 </Col>
//                                 <Col md="2">
//                                   <div className="form-group">
//                                     <select type="select" className="form-select packclass" name="packaging_id" onChange={(ev) => {
//                                       selectChanged("packaging", index, ev)
//                                     }}
//                                     >
//                                       <option disabled={false} value="">Packaging</option>
//                                       {packagings &&
//                                         packagings.map((pack, inde) => (
//                                           <option key={inde} value={pack._id}>{pack.name}</option>
//                                         ))
//                                       }
//                                     </select>
//                                   </div>
//                                 </Col>
//                                 <Col md="2">
//                                   <div className="form-group">
//                                     <select type="select" className="form-select sizeclass" name="packaging_size_id" onChange={(ev) => {
//                                       selectChanged("packagingsize", index, ev)
//                                     }}
//                                     >
//                                       <option disabled={false} value="">Size</option>
//                                       {packagingSizes &&
//                                         packagingSizes.map((size, ind) => (
//                                           <option key={ind} value={size._id}>{size.name}</option>
//                                         ))
//                                       }
//                                     </select>
//                                   </div>
//                                 </Col >
//                                 <Col md="2">
//                                   <div className="form-group">
//                                     <Input type="text" placeholder="Quantity" className="form-control qtyclass" name="quantity"
//                                     />
//                                   </div>
//                                 </Col>
//                                 <Col md="2">
//                                   <div className="form-group">
//                                     <Input type="text" placeholder="Weight" className="form-control weightclass" name="weight"
//                                     />
//                                   </div>
//                                 </Col>
//                                 <Col md="1" >
//                                   <div className="form-group">
//                                     <Input type="text" placeholder="Price" className="form-control priceclass" value={preprice} name="price"
//                                     />
//                                   </div>
//                                 </Col>
//                                 <Col md="1" className="pt-1">
//                                   <i onClick={() => addRow()} className="lnr-plus-circle h3 fw-bold"> </i>
//                                   {rows !== 1 &&
//                                     <i onClick={() => remove_option(index)} className="lnr-cross-circle h3 fw-bold"> </i>
//                                   }
//                                 </Col>
//                               </Row>
//                             </div>
//                           )
//                         })}
//                               <Col md="12">
//                                 <FormGroup>
//                                   <Field name="service_id">
//                                     {({ input, meta }) => (
//                                       <div>
//                                         <Input {...input} type="select" className="packclass"
//                                           invalid={meta.error && meta.touched}
//                                         >
//                                           <option disabled={false} value="">Service</option>
//                                           {services &&
//                                             services.map((service, index) => (
//                                               <option key={index} value={service._id}>{service.name}</option>
//                                             ))
//                                           }
//                                         </Input>
//                                         {meta.error && meta.touched &&
//                                           <span className="text-danger">{meta.error}</span>}
//                                       </div>
//                                     )}
//                                   </Field>
//                                 </FormGroup>
//                               </Col>
//                               <Col md="12">
//                                 <FormGroup>
//                                   <Field name="packaging_id">
//                                     {({ input, meta }) => (
//                                       <div>
//                                         <Input {...input} type="select" className="packclass"
//                                           invalid={meta.error && meta.touched}
//                                         >
//                                           <option disabled={false} value="">Packaging</option>
//                                           {packagings &&
//                                             packagings.map((pack, inde) => (
//                                               <option key={inde} value={pack._id}>{pack.name}</option>
//                                             ))
//                                           }
//                                         </Input>
//                                         {meta.error && meta.touched &&
//                                           <span className="text-danger">{meta.error}</span>}
//                                       </div>
//                                     )}
//                                   </Field>
//                                 </FormGroup>
//                               </Col>
//                               <Col md="12">
//                                 <FormGroup>
//                                   <Field name="packaging_size_id">
//                                     {({ input, meta }) => (
//                                       <div>
//                                         <Input {...input} type="select" className="sizeclass"
//                                           invalid={meta.error && meta.touched}
//                                         >
//                                           <option disabled={false} value="">Size</option>
//                                           {packagingSizes &&
//                                             packagingSizes.map((size, ind) => (
//                                               <option key={ind} value={size._id}>{size.name}</option>
//                                             ))
//                                           }
//                                         </Input>
//                                         {meta.error && meta.touched &&
//                                           <span className="text-danger">{meta.error}</span>}
//                                       </div>
//                                     )}
//                                   </Field>
//                                 </FormGroup>
//                               </Col >
//                               <Col md="12" >
//                                 <FormGroup>
//                                   <Field name="price" >
//                                     {({ input, meta }) => (
//                                       <div>
//                                         <Input {...input} type="text" placeholder="Price" className="priceclass"
//                                           invalid={meta.error && meta.touched}
//                                         />
//                                         {meta.error && meta.touched &&
//                                           <span className="text-danger">{meta.error}</span>}
//                                       </div>
//                                     )}
//                                   </Field>
//                                 </FormGroup>
//                               </Col>
//                               <div className="text-center">
//                                 <FormGroup>
//                                   <div className="text-center">
//                                     <button
//                                       type="submit"
//                                       className="btn btn-secondary btn-lg btn-hover-shine me-3"
//                                     >
//                                       Add
//                                     </button>
//                                     <button
//                                       type="button"
//                                       className="btn btn-outline-success btn-lg btn-hover-shine me-3"
//                                       onClick={toggle}
//                                     >
//                                       Cancel
//                                     </button>
//                                   </div>
//                                 </FormGroup>
//                               </div>
//                             </form>
//                           )}
//                         />
//                       </Col>
//                     </Row>
//                   </CardBody>
//                 </Card>
//               </ModalBody>
//             </LoadingOverlay>
//           </Modal>
//         </span>
//       )}
//     </Translation>
//   );
// };

// export default PricingAddCustomer;
