import React, { useState, useEffect, Fragment } from "react";
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from "react-router-dom";
import { Col, Row, Label, FormGroup, Input, Card, CardBody } from "reactstrap";
import { Form, Field } from "react-final-form";
import moment from "moment";

import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../../../components/Loaders/DnaLoader";
// import "../../../assets/custom css/loadoverlay.scss";

import Alerts from "../../../../components/Alerts/alerts";

import PaymentService from "./payments.service.js";
import AuthService from "../../../Authentication/auth.service";
import RoleService from "../../../Roles/roles.service";


const AddUser = () => {

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;
  console.log(state);
  const [loading, setLoading] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [customer, setCustomer] = useState([]);

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    fetchCustomers();

  }, []);

  const fetchCustomers = async () => {
    try {
      var getData = {
        functionName: "GetCustomers",
        postData: {
          "sort": { _id: -1 },
          "offset": 0,
          "limit": 1000,
          "filter": {}
        },
      }
      const response = await PaymentService.getAllCustomers(getData);
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        const data = await response.data.data;
        let customer;
        console.log(data);
        console.log(state.state);
        data.forEach((customers) => {
          if (customers._id === state.state.customer_id) {
            customer = customers;
          }
        });
        console.log(customer);
        setCustomer(customer);
        setCustomers(data);
        console.log(data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const onSubmit = values => {
    console.log(values);
    var payloadData = {
      _id: state.state._id,
      functionName: "UpdatePayments",
      invoice_number: values.invoice_number,
      amount: parseFloat(values.total_amount).toFixed(2),
      total_amount: parseFloat(state.state.total_amount).toFixed(2),
      payment_method: values.payment_method,
      customer_id: customer._id,
      transaction_id: values.transaction_id,
      channel: values.channel,
      payment_date: values.payment_date,
      order_no: values.order_no,
      invoice_id: state.state.invoice_id,
      currency: localStorage.getItem("currency")

    }
    console.log(payloadData);
    setLoading(true);
    PaymentService.updatePayment(payloadData).then((response) => {
      console.log("API Response:-", response);
      setLoading(false);
      if (response.data.status) {
        if (state.check) {
          localStorage.setItem('tab5', "5");
        }

        navigate(-1)
        if (response.data.message !== '') {
          Alerts.swalSuccessAlert(response.data.message)
        }
        else {
          Alerts.swalSuccessAlert(t("Payment Updated Successfully."));
        }
      }
      else {
        if (response.data.message !== '') {
          if (response.data.message === "Unauthorized") {
            AuthService.logout();
            navigate("/login");
            window.location.reload();
          }
          else {
            Alerts.swalErrorAlert(response.data.message);
          }
        }
        else {
          Alerts.swalErrorAlert(t("Error: Can't add Payment at the moment. Please try again later."))
        }
      }
    },
      (error) => {
        setLoading(false);
        const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        Alerts.swalErrorAlert(t("Network Operation failed."))
      });

  }
  function getCurrentDateTime() {
    const currentDate = new Date();
    const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: true };
    return currentDate.toLocaleString('en-US', options);
  }

  const validateFtn = (values) => {
    const errors = {};
    if (!values.invoice_number) {
      errors.invoice_number = t("Invoice Number Required.");
    }
    if (!values.total_amount) {
      errors.total_amount = t("Amount Required.");
    }
    if (!values.customer_id) {
      errors.customer_id = t("Customer Required.");
    }
    // if (!values.transaction_id) {
    //   errors.transaction_id = t("Transaction Required.");
    // }
    // if (!values.channel) {
    //   errors.channel = t("Channel Required  ");
    // }
    // if (!values.payment_method) {
    //   errors.payment_method = t("Method Required.");
    // }
    if (!values.payment_date) {
      errors.payment_date = t("Payment Date Required");
    }

    return errors;
  };

  return (
    <Fragment>
      <div className="app-main__outer">
        <div className="app-main__inner">
          <div className="app-page-title">
            <div className="page-title-wrapper justify-content-left">
              <div className="page-title-heading fw-bold">
                <div className="page-title-icon">
                  <i className="pe-7s-add-user icon-gradient bg-happy-green" />
                </div>
                <div>{t('Update Payment for Invoice')}
                  <div className="page-title-subheading">
                    {t('Fill the form below to Update payment details for invoice.')}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-center">
            <div className="col-md-12">
              <Card className="main-card mb-3">
                <CardBody>
                  <Form onSubmit={onSubmit}
                    validate={validateFtn}
                    initialValues={state.state}
                    render={({ handleSubmit, values, submitting, validating, valid }) => (
                      <form onSubmit={handleSubmit}>
                        <LoadingOverlay tag="div" active={loading}
                          styles={{ overlay: (base) => ({ ...base }) }}
                          spinner={<DNALoader />}>
                          <Row>
                            <Col md={6}>
                              <FormGroup>
                                <Field name="invoice_number">
                                  {({ input, meta }) => (
                                    <div>
                                      <Label for="invoice_number">{t('Invoice Number')}</Label>
                                      <Input {...input} type="text"
                                        invalid={meta.error && meta.touched}
                                        placeholder={t('Invoice Number')}
                                        disabled
                                      />
                                      {meta.error && meta.touched &&
                                        <span className="text-danger">{meta.error}</span>}
                                    </div>
                                  )}
                                </Field>
                              </FormGroup>
                              <FormGroup>
                                <Field name="total_amount">
                                  {({ input, meta }) => (
                                    <div>
                                      <Label for="total_amount">{t(`Amount (${localStorage.getItem("currency")})`)}</Label>
                                      <Input {...input} type="number"
                                        value={parseFloat(input.value).toFixed(2)}
                                        invalid={meta.error && meta.touched}
                                        placeholder={t('Amount')}
                                      />
                                      {meta.error && meta.touched &&
                                        <span className="text-danger">{meta.error}</span>}
                                    </div>
                                  )}
                                </Field>
                              </FormGroup>

                              <FormGroup>
                                <Field name="customer_id" initialValue={customer.pic_name}>
                                  {({ input, meta }) => (
                                    <div>
                                      <Label for="customer_id">{t('Customer Name')}</Label>
                                      <Input {...input} type="text"
                                        invalid={meta.error && meta.touched}
                                        placeholder="Customer"
                                        disabled
                                      />
                                      {meta.error && meta.touched &&
                                        <span className="text-danger">{meta.error}</span>}
                                    </div>
                                  )}
                                </Field>
                              </FormGroup>


                            </Col>
                            <Col md={6}>
                              {/* <FormGroup>
                                <Field name="channel">
                                  {({ input, meta }) => (
                                    <div>
                                      <Label for="channel">{t('Channel')}</Label>
                                      <Input {...input} type="text"
                                        invalid={meta.error && meta.touched}
                                        placeholder='Channel'
                                      />
                                      {meta.error && meta.touched &&
                                        <span className="text-danger">{meta.error}</span>}
                                    </div>
                                  )}
                                </Field>
                              </FormGroup> */}
                              <FormGroup>
                                <Field name="payment_method">
                                  {({ input, meta }) => (
                                    <div>
                                      <Label for="payment_method">{t('Payment Method')}</Label>
                                      <Input {...input} type="text"
                                        invalid={meta.error && meta.touched}
                                        placeholder='Payment Method'
                                      >
                                      </Input>
                                      {meta.error && meta.touched &&
                                        <span className="text-danger">{meta.error}</span>}
                                    </div>
                                  )}
                                </Field>
                              </FormGroup>
                              <FormGroup>
                                <Field name="order_no">
                                  {({ input, meta }) => (
                                    <div>
                                      <Label for="order_no">{t('Order Number')}</Label>
                                      <Input {...input} type="text"
                                        invalid={meta.error && meta.touched}
                                        placeholder={t('Order Number')}
                                        disabled
                                      />
                                      {meta.error && meta.touched &&
                                        <span className="text-danger">{meta.error}</span>}
                                    </div>
                                  )}
                                </Field>
                              </FormGroup>
                              {/* <FormGroup>
                                <Field name="transaction_id">
                                  {({ input, meta }) => (
                                    <div>
                                      <Label for="transaction_id">{t('Transaction Id')}</Label>
                                      <Input {...input} type="text"
                                        placeholder="Transaction "
                                      >
                                      </Input>
                                      {meta.error && meta.touched &&
                                        <span className="text-danger">{meta.error}</span>}
                                    </div>
                                  )}
                                </Field>
                              </FormGroup> */}
                              <FormGroup>
                                <Field name="payment_date">
                                  {({ input, meta }) => (
                                    <div>
                                      <Label for="payment_date">{t('Payment Date')}</Label>
                                      <Input
                                        {...input}
                                        type="date"
                                        value={moment(input.value).format("YYYY-MM-DD")}
                                        // Display the date in "YYYY-MM-DD" format in the input
                                        invalid={meta.error && meta.touched}
                                      />
                                      {meta.error && meta.touched && (
                                        <span className="text-danger">{meta.error}</span>
                                      )}
                                    </div>
                                  )}
                                </Field>
                              </FormGroup>

                            </Col>
                            <FormGroup>
                              <div className="d-flex justify-content-center">
                                <button type="button" onClick={() => {
                                  if (state.check) {
                                    localStorage.setItem('tab5', "5");
                                  }
                                  navigate(-1)
                                }} className="btn btn-outline-light btn-hover-shine me-3 mt-3">{t('Cancel')}</button>
                                <button className="btn btn-custom-color btn-hover-shine me-3 mt-3" disabled={!valid} >{t('Update Payment!')}</button>
                              </div>
                            </FormGroup>

                          </Row>
                        </LoadingOverlay>
                      </form>
                    )}
                  />
                </CardBody>
              </Card>
            </div>
          </div>

        </div>
      </div>
    </Fragment>
  );

};
export default AddUser;























{/* <div className="row">
            <div className="col-lg-3" ></div>
              <div className="col-lg-6" >
              <div className="card">
                <div className="card-body">
                  <Form onSubmit={addupdateuser} ref={form}>
                    {!successful && (
                      <div>
                        <div className="form-group">
                          <div className="col-md-12 col-xl-12" >
                            <label htmlFor="username">Name</label>
                            <Input
                              type="text"
                              className="form-control"
                              name="username"
                              value={username}
                              placeholder="User Name"
                              onChange={onChangeUsername}
                              validations={[required, vusername]}
                              autoComplete="off"
                            />
                          </div>
                        </div>
                        <br/>

                        <div className="form-group">
                          <div className="col-md-12 col-xl-12" >
                            <label htmlFor="useremail">Email</label>
                            <Input
                              type="email"
                              className="form-control"
                              name="useremail"
                              value={useremail}
                              placeholder="User Email"
                              onChange={onChangeUseremail}
                              validations={[required]}
                            />
                          </div>
                        </div>
                        <br/>

                        {!state &&
                          <div className="form-group">
                            <div className="col-md-12 col-xl-12" >
                              <label htmlFor="useremail">Password</label>
                              <Input
                                type="password"
                                className="form-control"
                                name="password"
                                value={password}
                                placeholder="User Password"
                                onChange={onChangePassword}
                                validations={[required]}
                                autoComplete="new-password"
                              />
                            </div>
                            <br/>
                          </div>
                        }

                        <div className="form-group">
                          <div className="col-md-12 col-xl-12" >
                            <label htmlFor="usercontact">Contact No.</label>
                            <Input
                              type="number"
                              className="form-control"
                              name="contact"
                              value={contact}
                              placeholder="User Contact No"
                              onChange={onChangecontact}
                              validations={[required]}
                            />
                          </div>
                        </div>
                        <br/>

                        <div className="form-group">
                          <div className="col-md-12 col-xl-12" >
                            <label htmlFor="role">Role</label>
                            <select className="form-select" onChange={onChangeRole} value={userrole}>
                              <option disabled={true} value="">--Choose an option--</option>
                              {roles &&
                                roles.map((role, inde) => (
                                  <option key = {inde} value={role._id}>{role.name}</option>
                                ))
                              }
                            </select>
                            <div className="form-group" style={{marginTop: "16px", marginLeft: "8px"}}>
                              {rolerequired == false && 
                                <p style={{color:"red", fontSize:"10px"}}>This selection is required!</p>}
                            </div>
                          </div>
                        </div>
                        <br/>

                        <div className="form-group">
                          <div className="col-md-12 col-xl-12" >
                            <label htmlFor="status">Status</label>
                            <select className="form-select" onChange={onChangeStatus} value={userstatus}>
                              <option disabled={true} value="">--Choose an option--</option>
                              {statuses &&
                                statuses.map((stat, ind) => (
                                  <option key = {ind} value={stat.id}>{stat.name}</option>
                                ))
                              }
                            </select>
                            <div className="form-group" style={{marginTop: "16px", marginLeft: "8px"}}>
                              {statusrequired == false && 
                                <p style={{color:"red", fontSize:"10px"}}>This selection is required!</p>}
                            </div>
                          </div>
                        </div>
                        <br/>
                       
                        <div className="form-group">
                          <div className="col-md-12 col-xl-12" >
                            <center>
                              <button type="button" onClick={() => navigate(-1)} className="btn btn-outline-light btn-hover-shine me-3">Cancel</button>
                              {!state &&
                                <button className="btn btn-custom-color btn-hover-shine me-3">Add User</button>
                              }
                              {state &&
                                <button className="btn btn-custom-color btn-hover-shine me-3">Update User</button>
                              }
                            </center>
                          </div>
                        </div>
                      </div>
                    )}

                    {message && (
                      <div className="form-group">
                        <div
                          className={
                            successful ? "alert alert-success" : "alert alert-danger"
                          }
                          role="alert"
                        >
                          {message}
                        </div>
                      </div>
                    )}
                    <CheckButton style={{ display: "none" }} ref={checkBtn} />
                  </Form>

                </div>
              </div>
            </div>
</div> */}



{/* <Form onSubmit={addupdateuser} ref={form}>
    {!successful && (
      <div>
        <div className="form-group">
          <div className="col-md-12 col-xl-12" >
            <label htmlFor="username">Name</label>
            <Input
              type="text"
              className="form-control"
              name="username"
              value={username}
              placeholder="User Name"
              onChange={onChangeUsername}
              validations={[required, vusername]}
              autoComplete="off"
            />
          </div>
        </div>
        <br/>

        <div className="form-group">
          <div className="col-md-12 col-xl-12" >
            <label htmlFor="useremail">Email</label>
            <Input
              type="email"
              className="form-control"
              name="useremail"
              value={useremail}
              placeholder="User Email"
              onChange={onChangeUseremail}
              validations={[required]}
            />
          </div>
        </div>
        <br/>

        {!state &&
          <div className="form-group">
            <div className="col-md-12 col-xl-12" >
              <label htmlFor="useremail">Password</label>
              <Input
                type="password"
                className="form-control"
                name="password"
                value={password}
                placeholder="User Password"
                onChange={onChangePassword}
                validations={[required]}
                autoComplete="new-password"
              />
            </div>
            <br/>
          </div>
        }

        <div className="form-group">
          <div className="col-md-12 col-xl-12" >
            <label htmlFor="usercontact">Contact No.</label>
            <Input
              type="number"
              className="form-control"
              name="contact"
              value={contact}
              placeholder="User Contact No"
              onChange={onChangecontact}
              validations={[required]}
            />
          </div>
        </div>
        <br/>

        <div className="form-group">
          <div className="col-md-12 col-xl-12" >
            <label htmlFor="role">Role</label>
            <select className="form-select" onChange={onChangeRole} value={userrole}>
              <option disabled={true} value="">--Choose an option--</option>
              {roles &&
                roles.map((role, inde) => (
                  <option key = {inde} value={role._id}>{role.name}</option>
                ))
              }
            </select>
            <div className="form-group" style={{marginTop: "16px", marginLeft: "8px"}}>
              {rolerequired == false && 
                <p style={{color:"red", fontSize:"10px"}}>This selection is required!</p>}
            </div>
          </div>
        </div>
        <br/>

        <div className="form-group">
          <div className="col-md-12 col-xl-12" >
            <label htmlFor="status">Status</label>
            <select className="form-select" onChange={onChangeStatus} value={userstatus}>
              <option disabled={true} value="">--Choose an option--</option>
              {statuses &&
                statuses.map((stat, ind) => (
                  <option key = {ind} value={stat.id}>{stat.name}</option>
                ))
              }
            </select>
            <div className="form-group" style={{marginTop: "16px", marginLeft: "8px"}}>
              {statusrequired == false && 
                <p style={{color:"red", fontSize:"10px"}}>This selection is required!</p>}
            </div>
          </div>
        </div>
        <br/>
        
        <div className="form-group">
          <div className="col-md-12 col-xl-12" >
            <center>
              <button type="button" onClick={() => navigate(-1)} className="btn btn-soft-secondary btn-block me-2">Cancel</button>
              {!state &&
                <button className="btn btn-custom-color btn-block me-2">Add User</button>
              }
              {state &&
                <button className="btn btn-custom-color btn-block me-2">Update User</button>
              }
            </center>
          </div>
        </div>
      </div>
    )}
    {message && (
      <div className="form-group">
        <div
          className={
            successful ? "alert alert-success" : "alert alert-danger"
          }
          role="alert"
        >
          {message}
        </div>
      </div>
    )}
    <CheckButton style={{ display: "none" }} ref={checkBtn} />
  </Form> */}