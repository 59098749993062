import axios from "axios";

const API_URL  = process.env.REACT_APP_BASEURL;

  
const headers =  { 
  'Content-Type': 'application/json',
  'token': localStorage.getItem("session-id")
}


const getAll = () => {
  var payload = {
    functionName: "GetUserRole",
    postData: {},
  }
  return axios.post(API_URL, payload, {headers});
};


const deleteRole = (id) => {
  var payload = {
    functionName: "DeleteUserRole",
    postData: {
      _id: id,
    },
  }
  return axios.post(API_URL, payload, {headers});
};


const addRole = payload => {
  console.log("Received addRole Payload:-", payload)
  return axios.post(API_URL, payload, {headers});
}

const updateRole = payload => {
  console.log("Received addRole Payload:-", payload)
  return axios.post(API_URL, payload, {headers});
}


const RoleService = {
  getAll,
  deleteRole,
  addRole,
  updateRole
};

export default RoleService;