import React, { Fragment, useEffect, useState } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import cx from "classnames";
import ResizeDetector from "react-resize-detector";
import { useTranslation } from 'react-i18next';

import ConfigurationService from "./pages/Configuration/configuration.service";

import Leftbar from "./components/Left Bar/Leftbar";
import Topbar from "./components/Top Bar/Topbar";

import Login from "./pages/Authentication/Login";
import ResetPassword from "./pages/Authentication/ResetPassword";
import ForgotPassword from "./pages/Authentication/ForgotPassword";

import UserLogin from "./pages/Authentication_usertypes/Login";
import UserResetPassword from "./pages/Authentication_usertypes/ResetPassword";
import UserForgotPassword from "./pages/Authentication_usertypes/ForgotPassword";

import Dashboard from "./pages/Dashboard/Dashboard";
import MyProfile from "./pages/Profile/MyProfile";

import EmailTemplates from "./pages/EmailTemplates/EmailTemplates";
import AddEmailTemplate from "./pages/EmailTemplates/AddEmailTemplate";
import UpdateEmailTemplate from "./pages/EmailTemplates/UpdateEmailTemplate";
import ViewEmailTemplate from "./pages/EmailTemplates/ViewEmailTemplate";

import Configuration from "./pages/Configuration/Configuration";
import FinanceSettings from "./pages/Settings/Settings";

import ActivityLogs from "./pages/Activity Logs/ActivityLogs";
import ViewActivityLog from "./pages/Activity Logs/ViewActivityLog";

import EntitiesList from "./pages/Entities/EntitiesList";
import AddEntity from "./pages/Entities/AddEntity";
import UpdateEntity from "./pages/Entities/UpdateEntity";

import AttributesList from "./pages/Attributes/AttributesList";
import AddAttribute from "./pages/Attributes/AddAttribute";
import UpdateAttribute from "./pages/Attributes/UpdateAttribute";



import RolesList from "./pages/Roles/RolesList";
import AddRole from "./pages/Roles/AddRole";
import UpdateRole from "./pages/Roles/UpdateRole";

import UsersList from "./pages/Users Management/UsersList";
import AddUser from "./pages/Users Management/AddUser";
import UpdateUser from "./pages/Users Management/UpdateUser";

import DataTableView from "./pages/Dynamic Modules/DataTableView";
import InsertionForm from "./pages/Dynamic Modules/InsertionForm";
import UpdationFrom from "./pages/Dynamic Modules/UpdationFrom";

import PageNotFound from "./pages/Page Not Found/PageNotFound";
import RestrictedAccess from "./pages/Restricted Access/RestrictedAccess";
import OrderDetails from './pages/Edsha/Customers/OrderDetails'

import UserFormList from "./pages/DynamicUserForms/UserFormList";
import AddUserForm from "./pages/DynamicUserForms/AddUserForm";
import UpdateUserForm from "./pages/DynamicUserForms/UpdateUserForm";

import DynamicSignup from "./pages/DynamicUserForms/DynamicSignup";

import UserFormAttributesList from "./pages/UserForm_Attributes/AttributesList";
import UserFormAddAttribute from "./pages/UserForm_Attributes/AddAttribute";
import UserFormUpdateAttribute from "./pages/UserForm_Attributes/UpdateAttribute";

import ServicesList from "./pages/Edsha/Services Management/ServicesList";
import AddService from "./pages/Edsha/Services Management/AddService";
import ServiceDetails from "./pages/Edsha/Services Management/ServiceDetails";

import FleetList from "./pages/Edsha/Fleet Management/FleetList";
import AddFleet from "./pages/Edsha/Fleet Management/AddFleet";
import UpdateFleet from "./pages/Edsha/Fleet Management/UpdateFleet";
import FleetDetails from "./pages/Edsha/Fleet Management/FleetDetails";



import CustomersReportsList from "./pages/Edsha/Reports/CustomersReportsList";
import InvoicesReportsList from "./pages/Edsha/Reports/InvoicesReportsList";
import Orders_Calendar from "./pages/Edsha/Reports/Orders_Calendar";
import PaymentsReportsList from "./pages/Edsha/Reports/PaymentsReportsList";
import OrdersReportsList from "./pages/Edsha/Reports/OrdersReportsList";


import DriverList from "./pages/Edsha/Drivers/DriversList";
import AddDriver from "./pages/Edsha/Drivers/DriverInsertionForm";
import UpdateDriver from "./pages/Edsha/Drivers/DriverUpdationForm";
import DriverDetails from "./pages/Edsha/Drivers/DriverDetails";

import InvoicesList from "./pages/Edsha/Invoices/InvoicesList";
import StatementsList from "./pages/Edsha/Statements/StatementsList";
import PaymentsList from "./pages/Edsha/Payments/PaymentsList";
import AddInvoice from "./pages/Edsha/Invoices/AddInvoice";
import UpdateInvoice from "./pages/Edsha/Invoices/UpdateInvoice";

import AddPayments from "./pages/Edsha/Invoices/payments/AddPayments";
import UpdatePayments from "./pages/Edsha/Invoices/payments/UpdatePayments";




import CustomersList from "./pages/Edsha/Customers/CustomersList";
import OrdersList from "./pages/Edsha/Customers/OrdersList";
import CustomerInsertionForm from "./pages/Edsha/Customers/CustomerInsertionForm";
import CustomerUpdationForm from "./pages/Edsha/Customers/CustomerUpdationForm";
import CustomerDetails from "./pages/Edsha/Customers/CustomerDetails";

const App = () => {

  const { t, i18n } = useTranslation();
  const location = useLocation();
  const accessPages = localStorage.getItem('access_pages')?.split(',') ?? [];
  console.log(accessPages)
  console.log(accessPages.length)
  const pathname = window.location.pathname
  useEffect(() => {
    getColors();
    // getSetLanguage();
  }, []);

  function getColors() {
    const buttonsColor = localStorage.getItem("buttons_color");
    const topbarColor = localStorage.getItem("topbar_color");
    const leftbarColor = localStorage.getItem("leftbar_color");
    const buttonsTextColor = localStorage.getItem("buttons_text_color");
    setColors(buttonsColor, topbarColor, leftbarColor, buttonsTextColor);
  }

  function setColors(buttons_color, topbar_color, leftbar_color, buttons_text_color) {
    document.documentElement.style.setProperty('--buttons-color', buttons_color);
    document.documentElement.style.setProperty('--topbar-color', topbar_color);
    document.documentElement.style.setProperty('--leftbar-color', leftbar_color);
    document.documentElement.style.setProperty('--buttons-text-color', buttons_text_color);
  }

  // function getSetLanguage() {
  //   if(localStorage.getItem("language")) {
  //     const language = localStorage.getItem("language");
  //     i18n.changeLanguage(language);
  //   }
  //   else {
  //     localStorage.setItem('language', 'en');
  //     i18n.changeLanguage('en');
  //   }
  // }

  const userType = localStorage.getItem("user_type");
  const register = localStorage.getItem("register");
  const currentUser = JSON.parse(localStorage.getItem("user"));
  const reset = localStorage.getItem("reset");
  const resetpass = localStorage.getItem("resetpass");
  const regprod = localStorage.getItem("regprod");
  // if (!currentUser && !reset && !resetpass && !regprod && userType === 'admin') {
  //   console.log("I am at checkpoint 1")
  //   return <Login />
  // }

  // if(reset && userType === 'admin') {
  //   return <ForgotPassword />
  // }

  // if(resetpass && userType === 'admin') {
  //   return <ResetPassword />
  // }


  if (location.pathname === '/login') {
    console.log("Login Page inbound")
    return <Login />
  }

  if (location.pathname === '/forgot-password') {
    console.log("Forgot Page inbound")
    return <ForgotPassword />
  }

  if (location.pathname === '/auth-password') {
    console.log("Reset Pass Page inbound")
    return <ResetPassword />
  }

  if (location.pathname === '/UserLogin') {
    console.log("Login Page inbound")
    return <UserLogin />
  }

  if (location.pathname === '/Userforgot-password') {
    console.log("Forgot Page inbound")
    return <UserForgotPassword />
  }

  if (location.pathname === '/Userauth-password') {
    console.log("Reset Pass Page inbound")
    return <UserResetPassword />
  }

  if (location.pathname === '/404') {
    console.log("PageNotFound inbound")
    return <PageNotFound />
  }

  if (location.pathname === '/restricted-access') {
    console.log("RestrictedAccess page inbound")
    return <RestrictedAccess />
  }

  else {
    return (
      <ResizeDetector handleWidth
        render={({ width }) => (
          <Fragment>
            <div
              className={cx(
                "app-container app-theme-white",
                { "fixed-header": true },
                { "fixed-sidebar": true || width < 1500 },
                { "fixed-footer": true },
                { "closed-sidebar": true || width < 1500 },
                {
                  "closed-sidebar-mobile": true || width < 1500,
                },
                { "sidebar-mobile-open": false },
                { "body-tabs-shadow-btn": true }
              )}>
              {pathname.search('/signup') < 0 && <Topbar />}
              <div className="app-main">
                {pathname.search('/signup') < 0 && <Leftbar />}
                <Routes>
                  <Route exact path={"/"} element={<Dashboard />} />
                  {/* <Route exact path="/login" element={<Login />} />
                      <Route exact path="/auth-password" element={<ResetPassword />} />
                      <Route exact path="/forgot-password" element={<ForgotPassword />} /> */}

                  <Route exact path={"/home"} element={<Dashboard />} />
                  <Route exact path="/my-profile" element={<MyProfile />} />



                  <Route exact path="/email-templates" element={accessPages.length === 1 ? <EmailTemplates /> :
                    accessPages.includes('email-templates') ? <EmailTemplates />
                      : <Navigate to="/404" />
                  } />
                  <Route exact path="/add-email-template" element={accessPages.length === 1 ? <AddEmailTemplate /> :
                    accessPages.includes('add-email-template') ? <AddEmailTemplate />
                      : <Navigate to="/404" />
                  } />
                  <Route path="/update-email-template" element={<UpdateEmailTemplate />} />
                  <Route path="/view-email-template" element={<ViewEmailTemplate />} />

                  <Route exact path="/configuration" element={accessPages.length === 1 ? <Configuration /> :
                    accessPages.includes('configuration') ? <Configuration />
                      : <Navigate to="/404" />
                  } />
                  <Route exact path="/activity-logs" element={accessPages.length === 1 ? <ActivityLogs /> :
                    accessPages.includes('activity-logs') ? <ActivityLogs />
                      : <Navigate to="/404" />
                  } />
                  <Route exact path="/view-activity-log" element={<ViewActivityLog />} />

                  <Route exact path="/entities-list" element={accessPages.length === 1 ? <EntitiesList /> :
                    accessPages.includes('entities-list') ? <EntitiesList />
                      : <Navigate to="/404" />
                  } />
                  <Route exact path="/add-entity" element={accessPages.length === 1 ? <AddEntity /> :
                    accessPages.includes('add-entity') ? <AddEntity />
                      : <Navigate to="/404" />
                  } />
                  <Route exact path="/update-entity" element={accessPages.length === 1 ? <UpdateEntity /> :
                    accessPages.includes('update-entity') ? <UpdateEntity />
                      : <Navigate to="/404" />
                  } />

                  <Route exact path="/attributes-list" element={accessPages.length === 1 ? <AttributesList /> :
                    accessPages.includes('attributes-list') ? <AttributesList />
                      : <Navigate to="/404" />
                  } />

                  <Route exact path="/add-attribute" element={accessPages.length === 1 ? <AddAttribute /> :
                    accessPages.includes('add-attribute') ? <AddAttribute />
                      : <Navigate to="/404" />
                  } />
                  <Route exact path="/update-attribute" element={accessPages.length === 1 ? <UpdateAttribute /> :
                    accessPages.includes('update-attribute') ? <UpdateAttribute />
                      : <Navigate to="/404" />
                  } />

                  <Route exact path="/roles-list" element={accessPages.length === 1 ? <RolesList /> :
                    accessPages.includes('roles-list') ? <RolesList />
                      : <Navigate to="/404" />
                  } />
                  <Route exact path="/add-role" element={accessPages.length === 1 ? <AddRole /> :
                    accessPages.includes('add-role') ? <AddRole />
                      : <Navigate to="/404" />
                  } />
                  <Route exact path="/update-role" element={<UpdateRole />} />

                  <Route exact path="/users-list" element={accessPages.length === 1 ? <UsersList /> :
                    accessPages.includes('users-list') ? <UsersList />
                      : <Navigate to="/404" />
                  } />
                  <Route exact path="/add-user" element={<AddUser />} />
                  <Route exact path="/update-user" element={<UpdateUser />} />

                  <Route path="/viewdata/:id" element={<DataTableView />} />
                  <Route path="/adddata/:id" element={<InsertionForm />} />
                  <Route path="/editdata/:id" element={<UpdationFrom />} />

                  <Route exact path="/userform-attributes-list" element={accessPages.length === 1 ? <UserFormAttributesList /> :
                    accessPages.includes('userform-attributes-list') ? <UserFormAttributesList />
                      : <Navigate to="/404" />
                  } />
                  <Route exact path="/add-userform-attribute" element={accessPages.length === 1 ? <UserFormAddAttribute /> :
                    accessPages.includes('add-userform-attribute') ? <UserFormAddAttribute />
                      : <Navigate to="/404" />
                  } />
                  <Route exact path="/update-userform-attribute" element={accessPages.length === 1 ? <UserFormUpdateAttribute /> :
                    accessPages.includes('update-userform-attribute') ? <UserFormUpdateAttribute />
                      : <Navigate to="/404" />
                  } />

                  <Route exact path="/add-payment" element={<AddPayments />} />
                  <Route exact path="/update-payment" element={<UpdatePayments />} />

                  <Route exact path="/userform-list" element={accessPages.length === 1 ? <UserFormList /> :
                    accessPages.includes('userform-list') ? <UserFormList />
                      : <Navigate to="/404" />
                  } />
                  <Route exact path="/add-userform" element={accessPages.length === 1 ? <AddUserForm /> :
                    accessPages.includes('add-userform') ? <AddUserForm />
                      : <Navigate to="/404" />
                  } />
                  <Route exact path="/update-userform" element={accessPages.length === 1 ? <UpdateUserForm /> :
                    accessPages.includes('update-userform') ? <UpdateUserForm />
                      : <Navigate to="/404" />
                  } />

                  <Route path="/register/:id" element={<DynamicSignup />} />

                  {/* <Route path="/404" element={<PageNotFound />} /> */}
                  <Route path="*" element={<Navigate to="/404" />} />
                  {/* <Route path="/restricted-access" element={<RestrictedAccess />} /> */}


                  <Route exact path="/services-list" element={accessPages.length === 1 ? <ServicesList /> :
                    accessPages.includes('services-list') ? <ServicesList />
                      : <Navigate to="/404" />
                  } />
                  <Route exact path="/add-service" element={<AddService />} />
                  <Route exact path="/service-details" element={<ServiceDetails />} />

                  <Route exact path="/fleet-list" element={accessPages.length === 1 ? <FleetList /> :
                    accessPages.includes('fleet-list') ? <FleetList />
                      : <Navigate to="/404" />
                  } />
                  <Route exact path="/add-fleet" element={<AddFleet />} />
                  <Route exact path="/edit-fleet" element={<UpdateFleet />} />
                  <Route exact path="/fleet-details" element={<FleetDetails />} />

                  <Route exact path="/driver-list" element={accessPages.length === 1 ? <DriverList /> :
                    accessPages.includes('driver-list') ? <DriverList />
                      : <Navigate to="/404" />
                  } />
                  <Route exact path="/add-driver" element={<AddDriver />} />
                  <Route exact path="/update-driver" element={<UpdateDriver />} />
                  <Route exact path="/driver-details" element={<DriverDetails />} />

                  <Route exact path="/orders-list" element={accessPages.length === 1 ? <OrdersList /> :
                    accessPages.includes('orders-list') ? <OrdersList />
                      : <Navigate to="/404" />
                  } />

                  <Route exact path="/customers-list" element={accessPages.length === 1 ? <CustomersList /> :
                    accessPages.includes('customers-list') ? <CustomersList />
                      : <Navigate to="/404" />
                  } />
                  <Route exact path="/add-customer" element={<CustomerInsertionForm />} />
                  <Route exact path="/update-customer" element={<CustomerUpdationForm />} />
                  <Route exact path="/customer-details" element={<CustomerDetails />} />
                  <Route exact path="/order-details" element={<OrderDetails />} />


                  <Route exact path="/statements-list" element={accessPages.length === 1 ? <StatementsList /> :
                    accessPages.includes('statements-list') ? <StatementsList />
                      : <Navigate to="/404" />
                  } />
                  <Route exact path="/payments-report" element={accessPages.length === 1 ? <PaymentsReportsList /> :
                    accessPages.includes('payments-report') ? <PaymentsReportsList />
                      : <Navigate to="/404" />
                  } />
                  <Route exact path="/invoices-report" element={accessPages.length === 1 ? <InvoicesReportsList /> :
                    accessPages.includes('invoices-report') ? <InvoicesReportsList />
                      : <Navigate to="/404" />
                  } />
                  <Route exact path="/orders_calendar" element={ <Orders_Calendar /> } />

                  <Route exact path="/orders-report" element={accessPages.length === 1 ? <OrdersReportsList /> :
                    accessPages.includes('orders-report') ? <OrdersReportsList />
                      : <Navigate to="/404" />
                  } />
                  <Route exact path="/customers-report" element={accessPages.length === 1 ? <CustomersReportsList /> :
                    accessPages.includes('customers-report') ? <CustomersReportsList />
                      : <Navigate to="/404" />
                  } />

                  <Route exact path="/invoices-list" element={
                    // accessPages.length === 1 ? <InvoicesList /> : accessPages.includes('invoices-list') ?
                    <InvoicesList />
                    // : <Navigate to="/404" />
                  } />

                  <Route exact path="/payments-list" element={accessPages.length === 1 ? <PaymentsList /> :
                    accessPages.includes('payments-list') ? <PaymentsList />
                      : <Navigate to="/404" />
                  } />

                  <Route exact path="/Finance-Settings" element={accessPages.length === 1 ? <FinanceSettings /> :
                    accessPages.includes('Finance-Settings') ? <FinanceSettings />
                      : <Navigate to="/404" />
                  } />

                  <Route exact path="/add-invoice" element={<AddInvoice />} />
                  <Route exact path="/update-invoice" element={<UpdateInvoice />} />

                </Routes>
              </div>
            </div>
          </Fragment>
        )}
      />
    );
  }

};

export default App;

// <Route exact path="/users" element={<UsersList />} />
// <Route path="/users/:id" element={<Users />} />
// <Route path="/datatable" element={<DataTableTest />} />