import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import axios from 'axios';

import {Card, CardBody, CardTitle } from "reactstrap";
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../../components/Loaders/DnaLoader";
import "../../../assets/custom css/loadoverlay.scss";

import Alerts from "../../../components/Alerts/alerts";

import DriverService from "./Driver.service";
import AuthService from "../../Authentication/auth.service";
import { useNavigate, useLocation, useParams} from 'react-router-dom';
import $ from 'jquery';


const DriverInsertionForm = () => {
  
    const params = useParams();
   
   

    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const module_name = "driver";
   
    const { state } = location;
    
    const [loading, setLoading] = useState(false);
    const form = useRef();
    const [pre_params, setpre_params] = useState('')
    const [collectionlist, setcollectionlist] = useState([])
    const [fieldlist, setfieldlist] = useState([])
    const [fieldlist_validate, setfieldlist_validate] = useState([]) 
    
    useEffect(() => {
      i18n.changeLanguage(localStorage.getItem('language'));
      fetchDynamicData();
    }, []); 
      
    const fetchDynamicData = async () => {
      try {
        const response = await DriverService.getuserform(module_name);
        if(response.data.message === "Unauthorized") {
          AuthService.logout();
          navigate("/login");
          window.location.reload();
        }
        else {
          const data = await response.data.data;
          
          var custom_fields = data[0].custom_fields;
          for(var i=0;i < custom_fields.length; i++){
            if(custom_fields[i].options_collection !==''){
              
              const optresponse = await DriverService.getoptions(custom_fields[i].options_collection,"",{},5000); 
              const optdata = await optresponse.data.data;
              var options = [];
              
              for(var j=0;j < optdata.length; j++){
                options.push({label:optdata[j][custom_fields[i].Options_Field_Name],value:optdata[j]._id,})
              }
              custom_fields[i].options = options;
            }
            if(custom_fields[i].parent_col === pre_params.Parent_referal_name ){
              custom_fields[i].default_value = pre_params.Parentid
            }else{
              custom_fields[i].default_value = ""
            }
          }
        
          data[0].custom_fields = custom_fields;
          
          setcollectionlist(data[0]);
          setfieldlist(data[0].custom_fields);
          var cust = data[0].custom_fields;
          var validations = {};
          for (var i =0; i < cust.length;i++) {
            validations[cust[i].referal_name] = cust[i].is_required;
          }
          setfieldlist_validate(validations);
        }
      } catch (error) {
          console.log("error", error);
      }
    };
  
  
    async function handleSubmit(e) {
      e.preventDefault();
      var field_errors =  [];
    
    var formData = new FormData();
    for(var i in fieldlist) {
      var field = fieldlist[i];
      if(field.field_type === 'radio'){
        
        const elements1 = Array.from(
          document.getElementsByClassName('prop'+field.referal_name)
        );
        var elementvalue = 0;
        elements1.forEach(element => {
          
          if(element.checked){
            elementvalue = element.value
          }
        });
        formData.append(field.referal_name, elementvalue)
      }else if(field.field_type === 'checkbox'){
        const elements1 = Array.from(
          document.getElementsByClassName('prop'+field.referal_name)
        );
        var elementvalue = [];
        elements1.forEach(element => {
          
          if(element.checked){
            elementvalue.push(element.value)
          }
        });
        var fieldelevalue = 0;
        if(elementvalue.length ==0){
          formData.append(field.referal_name, 0)
        }else if(elementvalue.length ==1){
          formData.append(field.referal_name, elementvalue[0])
        }else{
          formData.append(field.referal_name, elementvalue)
        }
        
      }
      else{
        
        var fieldvalue = document.getElementById(field.referal_name).value;
        if(field.field_type === 'file'){
          formData.append(field.referal_name, $('#'+field.referal_name).prop('files')[0]);
        }else{
          formData.append(field.referal_name,fieldvalue)
        }
        //if(field.step-1 === compState){
          var fieldvalue = document.getElementById(field.referal_name).value;
          if(field.is_required){
          
            if(fieldvalue ===''){
              field_errors.push({field:field.referal_name,status:false,message:field.name+" is required"});
              document.getElementById(field.referal_name).style.border ="2px solid red"
              document.getElementById(field.referal_name+"error-box").innerHTML =" is required."
            }else{
              
              document.getElementById(field.referal_name).removeAttribute("style");
              document.getElementById(field.referal_name+"error-box").innerHTML ="";
            }
          }
          if(field.is_unique && fieldvalue !==''){
            
            var filter = {}
            filter[field.referal_name] = fieldvalue;
            const response = await DriverService.getcollection(collectionlist.collection_name,filter,1,0);
            const data = await response.data;
            if(data.count){
              if(data.count.count > 0 ){
                field_errors.push({field:field.referal_name,status:false,message:field.name+" already exists"});
                document.getElementById(field.referal_name).style.border ="2px solid red"
                document.getElementById(field.referal_name+"error-box").innerHTML =" already exists."
              } else{
                document.getElementById(field.referal_name)?.removeAttribute("style");
                document.getElementById(field.referal_name+"error-box").innerHTML ="";
              }
            } else{
              document.getElementById(field.referal_name).removeAttribute("style");
              document.getElementById(field.referal_name+"error-box").innerHTML ="";
            } 
          }
        //}

      }
        
    }
    
    if(field_errors.length ===0){

      formData.append("functionName","InsetTypeUsers")
      formData.append("formId",collectionlist._id)
      formData.append("user_type_id",collectionlist._id)
      formData.append("user_type",collectionlist.name)
      formData.append("collection_name",collectionlist.collection_name);

       
        setLoading(true);
        
        DriverService.save_user(formData).then((response) => {
         
          setLoading(false);
          if(response.data.status){
            navigate("/driver-list" )
            if(response.data.message !== '') {
              Alerts.swalSuccessAlert(response.data.message)
            }
            else {
              Alerts.swalSuccessAlert(t("Data added successfully."));
            }
          }
          else {
            if(response.data.message !== '') {
              if(response.data.message === "Unauthorized") {
                AuthService.logout();
                navigate("/login");
                window.location.reload();
              }
              else {
                Alerts.swalErrorAlert(response.data.message);
              }
            }
            else {
              Alerts.swalErrorAlert(t("Error: Can't add data at the moment. Please try again later."))
            }
          }
        },
        (error) => {
          setLoading(false);
          const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          Alerts.swalErrorAlert(t("Network Operation failed."))
        });       
      }
    
    };
    

    return (
      <>
        <div className="app-main__outer">
          <div className="app-main__inner">
            <div className="app-page-title">
              <div className="page-title-wrapper justify-content-left">
                <div className="page-title-heading fw-bold">
                  <div className="page-title-icon">
                    <i className="pe-7s-plus icon-gradient bg-happy-green" />
                  </div>
                  
                      <div>{collectionlist.page_title}
                        <div className="page-title-subheading">
                        {t('Fill the form below to add')}  {collectionlist.page_title}.
                        </div>
                      </div>
                   
                </div>
              </div>
            </div>
        
            <div className="d-flex justify-content-center">
              <div className="col-md-6">
                <Card className="main-card mb-3">
                  <CardBody>
                    <LoadingOverlay tag="div" active={loading}
                      styles = {{overlay: (base) => ({...base})}}
                      spinner = {<DNALoader/>}>  
                      <Form onSubmit={handleSubmit} ref={form}>
                        {fieldlist && fieldlist.map((field, index) => {
                          if(field.field_type === 'text' || field.field_type === 'email' || field.field_type === 'number'|| field.field_type === 'password' || field.field_type === 'file'){
                            return (
                              <>
                                <div className="row" key={index}>
                                  <div className="col-md-12 col-xl-12" >
                                    <div className="form-group" >
                                      <label htmlFor="name">{field.field_lable} <span id={field.referal_name+"error-box"} style={{color:"red"}}></span></label>
                                      <Input type={field.field_type}  className="form-control" placeholder={field.field_lable}  name={field.referal_name} id={field.referal_name}    />
                                    </div>
                                  </div>
                                </div>
                                <br/>
                              </>
                            )
                          }
                          else if(field.field_type === 'dropdown'){
                            
                            return (
                              <>
                                <div className="row" key={index}>
                                  <div className="col-md-12 col-xl-12" >
                                    <div className="form-group" >
                                      <label htmlFor="name">{field.field_lable} <span id={field.referal_name+"error-box"} style={{color:"red"}}></span></label>
                                      <select className="form-select" name={field.referal_name} id={field.referal_name}    >
                                          <option disabled={false} value="">{t('--Choose an option--')}</option>
                                          {field.options.map((option, optionsindex) =>   (
                                              <option key={field.referal_name+optionsindex} value ={option.value}>{option.label}</option>
                                          ))}
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              <br/>
                              </>
                            )
                          }
                          else if(field.field_type === 'radio' || field.field_type === 'checkbox'){
                            return (
                              <>
                                <div className="row" key={index}>
                                  <div className="col-md-12 col-xl-12" ><label htmlFor="name">{field.field_lable} <span id={field.referal_name+"error-box"} style={{color:"red"}}></span></label></div>
                                  <br/>
                                  <div className="col-md-12 col-xl-12" >
                                    <ul className="child mb-0 p-0">
                                      {field.options.map((option, optionsindex) =>   (
                                        <li className="form-check form-check-inline">
                                          <input  className={"form-check-input prop"+field.referal_name} 
                                              type={field.field_type}
                                              value ={option.value}  name={field.referal_name} id={field.referal_name+optionsindex}
                                          />
                                          <label className="form-check-label" htmlFor="users">
                                            {option.label}
                                          </label>
                                        </li>
                                      ))}   
                                    </ul>
                                  </div>
                                </div>
                                <br/>
                              </>
                            )
                          }
                          else {
                            return (
                              <>
                                <div className="row" key={index}>
                                  <div className="col-md-12 col-xl-12" >
                                    <div className="form-group" >
                                      <label htmlFor="name">{field.field_lable} <span id={field.referal_name+"error-box"} style={{color:"red"}}></span></label>
                                      <Input type="text" className="form-control" placeholder={field.field_lable}  name={field.referal_name} id={field.referal_name}    />
                                    </div>
                                  </div>
                                </div>
                              <br/>
                              </>
                            )
                          }
                        })}
                        <br/>
                        <div className="row">
                          <div className="col-md-12 col-xl-12" >
                            <center>
                              <button type="button" onClick={() => navigate(-1)} className="btn btn-outline-light btn-hover-shine me-3 mt-3">{t('Cancel')}</button>
                              <button className="btn btn-custom-color btn-hover-shine me-3 mt-3" >{t('Add data')}</button>
                            </center>
                          </div>
                        </div>
                      </Form>
                    </LoadingOverlay>
                  </CardBody>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </>
    );


}

export default DriverInsertionForm;