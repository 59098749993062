import React, { useEffect, useState } from "react";
import { Translation } from "react-i18next";
import { Navigate } from "react-router-dom";
import { Row, Col, FormGroup, Input, Button, Label, Modal, ModalHeader, ModalBody, ModalFooter, Card, CardBody } from "reactstrap";
import { Form, Field } from "react-final-form";
import ServicesService from "../services.service";
import AuthService from "../../../Authentication/auth.service";
import { useTranslation } from 'react-i18next';

import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../../../components/Loaders/DnaLoader";
import "../../../../assets/custom css/loadoverlay.scss";

import Alerts from "../../../../components/Alerts/alerts";

class EditService extends React.Component {


  constructor(props) {
    super(props);
    console.log("Received Prop:-", props);
    this.state = {
      modal: false,
      backdrop: true,
      loading: false,
      loginNavigate: false
    };
    this.toggle = this.toggle.bind(this);
    this.validateFtn = this.validateFtn.bind(this);
    this.onSubmit = this.onSubmit.bind(this)
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
    // window.location.reload();
  }

  validateFtn = values => {
    const errors = {};
    if (!values.name) {
      errors.name = "Service Name Required.";
    }
    if (!values.code) {
      errors.code = "Service Code Required.";
    }
    if (!values.description) {
      errors.description = "Service Description Required.";
    }
    return errors;
  };

  onSubmit = values => {
    // const navigate = useNavigate();
    console.log(values);
    var payloadData = {
      functionName: "UpdateService",
      postData: {
        name: values.name,
        code: values.code,
        description: values.description,
        _id: this.props.data._id
      }
    }
    this.setState({
      loading: !this.state.loading,
    });
    ServicesService.updateService(payloadData).then((response) => {
      console.log("API Response:-", response);
      this.setState({
        loading: !this.state.loading,
      });
      if (response.data.status) {
        // this.toggle();
        // window,location.reload();
        if (response.data.message !== '') {
          Alerts.swalSuccessAlert(response.data.message).then(res => {
            console.log("Resp:-",res)
            this.toggle();
            window.location.reload();
          }) 
        }
        else {
          Alerts.swalSuccessAlert("Service Updated successfully.").then(res => {
            console.log("Resp:-",res)
            this.toggle();
            window.location.reload();
          }) 
        }
      }
      else {
        if (response.data.message !== '') {
          if (response.data.message === "Unauthorized") {
            AuthService.logout();
            this.setState({ loginNavigate: true });
            window.location.reload();
          }
          else {
            Alerts.swalErrorAlert(response.data.message).then(res => {
              console.log("Resp:-",res)
              this.toggle();
              window.location.reload();
            }) 
          }
        }
        else {
          Alerts.swalErrorAlert("Error: Can't update Service at the moment. Please try again later.")
        }
      }
    },
      (error) => {
        this.setState({
          loading: !this.state.loading,
        });
        const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        Alerts.swalErrorAlert("Network Operation failed.")
      });
  }


  render() {
    {this.state.loginNavigate &&
      <Navigate to={'/login'} />
    }
      return (
        <Translation>
          {(t) => (
            <span className="d-inline-block mb-2 me-2">
              <i onClick={this.toggle} className="pe-7s-pen btn-icon-wrapper text-primary"> </i>
              <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className} backdrop={true}>

                <LoadingOverlay tag="div" active={this.state.loading}
                  styles={{ overlay: (base) => ({ ...base }) }}
                  spinner={<DNALoader />}>

                  <ModalHeader >{t('Edit Service')}</ModalHeader>
                  <ModalBody>

                    <Card className="main-card mb-3">
                      <CardBody>
                        <Row>


                          <Col md="12" className="pt-3">
                            <Form onSubmit={this.onSubmit}
                              initialValues={this.props.data}
                              validate={this.validateFtn}
                              render={({ handleSubmit, values, submitting, validating, valid }) => (
                                <form onSubmit={handleSubmit}>


                                  <Row>

                                    <Col md="6">
                                      <FormGroup>
                                        <Field name="name">
                                          {({ input, meta }) => (
                                            <div>
                                              <Input {...input} type="text" placeholder="Service Name"
                                                invalid={meta.error && meta.touched}
                                              />
                                              {meta.error && meta.touched &&
                                                <span className="text-danger">{meta.error}</span>}
                                            </div>
                                          )}
                                        </Field>
                                      </FormGroup>


                                    </Col>
                                    <Col md="6">
                                      <FormGroup>
                                        <Field name="code">
                                          {({ input, meta }) => (
                                            <div>
                                              <Input {...input} type="text" placeholder="Service Code"
                                                invalid={meta.error && meta.touched}
                                              />
                                              {meta.error && meta.touched &&
                                                <span className="text-danger">{meta.error}</span>}
                                            </div>
                                          )}
                                        </Field>
                                      </FormGroup>
                                    </Col >
                                    <Col md="12" >
                                      <FormGroup>
                                        <Field name="description">
                                          {({ input, meta }) => (
                                            <div>
                                              <Input {...input} type="text" placeholder="Service Description"
                                                invalid={meta.error && meta.touched}
                                              />
                                              {meta.error && meta.touched &&
                                                <span className="text-danger">{meta.error}</span>}
                                            </div>
                                          )}
                                        </Field>
                                      </FormGroup>
                                    </Col>
                                    <div className="text-center">
                                      <FormGroup>
                                        <div className="text-center">
                                          <button type="submit" className="btn btn-secondary btn-lg btn-hover-shine me-3">
                                            Update </button>
                                          <button type="button" className="btn btn-outline-success btn-lg btn-hover-shine me-3" onClick={this.toggle}>
                                            Cancel
                                          </button>
                                        </div>
                                      </FormGroup>


                                    </div>
                                  </Row>



                                </form>
                              )}
                            />



                          </Col>
                          {/* 
                 */}
                        </Row>
                      </CardBody>
                    </Card>
                  </ModalBody>
                </LoadingOverlay>

              </Modal>
            </span>
          )}
        </Translation>
      );
    };


  };

export default EditService;

