import React, { useEffect, useState, useRef, Fragment } from "react";
import { useTranslation } from 'react-i18next';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { format, parseISO, set } from 'date-fns';
import styled from "styled-components";
import { useNavigate, useLocation, useParams, Link } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import {
    Row,
    Col,
    Card,
    CardBody,
    Input
} from "reactstrap";
import DateRangePickerComponent from '../Reports/DateRangePickerComponent';
import Form from "react-validation/build/form";
import ServicesService from "../Services Management/services.service.js";
import CustomerService from "../Customers/Customer.service.js";
import MultiStep from "../Customers/Customer Wizards/Wizard.js";
import moment from 'moment';

import OrderItems from "../Customers/Customer Components/SingleOrder/OrderItems";
import OrderDetails from "../Customers/Customer Components/SingleOrder/OrderDetails";
import OrderMedia from '../Customers/Customer Components/SingleOrder/OrderMedia.js';
import AssignOrderManual from "./Customer Modals/Customer Order Modals/AssignOrderManual";
import FleetService from "../Fleet Management/fleet.service";
import DriverService from "../Drivers/Driver.service";
import AlertService from "../../../components/Alerts/alerts";

import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../../components/Loaders/DnaLoader";
import "../../../assets/custom css/loadoverlay.scss";
import Alerts from "../../../components/Alerts/alerts";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faLockOpen, faLock, faKey } from "@fortawesome/free-solid-svg-icons";

// import CustomerService from "./Customer.service";
import AuthService from "../../Authentication/auth.service";
import DataTable from 'react-data-table-component';

// library.add(
//   faLockOpen,
//   faLock,
//   faKey
// );

const Label = styled.label`
font-weight: bold;
`;

const CustomerOrders = () => {

    const navigate = useNavigate();
    const form = useRef();
    const { t, i18n } = useTranslation();
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const state = location.state.event;
    console.log("Received Object:-", state);
    const [orders, setOrders] = useState([]);
    const [rows, setrows] = useState([]);
    const [services, setServices] = useState([]);
    const [fleets, setFleets] = useState([]);
    const [packagings, setPackagings] = useState([]);
    // const [packagingSizes, setPackagingSizes] = useState([]);
    const [drivers, setDrivers] = useState([]);
    const [param, setParam] = useState("allorders");
    const [singleOrderdata, setSingleOrderData] = useState("");
    const [modelopen, setModelopen] = useState(false);
    const [updateParam, setupdateParam] = useState("false");
    const [switchtoggle, setSwitchtoggle] = useState(false);
    const [myindex, setMyIndex] = useState([]);
    const [orderDetails, setOrderDetails] = useState([]);
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

    const [fservice_id, setfservice_id] = useState("");
    const [fpackaging_id, setfpackaging_id] = useState("");
    // const [fpackaging_size_id, setfpackaging_size_id] = useState("");
    const [myDataPopObj, setmyDataPopObj] = useState([]);
    const [priceidx, setpriceidx] = useState(0);
    const [currentPage, setCurrentPage] = useState("");
    const [totalRows, setTotalRows] = useState(0);

    const [searchStartDate, setsearchStartDate] = useState('');
    const [searchEndDate, setsearchEndDate] = useState('');

    const [searchName, setsearchName] = useState('');
    const [searchRegNo, setsearchRegNo] = useState('');
    const [searchEmail, setsearchEmail] = useState('');
    const [searchPhone, setsearchPhone] = useState('');
    const [pageLimit, setPageLimit] = useState(10);
    const [searchStatus, setsearchStatus] = useState('');
    const [searchStartDateOrder, setsearchStartDateOrder] = useState('');
    const [searchEndDateOrder, setsearchEndDateOrder] = useState('');

    useEffect(() => {
        i18n.changeLanguage(localStorage.getItem('language'));
        setViewFtn('orderdetails', state)
        getPackaging();
        // getPackagingSizes();
        getFleets();
        getServices(state.customer_id);
        getDrivers();
        setOrderDetails([]);
    }, []);

    useEffect(() => {
        if (orderDetails[priceidx]) {
            if (orderDetails[priceidx].packaging
                // && orderDetails[priceidx].packagingsize 
                && orderDetails[priceidx].services) {
                SearchPrice();
            }

        }
    }, [fservice_id, fpackaging_id
        // , fpackaging_size_id
        , priceidx]);


    function setViewFtn(param, data) {
        setParam(param);
        if (param === 'allorders') {
            setSingleOrderData("");
            setOrderDetails([]);
            if (data === "reload") {
                fetchCustomerOrders(0, pageLimit);
            }
        }
        if (param === 'orderdetails') {
            console.log(data)
            setSingleOrderData(data)
        }
        else {
            setSingleOrderData("")
        }
        setmyDataPopObj([]);
        setfservice_id("");
        setfpackaging_id("");
        // setfpackaging_size_id("");
        setrows([]);
        addRow1();
    }


    function SearchPrice() {
        var getData = {
            functionName: "GetPricing",
            postData: {
                "user_id": state._id,
                "service_id": orderDetails[priceidx].services,
                "packaging_id": orderDetails[priceidx].packaging,
                // "packaging_size_id": orderDetails[priceidx].packagingsize
            },
        }
        setLoading(true);
        CustomerService.getCustomerPricing(getData).then((response) => {
            setLoading(false);
            console.log("Get customer pricing response:-", response)
            if (response.data.status) {
                var updatedPopObj = [...myDataPopObj];
                if (response.data.data.length !== 0) {
                    // var popDataObj = {
                    //   [`price${ind}`]: response.data.data[0]?.price
                    // };
                    updatedPopObj[priceidx] = (response.data.data[0]?.price);
                    setmyDataPopObj(updatedPopObj);
                    // setfservice_id("");
                    // setfpackaging_id("");
                    // setfpackaging_size_id("");
                    console.log("Final Obj:-", myDataPopObj)
                    // setmyDataPopObj("");
                }
                else {
                    // setfservice_id("");
                    // setfpackaging_id("");
                    // setfpackaging_size_id("");
                    updatedPopObj[priceidx] = ("");
                    setmyDataPopObj(updatedPopObj);
                }

            }
            else {
                if (response.data.message === "Unauthorized") {
                    AuthService.logout();
                    navigate("/login");
                    window.location.reload();
                }
            }
        },
            (error) => {
                setLoading(false);
                const errContent =
                    (error.response && error.response.data) || error.message || error.toString();
                console.log("Net:-", errContent)
                if (errContent) {
                    Alerts.swalErrorAlert(errContent)
                }
                else {
                    Alerts.swalErrorAlert(t("Server Error, Please try again later."))
                }
            })
    }

    function toggleModal() {
        setModelopen(!modelopen)
        setSwitchtoggle(false)
    }

    function addRow1() {
        setrows(1);
    };

    function getServices(_id) {
        var getData = {
            functionName: "getCustomerServices",
            postData: {
                "sort": { _id: -1 },
                "offset": 0,
                "limit": 1000,
                "filter": {},
                customer_id: _id ? _id : state.customer_id

            },
        }
        ServicesService.getAllServices(getData).then((response) => {
            console.log("Get Services response:-", response);
            setServices(response.data.data)
        },
            (error) => {
                const errContent =
                    (error.response && error.response.data) || error.message || error.toString();
                console.log("Net:-", errContent)
                if (errContent) {
                    Alerts.swalErrorAlert(errContent)
                }
                else {
                    Alerts.swalErrorAlert("Server Error, Please try again later.")
                }
            })
    }

    function getPackaging(_id) {
        setLoading(true);
        var getData = {
            functionName: "GetCustomerPackaging",
            postData: {
                "sort": { _id: -1 },
                "offset": 0,
                "limit": 1000,
                "filter": {},
                customer_id: _id ? _id : state.customer_id

            },
        }
        ServicesService.GetCustomerPackaging(getData).then((response) => {
            setLoading(false);
            console.log("Get Packagings response:-", response)
            if (response.data.message === "Unauthorized") {
                AuthService.logout();
                navigate("/login");
                window.location.reload();
            }
            else {
                setPackagings(response.data.data);
            }
        },
            (error) => {
                setLoading(false);
                const errContent =
                    (error.response && error.response.data) || error.message || error.toString();
                console.log("Net:-", errContent)
                if (errContent) {
                    Alerts.swalErrorAlert(errContent)
                }
                else {
                    Alerts.swalErrorAlert(t("Server Error, Please try again later."))
                }
            })
    };

    // function getPackagingSizes() {
    //     setLoading(true);
    //     ServicesService.getPackagingSizes().then((response) => {
    //         setLoading(false);
    //         console.log("Get PackagingSizes response:-", response)
    //         if (response.data.message === "Unauthorized") {
    //             AuthService.logout();
    //             navigate("/login");
    //             window.location.reload();
    //         }
    //         else {
    //             setPackagingSizes(response.data.data);
    //         }
    //     },
    //         (error) => {
    //             setLoading(false);
    //             const errContent =
    //                 (error.response && error.response.data) || error.message || error.toString();
    //             console.log("Net:-", errContent)
    //             if (errContent) {
    //                 Alerts.swalErrorAlert(errContent)
    //             }
    //             else {
    //                 Alerts.swalErrorAlert(t("Server Error, Please try again later."))
    //             }
    //         })
    // };

    function getFleets() {
        var getData = {
            functionName: "GetFleets",
            postData: {
            },
        }
        setLoading(true);
        FleetService.getAllFleets(getData).then((response) => {
            setLoading(false);
            console.log("Get Fleets response:-", response)
            if (response.data.message === "Unauthorized") {
                AuthService.logout();
                navigate("/login");
                window.location.reload();
            }
            else {
                setFleets(response.data.data);
            }
        },
            (error) => {
                setLoading(false);
                const errContent =
                    (error.response && error.response.data) || error.message || error.toString();
                console.log("Net:-", errContent)
                if (errContent) {
                    Alerts.swalErrorAlert(errContent)
                }
                else {
                    Alerts.swalErrorAlert(t("Server Error, Please try again later."))
                }
            })
    };

    function getDrivers() {
        var getData = {
            functionName: "GetDrivers",
            postData: {
            },
        }
        setLoading(true);
        DriverService.getAllDrivers(getData).then((response) => {
            setLoading(false);
            console.log("Get Drivers response:-", response)
            if (response.data.message === "Unauthorized") {
                AuthService.logout();
                navigate("/login");
                window.location.reload();
            }
            else {
                setDrivers(response.data.data);
            }
        },
            (error) => {
                setLoading(false);
                const errContent =
                    (error.response && error.response.data) || error.message || error.toString();
                console.log("Net:-", errContent)
                if (errContent) {
                    Alerts.swalErrorAlert(errContent)
                }
                else {
                    Alerts.swalErrorAlert(t("Server Error, Please try again later."))
                }
            })
    };

    function addRow() {
        if (orderDetails[priceidx] === undefined) {
            AlertService.swalErrorAlert("Please Fill Existing Rows First")
        } else if (orderDetails[priceidx]) {
            if (!orderDetails[priceidx].packaging
                // || !orderDetails[priceidx].packagingsize 
                || !orderDetails[priceidx].services) {
                AlertService.swalErrorAlert("Please Fill existing Rows First")
            } else {
                var saddRow = rows;
                saddRow = saddRow + 1;
                setrows(saddRow);

            }
        } else {
            var saddRow = rows;
            saddRow = saddRow + 1;
            setrows(saddRow);
        }
    }

    function remove_option(index) {
        if (index !== 0) {
            document.getElementById('parent' + index).remove();
        }
    }

    const AddInvoice = (row) => {
        if (row.invoice_status === 'true') {
            AlertService.swalErrorAlert("Invoice Already Exists.");
        } else {
            const modifiedRow = { ...row, check: '4' };
            navigate('/add-invoice', { state: modifiedRow });
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        var formvalid0 = 'false';
        var formvalid1 = 'false';
        // var formvalid2 = 'false';
        var formvalid3 = 'false';
        var formvalid4 = 'false';
        var formvalid5 = 'false';
        const elements0 = Array.from(
            document.getElementsByClassName('serviceclass')
        );
        var services_ids = [];
        for (let i = 0; i < elements0.length; i++) {
            if (elements0[i].value) {
                services_ids.push(elements0[i].value)
                formvalid0 = 'true'
            }
            else {
                formvalid0 = 'false'
                break;
            }
        }

        const elements1 = Array.from(
            document.getElementsByClassName('packclass')
        );
        var pack = [];
        for (let i = 0; i < elements1.length; i++) {
            if (elements1[i].value) {
                pack.push(elements1[i].value)
                formvalid1 = 'true'
            }
            else {
                formvalid1 = 'false'
                break;
            }
        }


        // const elements2 = Array.from(
        //     document.getElementsByClassName('sizeclass')
        // );
        // var sizes = [];
        // for (let i = 0; i < elements2.length; i++) {
        //     if (elements2[i].value) {
        //         sizes.push(elements2[i].value)
        //         formvalid2 = 'true'
        //     }
        //     else {
        //         formvalid2 = 'false'
        //         break;
        //     }
        // }

        const elements3 = Array.from(
            document.getElementsByClassName('qtyclass')
        );
        var quantities = [];
        for (let i = 0; i < elements3.length; i++) {
            if (elements3[i].value) {
                quantities.push(elements3[i].value)
                formvalid3 = 'true'
            }
            else {
                formvalid3 = 'false'
                break;
            }
        }

        const elements4 = Array.from(
            document.getElementsByClassName('weightclass')
        );
        var weights = [];
        for (let i = 0; i < elements4.length; i++) {
            if (elements4[i].value) {
                weights.push(elements4[i].value)
                formvalid4 = 'true'
            }
            else {
                formvalid4 = 'false'
                break;
            }
        }

        const elements5 = Array.from(
            document.getElementsByClassName('priceclass')
        );
        var prices = [];
        for (let i = 0; i < elements5.length; i++) {
            if (elements5[i].value) {
                prices.push(elements5[i].value)
                formvalid5 = 'true'
            }
            else {
                formvalid5 = 'false'
                break;
            }
        }

        if (formvalid0 === 'false' || formvalid1 === 'false'
            //  || formvalid2 === 'false' 
            || formvalid3 === 'false' || formvalid4 === 'false' || formvalid5 === 'false') {
            Alerts.swalErrorAlert("Please fill all the fields.")
        }
        else {
            var payloadData = {
                functionName: "AddOrder",
                postData: {
                    user_id: state._id,
                    service_id: services_ids,
                    packaging_id: pack,
                    // packaging_size_id: sizes,
                    quantity: quantities,
                    weight: weights,
                    price: prices,
                    order_status: 'Quoted'
                }
            }
            setLoading(true);
            CustomerService.addCustomerOrder(payloadData).then((response) => {
                console.log("API Response:-", response);
                setLoading(false);
                if (response.data.status) {
                    setViewFtn('allorders', "reload");
                    if (response.data.message !== '') {
                        Alerts.swalSuccessAlert(response.data.message)
                    }
                    else {
                        Alerts.swalSuccessAlert(t("Customer order added successfully."));
                    }
                }
                else {
                    if (response.data.message !== '') {
                        if (response.data.message === "Unauthorized") {
                            AuthService.logout();
                            navigate("/login");
                            window.location.reload();
                        }
                        else {
                            Alerts.swalErrorAlert(response.data.message);
                        }
                    }
                    else {
                        Alerts.swalErrorAlert(t("Error: Can't add Customer order at the moment. Please try again later."))
                    }
                }
            },
                (error) => {
                    setLoading(false);
                    const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    Alerts.swalErrorAlert(t("Network Operation failed."))
                });
        }
    }

    function fetchCustomerOrders(page_index, page_limit) {
        var filter = {};
        if (searchName !== "") {
            console.log("Search name:-", searchName)
            filter["order_no"] = searchName
        }
        if (searchRegNo !== "") {
            console.log("Search Reg No:-", searchRegNo)
            filter["reg_no"] = searchRegNo
        }
        if (searchEmail !== "") {
            filter["email"] = searchEmail
        }
        if (searchPhone !== "") {
            filter["phone"] = searchPhone
        }
        if (searchStatus !== "") {
            filter["order_status"] = searchStatus
        }
        if (searchStartDate !== "" && searchEndDate !== "") {
            console.log("Search Reg No:-", searchStartDate)
            filter["startDate"] = searchStartDate
            filter["endDate"] = searchEndDate
        }
        if (searchStartDateOrder !== "" && searchEndDateOrder !== "") {
            filter["startDate_order"] = searchStartDateOrder
            filter["endDate_order"] = searchEndDateOrder
        }
        var offset = (page_index - 1) * page_limit;
        if (offset < 1) {
            offset = 0;
        }
        var getData = {
            functionName: "GetOrdersByFilter",
            postData: {
                "sort": { _id: -1 },
                "offset": offset,
                "limit": page_limit,
                "filter": filter
            },
        }
        setLoading(true);
        CustomerService.getCustomerOrders(getData).then((response) => {
            setLoading(false);
            console.log("Get Customer Orders response:-", response)
            if (response.data.message === "Unauthorized") {
                AuthService.logout();
                navigate("/login");
                window.location.reload();
            }
            else {
                setOrders(response.data.data);
            }
        },
            (error) => {
                setLoading(false);
                const errContent =
                    (error.response && error.response.data) || error.message || error.toString();
                console.log("Net:-", errContent)
                if (errContent) {
                    Alerts.swalErrorAlert(errContent)
                }
                else {
                    Alerts.swalErrorAlert(t("Server Error, Please try again later."))
                }
            })
    };

    function confirmDeleteOrder(_id, userId) {
        let msg = t("Once deleted, you will not be able to recover this customer order!")
        Alerts.swalConfirmDeleteAlert(msg).then(res => {
            console.log("Resp:-", res)
            if (res === true) {
                sendDelete(_id, userId)
            }
        })
    };

    function sendDelete(id, user_id) {
        setLoading(true);
        CustomerService.deleteCustomerOrder(id, user_id).then((response) => {
            setLoading(false);
            console.log("Delete Customer order details response:-", response)
            if (response.data.status) {
                fetchCustomerOrders();
                if (response.data.message !== '') {
                    Alerts.swalSuccessAlert(response.data.message)
                }
                else {
                    Alerts.swalSuccessAlert(t("Customer Order Deleted Successfully."))
                }
                console.log(response.data.message)
            }
            else {
                if (response.data.message !== '') {
                    if (response.data.message === "Unauthorized") {
                        AuthService.logout();
                        navigate("/login");
                        window.location.reload();
                    }
                    else {
                        Alerts.swalErrorAlert(response.data.message);
                    }
                }
                else {
                    Alerts.swalErrorAlert(t("Error: Can't delete the customer pricing at the moment."))
                }
            }
        },
            (error) => {
                setLoading(false);
                const errContent =
                    (error.response && error.response.data) || error.message || error.toString();
                Alerts.swalErrorAlert(t("Delete operation failed"))
            })
    };


    function resetFilters() {
        setResetPaginationToggle(!resetPaginationToggle);
        setsearchName('');
        setsearchRegNo('');
        setsearchEmail('');
        setsearchPhone('');
        setsearchStatus('');
        setsearchStartDate('');
        setsearchEndDate('');
        setsearchStartDateOrder('');
        setsearchEndDateOrder('');
        var offset = 0;
        var getData = {
            functionName: "GetOrdersByFilter",
            postData: {
                "sort": { _id: -1 },
                "offset": offset,
                "limit": pageLimit,
                "filter": {}
            },
        }
        setLoading(true);
        CustomerService.getCustomerOrders(getData).then((response) => {
            setLoading(false);
            console.log("Get Customers response:-", response)
            if (response.data.message === "Unauthorized") {
                AuthService.logout();
                navigate("/login");
                window.location.reload();
            }
            else {
                setOrders(response.data.data);
                setTotalRows(response.data.count.count);
            }
        },
            (error) => {
                setLoading(false);
                const errContent =
                    (error.response && error.response.data) || error.message || error.toString();
                console.log("Net:-", errContent)
                if (errContent) {
                    Alerts.swalErrorAlert(errContent)
                }
                else {
                    Alerts.swalErrorAlert(t("Server Error, Please try again later."))
                }
            })
    }

    // function handleSwitch(e) {
    //   console.log('Switch event:-', e)
    //   if (e === true) {
    //     setSwitchtoggle(true)
    //     setModelopen(!modelopen)
    //   }
    //   else {
    //     setSwitchtoggle(false)
    //     setModelopen(!modelopen)
    //   }
    // }

    // const confirmAssign = values => {

    // }

    // const validateAssingFtn = values => {
    //   const errors = {};
    //   if (!values.assigndate) {
    //     errors.assigndate = "Date Time Required.";
    //   }
    //   return errors;
    // };

    const mycolumns = [
        {
            name: "Date Time",
            selector: row => moment(row.created_at).format("DD-MM-YYYY HH:mm:ss A")
        },
        {
            name: "Pic Name",
            selector: (row) => {
                if (row.userDetails[0]) {
                    return (
                        <div>
                            {row.userDetails[0].pic_name}
                        </div>
                    )
                }
            }
        },
        {
            name: "Service",
            selector: row => {
                for (let i = 0; i < services.length; i++) {
                    if (row.orderDetails[0]?.service_id === services[i]._id) {
                        return (
                            <div>
                                {services[i].name}
                            </div>
                        );
                    }
                }
            }
        },
        {
            name: "Vehicle",
            selector: row => {
                for (let i = 0; i < fleets.length; i++) {
                    if (row?.fleet_id === fleets[i]._id) {
                        return (
                            <div>
                                {fleets[i].name}
                            </div>
                        );
                    }
                }
                {/* {row?.fleet_id === "" && 
              <div>Not Assigned</div>
            }
            {row?.fleet_id !== "" && 
              <div>{row.fleet_id}</div>
            } */}
            }
        }, {
            name: "Status",
            selector: row => {
                if (row.order_status === "Quoted") {
                    return (
                        <div className="text-info">
                            {row.order_status}
                        </div>
                    )
                }
                if (row.order_status === "Confirmed") {
                    return (
                        <div className="text-primary">
                            {row.order_status}
                        </div>
                    )
                }
                if (row.order_status === "Assigned") {
                    return (
                        <div className="text-warning">
                            {row.order_status}
                        </div>
                    )
                }
                if (row.order_status === "Invoiced") {
                    return (
                        <div className="text-warning">
                            {row.order_status}
                        </div>
                    )
                }
                if (row.order_status === "Completed") {
                    return (
                        <div className="text-success">
                            {row.order_status}
                        </div>
                    )
                }
                if (row.order_status === "Cancelled") {
                    return (
                        <div className="text-danger">
                            {row.order_status}
                        </div>
                    )
                }
                else {
                    return (
                        <div>
                            {row.order_status}
                        </div>
                    )
                }


            }
        },
        {
            name: 'Actions',
            selector: (row) => {
                return (
                    <div>
                        <button className="mt-2 mb-2 me-2 btn-icon btn">
                            <Tooltip placement="top" title="Add Invoice" arrow>
                                <i
                                    className="pe-7s-note text-info btn-icon-wrapper fa fa-file-invoice"
                                    onClick={() => AddInvoice(row)}
                                ></i>
                            </Tooltip>
                            <Tooltip placement="top" title="Order Details" arrow>
                                <i className="pe-7s-note2 text-info btn-icon-wrapper" onClick={() => setViewFtn('orderdetails', state)}></i>
                            </Tooltip>
                            <Tooltip placement="top" title="Delete Order" arrow>
                                < i onClick={() => confirmDeleteOrder(row._id, row.user_id)} className="pe-7s-trash text-danger btn-icon-wrapper"> </i>
                            </Tooltip>
                            <AssignOrderManual data={row} onSuccess={handleSuccess} drivers={drivers} fleets={fleets} />
                            {/* <i>
                <PricingEditCustomer data={row} />
              </i> */}
                            {/* {row.order_status !== 'created' && row.order_status !== 'inprogress' &&
                < i onClick={() => confirmDeleteOrder(row._id, row.user_id)} className="pe-7s-trash text-danger btn-icon-wrapper"> </i>
              } */}
                        </button>
                    </div >
                );
            }
        },
    ];

    const handleBack = () => {
        setViewFtn("allorders", "")
    };

    const handleEdit = (editparam) => {
        console.log("param:-", editparam)
        // if(editparam === "true") {
        //   if(updateParam === "false") {
        //     setupdateParam(editparam)
        //   }
        //   else {
        //     setViewFtn("allorders", "reload")
        //   }
        setViewFtn("allorders", "reload")

        // }
        // else {
        //   setViewFtn("allorders", "")
        // }
    }

    // const handleDetailsEditing = (editparam) => {
    //   console.log("param:-", editparam)
    //   if(editparam === "true") {
    //     setViewFtn("allorders", "reload")
    //   }
    //   else {
    //     setViewFtn("allorders", "")
    //   }
    // }

    // const handleItemsEditing = (editparam) => {
    //   console.log("param:-", editparam)
    //   if(editparam === "true") {
    //     setViewFtn("allorders", "reload")
    //   }
    //   else {
    //     setViewFtn("allorders", "")
    //   }
    // }

    // const handleMediaEditing = (editparam) => {
    //   console.log("param:-", editparam)
    //   if(editparam === "true") {
    //     setViewFtn("allorders", "reload")
    //   }
    //   else {
    //     setViewFtn("allorders", "")
    //   }
    // }

    const handleSuccess = () => {
        setViewFtn("allorders", "reload")
    };

    // const [filter , setFilter] = useState({
    //   service_id:'',
    //   packaging_id:'',
    //   packaging_size_id:'',
    //   index:''
    // })

    function selectChanged(type, ind, ev) {
        console.log("type:-", type)
        console.log("index:-", ind)
        console.log("value:-", ev.target.value)
        setpriceidx(ind)
        const updatedOrderDetails = [...orderDetails];
        const newValue = { [type]: ev.target.value };
        updatedOrderDetails[ind] = {
            ...updatedOrderDetails[ind],
            ...newValue,
        };
        setOrderDetails(updatedOrderDetails);
        if (type === "services") {
            setfservice_id(ev.target.value)
        }
        if (type === "packaging") {
            setfpackaging_id(ev.target.value)
        }
        // if (type === "packagingsize") {
        //     setfpackaging_size_id(ev.target.value)
        // }
    }
    function searchCustomers() {
        setResetPaginationToggle(!resetPaginationToggle);
        var page = 0
        handlePageChange(page++)
    }
    function handlePageChange(page_index) {
        console.log("Page index:-", page_index)
        setCurrentPage(page_index)
        fetchCustomerOrders(page_index, pageLimit);
    }
    const steps = [
        { name: "Order Details", component: <OrderDetails data={singleOrderdata} drivers={drivers} fleets={fleets} OnEditDetails={handleEdit} onBack={handleBack} onSuccess={handleSuccess} /> },
        {
            name: "Order Items", component: <OrderItems data={singleOrderdata} services={services} packagings={packagings}
                //  packagingSizes={packagingSizes} 
                OnEditItems={handleEdit} onBack={handleBack} onSuccess={handleSuccess} />
        },
        { name: "Order Media", component: <OrderMedia data={singleOrderdata} OnEditMedia={handleEdit} onBack={handleBack} onSuccess={handleSuccess} /> }
    ];

    const handleDateChange = (startDateFormatted, endDateFormatted) => {
        setsearchStartDate(startDateFormatted);
        setsearchEndDate(endDateFormatted);
    };
    const handleDateChangeOrder = (startDateFormatted, endDateFormatted) => {
        setsearchStartDateOrder(startDateFormatted);
        setsearchEndDateOrder(endDateFormatted);
    };
    return (
        <Fragment>
            <div className="app-main__outer">
                <div className="app-main__inner">
                    <div className=" bg-light-gray bg-animation ">
                        <div className="d-flex  justify-content-center align-items-center">
                            <Col md="12" className="mx-auto app-login-box">
                                <div className="app-page-title">
                                    <div className="page-title-wrapper">
                                        <div className="page-title-heading fw-bold">
                                            <div className="page-title-icon">
                                                <i className="pe-7s-box1 icon-gradient bg-tempting-azure" />
                                            </div>
                                            <div>
                                                Order Details
                                                <div className="page-title-subheading">
                                                    View Specific Order, Create, Edit and Delete an Order.
                                                </div>
                                            </div>

                                        </div>
                                        <div className="page-title-actions">
                                            <button type="button" onClick={() => navigate(-1)} className="btn btn-outline-success btn-hover-shine me-3">
                                                Back
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <Row>



                                    {param === 'allorders' ?
                                        <Fragment>
                                            <Row>
                                                <Col md="12">
                                                    <Card className="main-card mb-3">
                                                        <CardBody>
                                                            <LoadingOverlay tag="div" active={loading}
                                                                styles={{ overlay: (base) => ({ ...base }) }}
                                                                spinner={<DNALoader />}>
                                                                <Row className="mt-2">



                                                                    {/* <Col style={{ marginBottom: "20px" }} md="1">
                                    <Label style={{ marginTop: "7px", }} >{t("Pickup ")}</Label>
                                  </Col> */}
                                                                    <Col style={{
                                                                        marginLeft: '10px',
                                                                        background: '#F9F9FB', height: "35px", border: '1px solid #ccc',
                                                                        borderRadius: '6px', marginBottom: '20px', display: 'flex', alignItems: 'center'
                                                                    }} xs="12" sm="8" md="6" lg="4">
                                                                        <Label style={{ width: '150px', background: '#F9F9FB', paddingRight: '10px' }}>
                                                                            {t('Pickup Date')}</Label>
                                                                        <div className="d-flex justify-content-end align-items-center h-100 pr-2">
                                                                            <div style={{ borderRight: '2px solid #ccc', height: '4%' }}></div>
                                                                        </div>
                                                                        <DateRangePickerComponent onDateChange={handleDateChange} />
                                                                    </Col>
                                                                    <Col style={{
                                                                        marginLeft: '10px',
                                                                        background: '#F9F9FB', height: "35px", border: '1px solid #ccc',
                                                                        borderRadius: '6px', marginBottom: '20px', display: 'flex', alignItems: 'center'
                                                                    }} xs="12" sm="8" md="6" lg="4">
                                                                        <Label style={{ width: '150px', background: '#F9F9FB', paddingRight: '10px' }}>
                                                                            {t('Order Date')}</Label>
                                                                        <div className="d-flex justify-content-end align-items-center h-100 pr-2">
                                                                            <div style={{ borderRight: '2px solid #ccc', height: '4%' }}></div>
                                                                        </div>
                                                                        <DateRangePickerComponent onDateChange={handleDateChangeOrder} />
                                                                    </Col>
                                                                    <Col md="2">
                                                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                                                            <Label>{t("Order No.")}</Label>
                                                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                                                <Input type="text" name="fname" value={searchName} placeholder="Order No." onChange={event => setsearchName(event.target.value)} />
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                    <Col style={{ display: "flex", alignItems: "flex-end" }} md="1">
                                                                        <button type="button" className="btn btn-custom-color btn-hover-shine ms-1" onClick={() => searchCustomers()}>
                                                                            Search
                                                                        </button>
                                                                    </Col>
                                                                    <Col style={{ display: "flex", alignItems: "flex-end" }} md="1">
                                                                        <button type="button" className="btn btn-custom-color btn-hover-shine" onClick={() => resetFilters()}>
                                                                            Reset
                                                                        </button>
                                                                    </Col>
                                                                    <Col style={{ display: "flex", alignItems: "flex-end" }} md="2">
                                                                        <a onClick={() => setViewFtn('addneworder', "")} className="btn btn-custom-color btn-hover-shine ms-1 float-end">
                                                                            Add New
                                                                        </a>
                                                                    </Col>



                                                                    <Col style={{ marginBottom: "10px" }} md="3">
                                                                        <Label style={{ marginTop: "7px", }} >{t("Status")}</Label>
                                                                        <select
                                                                            name="fstatus"
                                                                            value={searchStatus}
                                                                            onChange={(event) => setsearchStatus(event.target.value)}
                                                                            style={{
                                                                                width: "100%",
                                                                                borderColor: '#CED4DA',
                                                                                height: "34px",
                                                                                backgroundColor: "white",
                                                                                borderRadius: "5px",
                                                                            }}
                                                                        >
                                                                            <option disabled value="">{t("Select Status")}</option>
                                                                            <option value='Quoted'>{t("Quoted")}</option>
                                                                            <option value='Assigned'>Assigned</option>
                                                                            <option value='Invoiced'>Invoiced</option>
                                                                            <option value="Postponed">{t('Postponed')}</option>
                                                                            <option value='Cancelled'>Cancelled</option>
                                                                            <option value='Completed'>Completed</option>
                                                                            <option value='Confirmed'>Confirmed</option>

                                                                        </select>
                                                                    </Col>


                                                                    <Col md="12" className="pt-3">
                                                                        <DataTable
                                                                            data={orders}
                                                                            columns={mycolumns}
                                                                            pagination
                                                                            fixedHeader
                                                                            fixedHeaderScrollHeight="400px"
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                            </LoadingOverlay>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </Fragment>
                                        : param === 'addneworder' ?
                                            <Fragment>
                                                <Row>
                                                    <Col md="12">
                                                        <Card className="main-card mb-3">
                                                            <CardBody>
                                                                <LoadingOverlay tag="div" active={loading}
                                                                    styles={{ overlay: (base) => ({ ...base }) }}
                                                                    spinner={<DNALoader />}>
                                                                    <Form onSubmit={handleSubmit} ref={form} default>
                                                                        <Row>
                                                                            <h4 className="fw-bold mb-1">Add Order</h4>
                                                                        </Row>
                                                                        {Array.from({ length: rows }, (_, index) => {
                                                                            var preprice = "";
                                                                            if (index < myDataPopObj.length) {
                                                                                preprice = myDataPopObj[index];
                                                                            }
                                                                            return (
                                                                                <div>
                                                                                    <Row id={"parent" + index} className="mt-4">
                                                                                        <Col md="2">
                                                                                            <div className="form-group">
                                                                                                <select type="select" className="form-select serviceclass" name="service_id" onChange={(ev) => {
                                                                                                    selectChanged("services", index, ev)
                                                                                                }}
                                                                                                >
                                                                                                    <option disabled={false} value="">Service</option>
                                                                                                    {services &&
                                                                                                        services.map((serv, indexx) => (
                                                                                                            <option key={indexx} value={serv._id}>{serv.name}</option>
                                                                                                        ))
                                                                                                    }
                                                                                                </select>
                                                                                            </div>
                                                                                        </Col>
                                                                                        <Col md="2">
                                                                                            <div className="form-group">
                                                                                                <select type="select" className="form-select packclass" name="packaging_id" onChange={(ev) => {
                                                                                                    selectChanged("packaging", index, ev)
                                                                                                }}
                                                                                                >
                                                                                                    <option disabled={false} value="">Packaging</option>
                                                                                                    {packagings &&
                                                                                                        packagings.map((pack, inde) => (
                                                                                                            <option key={inde} value={pack._id}>{pack.name}</option>
                                                                                                        ))
                                                                                                    }
                                                                                                </select>
                                                                                            </div>
                                                                                        </Col>
                                                                                        {/* <Col md="2">
                                                                                            <div className="form-group">
                                                                                                <select type="select" className="form-select sizeclass" name="packaging_size_id" onChange={(ev) => {
                                                                                                    selectChanged("packagingsize", index, ev)
                                                                                                }}
                                                                                                >
                                                                                                    <option disabled={false} value="">Size</option>
                                                                                                    {packagingSizes &&
                                                                                                        packagingSizes.map((size, ind) => (
                                                                                                            <option key={ind} value={size._id}>{size.name}</option>
                                                                                                        ))
                                                                                                    }
                                                                                                </select>
                                                                                            </div>
                                                                                        </Col > */}
                                                                                        <Col md="2">
                                                                                            <div className="form-group">
                                                                                                <Input type="text" placeholder="Quantity" className="form-control qtyclass" name="quantity"
                                                                                                />
                                                                                            </div>
                                                                                        </Col>
                                                                                        <Col md="2">
                                                                                            <div className="form-group">
                                                                                                <Input type="text" placeholder="Weight" className="form-control weightclass" name="weight"
                                                                                                />
                                                                                            </div>
                                                                                        </Col>
                                                                                        <Col md="1" >
                                                                                            <div className="form-group">
                                                                                                <Input type="text" placeholder="Price" className="form-control priceclass" value={preprice} name="price"
                                                                                                />
                                                                                            </div>
                                                                                        </Col>
                                                                                        <Col md="1" className="pt-1">
                                                                                            <i onClick={() => addRow()} className="lnr-plus-circle h3 fw-bold"> </i>
                                                                                            {rows !== 1 &&
                                                                                                <i onClick={() => remove_option(index)} className="lnr-cross-circle h3 fw-bold"> </i>
                                                                                            }
                                                                                        </Col>
                                                                                    </Row>
                                                                                </div>
                                                                            )
                                                                        })}

                                                                        <Row className="mt-5">
                                                                            <div className="text-center form-group">
                                                                                <button type="submit" className="btn btn-secondary btn-lg btn-hover-shine me-3">
                                                                                    Save </button>
                                                                                <button type="button" onClick={() => setViewFtn('orderdetails', state)} className="btn btn-outline-success btn-lg btn-hover-shine me-3">
                                                                                    Cancel
                                                                                </button>
                                                                            </div>

                                                                        </Row>
                                                                    </Form>
                                                                </LoadingOverlay>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                </Row>

                                            </Fragment>
                                            : param === 'orderdetails' ?
                                                <Fragment>
                                                    <Row>
                                                        <Col md="12">
                                                            <Card className="main-card mb-3">
                                                                <CardBody>
                                                                    <div className="forms-wizard-vertical">
                                                                        <MultiStep showNavigation={true} steps={steps} />
                                                                    </div>
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                    </Row>
                                                </Fragment>
                                                : ""}
                                </Row>
                            </Col>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
};
export default CustomerOrders;