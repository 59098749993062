import React, { Fragment, useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  Input,
  FormGroup,
  Label,
} from "reactstrap";
import { Form, Field } from "react-final-form";
import moment from 'moment';

import CustomerService from "../../Customer.service";
import AuthService from "../../../../Authentication/auth.service";
import FleetService from "../../../Fleet Management/fleet.service";
import DriverService from "../../../Drivers/Driver.service";

import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../../../../components/Loaders/DnaLoader";
import "../../../../../assets/custom css/loadoverlay.scss";
import Alerts from "../../../../../components/Alerts/alerts";
import UserService from "../../../../Users Management/users.service";


export default class OrderDetails extends React.Component {

  componentDidMount() {
    this.getUsers();
    // this.getFleets();
    // this.getDrivers();
  }

  constructor(props) {
    super(props);
    console.log("Received Prop:-", props);
    this.state = {
      loading: false,
      loginNavigate: false,
      users: [],
      initialvalues: this.props.data,
      edited: "false"
      // fleets: [],
      // drivers: []
    };
    this.validateFtn = this.validateFtn.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.getUsers = this.getUsers.bind(this);
    this.changeDateFormat = this.changeDateFormat.bind(this);
    this.revealUser = this.revealUser.bind(this);
    this.getSingleOrder = this.getSingleOrder.bind(this);
    this.goBack = this.goBack.bind(this)
    // this.getFleets = this.getFleets.bind(this);
    // this.getDrivers = this.getDrivers.bind(this);
  }

  getUsers() {
    var getData = {
      functionName: "GetUsers",
      postData: {
      },
    }
    UserService.getAllUsers(getData).then((response) => {
      console.log("Get Users response:-", response)
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        this.setState({ loginNavigate: true });
        window.location.reload();
      }
      else {
        this.setState({
          users: response.data.data,
        });
      }
    },
      (error) => {
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent)
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert("Server Error, Please try again later.")
        }
      })
  }

  getSingleOrder() {
    var getData = {
      functionName: "GetOrder",
      postData: {
        _id: this.props.order_id
      },
    }
    CustomerService.getSingleOrder(getData).then((response) => {
      console.log("Get Order response:-", response)
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        this.setState({ loginNavigate: true });
        window.location.reload();
      }
      else {
        this.setState({
          initialvalues: response.data.data,
        });
      }
    },
      (error) => {
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent)
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert("Server Error, Please try again later.")
        }
      })
  }

  // getFleets() {
  //   var getData = {
  //     functionName: "GetFleets",
  //     postData: {
  //     },
  //   }
  //   FleetService.getAllFleets(getData).then((response) => {
  //     console.log("Get Fleets response:-", response)
  //     if (response.data.message === "Unauthorized") {
  //       AuthService.logout();
  //       this.setState({ loginNavigate: true });
  //       window.location.reload();
  //     }
  //     else {
  //       this.setState({
  //         fleets: response.data.data,
  //       });
  //     }
  //   },
  //     (error) => {
  //       const errContent =
  //         (error.response && error.response.data) || error.message || error.toString();
  //       console.log("Net:-", errContent)
  //       if (errContent) {
  //         Alerts.swalErrorAlert(errContent)
  //       }
  //       else {
  //         Alerts.swalErrorAlert("Server Error, Please try again later.")
  //       }
  //     })
  // }

  // getDrivers() {
  //   var getData = {
  //     functionName: "GetDrivers",
  //     postData: {
  //     },
  //   }
  //   DriverService.getAllDrivers(getData).then((response) => {
  //     console.log("Get Driver response:-", response)
  //     if (response.data.message === "Unauthorized") {
  //       AuthService.logout();
  //       this.setState({ loginNavigate: true });
  //       window.location.reload();
  //     }
  //     else {
  //       this.setState({
  //         drivers: response.data.data,
  //       });
  //     }
  //   },
  //     (error) => {
  //       const errContent =
  //         (error.response && error.response.data) || error.message || error.toString();
  //       console.log("Net:-", errContent)
  //       if (errContent) {
  //         Alerts.swalErrorAlert(errContent)
  //       }
  //       else {
  //         Alerts.swalErrorAlert("Server Error, Please try again later.")
  //       }
  //     })
  // }

  validateFtn = values => {
    const errors = {};
    // if (!values.created_at) {
    //   errors.created_at = "Order Date Time Required.";
    // }
    // if (!values.order_status) {
    //   errors.order_status = "Order Status Required.";
    // }
    // if (!values.driver_id) {
    //   errors.driver_id = "Driver Name Required.";
    // }
    // if (!values.fleet_id) {
    //   errors.fleet_id = "Vehicle Number Required.";
    // }
    // if (!values.created_by) {
    //   errors.created_by = "Order Placed By Required.";
    // }
    // if (!values.pick_datetime) {
    //   errors.pick_datetime = "Pickup Date Time Required.";
    // }
    // if (!values.arrived_at) {
    //   errors.arrived_at = "Arrival Date Time Required.";
    // }
    return errors;
  };

  onSubmit = values => {
    console.log(values);
    var payloadData = {
      functionName: "UpdateOrder",
      postData: {
        created_at: values.created_at,
        order_status: values.order_status,
        driver_id: values.driver_id,
        fleet_id: values.fleet_id,
        remarks: values.remarks,
        created_by: values.created_by,
        pick_datetime: values.pick_datetime,
        arrived_at: values.arrived_at,
        customer_id: this.props.data.customer_id,
        _id: this.props.data._id
      }
    }
    this.setState({
      loading: !this.state.loading,
    });
    CustomerService.updateCustomerOrderDetails(payloadData).then((response) => {
      console.log("API Response:-", response);
      this.setState({
        loading: !this.state.loading,
      });
      if (response.data.status) {
        this.setState({
          edited: "true",
        });
        if (response.data.message !== '') {
          this.getSingleOrder();
          Alerts.swalSuccessAlert(response.data.message).then(res => {
            console.log("Resp:-", res)
            // this.props.onSuccess();
          })
        }
        else {
          Alerts.swalSuccessAlert("Customer Order Details Updated successfully.").then(res => {
            console.log("Resp:-", res)
            // this.props.onSuccess();
          })
        }
      }
      else {
        if (response.data.message !== '') {
          if (response.data.message === "Unauthorized") {
            AuthService.logout();
            this.setState({ loginNavigate: true });
            window.location.reload();
          }
          else {
            Alerts.swalErrorAlert(response.data.message).then(res => {
              console.log("Resp:-", res)
            })
          }
        }
        else {
          Alerts.swalErrorAlert("Error: Can't update details at the moment. Please try again later.")
        }
      }
    },
      (error) => {
        this.setState({
          loading: !this.state.loading,
        });
        const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        Alerts.swalErrorAlert("Network Operation failed.")
      });
  }

  changeDateFormat = value => {
    return (
      moment(value).format("DD-MM-YYYY HH:mm:ss A")
    )
  }

  revealUser = value => {
    for (let i = 0; i < this.state.users.length; i++) {
      if (value === this.state.users[i]._id) {
        return (
          this.state.users[i].name
        );
      }
    }
  }

  goBack() {
    this.props.OnEditDetails(this.state.edited)
  }


  render() {
    {
      this.state.loginNavigate &&
        <Navigate to={'/login'} />
    }
    return (
      <Fragment>
        <div className="form-wizard-content">
          <Row>
            <Col md="12" className="pt-1">
              <LoadingOverlay tag="div" active={this.state.loading}
                styles={{ overlay: (base) => ({ ...base }) }}
                spinner={<DNALoader />}>
                <Form onSubmit={this.onSubmit}
                  initialValues={this.state.initialvalues}
                  validate={this.validateFtn}
                  render={({ handleSubmit, values, submitting, validating, valid }) => (
                    <form onSubmit={handleSubmit}>
                      <Row>
                        <Col md="6">
                          <Row className="align-items-center">
                            <Col md="4" ><Label className="pb-2">Order Date Time</Label></Col>
                            <Col md="8">
                              <FormGroup>
                                <Field name="created_at">
                                  {({ input, meta }) => (
                                    <div>
                                      <Input {...input} type="datetime" placeholder="Order Date Time" value={this.changeDateFormat(this.props.data.created_at)}
                                        invalid={meta.error && meta.touched} readOnly
                                      />
                                      {meta.error && meta.touched &&
                                        <span className="text-danger">{meta.error}</span>}
                                    </div>
                                  )}
                                </Field>
                              </FormGroup>
                            </Col>
                          </Row>
                        </Col>
                        <Col md="6">
                          <Row className="align-items-center">
                            <Col md="4" ><Label className="pb-2">Status</Label></Col>
                            <Col md="8">
                              <FormGroup>
                                <Field name="order_status">
                                  {({ input, meta }) => (
                                    <div>
                                      <Input {...input} type="select" placeholder="Status"
                                        invalid={meta.error && meta.touched}
                                      >
                                        <option disabled={false} value="">--Choose an option--</option>
                                        <option value="Quoted">Quoted</option>
                                        <option value="Confirmed">Confirmed</option>
                                        <option value="Postponed">{('Postponed')}</option>
                                        <option value="Assigned">Assigned</option>
                                        <option value="Completed">Completed</option>
                                        <option value="Cancelled">Cancelled</option>
                                      </Input>
                                      {meta.error && meta.touched &&
                                        <span className="text-danger">{meta.error}</span>}
                                    </div>
                                  )}
                                </Field>
                              </FormGroup>
                            </Col>
                          </Row>
                        </Col>
                        <Col md="6">
                          <Row className="align-items-center">
                            <Col md="4" ><Label className="pb-2">Driver Name</Label></Col>
                            <Col md="8">
                              <FormGroup>
                                <Field name="driver_id">
                                  {({ input, meta }) => (
                                    <div>
                                      <Input {...input} type="select" placeholder="Driver Name"
                                        invalid={meta.error && meta.touched}
                                      >
                                        <option disabled={false} value="">--Choose an option--</option>
                                        {this.props.drivers &&
                                          this.props.drivers.map((driver, inde) => (
                                            <option key={inde} value={driver._id}>{driver.name}</option>
                                          ))
                                        }
                                      </Input>
                                      {meta.error && meta.touched &&
                                        <span className="text-danger">{meta.error}</span>}
                                    </div>
                                  )}
                                </Field>
                              </FormGroup>
                            </Col>
                          </Row>
                        </Col>
                        <Col md="6">
                          <Row className="align-items-center">
                            <Col md="4" ><Label className="pb-2">Customer Name</Label></Col>
                            <Col md="8">
                              <FormGroup>
                                <Field name="fleet_id">
                                  {({ input, meta }) => (
                                    <div>
                                      <Input {...input} type="text" placeholder="Customer Name"
                                        value={this.props.data?.userDetails[0]?.company_name}
                                        invalid={meta.error && meta.touched}
                                      >
                                      </Input>
                                      {meta.error && meta.touched &&
                                        <span className="text-danger">{meta.error}</span>}
                                    </div>
                                  )}
                                </Field>
                              </FormGroup>
                            </Col>
                          </Row>
                        </Col>

                        <Col md="6">
                          <Row className="align-items-center">
                            <Col md="4" ><Label className="pb-2">Order Placed By</Label></Col>
                            <Col md="8">
                              <FormGroup>
                                <Field name="created_by">
                                  {({ input, meta }) => (
                                    <div>
                                      <Input {...input} type="text" placeholder="Order Placed By" readOnly value={this.revealUser(this.props.data.created_by)}
                                        invalid={meta.error && meta.touched}
                                      />
                                      {meta.error && meta.touched &&
                                        <span className="text-danger">{meta.error}</span>}
                                    </div>
                                  )}
                                </Field>
                              </FormGroup>
                            </Col>
                          </Row>
                        </Col>
                        <Col md="6">
                          <Row className="align-items-center">
                            <Col md="4" ><Label className="pb-2">Pickup Date Time</Label></Col>
                            <Col md="8">
                              <FormGroup>
                                <Field name="pick_datetime">
                                  {({ input, meta }) => (
                                    <div>
                                      <Input {...input} type="datetime-local" placeholder="Pickup Date Time"
                                        invalid={meta.error && meta.touched}
                                      />
                                      {meta.error && meta.touched &&
                                        <span className="text-danger">{meta.error}</span>}
                                    </div>
                                  )}
                                </Field>
                              </FormGroup>
                            </Col>
                          </Row>
                        </Col>
                        <Col md="6">
                          <Row className="align-items-center">
                            <Col md="4" ><Label className="pb-2">Arrival Date Time</Label></Col>
                            <Col md="8">
                              <FormGroup>
                                <Field name="arrived_at">
                                  {({ input, meta }) => (
                                    <div>
                                      <Input {...input} type="datetime-local" placeholder="Arrival Date Time"
                                        invalid={meta.error && meta.touched}
                                      />
                                      {meta.error && meta.touched &&
                                        <span className="text-danger">{meta.error}</span>}
                                    </div>
                                  )}
                                </Field>
                              </FormGroup>
                            </Col>
                          </Row>
                        </Col>

                        <Col md="6">
                          <Row className="align-items-center">
                            <Col md="4" ><Label className="pb-2">Vehicle Number</Label></Col>
                            <Col md="8">
                              <FormGroup>
                                <Field name="fleet_id">
                                  {({ input, meta }) => (
                                    <div>
                                      <Input {...input} type="select" placeholder="Vehicle Number"
                                        invalid={meta.error && meta.touched}
                                      >
                                        <option disabled={false} value="">--Choose an option--</option>
                                        {this.props.fleets &&
                                          this.props.fleets.map((fleet, inde) => (
                                            <option key={inde} value={fleet._id}>{fleet.reg_no}</option>
                                          ))
                                        }
                                      </Input>
                                      {meta.error && meta.touched &&
                                        <span className="text-danger">{meta.error}</span>}
                                    </div>
                                  )}
                                </Field>
                              </FormGroup>
                            </Col>
                          </Row>
                        </Col>
                        <Col md="6">
                          <Row className="align-items-center">
                            <Col md="4" ><Label className="pb-2">{('Remarks')}</Label></Col>
                            <Col md="8">
                              <FormGroup>
                                <Field name="remarks">
                                  {({ input, meta }) => (
                                    <div>
                                      <Input {...input} type="text" placeholder={('Remarks')}
                                        invalid={meta.error && meta.touched}
                                      />
                                      {meta.error && meta.touched &&
                                        <span className="text-danger">{meta.error}</span>}
                                    </div>
                                  )}
                                </Field>
                              </FormGroup>
                            </Col>
                          </Row>
                        </Col>

                        <div className="text-center mt-3">
                          <FormGroup>
                            <div className="text-center">
                              <button type="submit" className="btn btn-secondary btn-lg btn-hover-shine me-3">
                                Update </button>
                              {/* <button type="button" className="btn btn-outline-success btn-lg btn-hover-shine me-3" onClick={this.goBack}>
                                Back
                              </button> */}
                            </div>
                          </FormGroup>
                        </div>
                      </Row>
                    </form>
                  )}
                />
              </LoadingOverlay>
            </Col>
          </Row>
        </div>
      </Fragment>
    );
  }
}
