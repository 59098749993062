import React from "react";
import { Translation } from "react-i18next";

import { Row, Col, FormGroup, Input, Button, Label, Modal, ModalHeader, ModalBody, ModalFooter, Card, CardBody } from "reactstrap";



class DocImage extends React.Component {

    constructor(props) {
        super(props);
        console.log("Received Prop:-", props);
        this.state = {
            modal: false,
            backdrop: true,
            upfile: this.props.data
        };
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState({
            modal: !this.state.modal,
        });
    }




    render() {

        return (
            <Translation>
                {(t) => (
                    <span className="d-inline-block ms-1">
                        <a onClick={this.toggle} className="color-link" style={{cursor: "pointer"}}>
                            View
                        </a>
                        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className} backdrop={true}>
                           
                                <ModalHeader >Document Image</ModalHeader>
                                <ModalBody>
                                    <img width={470} src={this.state.upfile} />
                                    <div className="modal-footer justify-content-center">
                                        <button onClick={this.toggle} className="btn btn-outline-success btn-hover-shine ">Close</button>
                                    </div>
                                </ModalBody>
                           
                        </Modal>
                    </span>
                )}
            </Translation>
        );
    };


};

export default DocImage;