import React, { Fragment, useState } from "react";
import { Card, CardBody, Modal, ModalBody, ModalHeader, ModalFooter, Button } from "reactstrap";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useNavigate } from 'react-router-dom';

const localizer = momentLocalizer(moment);
const eventStyles = {
  event: {
    backgroundColor: "#E3FFF1",
    padding: "10px",
    borderRadius: "5px",
    border: "none",
    boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)",
  },
};

const CalendarBasic = ({ check, Calender }) => {
  const [selectedEvent, setSelectedEvent] = useState(null);
  const navigate = useNavigate();

  const eventStyleGetter = (event, start, end, isSelected) => {
    let backgroundColor = event.backgroundColor || "#E3FFF1";

    if (event.remarks !== '') {
      backgroundColor = event.backgroundColor || "#545CD8";
    }
    return {
      style: {
        backgroundColor,
      },
    };
  }

  const openEventModal = (event) => {
    setSelectedEvent(event);
  }

  const closeEventModal = () => {
    setSelectedEvent(null);
  }

  const handleDetails = (event) => {
    console.log(event);
    navigate("/order-details", { state: { event } });
  }

  const CustomEvent = ({ event }) => {
    return (
      <div style={eventStyles}>
        <div style={{ color: event?.remarks === '' ? "#00A650" : '#fff' }}>
          {/* {moment(event.created_at).format("DD-MM-YYYY - h:mm A")} */}
          {event.fleetDetails?.[0]?.reg_no}
        </div>
        <div style={{ marginTop: '10px', marginBottom: '10px', color: event?.remarks === '' ? "#00A650" : '#fff' }}>
          {event?.userDetails?.[0]?.company_name}</div>
        <div style={{ color: event?.remarks === '' ? "#00A650" : '#fff' }}>
          {event.serviceDetails && event.serviceDetails.map(service => (
            <div key={service._id}>{service.name}</div>
          ))}
        </div>
      </div>
    );
  };

  const EventModal = ({ event, onClose }) => {
    const modalContentStyle = {
      padding: '20px',
      fontSize: '16px',
    };

    const labelStyle = {
      fontWeight: 'bold',
      marginBottom: '5px',
      flex: '1',
    };

    const valueStyle = {
      marginBottom: '15px',
      flex: '2',
      maxHeight: '100px',
      width: '200px', // Specify the desired width
      display: 'flex',
      justifyContent: 'flex-end', // Align values to the right
    };

    const labelValueContainerStyle = {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '10px', // Add space between label-value pairs
    };

    return (
      <Modal isOpen={true} toggle={onClose}>
        <ModalHeader style={{ backgroundColor: '#007BFF', color: 'white' }}>
          Order Details
        </ModalHeader>
        <ModalBody style={modalContentStyle}>
          <div style={labelValueContainerStyle}>
            <div style={labelStyle}>Order No.</div>
            {check ?
              <div
                onClick={() => { handleDetails(event) }}
                style={{
                  marginBottom: '15px',
                  flex: '2',
                  width: '200px',
                  display: 'flex',
                  justifyContent: 'flex-end',
                  cursor: 'pointer',
                  color: '#06A550',
                }}
              >
                <PerfectScrollbar style={{ width: '80%' }}>
                  {event && event.order_no}
                </PerfectScrollbar>
              </div>
              :
              <div
                style={{
                  marginBottom: '15px',
                  flex: '2',
                  width: '200px',
                  display: 'flex',
                  justifyContent: 'flex-end',
                  cursor: 'pointer',
                  color: '#06A550',
                }}
              >
                <PerfectScrollbar style={{ width: '80%' }}>
                  {event && event.order_no}
                </PerfectScrollbar>
              </div>
            }

          </div>
          <div style={labelValueContainerStyle}>
            <div style={labelStyle}>Company Name:</div>
            <div style={valueStyle}>
              <PerfectScrollbar style={{ width: '80%' }}>
                {event && event?.userDetails && event?.userDetails[0] ? event.userDetails[0]?.company_name : ''}
              </PerfectScrollbar>
            </div>
          </div>
          <div style={labelValueContainerStyle}>
            <div style={labelStyle}>Assigned Driver:</div>
            <div style={valueStyle}>
              <PerfectScrollbar style={{ width: '80%' }}>
                {event && event?.driverDetails && event?.driverDetails[0] ? event?.driverDetails?.[0]?.name : 'Not Assign Yet'}
              </PerfectScrollbar>
            </div>
          </div>
          <div style={labelValueContainerStyle}>
            <div style={labelStyle}>Assigned Fleet:</div>
            <div style={valueStyle}>
              <PerfectScrollbar style={{ width: '80%' }}>
                {event && event?.fleetDetails && event?.fleetDetails[0] ? event?.fleetDetails?.[0]?.name : 'Not Assign Yet'}
              </PerfectScrollbar>
            </div>
          </div>
          <div style={labelValueContainerStyle}>
            {event?.pick_datetime ?
              <div style={labelStyle}>Order Pickup Date:</div>
              :
              <div style={labelStyle}>Order Date:</div>
            }
            <div style={valueStyle}>
              <PerfectScrollbar style={{ width: '80%' }}>
                {event?.pick_datetime ? moment(event?.pick_datetime).format("DD-MM-YYYY - hh:mm A") : moment(event?.created_at).format("DD-MM-YYYY - hh:mm A")}
              </PerfectScrollbar>
            </div>
          </div>
          <div style={labelValueContainerStyle}>
            <div style={labelStyle}>status:</div>
            <div style={valueStyle}>
              <PerfectScrollbar style={{ width: '80%' }}>
                {event?.order_status}
              </PerfectScrollbar>
            </div>
          </div>
          {event.remarks !== '' &&
            <div style={labelValueContainerStyle}>
              <div style={labelStyle}>Remarks:</div>
              <div style={valueStyle}>
                <PerfectScrollbar style={{ width: '80%' }}>
                  {event?.remarks}
                </PerfectScrollbar>
              </div>
            </div>
          }
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={onClose}>Close</Button>
        </ModalFooter>
      </Modal>
    );
  };

  return (
    <Fragment>
      <Card className="mb-3">
        <CardBody>
          <Calendar
            localizer={localizer}
            events={Calender}
            startAccessor={(event) => event.pick_datetime ? new Date(event.pick_datetime) : new Date(event.created_at)}
            endAccessor={(event) => {
              const endTime = event.pick_datetime ? new Date(event.pick_datetime) : new Date(event.created_at);
              endTime.setHours(23, 59, 59);
              return endTime;
            }}
            // startAccessor="created_at"
            // endAccessor="created_at"
            eventPropGetter={eventStyleGetter}
            onSelectEvent={openEventModal}
            components={{
              event: CustomEvent,
            }}
          />
        </CardBody>
      </Card>
      {selectedEvent && (
        <EventModal
          event={selectedEvent}
          onClose={closeEventModal}
        />
      )}
    </Fragment>
  );
};

export default CalendarBasic;
