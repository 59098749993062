import Swal from "sweetalert2";
import 'animate.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import i18n from 'i18next';


const swalErrorAlert = (msg) => {
  return new Promise(resolve => {
    Swal.fire({
      title: i18n.t("Error!"),
      text: msg,
      icon: "error",
      width: 400,
      confirmButtonText: i18n.t("OK"),
      showClass: {
        popup: 'animate__animated animate__fadeInDown'
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp'
      }
    }).then((result) => {
      if (result.isConfirmed) {
        resolve(true);
      }
      else {
        resolve(false);
      }
    })
  });
};

const swalSuccessAlert = (msg) => {
  return new Promise(resolve => {
    Swal.fire({
      title: i18n.t("Success!"),
      text: msg,
      icon: "success",
      width: 400,
      confirmButtonText: i18n.t("OK"),
      showClass: {
        popup: 'animate__animated animate__fadeInDown'
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp'
      }
    }).then((result) => {
      if (result.isConfirmed) {
        resolve(true);
      }
      else {
        resolve(false);
      }
    })
  })
};

const swalConfirmGeneralAlert = msg => {
  return new Promise(resolve => {
    Swal.fire({
      title: i18n.t('Confirm!!!'),
      text: msg,
      icon: "warning",
      customClass: {
        icon: 'no-border'
      },
      showCancelButton: true,
      confirmButtonColor: '#d92550',
      cancelButtonColor: 'grey',
      cancelButtonText: i18n.t('Cancel'),
      confirmButtonText: i18n.t('Yes, Do it!')
    }).then((result) => {
      if (result.isConfirmed) {
        resolve(true);
      }
      else {
        resolve(false);
      }
    })
  })
};

const swalConfirmDeleteAlert = msg => {
  return new Promise(resolve => {
    Swal.fire({
      title: i18n.t('Are you sure?'),
      text: msg,
      iconHtml: '<img src="../custom/trash.png" width=100px, height=100px>',
      customClass: {
        icon: 'no-border'
      },
      showCancelButton: true,
      confirmButtonColor: '#d92550',
      cancelButtonColor: 'grey',
      cancelButtonText: i18n.t('Cancel'),
      confirmButtonText: i18n.t('Yes, delete it!')
    }).then((result) => {
      if (result.isConfirmed) {
        resolve(true);
      }
      else {
        resolve(false);
      }
    })
  })
};

const successToast = (msg) => {
  toast.success(msg,
    {
      position: toast.POSITION.TOP_RIGHT,
      theme: "light",
    });
};



const AlertService = {
  swalErrorAlert,
  swalSuccessAlert,
  swalConfirmGeneralAlert,
  swalConfirmDeleteAlert,
  successToast
};
export default AlertService;

