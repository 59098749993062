import React, { Fragment, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  Input,
  FormGroup,
  Label,
} from "reactstrap";
import { Form, Field } from "react-final-form";

import FleetService from "./fleet.service";
import AuthService from "../../Authentication/auth.service";
import { useTranslation } from 'react-i18next';

import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../../components/Loaders/DnaLoader";
import "../../../assets/custom css/loadoverlay.scss";

import Alerts from "../../../components/Alerts/alerts";


const UpdateFleet = () => {

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [rows, setrows] = useState([]);
  const [models, setModels] = useState([]);
  const [brands, setbrands] = useState([]);
  const [selectedbrand, setselectedbrand] = useState("");
  const location = useLocation();
  const { state } = location;
  const [drivers, setDrivers] = useState([]);
  const [driver, setDriver] = useState('');
  const [pageLimit, setPageLimit] = useState(100);

  console.log("Received Data:-", state)

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    getBrands();
    fetchDrivers(0, pageLimit);
    getModels(state.brand_id);
    setselectedbrand(state.brand_id)
  }, []);

  function fetchDrivers(page_index, page_limit) {
    var filter = {};

    var offset = (page_index - 1) * page_limit;
    if (offset < 1) {
      offset = 0;
    }
    var getData = {
      functionName: "GetDrivers",
      postData: {
        "sort": { _id: -1 },
        "offset": offset,
        "limit": page_limit,
        "filter": filter
      },
    }
    setLoading(true);
    FleetService.getAllDrivers(getData).then((response) => {
      setLoading(false);
      console.log("Get Drivers response:-", response)
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        setDrivers(response.data.data);
        response?.data?.data.forEach(element => {
          if (element._id === state?.driver) {
            setDriver(element.name);
          }
        });
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent)
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  };
  function getModels(id) {
    setLoading(true);
    FleetService.getModels(id).then((response) => {
      setLoading(false);
      console.log("Get Packagings response:-", response)
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        setModels(response.data.data);
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent)
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  };

  function getBrands() {
    setLoading(true);
    FleetService.getBrands().then((response) => {
      setLoading(false);
      console.log("Get PackagingSizes response:-", response)
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        setbrands(response.data.data);
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent)
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  };

  function gotBrand(e) {
    console.log(e.target.value);
    setselectedbrand(e.target.value)
    getModels(e.target.value)
  }


  const onSubmit = values => {
    console.log(values);
    var payloadData = {
      functionName: "UpdateFleet",
      postData: {
        _id: state._id,
        name: values.name,
        brand_id: selectedbrand,
        model_id: values.model_id,
        manufacturing_year: values.manufacturing_year,
        reg_no: values.reg_no,
        color: values.color,
        width: values.width,
        remarks: values.remarks,
        length: values.length,
        height: values.height,
        loadcap: values.loadcap,
        driver: values.driver,
      }
    }
    setLoading(true);
    FleetService.addFleet(payloadData).then((response) => {
      console.log("API Response:-", response);
      setLoading(false);
      if (response.data.status) {
        navigate("/fleet-list")
        if (response.data.message !== '') {
          Alerts.swalSuccessAlert(response.data.message)
        }
        else {
          Alerts.swalSuccessAlert(t("Fleet updated successfully."));
        }
      }
      else {
        if (response.data.message !== '') {
          if (response.data.message === "Unauthorized") {
            AuthService.logout();
            navigate("/login");
            window.location.reload();
          }
          else {
            Alerts.swalErrorAlert(response.data.message);
          }
        }
        else {
          Alerts.swalErrorAlert(t("Error: Can't update fleet at the moment. Please try again later."))
        }
      }
    },
      (error) => {
        setLoading(false);
        const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        Alerts.swalErrorAlert(t("Network Operation failed."))
      });
  }

  const validateFtn = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = "Fleet Name Required.";
    }
    if (!values.model_id) {
      errors.model_id = "Please select Model.";
    }
    if (!values.brand_id) {
      errors.brand_id = "Please select Brand.";
    }
    if (!values.color) {
      errors.color = "Color Required.";
    }
    if (!values.width) {
      errors.width = "Container Width Required.";
    }
    if (!values.reg_no) {
      errors.reg_no = "Registration No Required.";
    }
    if (!values.height) {
      errors.height = "Container Height Required.";
    }
    if (!values.manufacturing_year) {
      errors.manufacturing_year = "Manufacturing Year Required.";
    }
    if (!values.length) {
      errors.length = "Container Length Required.";
    }
    if (!values.loadcap) {
      errors.loadcap = "Loading Capacity Required.";
    }
    return errors;
  };



  return (
    <Fragment>
      <div className="app-main__outer">
        <div className="app-main__inner">
          <div className=" bg-light-gray bg-animation">
            <div className="d-flex  justify-content-center align-items-center">
              <Col md="12" className="mx-auto app-login-box">

                <div className="app-page-title">
                  <div className="page-title-wrapper">
                    <div className="page-title-heading fw-bold">
                      <div className="page-title-icon">
                        <i className="lnr-bus icon-gradient bg-tempting-azure" />
                      </div>
                      <div>
                        Update Fleet
                        <div className="page-title-subheading">
                          {t(`Update Fleet's details.`)}
                        </div>
                      </div>
                    </div>
                    <div className="page-title-actions">
                      <button type="button" onClick={() => navigate(-1)} className="btn btn-outline-success btn-hover-shine me-3">
                        Back
                      </button>
                    </div>
                  </div>
                </div>

                <Card className="main-card mb-3">
                  <CardBody>
                    <Row>
                      <Col md="12" className="pt-3">
                        <LoadingOverlay tag="div" active={loading}
                          styles={{ overlay: (base) => ({ ...base }) }}
                          spinner={<DNALoader />}>
                          <Form onSubmit={onSubmit}
                            validate={validateFtn}
                            initialValues={state}
                            render={({ handleSubmit, values, submitting, validating, valid }) => (
                              <form onSubmit={handleSubmit}>
                                <Row>
                                  <Col md="6">

                                    <Row className="align-items-center">
                                      <Col md="4"><Label className="pb-1">Name</Label></Col>
                                      <Col md="8">
                                        <FormGroup>
                                          <Field name="name">
                                            {({ input, meta }) => (
                                              <div>
                                                <Input {...input} type="text" placeholder="Fleet Name"
                                                  invalid={meta.error && meta.touched}
                                                />
                                                {meta.error && meta.touched &&
                                                  <span className="text-danger">{meta.error}</span>}
                                              </div>
                                            )}
                                          </Field>
                                        </FormGroup>
                                      </Col>
                                    </Row>

                                    <Row className="align-items-center">
                                      <Col md="4"><Label className="pb-1">Brand</Label></Col>
                                      <Col md="8">
                                        <FormGroup>
                                          <Field name="brand_id">
                                            {({ input, meta }) => (
                                              <div>
                                                <Input {...input} type="select" value={selectedbrand}
                                                  invalid={meta.error && meta.touched}
                                                  onChange={event => gotBrand(event)}
                                                >
                                                  <option disabled={false} value="">Brand</option>
                                                  {brands &&
                                                    brands.map((brand, inde) => (
                                                      <option key={inde} value={brand._id}>{brand.name}</option>
                                                    ))
                                                  }
                                                </Input>
                                                {meta.error && meta.touched &&
                                                  <span className="text-danger">{meta.error}</span>}
                                              </div>
                                            )}
                                          </Field>
                                        </FormGroup>
                                      </Col>
                                    </Row>

                                    <Row className="align-items-center">
                                      <Col md="4"><Label className="pb-1">Color</Label></Col>
                                      <Col md="8">
                                        <FormGroup>
                                          <Field name="color">
                                            {({ input, meta }) => (
                                              <div>
                                                <Input {...input} type="text"
                                                  invalid={meta.error && meta.touched}
                                                />
                                                {meta.error && meta.touched &&
                                                  <span className="text-danger">{meta.error}</span>}
                                              </div>
                                            )}
                                          </Field>
                                        </FormGroup>
                                      </Col>
                                    </Row>

                                    <Row className="align-items-center">
                                      <Col md="4"><Label className="pb-1">Container Width</Label></Col>
                                      <Col md="8">
                                        <FormGroup>
                                          <Field name="width">
                                            {({ input, meta }) => (
                                              <div>
                                                <Input {...input} type="text"
                                                  invalid={meta.error && meta.touched}
                                                />
                                                {meta.error && meta.touched &&
                                                  <span className="text-danger">{meta.error}</span>}
                                              </div>
                                            )}
                                          </Field>
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                  </Col>

                                  <Col md="6">
                                    <Row className="align-items-center">
                                      <Col md="4"><Label className="pb-1">Registration No.</Label></Col>
                                      <Col md="8">
                                        <FormGroup>
                                          <Field name="reg_no">
                                            {({ input, meta }) => (
                                              <div>
                                                <Input {...input} type="text" placeholder="Registration No"
                                                  invalid={meta.error && meta.touched}
                                                />
                                                {meta.error && meta.touched &&
                                                  <span className="text-danger">{meta.error}</span>}
                                              </div>
                                            )}
                                          </Field>
                                        </FormGroup>
                                      </Col>
                                    </Row>

                                    <Row className="align-items-center">
                                      <Col md="4"><Label className="pb-1">Model</Label></Col>
                                      <Col md="8">
                                        <FormGroup>
                                          <Field name="model_id">
                                            {({ input, meta }) => (
                                              <div>
                                                <Input {...input} type="select"
                                                  invalid={meta.error && meta.touched}
                                                >
                                                  <option disabled={false} value="">Model</option>
                                                  {models &&
                                                    models.map((model, inde) => (
                                                      <option key={inde} value={model._id}>{model.name}</option>
                                                    ))
                                                  }
                                                </Input>
                                                {meta.error && meta.touched &&
                                                  <span className="text-danger">{meta.error}</span>}
                                              </div>
                                            )}
                                          </Field>
                                        </FormGroup>
                                      </Col>
                                    </Row>

                                    <Row className="align-items-center">
                                      <Col md="4"><Label className="pb-1">Manufacturing Year</Label></Col>
                                      <Col md="8">
                                        <FormGroup>
                                          <Field name="manufacturing_year">
                                            {({ input, meta }) => (
                                              <div>
                                                <Input {...input} type="number"
                                                  invalid={meta.error && meta.touched}
                                                />
                                                {meta.error && meta.touched &&
                                                  <span className="text-danger">{meta.error}</span>}
                                              </div>
                                            )}
                                          </Field>
                                        </FormGroup>
                                      </Col>
                                    </Row>

                                    <Row className="align-items-center">
                                      <Col md="4"><Label className="pb-1">Container Length</Label></Col>
                                      <Col md="8">
                                        <FormGroup>
                                          <Field name="length">
                                            {({ input, meta }) => (
                                              <div>
                                                <Input {...input} type="number"
                                                  invalid={meta.error && meta.touched}
                                                />
                                                {meta.error && meta.touched &&
                                                  <span className="text-danger">{meta.error}</span>}
                                              </div>
                                            )}
                                          </Field>
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                  </Col >

                                  <Col md="6" >
                                    <Row className="align-items-center">
                                      <Col md="4"><Label className="pb-1">Container Height</Label></Col>
                                      <Col md="8">
                                        <FormGroup>
                                          <Field name="height">
                                            {({ input, meta }) => (
                                              <div>
                                                <Input {...input} type="number"
                                                  invalid={meta.error && meta.touched}
                                                />
                                                {meta.error && meta.touched &&
                                                  <span className="text-danger">{meta.error}</span>}
                                              </div>
                                            )}
                                          </Field>
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col md="6" >
                                    <Row className="align-items-center">
                                      <Col md="4"><Label className="pb-1">Loading Capacity</Label></Col>
                                      <Col md="8">
                                        <FormGroup>
                                          <Field name="loadcap">
                                            {({ input, meta }) => (
                                              <div>
                                                <Input {...input} type="number"
                                                  invalid={meta.error && meta.touched}
                                                />
                                                {meta.error && meta.touched &&
                                                  <span className="text-danger">{meta.error}</span>}
                                              </div>
                                            )}
                                          </Field>
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col md="6" >

                                    <Row className="align-items-center">
                                      <Col md="4"><Label className="pb-1">Select Driver</Label></Col>
                                      <Col md="8">
                                        <FormGroup>
                                          <Field name="driver" initialValue={state.DriverData.length > 0 ? state.DriverData[0]._id : ''}>
                                            {({ input, meta }) => (
                                              <div>
                                                <Input {...input} type="select"
                                                  invalid={meta.error && meta.touched}
                                                >
                                                  <option disabled={false} value="">Drivers</option>
                                                  {drivers &&
                                                    drivers.map((driver) => (
                                                      <option key={driver._id} value={driver._id}>{driver.name}</option>
                                                    ))
                                                  }
                                                </Input>
                                                {meta.error && meta.touched &&
                                                  <span className="text-danger">{meta.error}</span>}
                                              </div>
                                            )}
                                          </Field>
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col md="6" >
                                    <Row className="align-items-center">
                                      <Col md="4"><Label className="pb-1">Remarks</Label></Col>
                                      <Col md="8">
                                        <FormGroup>
                                          <Field name="remarks">
                                            {({ input, meta }) => (
                                              <div>
                                                <Input {...input} type="text"
                                                  invalid={meta.error && meta.touched}
                                                />
                                                {meta.error && meta.touched &&
                                                  <span className="text-danger">{meta.error}</span>}
                                              </div>
                                            )}
                                          </Field>
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>

                                <Row>
                                  <FormGroup>
                                    <div className="text-center">
                                      <button type="submit" className="btn btn-secondary btn-lg btn-hover-shine me-3">
                                        Save </button>
                                      <button type="button" onClick={() => navigate(-1)} className="btn btn-outline-success btn-lg btn-hover-shine me-3">
                                        Cancel
                                      </button>
                                    </div>
                                  </FormGroup>
                                </Row>

                              </form>
                            )}
                          />
                        </LoadingOverlay>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </div>

          </div>
        </div>
      </div>
    </Fragment>
  );

};


export default UpdateFleet;
