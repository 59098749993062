import React, { useState, useEffect } from "react";
import { Translation } from "react-i18next";
import { Navigate } from "react-router-dom";
import { Row, Col, FormGroup, Input, Label, Modal, ModalHeader, ModalBody, ModalFooter, Button, CardBody, Card } from "reactstrap";
import { Form, Field } from "react-final-form";
import Switch from "react-switch";
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment';
import { useNavigate, useLocation, useParams, Link } from 'react-router-dom';

import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../../../../components/Loaders/DnaLoader";
import "../../../../../assets/custom css/loadoverlay.scss";
import "../../../../../assets/custom css/modalwidths.scss";
import Alerts from "../../../../../components/Alerts/alerts";

import CustomerService from "../../Customer.service";
import AuthService from "../../../../Authentication/auth.service";
import FleetService from "../../../Fleet Management/fleet.service";
import DriverService from "../../../Drivers/Driver.service";

const AssignOrderManual = (props) => {
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loginNavigate, setLoginNavigate] = useState(false);
  const [switchtoggle, setSwitchToggle] = useState(false);
  const [prePopulatedData, setPrePopulatedData] = useState({});
  const [weight, setWeight] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [Contacts, setContacts] = useState([]);
  const [EmailRow, setEmailRow] = useState();
  const [payloadData, setPayloadData] = useState();
  console.log('props', props);
  useEffect(() => {
    setData();
    // getFleets();
    // getDrivers();
  }, []);

  const toggle = () => {
    setModal(!modal);
  };
  const toggle1 = () => {
    setModal1(!modal1);
  };

  function handleMail(row) {
    setEmailRow('')
    setSelectedEmails('');
    setEmailRow(props.data)
    var getData = {
      functionName: "GetUserContacts",
      postData: {
        user_id: row.postData.user_id
      },
    }
    setLoading(true);
    CustomerService.GetUserContacts(getData).then((response) => {
      setLoading(false);
      console.log("Get Customer Contact response:-", response.data.data)
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        setContacts(response.data.data)
        toggle1();
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent)
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert("Server Error, Please try again later.")
        }
      })
  };


  function sendMail(row) {
    setLoading(true);
    payloadData.postData.selectedEmails = row?.selectedEmails;
    CustomerService.manualDriverAssign(payloadData).then((response) => {
      setLoading(false);
      if (response.data.status) {
        const message = response.data.message ? response.data.message : "Order is assigned successfully.";
        Alerts.swalSuccessAlert(message).then(() => {
          props.onSuccess();
          toggle();

        });
      } else {
        const errorMessage = response.data.message ? response.data.message : "Error: Can't assign order at the moment. Please try again later.";
        if (errorMessage === "Unauthorized") {
          AuthService.logout();
          setLoginNavigate(true);
          window.location.reload();
        } else {
          Alerts.swalErrorAlert(errorMessage);
        }
      }
    }).catch((error) => {
      setLoading(false);
      Alerts.swalErrorAlert("Network Operation failed.");
    });
    // let tax_percentage = localStorage.getItem("tax_percentage");
    // CustomerService.sendOrder_PDF_Mail(row, tax_percentage).then((response) => {
    //   console.log("Send mail to:-", response)
    //   setLoading(false);
    //   if (response.data.status) {
    //     if (response.data.message !== '') {
    //       // Alerts.swalSuccessAlert(response.data.message)
    //     }
    //     else {
    //       // Alerts.swalSuccessAlert("Order emailed Successfully.")
    //     }
    //     console.log(response.data.message)
    //   }
    //   else {
    //     if (response.data.message !== '') {
    //       if (response.data.message === "Unauthorized") {
    //         AuthService.logout();
    //         navigate("/login");
    //         window.location.reload();
    //       }
    //       else {
    //         Alerts.swalErrorAlert(response.data.message);
    //       }
    //     }
    //     else {
    //       Alerts.swalErrorAlert("Error: Can't send the Password.")
    //     }
    //   }
    // },
    //   (error) => {
    //     setLoading(false);
    //     const errContent =
    //       (error.response && error.response.data) || error.message || error.toString();
    //     Alerts.swalErrorAlert("Operation failed")
    //   })
  };


  const handleSubmit1 = (values) => {
    toggle1();
    console.log('Selected emails:', selectedEmails);
    console.log('EmailRow:', EmailRow);
    const updatedRow = { ...EmailRow, selectedEmails }; // Combine existing rows with newly selected emails
    console.log('Updated row:', updatedRow);
    sendMail(updatedRow);
  }

  function onCancel() {
    setModal1(!modal1);
  }
  const [selectedEmails, setSelectedEmails] = useState([]);

  const handleCheckboxChange = (e) => {
    const email = e.target.value;
    const isChecked = e.target.checked;
    if (isChecked) {
      setSelectedEmails([...selectedEmails, email]);
    } else {
      setSelectedEmails(selectedEmails.filter(item => item !== email));
    }
  };
  const setData = () => {
    const currentPrePopulatedData = { ...props.data };
    const formattedDate = moment(props.data.pick_datetime, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');
    currentPrePopulatedData.pick_datetime = formattedDate;
    setPrePopulatedData(currentPrePopulatedData);
  };

  const validateFtn = (values) => {
    const errors = {};
    if (!values.pick_datetime) {
      errors.pick_datetime = "Pick Date Time Required.";
    }
    if (!values.driver_id) {
      errors.driver_id = "Driver Name Required.";
    }
    if (!values.fleet_id) {
      errors.fleet_id = "Vehicle Number Required.";
    }
    return errors;
  };

  const onSubmit = async (values) => {
    const payloadData = {
      functionName: "AssignOrder",
      postData: {
        user_id: props.data.customer_id,
        user_id: props.data.customer_id,
        driver_id: values.driver_id,
        fleet_id: values.fleet_id,
        pick_datetime: values.pick_datetime,
        _id: props.data._id,
        is_auto_assigned: false,
        order_status: 'Assigned',
      }
    };
    setPayloadData(payloadData);
    handleMail(payloadData)
  };

  const handleSwitch = (e) => {
    setSwitchToggle(e);
    if (e === true) {
      getAutoAssignData();
    }
  };

  const getAutoAssignData = () => {
    let tempWeight = 0;
    let tempQuantity = 0;
    props?.data?.orderDetails?.forEach((item) => {
      tempWeight += parseInt(item?.weight);
      tempQuantity += parseInt(item?.quantity);
    });

    setWeight(tempWeight);
    setQuantity(tempQuantity);

    const payloadData = {
      functionName: "AutoAssignOrder",
      postData: {
        user_id: props.data.customer_id,
        weight: tempWeight,
        quantity: tempQuantity,
        startDate: moment(),
        endDate: moment().add(parseInt(localStorage.getItem('days_range')), 'days'),
        is_auto_assigned: true,
        auto_assign_distance: localStorage.getItem("auto_assign_distance"),
        _id: props.data._id,
        order_status: props.data.order_status
      }
    };

    setLoading(true);
    CustomerService.autoDriverAssign(payloadData).then((response) => {
      setLoading(false);
      if (response.data.status) {
        const message = response.data.message ? response.data.message : "Order is assigned successfully.";
        Alerts.swalSuccessAlert(message).then(() => {
          props.onSuccess();
        });
        if (response.data.data) {
          const currentPrePopulatedData = { ...prePopulatedData };
          currentPrePopulatedData.driverDetails = response.data.data.driverDetails;
          currentPrePopulatedData.fleetDetails = response.data.data.fleetDetails;
          currentPrePopulatedData.driver_id = response.data.data.driver_id;
          currentPrePopulatedData.fleet_id = response.data.data.fleet_id;
          currentPrePopulatedData.pick_datetime = moment(response.data.data.pick_datetime, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');
          setPrePopulatedData(currentPrePopulatedData);
        }
      } else {
        const errorMessage = response.data.message ? response.data.message : "Error: Can't assign order at the moment. Please try again later.";
        if (errorMessage === "Unauthorized") {
          AuthService.logout();
          setLoginNavigate(true);
          window.location.reload();
        } else {
          Alerts.swalErrorAlert(errorMessage);
        }
      }
    }).catch((error) => {
      setLoading(false);
      Alerts.swalErrorAlert("Network Operation failed.");
    });
  };

  return (
    <Translation>
      {(t) => (
        <span>
          {loginNavigate && <Navigate to={'/login'} />}
          <Tooltip placement="top" title={t('Assign Fleet')} arrow>
            <i onClick={toggle} className="lnr-bus text-primary btn-icon-wrapper"></i>
          </Tooltip>
          <Modal isOpen={modal1} toggle={toggle1} className="custom_modal_width" backdrop={true}>
            <Card className="main-card mb-3">
              <CardBody>
                <h4 className="text-center fw-bold mb-4">Send Email</h4>
                <Form
                  onSubmit={handleSubmit1}
                  render={({ handleSubmit, submitting }) => (
                    <form onSubmit={handleSubmit}>
                      <FormGroup>
                        <legend className="mb-3">Select Recipients</legend>
                        <div className="mb-3">
                          <Field name="email">
                            {({ input }) => (
                              <div className="form-check mb-2">
                                <Label check style={{ cursor: 'pointer' }}>
                                  <Input
                                    {...input}
                                    type="checkbox"
                                    id={EmailRow?.userDetails?.[0]?.email}
                                    value={EmailRow?.userDetails?.[0]?.email}
                                    onChange={handleCheckboxChange}
                                    className="form-check-input"
                                    style={{ cursor: 'pointer' }}
                                  />
                                  {EmailRow?.userDetails?.[0]?.email}
                                </Label>
                              </div>
                            )}
                          </Field>
                          {Contacts?.map(email => (
                            <div key={email._id}>
                              <Field name="emails">
                                {({ input }) => (
                                  <div className="form-check mb-2">
                                    <Label check style={{ cursor: 'pointer' }}>
                                      <Input
                                        {...input}
                                        type="checkbox"
                                        id={email._id}
                                        value={email.email}
                                        onChange={handleCheckboxChange}
                                        className="form-check-input"
                                        style={{ cursor: 'pointer' }}
                                      />
                                      {email.email}
                                    </Label>
                                  </div>
                                )}
                              </Field>
                            </div>
                          ))}
                        </div>
                      </FormGroup>
                      <Row className="mt-4 justify-content-center">
                        <div className="col-auto">
                          <Button
                            type="submit"
                            color="primary"
                            disabled={selectedEmails.length === 0}
                          >
                            Send
                          </Button>
                        </div>
                        <div className="col-auto">
                          <Button
                            type="button"
                            onClick={onCancel}
                            color="secondary"
                          >
                            Cancel
                          </Button>
                        </div>
                      </Row>
                    </form>
                  )}
                />
              </CardBody>
            </Card>
          </Modal>
          <Modal isOpen={modal} toggle={toggle} className="medium-modal" backdrop={true}>
            <LoadingOverlay tag="div" active={loading} styles={{ overlay: (base) => ({ ...base }) }} spinner={<DNALoader />}>
              <ModalHeader>{t('Manual Order Assign')}</ModalHeader>
              <ModalBody>
                <Row>
                  <Col md="12" className="mt-2">
                    <Form onSubmit={onSubmit}
                      validate={validateFtn}
                      initialValues={prePopulatedData}
                      render={({ handleSubmit, values, submitting, validating, valid }) => (
                        <form onSubmit={handleSubmit}>
                          <Row>
                            <Col md="2"><Label className="pb-1 mt-2">{t("Pickup On")}</Label></Col>
                            <Col md="10" className="mb-4">
                              <FormGroup>
                                <Field name="pick_datetime">
                                  {({ input, meta }) => {
                                    return (
                                      <div>
                                        <Input
                                          {...input}
                                          type="datetime-local"
                                          placeholder="Pickup Date"
                                          invalid={meta.error && meta.touched}
                                        />
                                        {meta.error && meta.touched && (
                                          <span className="text-danger">{meta.error}</span>
                                        )}
                                      </div>
                                    );
                                  }}
                                </Field>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="2"><Label className="pb-1 mt-2">{t("Driver Name")}</Label></Col>
                            <Col md="4">
                              <FormGroup>
                                <Field name="driver_id">
                                  {({ input, meta }) => (
                                    <div>
                                      <Input {...input} type="select" placeholder={t("Driver Name")}
                                        invalid={meta.error && meta.touched}
                                      >
                                        <option disabled={false} value="">{`--${t("Choose an option")}--`}</option>
                                        {props.drivers &&
                                          props.drivers.map((driver, inde) => (
                                            <option key={inde} value={driver._id}>{driver.name}</option>
                                          ))
                                        }
                                      </Input>
                                      {meta.error && meta.touched &&
                                        <span className="text-danger">{meta.error}</span>}
                                    </div>
                                  )}
                                </Field>
                              </FormGroup>
                            </Col>
                            <Col md="2"><Label className="pb-1 mt-2">{t("Vehicle Number")}</Label></Col>
                            <Col md="4">
                              <FormGroup>
                                <Field name="fleet_id">
                                  {({ input, meta }) => (
                                    <div>
                                      <Input {...input} type="select" placeholder={t("Vehicle Number")}
                                        invalid={meta.error && meta.touched}
                                      >
                                        <option disabled={false} value="">{`--${t("Choose an option")}--`}</option>
                                        {props.fleets &&
                                          props.fleets.map((fleet, inde) => (
                                            <option key={inde} value={fleet._id}>{fleet.reg_no}</option>
                                          ))
                                        }
                                      </Input>
                                      {meta.error && meta.touched &&
                                        <span className="text-danger">{meta.error}</span>}
                                    </div>
                                  )}
                                </Field>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row className="mt-4">
                            <Col md="2">
                              {t("Auto Assign")}
                            </Col>
                            <Col md="2">
                              <Switch checked={switchtoggle} onChange={event => handleSwitch(event)} onColor="#86d3ff"
                                onHandleColor="#2693e6" handleDiameter={20} uncheckedIcon={false} checkedIcon={false}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)" activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                height={14} width={36} className="me-2 mb-3" id="material-switch"
                              />
                            </Col>
                          </Row>
                          <Row>
                            <div className="text-center mt-3">
                              <FormGroup>
                                <div className="text-center">
                                  <button className="btn btn-secondary btn-lg btn-hover-shine me-3">
                                    {t("Confirm")} </button>
                                  <button type="button" className="btn btn-outline-success btn-lg btn-hover-shine me-3" onClick={toggle}>
                                    {("Cancel")}
                                  </button>
                                </div>
                              </FormGroup>
                            </div>
                          </Row>
                        </form>
                      )}
                    />
                  </Col>
                </Row>
              </ModalBody>
            </LoadingOverlay>
          </Modal>
        </span>
      )}
    </Translation>
  );
};

export default AssignOrderManual;




// import React from "react";
// import { Translation } from "react-i18next";
// import { Navigate } from "react-router-dom";
// import { Row, Col, FormGroup, Input, Button, Label, Modal, ModalHeader, ModalBody, ModalFooter, Card, CardBody } from "reactstrap";
// import { Form, Field } from "react-final-form";
// import Switch from "react-switch";
// import Tooltip from '@mui/material/Tooltip';
// import moment from 'moment';

// import LoadingOverlay from "react-loading-overlay-ts";
// import DNALoader from "../../../../../components/Loaders/DnaLoader";
// import "../../../../../assets/custom css/loadoverlay.scss";
// import "../../../../../assets/custom css/modalwidths.scss";
// import Alerts from "../../../../../components/Alerts/alerts";

// import CustomerService from "../../Customer.service";
// import AuthService from "../../../../Authentication/auth.service";
// import FleetService from "../../../Fleet Management/fleet.service";
// import DriverService from "../../../Drivers/Driver.service";


// class AssignOrderManual extends React.Component {

//   componentDidMount() {
//     this.setData()
//     // this.getFleets();
//     // this.getDrivers();
//   }

//   constructor(props) {
//     super(props);
//     this.state = {
//       modal: false,
//       backdrop: true,
//       loading: false,
//       loginNavigate: false,
//       switchtoggle: false,
//       prePopulatedData: [],
//       weight: 0,
//       driver_id: '',
//       fleet_id: '',
//       pick_datetime: '',
//       quantity: 0,

//       is_auto_assigned: false,

//     };
//     this.toggle = this.toggle.bind(this);
//     this.setData = this.setData.bind(this);
//     this.validateFtn = this.validateFtn.bind(this);
//     this.onSubmit = this.onSubmit.bind(this);
//     this.handleSwitch = this.handleSwitch.bind(this);
//     // this.getFleets = this.getFleets.bind(this);
//     // this.getDrivers = this.getDrivers.bind(this);
//   }

//   toggle() {
//     this.setState({
//       modal: !this.state.modal,
//     });
//   }

//   setData() {

//     console.log('this.props.data', this.props.data)
//     const currentPrePopulatedData = { ...this.props.data };
//     // Update the driver_id in the state object
//     console.log(this.props.data.pick_datetime)
//     const formattedDate = moment(this.props.data.pick_datetime, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');

//     // Assign the formatted date to currentPrePopulatedData.pick_datetime
//     currentPrePopulatedData.pick_datetime = formattedDate;

//     // Update the state with the modified object
//     this.setState({
//       prePopulatedData: currentPrePopulatedData,
//     }, () => {
//       console.log(this.state.prePopulatedData); // Log the updated state after setting state
//     });


//   }


//   // getFleets() {
//   //   var getData = {
//   //     functionName: "GetFleets",
//   //     postData: {
//   //     },
//   //   }
//   //   FleetService.getAllFleets(getData).then((response) => {
//   //     console.log("Get Fleets response:-", response)
//   //     if (response.data.message === "Unauthorized") {
//   //       AuthService.logout();
//   //       this.setState({ loginNavigate: true });
//   //       window.location.reload();
//   //     }
//   //     else {
//   //       this.setState({
//   //         fleets: response.data.data,
//   //       });
//   //     }
//   //   },
//   //     (error) => {
//   //       const errContent =
//   //         (error.response && error.response.data) || error.message || error.toString();
//   //       console.log("Net:-", errContent)
//   //       if (errContent) {
//   //         Alerts.swalErrorAlert(errContent)
//   //       }
//   //       else {
//   //         Alerts.swalErrorAlert("Server Error, Please try again later.")
//   //       }
//   //     })
//   // }

//   // getDrivers() {
//   //   var getData = {
//   //     functionName: "GetDrivers",
//   //     postData: {
//   //     },
//   //   }
//   //   DriverService.getAllDrivers(getData).then((response) => {
//   //     console.log("Get Driver response:-", response)
//   //     if (response.data.message === "Unauthorized") {
//   //       AuthService.logout();
//   //       this.setState({ loginNavigate: true });
//   //       window.location.reload();
//   //     }
//   //     else {
//   //       this.setState({
//   //         drivers: response.data.data,
//   //       });
//   //     }
//   //   },
//   //     (error) => {
//   //       const errContent =
//   //         (error.response && error.response.data) || error.message || error.toString();
//   //       console.log("Net:-", errContent)
//   //       if (errContent) {
//   //         Alerts.swalErrorAlert(errContent)
//   //       }
//   //       else {
//   //         Alerts.swalErrorAlert("Server Error, Please try again later.")
//   //       }
//   //     })
//   // }

//   validateFtn = values => {
//     const errors = {};
//     if (!values.pick_datetime) {
//       errors.pick_datetime = "Pick Date Time Required.";
//     }
//     if (!values.driver_id) {
//       errors.driver_id = "Driver Name Required.";
//     }
//     if (!values.fleet_id) {
//       errors.fleet_id = "Vehicle Number Required.";
//     }
//     return errors;
//   }

//   onSubmit = values => {
//     console.log(values);

//     var payloadData = {
//       functionName: "AssignOrder",
//       postData: {
//         user_id: this.props.data.customer_id,
//         driver_id: values.driver_id,
//         fleet_id: values.fleet_id,
//         pick_datetime: values.pick_datetime,
//         _id: this.props.data._id,
//         is_auto_assigned: false,
//         order_status: 'Assigned',

//       }
//     }
//     this.setState({
//       loading: !this.state.loading,
//     });
//     CustomerService.manualDriverAssign(payloadData).then((response) => {
//       console.log("API Response:-", response);
//       this.setState({
//         loading: !this.state.loading,
//       });
//       if (response.data.status) {
//         if (response.data.message !== '') {
//           Alerts.swalSuccessAlert(response.data.message).then(res => {
//             console.log("Resp:-", res)
//             this.props.onSuccess();
//             this.toggle();
//           })
//         }
//         else {
//           Alerts.swalSuccessAlert("Order is assigned successfully.").then(res => {
//             console.log("Resp:-", res)
//             this.props.onSuccess();
//             this.toggle();
//           })
//         }
//       }
//       else {
//         if (response.data.message !== '') {
//           if (response.data.message === "Unauthorized") {
//             AuthService.logout();
//             this.setState({ loginNavigate: true });
//             window.location.reload();
//           }
//           else {
//             Alerts.swalErrorAlert(response.data.message).then(res => {
//               console.log("Resp:-", res)
//             })
//           }
//         }
//         else {
//           Alerts.swalErrorAlert("Error: Can't assign order at the moment. Please try again later.")
//         }
//       }
//     },
//       (error) => {
//         this.setState({
//           loading: !this.state.loading,
//         });
//         const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
//         Alerts.swalErrorAlert("Network Operation failed.")
//       });

//   }

//   handleSwitch = e => {
//     console.log('Switch event:-', e)
//     if (e === true) {
//       this.setState({
//         switchtoggle: true
//       });
//       this.setState({
//         is_auto_assigned: true
//       });

//       this.getAutoAssignData()
//     }
//     else {
//       this.setState({
//         is_auto_assigned: false
//       });
//       this.setState({
//         switchtoggle: false
//       });
//     }
//   }

//   getAutoAssignData() {
//     console.log(" auto assign is turned on");
//     console.log("Received Prop:-", this.props?.data?.orderDetails);
//     console.log("Received Data-Items:-", this.props?.data?.orderDetails);
//     this.props?.data?.orderDetails?.forEach((item) => {
//       this.state.weight += parseInt(item?.weight);
//       this.state.quantity += parseInt(item?.quantity);

//     })
//     console.log("weight ", this.state.weight)
//     console.log("quantity ", this.state.quantity)
//     var payloadData = {
//       functionName: "AutoAssignOrder",
//       postData: {
//         user_id: this.props.data.customer_id,
//         weight: this.state.weight,
//         quantity: this.state.quantity,
//         startDate: moment(),
//         endDate: moment().add(parseInt(localStorage.getItem('days_range')), 'days'),
//         is_auto_assigned: true,
//         auto_assign_distance: localStorage.getItem("auto_assign_distance"),
//         // driver_id: values.driver_id,
//         // fleet_id: values.fleet_id,
//         // pick_datetime: values.pick_datetime,
//         _id: this.props.data._id,
//         order_status: this.props.data.order_status
//       }
//     }
//     this.setState({
//       loading: !this.state.loading,
//     });
//     CustomerService.autoDriverAssign(payloadData).then((response) => {
//       console.log("auto API  Response:-", response);
//       this.setState({
//         loading: !this.state.loading,
//       });
//       if (response.data.status) {
//         if (response.data.message !== '') {
//           Alerts.swalSuccessAlert(response.data.message).then(res => {
//             console.log("Resp:-", res)
//             this.props.onSuccess();
//             // this.toggle();
//           })
//           console.log(response.data.data)
//           if (response.data.data) {
//             this.setState({
//               driver_id: response.data.data.driver_id,
//             });
//             this.setState({
//               fleet_id: response.data.data.fleet_id,
//             });
//             this.setState({
//               pick_datetime: response.data.data.pick_datetime,
//             });
//             const currentPrePopulatedData = { ...this.state.prePopulatedData };

//             // Update the driver_id in the state object
//             currentPrePopulatedData.driverDetails = response.data.data.driverDetails;
//             currentPrePopulatedData.fleetDetails = response.data.data.fleetDetails;
//             currentPrePopulatedData.driver_id = response.data.data.driver_id;
//             currentPrePopulatedData.fleet_id = response.data.data.fleet_id;
//             currentPrePopulatedData.pick_datetime = moment(response.data.data.pick_datetime, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');

//             // Update the state with the modified object
//             this.setState({
//               prePopulatedData: currentPrePopulatedData,
//             }, () => {
//               console.log(this.state.prePopulatedData); // Log the updated state after setting state
//             });

//           }
//         }
//         else {
//           Alerts.swalSuccessAlert("Order is assigned successfully.").then(res => {
//             console.log("Resp:-", res)
//             this.props.onSuccess();
//             // this.toggle();
//           })
//         }
//       }
//       else {
//         if (response.data.message !== '') {
//           if (response.data.message === "Unauthorized") {
//             AuthService.logout();
//             this.setState({ loginNavigate: true });
//             window.location.reload();
//           }
//           else {
//             Alerts.swalErrorAlert(response.data.message).then(res => {
//               console.log("Resp:-", res)
//             })
//           }
//         }
//         else {
//           Alerts.swalErrorAlert("Error: Can't assign order at the moment. Please try again later.")
//         }
//       }
//     },
//       (error) => {
//         this.setState({
//           loading: !this.state.loading,
//         });
//         const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
//         Alerts.swalErrorAlert("Network Operation failed.")
//       });

//   }

//   render() {
//     {
//       this.state.loginNavigate &&
//         <Navigate to={'/login'} />
//     }
//     return (
//       <Translation>
//         {(t) => (
//           <span >
//             <Tooltip placement="top" title={t('Assign Fleet')} arrow>
//               <i onClick={this.toggle} className="lnr-bus text-primary btn-icon-wrapper">
//               </i>
//             </Tooltip >
//             <Modal isOpen={this.state.modal} toggle={this.toggle} className="medium-modal" backdrop={true}>
//               <LoadingOverlay tag="div" active={this.state.loading}
//                 styles={{ overlay: (base) => ({ ...base }) }}
//                 spinner={<DNALoader />}>
//                 <ModalHeader>{t('Manual Order Assign')}</ModalHeader>
//                 <ModalBody>
//                   <Row>
//                     <Col md="12" className="mt-2">
//                       <Form onSubmit={this.onSubmit}
//                         validate={this.validateFtn}
//                         initialValues={this.state.prePopulatedData}
//                         render={({ handleSubmit, values, submitting, validating, valid }) => (
//                           <form onSubmit={handleSubmit}>
//                             <Row>
//                               <Col md="2"><Label className="pb-1 mt-2">{t("Pickup On")}</Label></Col>
//                               <Col md="10" className="mb-4">
//                                 <FormGroup>
//                                   <Field name="pick_datetime">
//                                     {({ input, meta }) => {
//                                       return (
//                                         <div>
//                                           <Input
//                                             {...input}
//                                             type="datetime-local" 
//                                             placeholder="Pickup Date"
//                                             invalid={meta.error && meta.touched}
//                                           />
//                                           {meta.error && meta.touched && (
//                                             <span className="text-danger">{meta.error}</span>
//                                           )}
//                                         </div>
//                                       );
//                                     }}
//                                   </Field>

//                                 </FormGroup>
//                               </Col>
//                             </Row>
//                             <Row>
//                               <Col md="2"><Label className="pb-1 mt-2">{t("Driver Name")}</Label></Col>
//                               <Col md="4">
//                                 <FormGroup>
//                                   <Field name="driver_id">
//                                     {({ input, meta }) => (
//                                       <div>
//                                         <Input {...input} type="select" placeholder={t("Driver Name")}
//                                           invalid={meta.error && meta.touched}
//                                         >
//                                           <option disabled={false} value="">{`--${t("Choose an option")}--`}</option>
//                                           {this.props.drivers &&
//                                             this.props.drivers.map((driver, inde) => (
//                                               <option key={inde} value={driver._id}>{driver.name}</option>
//                                             ))
//                                           }
//                                         </Input>
//                                         {meta.error && meta.touched &&
//                                           <span className="text-danger">{meta.error}</span>}
//                                       </div>
//                                     )}
//                                   </Field>
//                                 </FormGroup>
//                               </Col>
//                               <Col md="2"><Label className="pb-1 mt-2">{t("Vehicle Number")}</Label></Col>
//                               <Col md="4">
//                                 <FormGroup>
//                                   <Field name="fleet_id">
//                                     {({ input, meta }) => (
//                                       <div>
//                                         <Input {...input} type="select" placeholder={t("Vehicle Number")}
//                                           invalid={meta.error && meta.touched}
//                                         >
//                                           <option disabled={false} value="">{`--${t("Choose an option")}--`}</option>
//                                           {this.props.fleets &&
//                                             this.props.fleets.map((fleet, inde) => (
//                                               <option key={inde} value={fleet._id}>{fleet.reg_no}</option>
//                                             ))
//                                           }
//                                         </Input>
//                                         {meta.error && meta.touched &&
//                                           <span className="text-danger">{meta.error}</span>}
//                                       </div>
//                                     )}
//                                   </Field>
//                                 </FormGroup>
//                               </Col>
//                             </Row>
//                             <Row className="mt-4">
//                               <Col md="2">
//                                 {t("Auto Assign")}
//                               </Col>
//                               <Col md="2">
//                                 <Switch checked={this.state.switchtoggle} onChange={event => this.handleSwitch(event)} onColor="#86d3ff"
//                                   onHandleColor="#2693e6" handleDiameter={20} uncheckedIcon={false} checkedIcon={false}
//                                   boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)" activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
//                                   height={14} width={36} className="me-2 mb-3" id="material-switch"
//                                 />
//                               </Col>
//                             </Row>
//                             <Row>
//                               <div className="text-center mt-3">
//                                 <FormGroup>
//                                   <div className="text-center">
//                                     <button className="btn btn-secondary btn-lg btn-hover-shine me-3">
//                                       {t("Confirm")} </button>
//                                     <button type="button" className="btn btn-outline-success btn-lg btn-hover-shine me-3" onClick={this.toggle}>
//                                       {("Cancel")}
//                                     </button>
//                                   </div>
//                                 </FormGroup>
//                               </div>
//                             </Row>
//                           </form>
//                         )}
//                       />
//                     </Col>
//                   </Row>
//                 </ModalBody>
//               </LoadingOverlay>
//             </Modal>
//           </span>
//         )}
//       </Translation>
//     );
//   };
// };

// export default AssignOrderManual;



// import React from "react";
// import { Translation } from "react-i18next";
// import { Navigate } from "react-router-dom";
// import { Row, Col, FormGroup, Input, Button, Label, Modal, ModalHeader, ModalBody, ModalFooter, Card, CardBody } from "reactstrap";
// import { Form, Field } from "react-final-form";
// import Switch from "react-switch";
// import Tooltip from '@mui/material/Tooltip';
// import moment from 'moment';

// import LoadingOverlay from "react-loading-overlay-ts";
// import DNALoader from "../../../../../components/Loaders/DnaLoader";
// import "../../../../../assets/custom css/loadoverlay.scss";
// import "../../../../../assets/custom css/modalwidths.scss";
// import Alerts from "../../../../../components/Alerts/alerts";

// import CustomerService from "../../Customer.service";
// import AuthService from "../../../../Authentication/auth.service";
// import FleetService from "../../../Fleet Management/fleet.service";
// import DriverService from "../../../Drivers/Driver.service";


// class AssignOrderManual extends React.Component {

//   componentDidMount() {
//     this.setData()
//     // this.getFleets();
//     // this.getDrivers();
//   }

//   constructor(props) {
//     super(props);
//     this.state = {
//       modal: false,
//       modal1: false,
//       backdrop: true,
//       loading: false,
//       loginNavigate: false,
//       switchtoggle: false,
//       Contacts: [],
//       EmailRow: '',
//       selectedEmails: [],
//       prePopulatedData: [],
//       weight: 0,
//       driver_id: '',
//       fleet_id: '',
//       pick_datetime: '',
//       quantity: 0,

//       is_auto_assigned: false,

//     };
//     this.toggle = this.toggle.bind(this);
//     this.toggle1 = this.toggle1.bind(this);
//     this.setData = this.setData.bind(this);
//     this.validateFtn = this.validateFtn.bind(this);
//     this.onSubmit = this.onSubmit.bind(this);
//     this.handleSwitch = this.handleSwitch.bind(this);
//     // this.getFleets = this.getFleets.bind(this);
//     // this.getDrivers = this.getDrivers.bind(this);
//   }

//   toggle() {
//     this.setState({
//       modal: !this.state.modal,
//     });
//   }
//   toggle1() {
//     this.setState({
//       modal1: !this.state.modal1,
//     });
//   }
//   setData() {

//     console.log('this.props.data', this.props.data)
//     const currentPrePopulatedData = { ...this.props.data };
//     // Update the driver_id in the state object
//     console.log(this.props.data.pick_datetime)
//     const formattedDate = moment(this.props.data.pick_datetime, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');

//     // Assign the formatted date to currentPrePopulatedData.pick_datetime
//     currentPrePopulatedData.pick_datetime = formattedDate;

//     // Update the state with the modified object
//     this.setState({
//       prePopulatedData: currentPrePopulatedData,
//     }, () => {
//       console.log(this.state.prePopulatedData); // Log the updated state after setting state
//     });


//   }

//   validateFtn = values => {
//     const errors = {};
//     if (!values.pick_datetime) {
//       errors.pick_datetime = "Pick Date Time Required.";
//     }
//     if (!values.driver_id) {
//       errors.driver_id = "Driver Name Required.";
//     }
//     if (!values.fleet_id) {
//       errors.fleet_id = "Vehicle Number Required.";
//     }
//     return errors;
//   }

//   onSubmit = values => {
//     console.log(values);

//     var payloadData = {
//       functionName: "AssignOrder",
//       postData: {
//         user_id: this.props.data.customer_id,
//         driver_id: values.driver_id,
//         fleet_id: values.fleet_id,
//         pick_datetime: values.pick_datetime,
//         _id: this.props.data._id,
//         is_auto_assigned: false,
//         order_status: 'Assigned',

//       }
//     }
//     this.setState({
//       loading: !this.state.loading,
//     });
//     CustomerService.manualDriverAssign(payloadData).then((response) => {
//       console.log("API Response:-", response);
//       this.setState({
//         loading: !this.state.loading,
//       });
//       if (response.data.status) {
//         if (response.data.message !== '') {
//           Alerts.swalSuccessAlert(response.data.message).then(res => {
//             console.log("Resp:-", res)
//             this.props.onSuccess();
//             this.toggle();
//           })
//         }
//         else {
//           Alerts.swalSuccessAlert("Order is assigned successfully.").then(res => {
//             console.log("Resp:-", res)
//             this.props.onSuccess();
//             this.toggle();
//           })
//         }
//       }
//       else {
//         if (response.data.message !== '') {
//           if (response.data.message === "Unauthorized") {
//             AuthService.logout();
//             this.setState({ loginNavigate: true });
//             window.location.reload();
//           }
//           else {
//             Alerts.swalErrorAlert(response.data.message).then(res => {
//               console.log("Resp:-", res)
//             })
//           }
//         }
//         else {
//           Alerts.swalErrorAlert("Error: Can't assign order at the moment. Please try again later.")
//         }
//       }
//     },
//       (error) => {
//         this.setState({
//           loading: !this.state.loading,
//         });
//         const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
//         Alerts.swalErrorAlert("Network Operation failed.")
//       });

//   }

//   handleSwitch = e => {
//     console.log('Switch event:-', e)
//     if (e === true) {
//       this.setState({
//         switchtoggle: true
//       });
//       this.setState({
//         is_auto_assigned: true
//       });

//       this.getAutoAssignData()
//     }
//     else {
//       this.setState({
//         is_auto_assigned: false
//       });
//       this.setState({
//         switchtoggle: false
//       });
//     }
//   }

//   getAutoAssignData() {
//     console.log(" auto assign is turned on");
//     console.log("Received Prop:-", this.props?.data?.orderDetails);
//     console.log("Received Data-Items:-", this.props?.data?.orderDetails);
//     this.props?.data?.orderDetails?.forEach((item) => {
//       this.state.weight += parseInt(item?.weight);
//       this.state.quantity += parseInt(item?.quantity);

//     })
//     console.log("weight ", this.state.weight)
//     console.log("quantity ", this.state.quantity)
//     var payloadData = {
//       functionName: "AutoAssignOrder",
//       postData: {
//         user_id: this.props.data.customer_id,
//         weight: this.state.weight,
//         quantity: this.state.quantity,
//         startDate: moment(),
//         endDate: moment().add(parseInt(localStorage.getItem('days_range')), 'days'),
//         is_auto_assigned: true,
//         auto_assign_distance: localStorage.getItem("auto_assign_distance"),
//         // driver_id: values.driver_id,
//         // fleet_id: values.fleet_id,
//         // pick_datetime: values.pick_datetime,
//         _id: this.props.data._id,
//         order_status: this.props.data.order_status
//       }
//     }
//     this.setState({
//       loading: !this.state.loading,
//     });
//     CustomerService.autoDriverAssign(payloadData).then((response) => {
//       console.log("auto API  Response:-", response);
//       this.setState({
//         loading: !this.state.loading,
//       });
//       if (response.data.status) {
//         if (response.data.message !== '') {
//           Alerts.swalSuccessAlert(response.data.message).then(res => {
//             console.log("Resp:-", res)
//             this.props.onSuccess();
//             // this.toggle();
//           })
//           console.log(response.data.data)
//           if (response.data.data) {
//             this.setState({
//               driver_id: response.data.data.driver_id,
//             });
//             this.setState({
//               fleet_id: response.data.data.fleet_id,
//             });
//             this.setState({
//               pick_datetime: response.data.data.pick_datetime,
//             });
//             const currentPrePopulatedData = { ...this.state.prePopulatedData };

//             // Update the driver_id in the state object
//             currentPrePopulatedData.driverDetails = response.data.data.driverDetails;
//             currentPrePopulatedData.fleetDetails = response.data.data.fleetDetails;
//             currentPrePopulatedData.driver_id = response.data.data.driver_id;
//             currentPrePopulatedData.fleet_id = response.data.data.fleet_id;
//             currentPrePopulatedData.pick_datetime = moment(response.data.data.pick_datetime, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');

//             // Update the state with the modified object
//             this.setState({
//               prePopulatedData: currentPrePopulatedData,
//             }, () => {
//               console.log(this.state.prePopulatedData); // Log the updated state after setting state
//             });

//           }
//         }
//         else {
//           Alerts.swalSuccessAlert("Order is assigned successfully.").then(res => {
//             console.log("Resp:-", res)
//             this.props.onSuccess();
//             // this.toggle();
//           })
//         }
//       }
//       else {
//         if (response.data.message !== '') {
//           if (response.data.message === "Unauthorized") {
//             AuthService.logout();
//             this.setState({ loginNavigate: true });
//             window.location.reload();
//           }
//           else {
//             Alerts.swalErrorAlert(response.data.message).then(res => {
//               console.log("Resp:-", res)
//             })
//           }
//         }
//         else {
//           Alerts.swalErrorAlert("Error: Can't assign order at the moment. Please try again later.")
//         }
//       }
//     },
//       (error) => {
//         this.setState({
//           loading: !this.state.loading,
//         });
//         const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
//         Alerts.swalErrorAlert("Network Operation failed.")
//       });

//   }

//   handleSubmit1 = (values) => {
//     this.toggle1();
//     console.log('Selected emails:', selectedEmails);
//     console.log('EmailRow:', EmailRow);
//     const updatedRow = { ...EmailRow, selectedEmails }; // Combine existing rows with newly selected emails
//     console.log('Updated row:', updatedRow);

//     const confirmationMessage = t("Are you sure you want to send the invoice via email?");

//     Alerts.swalConfirmGeneralAlert(confirmationMessage).then(res => {
//       console.log("Response:", res);
//       if (res === true) {
//         this.sendMail(updatedRow);
//       }
//     });
//   }

//   sendMail(row) {
//     setLoading(true);
//     let tax_percentage = localStorage.getItem("tax_percentage");
//     CustomerService.sendOrder_PDF_Mail(row, tax_percentage).then((response) => {
//       console.log("Send mail to:-", response)
//       setLoading(false);
//       if (response.data.status) {
//         if (response.data.message !== '') {
//           Alerts.swalSuccessAlert(response.data.message)
//         }
//         else {
//           Alerts.swalSuccessAlert(t("Order emailed Successfully."))
//         }
//         console.log(response.data.message)
//       }
//       else {
//         if (response.data.message !== '') {
//           if (response.data.message === "Unauthorized") {
//             AuthService.logout();
//             navigate("/login");
//             window.location.reload();
//           }
//           else {
//             Alerts.swalErrorAlert(response.data.message);
//           }
//         }
//         else {
//           Alerts.swalErrorAlert(t("Error: Can't send the Password."))
//         }
//       }
//     },
//       (error) => {
//         setLoading(false);
//         const errContent =
//           (error.response && error.response.data) || error.message || error.toString();
//         Alerts.swalErrorAlert(t("Operation failed"))
//       })
//   };

//   onCancel() {
//     this.setState({
//       modal1: !this.state.modal1,
//     });
//   }

//   handleCheckboxChange = (e) => {
//     const email = e.target.value;
//     const isChecked = e.target.checked;
//     if (isChecked) {
//       setSelectedEmails([...selectedEmails, email]);
//     } else {
//       setSelectedEmails(selectedEmails.filter(item => item !== email));
//     }
//   };

//   render() {
//     {
//       this.state.loginNavigate &&
//         <Navigate to={'/login'} />
//     }
//     return (
//       <Translation>
//         {(t) => (
//           <span >
//             <Tooltip placement="top" title={t('Assign Fleet')} arrow>
//               <i onClick={this.toggle} className="lnr-bus text-primary btn-icon-wrapper">
//               </i>
//             </Tooltip >
//             <Modal isOpen={this.modal1} toggle={this.toggle1} className="custom_modal_width" backdrop={true}>
//               <Card className="main-card mb-3">
//                 <CardBody>
//                   <h4 className="text-center fw-bold mb-4">Send Email</h4>
//                   <Form
//                     onSubmit={this.handleSubmit1}
//                     render={({ handleSubmit, submitting }) => (
//                       <form onSubmit={handleSubmit}>
//                         <FormGroup>
//                           <legend className="mb-3">Select Recipients</legend>
//                           <div className="mb-3">
//                             <Field name="email">
//                               {({ input }) => (
//                                 <div className="form-check mb-2">
//                                   <Label check style={{ cursor: 'pointer' }}>
//                                     <Input
//                                       {...input}
//                                       type="checkbox"
//                                       id={EmailRow?.userDetails?.[0]?.email}
//                                       value={EmailRow?.userDetails?.[0]?.email}
//                                       onChange={this.handleCheckboxChange}
//                                       className="form-check-input"
//                                       style={{ cursor: 'pointer' }}
//                                     />
//                                     {EmailRow?.userDetails?.[0]?.email}
//                                   </Label>
//                                 </div>
//                               )}
//                             </Field>
//                             {this.Contacts.map(email => (
//                               <div key={email._id}>
//                                 <Field name="emails">
//                                   {({ input }) => (
//                                     <div className="form-check mb-2">
//                                       <Label check style={{ cursor: 'pointer' }}>
//                                         <Input
//                                           {...input}
//                                           type="checkbox"
//                                           id={email._id}
//                                           value={email.email}
//                                           onChange={this.handleCheckboxChange}
//                                           className="form-check-input"
//                                           style={{ cursor: 'pointer' }}
//                                         />
//                                         {email.email}
//                                       </Label>
//                                     </div>
//                                   )}
//                                 </Field>
//                               </div>
//                             ))}
//                           </div>
//                         </FormGroup>
//                         <Row className="mt-4 justify-content-center">
//                           <div className="col-auto">
//                             <Button
//                               type="submit"
//                               color="primary"
//                               disabled={selectedEmails.length === 0}
//                             >
//                               Send
//                             </Button>
//                           </div>
//                           <div className="col-auto">
//                             <Button
//                               type="button"
//                               onClick={this.onCancel}
//                               color="secondary"
//                             >
//                               Cancel
//                             </Button>
//                           </div>
//                         </Row>
//                       </form>
//                     )}
//                   />
//                 </CardBody>
//               </Card>
//             </Modal>
//             <Modal isOpen={this.state.modal} toggle={this.toggle} className="medium-modal" backdrop={true}>
//               <LoadingOverlay tag="div" active={this.state.loading}
//                 styles={{ overlay: (base) => ({ ...base }) }}
//                 spinner={<DNALoader />}>
//                 <ModalHeader>{t('Manual Order Assign')}</ModalHeader>
//                 <ModalBody>
//                   <Row>
//                     <Col md="12" className="mt-2">
//                       <Form onSubmit={this.onSubmit}
//                         validate={this.validateFtn}
//                         initialValues={this.state.prePopulatedData}
//                         render={({ handleSubmit, values, submitting, validating, valid }) => (
//                           <form onSubmit={handleSubmit}>
//                             <Row>
//                               <Col md="2"><Label className="pb-1 mt-2">{t("Pickup On")}</Label></Col>
//                               <Col md="10" className="mb-4">
//                                 <FormGroup>
//                                   <Field name="pick_datetime">
//                                     {({ input, meta }) => {
//                                       return (
//                                         <div>
//                                           <Input
//                                             {...input}
//                                             type="datetime-local"
//                                             placeholder="Pickup Date"
//                                             invalid={meta.error && meta.touched}
//                                           />
//                                           {meta.error && meta.touched && (
//                                             <span className="text-danger">{meta.error}</span>
//                                           )}
//                                         </div>
//                                       );
//                                     }}
//                                   </Field>

//                                 </FormGroup>
//                               </Col>
//                             </Row>
//                             <Row>
//                               <Col md="2"><Label className="pb-1 mt-2">{t("Driver Name")}</Label></Col>
//                               <Col md="4">
//                                 <FormGroup>
//                                   <Field name="driver_id">
//                                     {({ input, meta }) => (
//                                       <div>
//                                         <Input {...input} type="select" placeholder={t("Driver Name")}
//                                           invalid={meta.error && meta.touched}
//                                         >
//                                           <option disabled={false} value="">{`--${t("Choose an option")}--`}</option>
//                                           {this.props.drivers &&
//                                             this.props.drivers.map((driver, inde) => (
//                                               <option key={inde} value={driver._id}>{driver.name}</option>
//                                             ))
//                                           }
//                                         </Input>
//                                         {meta.error && meta.touched &&
//                                           <span className="text-danger">{meta.error}</span>}
//                                       </div>
//                                     )}
//                                   </Field>
//                                 </FormGroup>
//                               </Col>
//                               <Col md="2"><Label className="pb-1 mt-2">{t("Vehicle Number")}</Label></Col>
//                               <Col md="4">
//                                 <FormGroup>
//                                   <Field name="fleet_id">
//                                     {({ input, meta }) => (
//                                       <div>
//                                         <Input {...input} type="select" placeholder={t("Vehicle Number")}
//                                           invalid={meta.error && meta.touched}
//                                         >
//                                           <option disabled={false} value="">{`--${t("Choose an option")}--`}</option>
//                                           {this.props.fleets &&
//                                             this.props.fleets.map((fleet, inde) => (
//                                               <option key={inde} value={fleet._id}>{fleet.reg_no}</option>
//                                             ))
//                                           }
//                                         </Input>
//                                         {meta.error && meta.touched &&
//                                           <span className="text-danger">{meta.error}</span>}
//                                       </div>
//                                     )}
//                                   </Field>
//                                 </FormGroup>
//                               </Col>
//                             </Row>
//                             <Row className="mt-4">
//                               <Col md="2">
//                                 {t("Auto Assign")}
//                               </Col>
//                               <Col md="2">
//                                 <Switch checked={this.state.switchtoggle} onChange={event => this.handleSwitch(event)} onColor="#86d3ff"
//                                   onHandleColor="#2693e6" handleDiameter={20} uncheckedIcon={false} checkedIcon={false}
//                                   boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)" activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
//                                   height={14} width={36} className="me-2 mb-3" id="material-switch"
//                                 />
//                               </Col>
//                             </Row>
//                             <Row>
//                               <div className="text-center mt-3">
//                                 <FormGroup>
//                                   <div className="text-center">
//                                     <button className="btn btn-secondary btn-lg btn-hover-shine me-3">
//                                       {t("Confirm")} </button>
//                                     <button type="button" className="btn btn-outline-success btn-lg btn-hover-shine me-3" onClick={this.toggle}>
//                                       {("Cancel")}
//                                     </button>
//                                   </div>
//                                 </FormGroup>
//                               </div>
//                             </Row>
//                           </form>
//                         )}
//                       />
//                     </Col>
//                   </Row>
//                 </ModalBody>
//               </LoadingOverlay>
//             </Modal>
//           </span>
//         )}
//       </Translation>
//     );
//   };


// };

// export default AssignOrderManual;