import React, { useEffect, useState, Fragment } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import DataTable from 'react-data-table-component';
import {
  Row,
  Col
} from "reactstrap";
import Tooltip from '@mui/material/Tooltip';

import ServicesService from "./services.service";
import AuthService from "../../Authentication/auth.service";
import { useTranslation } from 'react-i18next';

import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../../components/Loaders/DnaLoader";
import "../../../assets/custom css/loadoverlay.scss";

import Alerts from "../../../components/Alerts/alerts";

import EditServicePrice from "./Services Modals/EditServicePrice";
import AddPackage from "./Services Modals/AddPackage";


const ServiceDetails = () => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const { state } = location;
  console.log("Received Object:-", state);
  const [service, setservice] = useState([]);
  const [masterprice, setMaster] = useState([]);
  const [packaging, setpackaging] = useState([]);
  // const [packagingsize, setpackagingsize] = useState([]);


  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    fetchSingleService();
  }, []);


  function fetchSingleService() {
    var getData = {
      functionName: "GetService",
      postData: {
        _id: state._id
      },
    }
    setLoading(true);
    ServicesService.getAllServices(getData).then((response) => {
      setLoading(false);
      console.log("Get Services response:-", response)
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        setservice(response.data.data[0])
        setMaster(response.data.data[0].masterpricingData);
        setpackaging(response.data.data[0].packaging_details);
        // setpackagingsize(response.data.data[0].packaging_size_details)
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent)
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  };

  function confirmDeleteService(_id, serviceId) {
    let msg = t("Once deleted, you will not be able to recover this master pricing detail!")
    Alerts.swalConfirmDeleteAlert(msg).then(res => {
      console.log("Resp:-", res)
      if (res === true) {
        sendDelete(_id, serviceId)
      }
    })
  };

  function sendDelete(id, service_id) {
    setLoading(true);
    ServicesService.deleteServiceDetail(id, service_id).then((response) => {
      setLoading(false);
      console.log("Delete service details response:-", response)
      if (response.data.status) {
        fetchSingleService()
        if (response.data.message !== '') {
          Alerts.swalSuccessAlert(response.data.message)
        }
        else {
          Alerts.swalSuccessAlert(t("Master Pricing Deleted Successfully."))
        }
        console.log(response.data.message)
      }
      else {
        if (response.data.message !== '') {
          if (response.data.message === "Unauthorized") {
            AuthService.logout();
            navigate("/login");
            window.location.reload();
          }
          else {
            Alerts.swalErrorAlert(response.data.message);
          }
        }
        else {
          Alerts.swalErrorAlert(t("Error: Can't delete the master pricing at the moment."))
        }
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        Alerts.swalErrorAlert(t("Delete operation failed"))
      })
  };


  const mycolumns = [
    {
      name: "Packaging",
      selector: row => {
        for (let i = 0; i < packaging.length; i++) {
          if (row.packaging_id === packaging[i]._id) {
            return (
              <div>
                {packaging[i].name}
              </div>
            );
          }
        }
      }
    },
    // {
    //   name: "Size",
    //   selector: row => {
    //     for (let i = 0; i < packagingsize.length; i++) {
    //       if (row.packaging_size_id === packagingsize[i]._id) {
    //         return (
    //           <div>
    //             {packagingsize[i].name}
    //           </div>
    //         );
    //       }
    //     }
    //   }
    // },
     {
      name: "Price",
      selector: row => row.price,
    },
    {
      name: 'Actions',
      selector: (row) => {
        return (
          <div>
            <button className="mt-2 mb-2 me-2 btn-icon btn">
              <Tooltip placement="top" title="Edit" arrow>
                <i>
                  <EditServicePrice data={row} />
                </i>
              </Tooltip >
              <Tooltip placement="top" title="Delete" arrow>
                <i onClick={() => confirmDeleteService(row._id, row.service_id)} className="pe-7s-trash text-danger btn-icon-wrapper"> </i>
              </Tooltip >
            </button>
          </div>
        );
      }
    },
  ];

  return (
    <Fragment>
      <div className="app-main__outer">
        <div className="app-main__inner">
          <div className="bg-light-gray bg-animation">
            <div className="d-flex justify-content-center align-items-center">
              <Col md="12" className="mx-auto app-login-box">
                <div className="app-page-title">
                  <div className="page-title-wrapper">
                    <div className="page-title-heading fw-bold">
                      <div className="page-title-icon">
                        <i className="lnr-cog icon-gradient bg-tempting-azure" />
                      </div>
                      {service &&
                        <div>
                          {service.name} ({service.code})
                          <div className="page-title-subheading">
                            {service.description}
                          </div>
                        </div>
                      }
                    </div>
                    <div className="page-title-actions">
                      <button type="button" onClick={() => navigate(-1)} className="btn btn-outline-success btn-hover-shine me-3">
                        Back
                      </button>
                    </div>
                  </div>
                </div>
                <Row className="mt-5">
                  <Col md="6">
                    <h4 className="fw-bold ">Master Pricing</h4>
                  </Col>
                  <Col md="6">
                    <AddPackage data={state._id} />
                  </Col>
                  <Col md="12" className="pt-3">
                    <LoadingOverlay tag="div" active={loading}
                      styles={{ overlay: (base) => ({ ...base }) }}
                      spinner={<DNALoader />}>
                      <DataTable data={masterprice}
                        columns={mycolumns}
                        pagination
                        fixedHeader
                        fixedHeaderScrollHeight="400px"
                      />
                    </LoadingOverlay>
                  </Col>
                </Row>
              </Col>
            </div>

          </div>
        </div>
      </div>
    </Fragment>
  );
};


export default ServiceDetails;

