import React, { useEffect, useState, Fragment } from "react";
import { useTranslation } from 'react-i18next';
import { Row, Col, Card, CardBody } from "reactstrap";

import { useNavigate, useLocation } from "react-router-dom";


const BasicInformation = () => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const location = useLocation();
  const { state } = location;
  console.log("Received Object:-", state);

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
  }, []);



  return (
    <Fragment>
      <Row>
        <Col md="6">
          <Card className="main-card mb-3">
            <CardBody>
              <table className="table table-striped table-bordered nowrap">
                <tbody>
                  <tr>
                    <th >Driver Name</th>
                    <td>{state.name}</td>
                  </tr>
                  <tr>
                    <th>Driver ID</th>
                    <td>{state.login_id}</td>
                  </tr>
                  <tr>
                    <th >Email</th>
                    <td>{state.email}</td>
                  </tr>
                  <tr>
                    <th >Phone Number</th>
                    <td>{state.dob}</td>
                  </tr>
                </tbody>
              </table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );

};
export default BasicInformation;

