import axios from "axios";

const GET_API_URL = process.env.REACT_APP_LOGINURL;


const headers = {
  'Content-Type': 'application/json',
  'token': localStorage.getItem("session-id")
}

const getConfiguration = () => {
  return axios.post(GET_API_URL + 'FinanceRoutes/Get_Finance_Config', { headers });
};
const setConfiguration = (data) => {
  return axios.post(GET_API_URL + 'FinanceRoutes/Set_Finance_Config', data, { headers });
};

const ConfigurationService = {
  getConfiguration,
  setConfiguration
};

export default ConfigurationService;

