import React from "react";
import { Translation } from "react-i18next";
import { Navigate } from "react-router-dom";
import { Row, Col, FormGroup, Input, Button, Label, Modal, ModalHeader, ModalBody, ModalFooter, Card, CardBody } from "reactstrap";
import { Form, Field } from "react-final-form";

import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../../../../components/Loaders/DnaLoader";
import "../../../../../assets/custom css/loadoverlay.scss";
import "../../../../../assets/custom css/modalwidths.scss";
import Alerts from "../../../../../components/Alerts/alerts";

import CustomerService from "../../Customer.service";
import AuthService from "../../../../Authentication/auth.service";
import ServicesService from "../../../Services Management/services.service";


class UpdateOrderItem extends React.Component {

  constructor(props) {
    super(props);
    console.log("Received Prop In update:-", props);
    this.state = {
      total_price: this.props.data.total_price,
      price: this.props.data.price,
      quantity: this.props.data.quantity,
      weight: this.props.data.weight,
      modal: false,
      backdrop: true,
      loading: false,
      loginNavigate: false
    };
    this.toggle = this.toggle.bind(this);
    this.validateFtn = this.validateFtn.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }

  handleChange = (value, type) => {
    console.log('handleChange');
    console.log('value', value);
    console.log('type', type);
    if (type === 'price') {
      this.setState(prevState => ({
        price: value
      }));
      this.setState(prevState => ({
        total_price: parseFloat(value).toFixed(2) * parseFloat(this.state.quantity).toFixed(2) * parseFloat(this.state.weight).toFixed(2)
      }))

    } else if (type === 'quantity') {
      this.setState({
        quantity: value
      })
      this.setState(prevState => ({
        total_price: parseFloat(value).toFixed(2) * parseFloat(this.state.price).toFixed(2) * parseFloat(this.state.weight).toFixed(2)
      }))

    } else if (type === 'weight') {
      this.setState({
        weight: value
      })
      this.setState(prevState => ({
        total_price: parseFloat(value).toFixed(2) * parseFloat(this.state.price).toFixed(2) * parseFloat(this.state.quantity).toFixed(2)
      }))
    }

  }

  validateFtn = values => {
    const errors = {};
    if (!values.service_id) {
      errors.service_id = "Please Select Service.";
    }
    if (!values.packaging_id) {
      errors.packaging_id = "Please Select Packaging.";
    }
    // if (!values.packaging_size_id) {
    //   errors.packaging_size_id = "Please Select Size.";
    // }
    if (!values.quantity) {
      errors.quantity = "Quantity is required.";
    }
    if (!values.weight) {
      errors.weight = "Weight is required.";
    }
    if (!values.price) {
      errors.price = "Price is required.";
    }
    return errors;
  }

  onSubmit = values => {
    console.log(values);
    var payloadData = {
      functionName: "UpdateOrderDetails",
      postData: {
        _id: this.props.data._id,
        order_id: this.props.data.order_id,
        user_id: this.props.data.user_id,
        service_id: values.service_id,
        packaging_id: values.packaging_id,
        // packaging_size_id: values.packaging_size_id,
        quantity: this.state.quantity,
        weight: this.state.weight,
        price: parseFloat(this.state.price).toFixed(2),
        total_price: parseFloat(this.state.total_price).toFixed(2)

      }
    }
    this.setState({
      loading: !this.state.loading,
    });
    CustomerService.updateSingleOrderItem(payloadData).then((response) => {
      console.log("API Response:-", response);
      this.setState({
        loading: !this.state.loading,
      });
      if (response.data.status) {
        if (response.data.message !== '') {
          Alerts.swalSuccessAlert(response.data.message).then(res => {
            console.log("Resp:-", res)
            this.props.onSuccess();
            this.toggle();
          })
        }
        else {
          Alerts.swalSuccessAlert("Customer Order Item Updated successfully.").then(res => {
            console.log("Resp:-", res)
            this.props.onSuccess();
            this.toggle();
          })
        }
      }
      else {
        if (response.data.message !== '') {
          if (response.data.message === "Unauthorized") {
            AuthService.logout();
            this.setState({ loginNavigate: true });
            window.location.reload();
          }
          else {
            Alerts.swalErrorAlert(response.data.message).then(res => {
              console.log("Resp:-", res)
            })
          }
        }
        else {
          Alerts.swalErrorAlert("Error: Can't Update item at the moment. Please try again later.")
        }
      }
    },
      (error) => {
        this.setState({
          loading: !this.state.loading,
        });
        const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        Alerts.swalErrorAlert("Network Operation failed.")
      });

  }

  render() {
    {
      this.state.loginNavigate &&
        <Navigate to={'/login'} />
    }
    return (
      <Translation>
        {(t) => (
          <span className="d-inline-block mb-2">
            <i onClick={this.toggle} className="pe-7s-pen btn-icon-wrapper text-primary"> </i>
            <Modal isOpen={this.state.modal} toggle={this.toggle} className="large-modal" backdrop={true}>
              <ModalHeader >{t("Update Customer Order Item")}</ModalHeader>
              <ModalBody>
                <LoadingOverlay tag="div" active={this.state.loading}
                  styles={{ overlay: (base) => ({ ...base }) }}
                  spinner={<DNALoader />}>
                  <Form onSubmit={this.onSubmit}
                    validate={this.validateFtn}
                    initialValues={this.props.data}
                    render={({ handleSubmit, values, submitting, validating, valid }) => (
                      <form onSubmit={handleSubmit}>
                        <Row className="mt-3">
                          <Col md="2">
                            <FormGroup>
                              <Field name="service_id">
                                {({ input, meta }) => (
                                  <div>
                                    <Input {...input} type="select" className="serviceclass"
                                      invalid={meta.error && meta.touched}
                                    >
                                      <option disabled={false} value="">{t("Service")}</option>
                                      {this.props.services &&
                                        this.props.services.map((serv, ind) => (
                                          <option key={ind} value={serv._id}>{serv.name}</option>
                                        ))
                                      }
                                    </Input>
                                    {meta.error && meta.touched &&
                                      <span className="text-danger">{meta.error}</span>}
                                  </div>
                                )}
                              </Field>
                            </FormGroup>
                          </Col>
                          <Col md="2">
                            <FormGroup>
                              <Field name="packaging_id">
                                {({ input, meta }) => (
                                  <div>
                                    <Input {...input} type="select" className="packclass"
                                      invalid={meta.error && meta.touched}
                                    >
                                      <option disabled={false} value="">{t("Packaging")}</option>
                                      {this.props.packagings &&
                                        this.props.packagings.map((pack, inde) => (
                                          <option key={inde} value={pack._id}>{pack.name}</option>
                                        ))
                                      }
                                    </Input>
                                    {meta.error && meta.touched &&
                                      <span className="text-danger">{meta.error}</span>}
                                  </div>
                                )}
                              </Field>
                            </FormGroup>
                          </Col>
                          {/* <Col md="2">
                            <FormGroup>
                              <Field name="packaging_size_id">
                                {({ input, meta }) => (
                                  <div>
                                    <Input {...input} type="select" className="sizeclass"
                                      invalid={meta.error && meta.touched}
                                    >
                                      <option disabled={false} value="">Size</option>
                                      {this.props.packagingSizes &&
                                        this.props.packagingSizes.map((size, ind) => (
                                          <option key={ind} value={size._id}>{size.name}</option>
                                        ))
                                      }
                                    </Input>
                                    {meta.error && meta.touched &&
                                      <span className="text-danger">{meta.error}</span>}
                                  </div>
                                )}
                              </Field>
                            </FormGroup>
                          </Col > */}
                          <Col md="2">
                            <FormGroup>
                              <Field name="quantity">
                                {({ input, meta }) => (
                                  <div>
                                    <Input
                                      {...input}
                                      type="number"
                                      placeholder={t("Quantity")}
                                      className="qtyclass"
                                      value={this.state.quantity}
                                      onChange={(e) => {
                                        this.handleChange(e.target.value, 'quantity');
                                      }}
                                      invalid={meta.error && meta.touched}
                                    />
                                    {meta.error && meta.touched &&
                                      <span className="text-danger">{meta.error}</span>}
                                  </div>
                                )}
                              </Field>
                            </FormGroup>
                          </Col>

                          <Col md="2">
                            <FormGroup>
                              <Field name="weight">
                                {({ input, meta }) => (
                                  <div>
                                    <Input
                                      {...input}
                                      type="number"
                                      placeholder={t("Weight")}
                                      className="weightclass"
                                      value={this.state.weight}
                                      onChange={(e) => {
                                        this.handleChange(e.target.value, 'weight');
                                      }}
                                      invalid={meta.error && meta.touched}
                                    />
                                    {meta.error && meta.touched &&
                                      <span className="text-danger">{meta.error}</span>}
                                  </div>
                                )}
                              </Field>
                            </FormGroup>
                          </Col>
                          <Col md="2" >
                            <FormGroup>
                              <Field name="price" >
                                {({ input, meta }) => (
                                  <div>
                                    <Input
                                      {...input}
                                      type="number"
                                      placeholder={t("Price")}
                                      className="priceclass"
                                      value={this.state.price}
                                      onChange={(e) => {
                                        this.handleChange(e.target.value, 'price');
                                      }}
                                      invalid={meta.error && meta.touched}
                                    />
                                    {meta.error && meta.touched &&
                                      <span className="text-danger">{meta.error}</span>}
                                  </div>
                                )}
                              </Field>
                            </FormGroup>
                          </Col>
                          <Col md="2" >
                            <FormGroup>
                              <Field name="total_price" >
                                {({ input, meta }) => (
                                  <div>
                                    <Input {...input} type="number" placeholder={t("Total Price" )}className="totalpriceclass"
                                      value={this.state.total_price}
                                      invalid={meta.error && meta.touched}
                                    />
                                    {meta.error && meta.touched &&
                                      <span className="text-danger">{meta.error}</span>}
                                  </div>
                                )}
                              </Field>
                            </FormGroup>
                          </Col>

                        </Row>
                        <Row className="mt-3">
                          <FormGroup>
                            <div className="text-center">
                              <button type="submit" className="btn btn-secondary btn-lg btn-hover-shine me-3">
                                {t("Save")} </button>
                              <button type="button" onClick={this.toggle} className="btn btn-outline-success btn-lg btn-hover-shine me-3">
                                {t("Cancel")}
                              </button>
                            </div>
                          </FormGroup>
                        </Row>
                      </form>
                    )}
                  />
                </LoadingOverlay>
              </ModalBody>
            </Modal>
          </span>
        )}
      </Translation>
    );
  };


};

export default UpdateOrderItem;