import React, { useEffect, Fragment, useState } from "react";
import { useTranslation } from 'react-i18next';
import { Row, Col, Card, CardBody } from "reactstrap";
import { useLocation } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../../../components/Loaders/DnaLoader";
import "../../../../assets/custom css/loadoverlay.scss";
import RoomIcon from '@mui/icons-material/Room';
import AlertService from "../../../../components/Alerts/alerts";

const CustomerBasicInfo = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { state } = location;
  const [loading, setLoading] = useState(false);

  const handleLinkClick = () => {
    if (state.address_url) {
      window.open(state.address_url, '_blank', 'noopener noreferrer');
    } else if (state.address) {
      // Construct Google Maps URL using the address text
      const addressText = encodeURIComponent(state.address);
      const mapsURL = `https://www.google.com/maps/search/?api=1&query=${addressText}`;
      window.open(mapsURL, '_blank', 'noopener noreferrer');
    } else {
      AlertService.swalErrorAlert("Please Enter a Valid Address URL")
    }
  };

  useEffect(() => {
  }, []);

  return (
    <Fragment>
      <Row>
        <Col md="12">
          <Card className="main-card">
            <LoadingOverlay tag="div" active={loading}
              styles={{ overlay: (base) => ({ ...base }) }}
              spinner={<DNALoader />}>
              <CardBody>
                <table className="table table-striped table-bordered nowrap" >
                  <tbody>
                    <tr style={{ width: '100%' }}>
                      <th style={{ width: '50%' }}>{t('Company Name')}</th>
                      <td style={{ width: '50%' }}>{state.company_name}</td>
                    </tr>
                    <tr>
                      <th>{t('Registration No')}</th>
                      <td>{state.reg_no}</td>
                    </tr>
                    <tr>
                      <th>{t('Email')}</th>
                      <td>{state.email}</td>
                    </tr>
                    <tr>
                      <th>{t('PIC')}</th>
                      <td>{state.pic_name}</td>
                    </tr>
                    <tr>
                      <th>{t('Phone')}</th>
                      <td>{state.phone}</td>
                    </tr>
                    {/* <tr>
                      <th>{t('Google Location')}</th>
                      <td>
                        <a
                          href={state.address_url}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            display: 'inline-block',
                            maxWidth: '10%',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {state.google_loc}
                        </a>
                      </td>
                    </tr> */}
                    {console.log("state.address", state.address)}
                    {console.log("state.address_url", state.address_url)}
                    <tr>
                      <th>{t('Google Location')}</th>
                      <td style={{
                        borderRight: '1px solid #dee2e6',
                        padding: '8px',
                      }}>
                        <div style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}>
                          <input
                            type="text"
                            value={state.address}
                            style={{

                              marginRight: '8px',
                              padding: '4px',
                              maxWidth: '500px',
                              width: '100%',
                              border: '1px solid #ccc',
                            }}
                          />
                          <div
                            onClick={handleLinkClick}
                            style={{
                              display: 'inline-block',
                              maxWidth: '10%',
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                              textOverflow: 'ellipsis',
                              cursor: 'pointer',
                            }}
                          >
                            <RoomIcon sx={{ color: 'red' }} className="pe-7s-up-arrow btn-icon-wrapper" />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>{t('Full Address')}</th>
                      <td style={{
                        borderRight: '1px solid #dee2e6',
                        padding: '8px',
                      }}>
                        <div style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}>
                          <input
                            type="text"
                            value={state.address}
                            style={{
                              marginRight: '8px',
                              padding: '4px',
                              maxWidth: '500px',
                              width: '100%',
                              border: '1px solid #ccc',
                            }}
                          />
                        </div>
                      </td>
                    </tr>

                    {/* <tr>
                      <th>{t('Full Address')}</th>
                      <td style={{
                        borderRight: '1px solid #dee2e6',
                        padding: '8px', 
                      }}>
                        <div style={{
                          display: 'inline-block',
                          maxWidth: '10%',
                          wordWrap: 'break-word',
                        }}>
                          {state.address}
                        </div>
                      </td>
                    </tr> */}

                  </tbody>
                </table>
              </CardBody>
            </LoadingOverlay>

          </Card>
        </Col>
      </Row>
    </Fragment >
  );
};

export default CustomerBasicInfo;
