import axios from "axios";

const B_URL = process.env.REACT_APP_LOGINURL
const API_URL = process.env.REACT_APP_LOGINURL;


const GetInvoicePayments = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(API_URL + 'DashboardRoutes/GetInvoicePayments', payload, { headers });
};

const GetOrdersDetails = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(API_URL + 'DashboardRoutes/GetOrdersDetails', payload, { headers });
};
const GetDashboardData = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(API_URL + 'DashboardRoutes/GetDashboardData', payload, { headers });
};
const GetOrders_Count = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(API_URL + 'DashboardRoutes/GetOrders_Count', payload, { headers });
};
const GetOrders = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(API_URL + 'orderRoutes/' + payload.functionName, payload, { headers });
};
const getAllServices = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(API_URL + 'ServicesRoutes/' + payload.functionName, payload, { headers });
};
const getAllFleets = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(API_URL + 'FleetsRoutes/' + payload.functionName, payload, { headers });
};
const getCustomerOrders = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(API_URL + 'orderRoutes/' + payload.functionName, payload, { headers });
};

const getAllDrivers = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'DriversRoutes/' + payload.functionName, payload, { headers });
};

const deleteCustomerOrder = (id, user_id) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  var payload = {
    functionName: "DeleteOrder",
    postData: {
      "_id": id,
      "user_id": user_id
    },
  }
  return axios.post(B_URL + 'orderRoutes/' + payload.functionName, payload, { headers });
};
const sendOrder_PDF_Mail = (row, tax_percentage) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  var payload = {
    functionName: "sendOrder_PDF_Mail",
    postData: {
      "tax_percentage": tax_percentage,
      "row": row,
    },
  }
  return axios.post(B_URL + "invoices", payload, { headers });
};

const getOrder_PDF = (payload) => {
  const headers = {

    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'invoices', payload, {
    headers,
    responseType: 'arraybuffer'
  });
};


const DashboardService = {
  getOrder_PDF,
  sendOrder_PDF_Mail,
  deleteCustomerOrder,
  getAllDrivers,
  GetOrders_Count,
  GetDashboardData,
  GetOrdersDetails,
  getCustomerOrders,
  GetOrders,
  GetInvoicePayments,
  getAllFleets,
  getAllServices
};

export default DashboardService;