import axios from "axios";

const API_URL = process.env.REACT_APP_LOGINURL;
const B_URL = process.env.REACT_APP_LOGINURL

const getAllDrivers = (payload) => {
    const headers = {
        'Content-Type': 'application/json',
        'token': localStorage.getItem("session-id")
    }
    return axios.post(B_URL + 'DriversRoutes/' + payload.functionName, payload, { headers });
};


const getAllFleets = (payload) => {
    const headers = {
        'Content-Type': 'application/json',
        'token': localStorage.getItem("session-id")
    }
    return axios.post(API_URL + 'FleetsRoutes/' + payload.functionName, payload, { headers });
};


const addFleet = (payload) => {
    const headers = {
        'Content-Type': 'application/json',
        'token': localStorage.getItem("session-id")
    }
    return axios.post(API_URL + 'FleetsRoutes/' + payload.functionName, payload, { headers });
};

const updateFleet = (payload) => {
    const headers = {
        'Content-Type': 'application/json',
        'token': localStorage.getItem("session-id")
    }
    return axios.post(API_URL + 'FleetsRoutes/' + payload.functionName, payload, { headers });
};


const deleteFleet = (id) => {
    const headers = {
        'Content-Type': 'application/json',
        'token': localStorage.getItem("session-id")
    }
    var payload = {
        functionName: "DeleteFleet",
        postData: {
            _id: id,
        },
    }
    return axios.post(API_URL + 'FleetsRoutes/' + payload.functionName, payload, { headers });
};

const getModels = (id) => {
    var payload = {
        functionName: "GetModels",
        postData: {
            brand_id: id
        },
    }
    const headers = {
        'Content-Type': 'application/json',
        'token': localStorage.getItem("session-id")
    }
    return axios.post(API_URL + 'ModelRoutes/' + payload.functionName, payload, { headers });
};


const getBrands = () => {
    var payload = {
        functionName: "GetBrands",
        postData: {},
    }
    const headers = {
        'Content-Type': 'application/json',
        'token': localStorage.getItem("session-id")
    }
    return axios.post(API_URL + 'BrandsRoutes/' + payload.functionName, payload, { headers });
};





const FleetService = {
    getAllDrivers,
    getAllFleets,
    addFleet,
    updateFleet,
    deleteFleet,
    getModels,
    getBrands
};

export default FleetService;