import axios from "axios";

const API_URL  = process.env.REACT_APP_LOGINURL+'signup_forms';//process.env.REACT_APP_BASEURL;
const storeduser = JSON.parse(localStorage.getItem("user"));

const headers = { 
  'Content-Type': 'application/json',
  'token': localStorage.getItem("session-id")
}

const getAll = (filter) => {
  var payload = {
    functionName: "GetCollectionData",
    "postData":{
        "collection_name": "user_forms",
        "filter":filter
    }
  }
  return axios.post(API_URL, payload, {headers});
};

const get_collection_data = (postData) => {
  var payload = {
    functionName: "GetCollectionData",
    "postData":postData
  }
  return axios.post(API_URL, payload, {headers});
};

const deleteEntity = (id,collection_name) => {
  var payload = {
    functionName: "DeleteUserForm",
    postData: {
      "_id": id,
      "collection_name":collection_name,
      "login_user_id": storeduser._id
    },
  }
  return axios.post(API_URL, payload, {headers});
};


const addEntity = payload => {
  console.log("Received addEntity Payload:-", payload)
  return axios.post(API_URL, payload, {headers});
}


const UpdateEntity = payload => {
  console.log("Received addEntity Payload:-", payload)
  return axios.post(API_URL, payload, {headers});
}

const fetchcollections = () => {
  var payload = {
    functionName: "FetchCollections",
    postData: {},
  }
  return axios.post(API_URL, payload, {headers});
}

const get_signiup_data = (id) => {
  var payload = {
    functionName: "GetUserForm",
    postData: {name:id},
  }
  
  return axios.post(API_URL, payload, {headers});
}



const save_user = (payload) => {
  
  const API_URL2  = process.env.REACT_APP_LOGINURL+'signup_forms_insert';
  return axios.post(API_URL2, payload, {headers});
}


const UserForms =  {
  getAll,
  deleteEntity,
  addEntity,
  UpdateEntity,
  fetchcollections,
  get_signiup_data,
  save_user,
  get_collection_data
}

export default UserForms;
