import React, { useEffect, useState, Fragment } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import FleetService from "./fleet.service";
import AuthService from "../../Authentication/auth.service";
import { useTranslation } from 'react-i18next';

import Alerts from "../../../components/Alerts/alerts";
import {
  Row,
  Col,
  Card,
  CardBody
} from "reactstrap";




const FleetDetails = () => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [fleetdetail, setfleetdetail] = useState('');
  const [brands, setBrands] = useState([]);
  const [models, setModels] = useState([]);
  const [brand, setBrand] = useState('');
  const [model, setModel] = useState('');
  const location = useLocation();
  console.log("Received Object location:-", location);
  const { state } = location;
  console.log("Received Object:-", state);


  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    setData();
  }, []);

  function setData() {
    if (state) {
      console.log("Going to set data object for display")
      setfleetdetail(state);
      console.log("Details After Set Data:-", fleetdetail);
      getBrands();
      getModels();
    }
    else {
      navigate(-1)
      window.location.reload()
    }
  };


  function getBrands() {
    setLoading(true);
    FleetService.getBrands().then((response) => {
      setLoading(false);
      console.log("Get Brands response:-", response)
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        var brand_res = response.data.data
        setBrands(brand_res);
        for (let i = 0; i < brand_res.length; i++) {
          if (state.brand_id === brand_res[i]._id) {
            setBrand(brand_res[i].name)
          }
        }
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent)
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  };

  function getModels() {
    setLoading(true);
    FleetService.getModels().then((response) => {
      setLoading(false);
      console.log("Get Models response:-", response)
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        var model_res = response.data.data;
        setModels(model_res);
        for (let i = 0; i < model_res.length; i++) {
          if (state.model_id === model_res[i]._id) {
            setModel(model_res[i].name)
          }
        }
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent)
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  };

  return (
    <Fragment>
      <div className="app-main__outer">
        <div className="app-main__inner">
          <div className=" bg-light-gray bg-animation">
            <div className="d-flex  justify-content-center align-items-center">
              <Col md="12" className="mx-auto app-login-box">
                <div className="app-page-title">
                  <div className="page-title-wrapper">
                    <div className="page-title-heading fw-bold">
                      <div className="page-title-icon">
                        <i className="lnr-bus icon-gradient bg-tempting-azure" />
                      </div>
                      <div>
                        {state.name} - {state.reg_no}
                        <div className="page-title-subheading">
                          {t('View Fleet with basic details.')}
                        </div>
                      </div>
                    </div>
                    <div className="page-title-actions">
                      <button type="button" onClick={() => navigate(-1)} className="btn btn-outline-success btn-hover-shine me-3">
                        Back
                      </button>
                    </div>
                  </div>
                </div>

                <Card className="main-card mb-3">
                  <CardBody >
                    <Row>
                      <Col md="6" className="pt-3">
                        <table className="mb-3 table table-borderless nowrap">
                          <tbody>
                            <tr>
                              <th style={{ width: "30%" }}>Name</th>
                              <td>{state.name}</td>
                            </tr>
                            <tr>
                              <th style={{ width: "35%" }}>Brand</th>
                              <td>{brand}</td>
                            </tr>
                            <tr>
                              <th style={{ width: "30%" }}>Color</th>
                              <td>{state.color}</td>
                            </tr>
                            <tr>
                              <th style={{ width: "30%" }}> Container Width </th>
                              <td>{state.width}</td>
                            </tr>
                            <tr>
                              <th style={{ width: "30%" }}> Container Height
                              </th>
                              <td>{state.height}</td>
                            </tr>
                            <tr>
                              <th style={{ width: "40%" }}> Driver Name </th>
                              <td>{state?.DriverData?.[0]?.name}</td>
                            </tr>
                            <tr>
                              <th style={{ width: "40%" }}> Remarks </th>
                              <td>{state?.remarks}</td>
                            </tr>
                          </tbody>
                        </table>
                      </Col>
                      <Col md="6" className="pt-3">
                        <table className="mb-3 table table-borderless nowrap">
                          <tbody>
                            <tr>
                              <th style={{ width: "30%" }}> Registration No. </th>
                              <td>{state.reg_no} </td>
                            </tr>
                            <tr>
                              <th style={{ width: "30%" }}> Model </th>
                              <td>{model}</td>
                            </tr>
                            <tr>
                              <th style={{ width: "40%" }}>Manufacturing Year</th>
                              <td>{state.manufacturing_year}</td>
                            </tr>
                            <tr>
                              <th style={{ width: "40%" }}> Container Length </th>
                              <td>{state.length}</td>
                            </tr>
                            <tr>
                              <th style={{ width: "40%" }}> Loading Capacity </th>
                              <td>{state.loadcap}</td>
                            </tr>
                            <tr>
                              <th style={{ width: "40%" }}> Driver Email </th>
                              <td>{state?.DriverData?.[0]?.email}</td>
                            </tr>
                          </tbody>
                        </table>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </div>

          </div>
        </div>
      </div>
    </Fragment>
  );

};


export default FleetDetails;

