import React, { useEffect, useState, Fragment } from "react";
import { useTranslation } from 'react-i18next';
import { format, parseISO } from 'date-fns';
import { useNavigate, Link } from 'react-router-dom';
import {
  Row,
  Col,
  Input, Card,
  CardBody, Label

} from "reactstrap";
import $ from 'jquery';
import './EditableTable.css';
import { CSVLink } from 'react-csv';

import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../../components/Loaders/DnaLoader";
import "../../../assets/custom css/loadoverlay.scss";

import Alerts from "../../../components/Alerts/alerts";

import CustomerService from "./Customer.service";
import AuthService from "../../Authentication/auth.service";

import DataTable from 'react-data-table-component';
import { Tooltip } from "@mui/material";

import CustomerFilter from "./CustomerFilter";
import AlertService from "../../../components/Alerts/alerts";
import DateRangePickerComponent from './DateRangePickerComponent';



const CustomersList = () => {
  const [isHovered, setIsHovered] = useState(false);

  const handleHover = () => {
    setIsHovered(!isHovered);
  };

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState("");
  const [searchName, setsearchName] = useState('');
  const [searchRegNo, setsearchRegNo] = useState('');
  const [searchEmail, setsearchEmail] = useState('');
  const [searchPhone, setsearchPhone] = useState('');
  const [searchStatus, setsearchStatus] = useState('');
  const [searchStartDate, setsearchStartDate] = useState('');
  const [searchEndDate, setsearchEndDate] = useState('');

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    fetchCustomers(0, pageLimit);
  }, [pageLimit]);

  function fetchCustomers(page_index, page_limit) {
    var filter = {};
    if (searchName !== "") {
      console.log("Search name:-", searchName)
      filter["company_name"] = searchName
    }
    if (searchRegNo !== "") {
      console.log("Search Reg No:-", searchRegNo)
      filter["reg_no"] = searchRegNo
    }
    if (searchEmail !== "") {
      filter["email"] = searchEmail
    }
    if (searchPhone !== "") {
      filter["phone"] = searchPhone
    }
    if (searchStatus !== "") {
      console.log("Search Reg No:-", searchStatus)
      filter["status"] = searchStatus
    }
    if (searchStartDate !== "" && searchEndDate !== "") {
      console.log("Search Reg No:-", searchStartDate)
      filter["startDate"] = searchStartDate
      filter["endDate"] = searchEndDate
    }
    var offset = (page_index - 1) * page_limit;
    if (offset < 1) {
      offset = 0;
    }
    var getData = {
      functionName: "GetCustomers",
      postData: {
        "sort": { _id: -1 },
        "offset": offset,
        "limit": page_limit,
        "filter": filter
      },
    }
    setLoading(true);
    console.log(getData);
    CustomerService.getAllCustomers(getData).then((response) => {
      setLoading(false);
      console.log("Get Customers response:-", response)
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        setCustomers(response.data.data);
        setTotalRows(response.data.count.count);
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent)
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  };


  function handlePageChange(page_index) {
    console.log("Page index:-", page_index)
    setCurrentPage(page_index)
    fetchCustomers(page_index, pageLimit);
  }

  async function handlePerRowsChange(newPageLimit, page_index) {
    setPageLimit(newPageLimit);
  }

  function nameFilter(e) {
    console.log(e.target.value);
    setsearchName(e.target.value)
  }
  function regFilter(e) {
    console.log(e.target.value);
    setsearchRegNo(e.target.value)
  }
  function emailFilter(e) {
    console.log(e.target.value);
    setsearchEmail(e.target.value)
  }

  function phoneFilter(e) {
    console.log(e.target.value);
    setsearchPhone(e.target.value)
  }
  function statusFilter(e) {
    console.log(e.target.value);
    setsearchStatus(e.target.value)
  }
  function resetFilters() {
    setResetPaginationToggle(!resetPaginationToggle);
    setsearchName('');
    setsearchRegNo('');
    setsearchEmail('');
    setsearchPhone('');
    setsearchStatus('');
    setsearchStartDate('');
    setsearchEndDate('');
    var offset = 0;
    var getData = {
      functionName: "GetCustomers",
      postData: {
        "sort": { _id: -1 },
        "offset": offset,
        "limit": pageLimit,
        "filter": {}
      },
    }
    setLoading(true);
    CustomerService.getAllCustomers(getData).then((response) => {
      setLoading(false);
      console.log("Get Customers response:-", response)
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        setCustomers(response.data.data);
        setTotalRows(response.data.count.count);
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent)
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  }

  function searchCustomers() {
    setResetPaginationToggle(!resetPaginationToggle);
    var page = 0
    handlePageChange(page++)
  }





  const mycolumns = [
    {
      name: t("Date Registered"),
      selector: "datetime_registered",
      sortable: true,
      cell: (row) => format(parseISO(row.datetime_registered), 'dd-MM-yyyy'),
    },
    {
      name: t("Company"),
      selector: "company_name",
      sortable: true,
    },
    {
      name: t("Reg No."),
      selector: "reg_no",
      sortable: true,
    },
    {
      name: t("Email"),
      selector: "email",
      sortable: true,
    },
    {
      name: t("PIC"),
      selector: "pic_name",
      sortable: true,
    },
    {
      name: t('Status'),
      width: '15%',
      selector: (row) => {
        return (
          <div>
            {row.customer_status === "Inactive" ?
              <button type="button"
                className="btn btn-danger btn-hover-shine me-3 btn">{row.customer_status}
              </button>
              :
              row.customer_status === "Active" ?
                <button type="button"
                  className="btn btn-success btn-hover-shine me-3 btn">{row.customer_status}
                </button>
                :
                ''
            }
          </div>
        );
      }
    },
    {
      name: t("Phone"),
      sortable: true,
      selector: "phone"
    },


  ];



  // const downloadCSV = () => {
  //   var getData = {
  //     functionName: "GetCustomers",
  //     postData: {
  //       "sort": { _id: -1 },
  //     },
  //   };
  //   setLoading(true);

  //   CustomerService.getAllCustomers(getData).then((response) => {
  //     setLoading(false);

  //     if (response.status === 200) {
  //       // Extract column headers from the first data row
  //       const data = response.data.data;
  //       if (data.length > 0) {
  //         const csvData = data.map((customer) => {
  //           return {
  //             company_name: customer.company_name,
  //             pic_name: customer.pic_name,
  //             email: customer.email,
  //             phone: customer.phone,
  //             house_no: customer.house_no,
  //             reg_no: customer.reg_no,
  //             google_loc: customer.google_loc,
  //             customer_status: customer.customer_status,
  //           };
  //         });

  //         return (
  //           <CSVLink
  //             headers={headers}
  //             data={csvData}
  //             filename={'customers.csv'}
  //             style={{ "textDecoration": "none", "color": "#000" }}
  //             className={"ExportDataSingle"}
  //           >
  //             <i className="pe-7s-download btn-icon-wrapper icon-gradient bg-plum-plate btn-lg px-1 py-0 mb-2 "> </i>
  //           </CSVLink>
  //         );
  //       } else {
  //         console.log("No data to download.");
  //       }
  //     } else if (response.status === 401) {
  //       AuthService.logout();
  //       navigate("/login");
  //       window.location.reload();
  //     } else {
  //       console.log("Response status: " + response.status);
  //     }
  //   },
  //     (error) => {
  //       setLoading(false);
  //       const errContent =
  //         (error.response && error.response.data) || error.message || error.toString();
  //       console.log("Net:-", errContent);
  //       if (errContent) {
  //         Alerts.swalErrorAlert(errContent);
  //       } else {
  //         Alerts.swalErrorAlert(t("Server Error, Please try again later."));
  //       }
  //     });
  // };

  // const downloadCSV = () => {
  //   var getData = {
  //     functionName: "GetCustomers",
  //     postData: {
  //       "sort": { _id: -1 },
  //     },
  //   }
  //   setLoading(true);
  //   CustomerService.getAllCustomers(getData).then((response) => {
  //     setLoading(false);
  //     if (response.status === 200) {
  //       // Extract column headers from the first data row
  //       const data = response.data.data;
  //       if (data.length > 0) {
  //         const csvData = data.map((customer) => {
  //           return {
  //             company_name: customer.company_name,
  //             pic_name: customer.pic_name,
  //             email: customer.email,
  //             phone: customer.phone,
  //             house_no: customer.house_no,
  //             reg_no: customer.reg_no,
  //             google_loc: customer.google_loc,
  //             customer_status: customer.customer_status,
  //           };
  //         });

  //         return (
  //           <CSVLink
  //             headers={headers}
  //             data={csvData}
  //             filename={'customers.csv'}
  //             style={{ "textDecoration": "none", "color": "#000" }}
  //             className={"ExportDataSingle"}
  //           >
  //             <i className="pe-7s-download btn-icon-wrapper icon-gradient bg-plum-plate btn-lg px-1 py-0 mb-2 "> </i>
  //           </CSVLink>
  //         );
  //         // // Get all available column names from the first row
  //         // const header = Object.keys(data[0]);

  //         // // Define the columns to exclude
  //         // const columnsToExclude = ['_id', 'lastLogin_datetime', 'datetime_modified', 'doctypeData'];

  //         // // Filter the header to exclude unwanted columns
  //         // const filteredHeader = header.filter(column => !columnsToExclude.includes(column));

  //         // // Filter the data to exclude corresponding properties
  //         // const filteredData = data.map(row => {
  //         //   const filteredRow = {};
  //         //   for (const key in row) {
  //         //     if (!columnsToExclude.includes(key)) {
  //         //       filteredRow[key] = row[key];
  //         //     }
  //         //   }
  //         //   return filteredRow;
  //         // });

  //         // // Create a CSV content with filtered headers and data
  //         // const csv = [filteredHeader.join(','), ...filteredData.map(row => filteredHeader.map(fieldName => row[fieldName]).join(','))].join('\n');

  //         // // Create a Blob with the CSV data
  //         // const blob = new Blob([csv], { type: 'text/csv' });

  //         // // Create a download link
  //         // const url = window.URL.createObjectURL(blob);
  //         // const a = document.createElement('a');
  //         // a.href = url;
  //         // a.download = 'data.csv';

  //         // // Trigger the download
  //         // a.click();

  //         // // Clean up
  //         // window.URL.revokeObjectURL(url);
  //       } else {
  //         console.log("No data to download.");
  //       }
  //     } else if (response.status === 401) {
  //       AuthService.logout();
  //       navigate("/login");
  //       window.location.reload();
  //     } else {
  //       console.log("Response status: " + response.status);
  //     }
  //   },
  //     (error) => {
  //       setLoading(false);
  //       const errContent =
  //         (error.response && error.response.data) || error.message || error.toString();
  //       console.log("Net:-", errContent);
  //       if (errContent) {
  //         Alerts.swalErrorAlert(errContent);
  //       } else {
  //         Alerts.swalErrorAlert(t("Server Error, Please try again later."));
  //       }
  //     })
  // }


  const handleDateChange = (startDateFormatted, endDateFormatted) => {
    setsearchStartDate(startDateFormatted);
    setsearchEndDate(endDateFormatted);
  };
  const headers = [
    { label: "Company Name", key: "company_name", style: { fontWeight: "bold", backgroundColor: "lightgray", color: "blue" } },
    { label: "Customer Name", key: "pic_name", style: { fontWeight: "bold", backgroundColor: "lightgray", color: "blue" } },
    { label: "Email", key: "email", style: { fontWeight: "bold", backgroundColor: "lightgray", color: "blue" } },
    { label: "Phone Number", key: "phone", style: { fontWeight: "bold", backgroundColor: "lightgray", color: "blue" } },
    { label: "Address", key: "address", style: { fontWeight: "bold", backgroundColor: "lightgray", color: "blue" } },
    { label: "Reg No.", key: "reg_no", style: { fontWeight: "bold", backgroundColor: "lightgray", color: "blue" } },
    // { label: "Google Loc", key: "google_loc", style: { fontWeight: "bold", backgroundColor: "lightgray", color: "blue" } },
    { label: "Status", key: "customer_status", style: { fontWeight: "bold", backgroundColor: "lightgray", color: "blue" } },
  ];
  const linkStyle = {
    marginLeft: '10px',
    textDecoration: 'none',
    color: isHovered ? 'black' : 'white',
  };
  return (
    <Fragment>
      <div className="app-main__outer">
        <div className="app-main__inner">
          <div className=" bg-light-gray bg-animation ">
            <div className="d-flex justify-content-center align-items-center">
              <Col md="12" className="mx-auto app-login-box">
                <div className="app-page-title">
                  <div className="page-title-wrapper">
                    <div className="page-title-heading fw-bold">
                      <div className="page-title-icon">
                        <i className="pe-7s-users icon-gradient bg-tempting-azure" />
                      </div>
                      <div>
                        {t("Customer Reports")}
                        <div className="page-title-subheading">
                          {t('View & Download Customers List')}
                        </div>
                      </div>
                    </div>
                    <div className="page-title-actions">
                      <button
                        type="button"
                        onClick={() => navigate(-1)}
                        className="btn btn-outline-success btn-hover-shine me-3"
                      >
                        {t("Back")}
                      </button>
                    </div>
                  </div>
                </div>
                <Row>
                  <Col md="12">
                    <Card className="main-card mb-3">
                      <CardBody>
                        <Row className="mb-3">
                          <Col xs="12" sm="6" md="4" lg="3" xl='2'>
                            <Input type="text" name="fname" value={searchName} placeholder={t("Search Text")} onChange={event => nameFilter(event)}></Input>
                          </Col>
                          <Col xs="12" sm="6" md="4" lg="3" xl='2'>
                            <select
                              name="fstatus"
                              value={searchStatus}
                              onChange={(event) => statusFilter(event)}
                              style={{
                                width: "100%",
                                borderColor: '#CED4DA',
                                height: "34px",
                                backgroundColor: "white",
                                border: '1px solid #ccc',
                                borderRadius: "5px",
                              }}
                            >
                              <option disabled={false} value="">{`--${t("Select Status")}--`}</option>
                              <option value='0'>{t("Active")}</option>
                              <option value='1'>{t("InActive")}</option>
                            </select>
                          </Col>
                          <Col style={{
                            marginLeft: '10px',
                            background: '#F9F9FB',
                            height: "35px",
                            border: '1px solid #ccc',
                            borderRadius: '6px',
                            marginBottom: '20px',
                            display: 'flex',
                            alignItems: 'center'
                          }} xs="12" sm="9" md="6" lg="5" xl='4'>
                            <Label style={{
                              marginTop: '10px',
                              background: '#F9F9FB',
                              paddingRight: '10px',
                              width: '200px',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}>
                              {t('Registered Date')}
                            </Label>
                            <div className="d-flex justify-content-end align-items-center h-100 pr-2">
                              <div style={{
                                borderRight: '2px solid #ccc',
                                height: '4%',
                                maxHeight: '4%',
                              }}></div>
                            </div>
                            <DateRangePickerComponent onDateChange={handleDateChange} />
                          </Col>



                          <Col xs="12" sm="6" md="2" lg="1">
                            <button type="button" className="btn btn-custom-color btn-hover-shine ms-1" onClick={() => searchCustomers()}>{t("Search")}</button>
                          </Col>
                          <Col xs="12" sm="6" md="2" lg="1">
                            <button type="button" className="btn btn-custom-color btn-hover-shine" onClick={() => resetFilters()}>{t("Reset")}</button>
                          </Col>
                          <Col style={{ marginLeft: '10px' }} xs="12" sm="6" md="2" lg="1">
                            <CSVLink
                              // ref={csvLinkRef}
                              headers={headers}
                              data={customers}
                              onMouseEnter={handleHover}
                              onMouseLeave={handleHover}
                              filename={"customers.csv"}
                              style={linkStyle}
                              className="btn btn-custom-color btn-hover-shine float-end"
                            >
                              {t("Download")}
                            </CSVLink>
                          </Col>
                        </Row>

                        {/* Data table section */}
                        <LoadingOverlay
                          tag="div"
                          active={loading}
                          styles={{ overlay: (base) => ({ ...base }) }}
                          spinner={<DNALoader />}
                        >
                          <DataTable
                            data={customers}
                            columns={mycolumns}
                            pagination
                            fixedHeader
                            fixedHeaderScrollHeight="400px"
                            paginationServer
                            paginationTotalRows={totalRows}
                            onChangePage={handlePageChange}
                            onChangeRowsPerPage={handlePerRowsChange}
                            paginationResetDefaultPage={resetPaginationToggle}
                          />
                        </LoadingOverlay>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};


export default CustomersList;