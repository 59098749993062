import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import styled from "styled-components";
import CustomerService from "../../../pages/Edsha/Invoices/Invoices.service";
import AuthService from "../../../pages/Authentication/auth.service";
import Alerts from "../../Alerts/alerts";
import { Row, Col } from 'react-bootstrap';
import DateRangePickerComponent from './DateRangePickerComponent'; // Adjust the import path as needed
import { CSVLink } from 'react-csv';
import { Input } from 'reactstrap';
import moment from "moment";
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../../components/Loaders/DnaLoader";
import "../../../assets/custom css/loadoverlay.scss";

const DateInput = styled.input.attrs(props => ({
  type: "date",
  placeholder: "Select Date",
}))`
  height: 32px;
  width: 100%;
  border-color:#ccc; 
  border-radius: 6px;
`;

const Inputs = styled.select.attrs(props => ({
  type: "select",
  size: props.small ? 5 : undefined
}))`
  height: 32px;
  width: 100%;
  border-radius: 6px;
  border-color:#ccc; 
  background: white;
`;

const StatusInput = styled.input.attrs(props => ({
  type: "text",
  placeholder: "Search Status"
}))`
  height: 32px;
  width: 100%;
  border-radius: 6px;
  
`;

const Label = styled.label`
  display: block;
  font-weight: bold;
`;

const FilterContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  gap: 40px;
`;

const FilterRow = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

const CollectionFilter = ({ search_text, onSearch_text, filterText1, filterText2,
  handleKeyDown, onFilter1, onFilter2, onFilterInvoice_StartDate, filterDue_EndDate,
  onFilterInvoice_EndDate, onFilterDue_StartDate, onFilterDue_EndDate,
  searchCustomers, resetFilters,
  filterDue_StartDate, filterInvoice_StartDate, filterInvoice_EndDate }) => {
  const { t, i18n } = useTranslation();
  const [collection, setCollection] = useState([]);
  const csvLinkRef = useRef();
  const [ExportData, setExportData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  let count = 1

  const navigate = useNavigate();
  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    fetchcollections();
  }, []);
  const [selectedDate, setSelectedDate] = useState(null);

  function fetchcollections() {
    var getData = {
      functionName: "GetCustomers",
      postData: {
        "sort": { _id: -1 },
        "offset": 0,
        "limit": 1000,
        "filter": {}
      },
    }
    CustomerService.getAllCustomers(getData).then((response) => {
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        console.log(response.data.data);
        if (response.data) {
          setCollection(response.data.data);
        }
      }
    },
      (error) => {
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  };

  console.log(filterText1);

  const handleDateChange = (startDateFormatted, endDateFormatted) => {
    // Call the callback functions to update the parent component's state
    onFilterDue_StartDate(startDateFormatted);
    onFilterDue_EndDate(endDateFormatted);
  };

  const handleDateChange_Invoice = (startDateFormatted, endDateFormatted) => {
    // Call the callback functions to update the parent component's state
    onFilterInvoice_StartDate(startDateFormatted);
    onFilterInvoice_EndDate(endDateFormatted);
  };



  const fetchRecords = (page_index, page_limit, filterText1, filterText2,
    filterInvoice_StartDate, filterInvoice_EndDate,
    filterDue_StartDate, filterDue_EndDate, search_text) => {
    console.log('fetchCustomers')
    const filter = {};
    if (filterText1) filter["customer_id"] = filterText1;
    if (search_text) filter["search_text"] = search_text;
    if (filterText2) filter["status"] = filterText2;
    if (filterDue_StartDate) filter["filterDue_StartDate"] = filterDue_StartDate;
    if (filterDue_EndDate) filter["filterDue_EndDate"] = filterDue_EndDate;
    if (filterInvoice_StartDate) filter["filterInvoice_StartDate"] = filterInvoice_StartDate;
    if (filterInvoice_EndDate) filter["filterInvoice_EndDate"] = filterInvoice_EndDate;

    const offset = (page_index - 1) * page_limit;
    setLoading(true);

    CustomerService.getAllPayments(offset, page_limit, filterText1, filterText2,
      filterInvoice_StartDate, filterInvoice_EndDate, filterDue_StartDate, filterDue_EndDate, search_text).then((response) => {
        setLoading(false);
        if (response.data.message === "Unauthorized") {
          AuthService.logout();
          navigate("/login");
          window.location.reload();
        } else {
          console.log("Response", response)
          const CSV_Filtered_Customers = prepareCustomerData(response.data.data);
          setExportData(CSV_Filtered_Customers);
          csvLinkRef.current.link.click();
        }
      }, (error) => {
        setLoading(false);
        const errContent = (error.response && error.response.data) || error.message || error.toString();
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        } else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      });
  };

  const handleExportClick = () => {
    console.log("Clicked")
    fetchRecords(1, 1000, filterText1, filterText2, filterInvoice_StartDate, filterInvoice_EndDate, filterDue_StartDate, filterDue_EndDate, search_text);
  };

  // const prepareCustomerData = (originalData) => {

  //   return originalData.map(row => ({
  //     row,
  //     customer_name: row.user_details ? row.user_details[localStorage.getItem('invoice_to_field')] : ''
  //   }));
  // };

  const prepareCustomerData = (originalData) => {
    return originalData.flatMap(row => {
      return [{
        ...row,
        payment_date: row.payment_date ? moment(row.payment_date).format('DD/MM/YYYY') : '',
        doc_type: 'IV',
        CANCELLED: "F",
        non_refundable: 0,
        PROJECT: '---',
        PAYMENTPROJECT: '---',
        currency_rate: 1,
        bank_charge: 0,
        customer_name: row.user_details.company_name ? row.user_details.company_name : ''
      }];
    });
  };


  const sortedCollection = collection.slice().sort((a, b) => {
    const nameA = a[localStorage.getItem('invoice_to_field')]?.toUpperCase();
    const nameB = b[localStorage.getItem('invoice_to_field')]?.toUpperCase();
    return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
  });

  const handleHover = () => {
    setIsHovered(!isHovered);
  };

  const headers = [
    { label: "DOCNO(20)", key: "Payment_ID" },
    { label: "CODE(10)", key: "code" },
    { label: "DOCDATE", key: "payment_date" },
    { label: "POSTDATE", key: "payment_date" },
    { label: "DESCRIPTION(200)", key: "description" },
    { label: "AREA(10)", key: "area" },
    { label: "AGENT(10)", key: "agent" },
    { label: "PAYMENTMETHOD(10)", key: "payment_method" },
    { label: "CHEQUENUMBER(20)", key: "cheque_number" },
    { label: "PROJECT(20)", key: "PROJECT" },
    { label: "PAYMENTPROJECT(20)", key: "PAYMENTPROJECT" },
    { label: "CURRENCYRATE", key: "currency_rate" },
    { label: "BANKCHARGE", key: "bank_charge" },
    { label: "DOCAMT", key: "doc_amt" },
    { label: "UNAPPLIEDAMT", key: "unapplied_amt" },
    { label: "CANCELLED(1)", key: "CANCELLED" },
    { label: "NONREFUNDABLE", key: "non_refundable" },
    { label: "BOUNCEDDATE", key: "bounced_date" },
    { label: "DOCTYPE", key: "doc_type" },
    { label: "KODOCNO", key: "ko_doc_no" },
    { label: "KOAMT", key: "ko_amt" },
    // { label: "CompanyName", key: "customer_name" },
    // { label: "Amount", key: "amount" },
    // { label: "Currency", key: "currency" },
    // { label: "Status", key: "status" },
  ];


  return (
    <>
      <LoadingOverlay tag="div" active={loading}
        styles={{ overlay: (base) => ({ ...base }) }}
        spinner={<DNALoader />}>

        <Row style={{ marginTop: '20px', marginBottom: '20px' }}>
          <Col xs="24" sm="12" md="6" lg="4" xl="3">
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Input style={{ width: '48%' }} type="text" name="fname" value={search_text} placeholder={t("Search Text")} onChange={onSearch_text}></Input>
              <Input
                type="select"
                style={{ width: '48%' }}
                placeholder={t("Search Status")}
                value={filterText2}
                onChange={onFilter2}
              >

                <option disabled={false} value="">--Select Status--</option>
                <option key='generated' value='generated'>Generated</option>
                <option key='paid' value='paid'>Paid</option>
                <option key='partial paid' value='partial paid'>Partial Paid</option>
                <option key='unpaid' value='unpaid'>Unpaid</option>
                <option key='void' value='void'>Void</option>
                <option key='cancelled' value='cancelled'>Cancelled</option>
              </Input>
            </div>
          </Col>

          <Col xs="24" sm="12" md="12" lg="8" xl="6">
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{
                background: '#F9F9FB',
                height: '35px',
                width: '48%',
                border: '1px solid #ccc',
                borderRadius: '6px',
                marginBottom: '20px',
                display: 'flex',
                alignItems: 'center'
              }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Label style={{ background: '#F9F9FB', paddingRight: '10px' }}>
                    {t('Payment')}
                  </Label>
                  <div className="d-flex justify-content-end align-items-center h-100 pr-2">
                    <div style={{ borderRight: '2px solid #ccc', height: '4%' }}></div>
                  </div>
                  {/* DateRangePickerComponent for Invoice Date */}
                  <DateRangePickerComponent onDateChange={handleDateChange_Invoice} />
                </div>
              </div>
              <Input
                type="select"
                style={{
                  width: "48%", height: '35px',
                }}
                placeholder={t("Search Customer")}
                value={filterText1}
                onChange={onFilter1}
                onKeyDown={handleKeyDown}
                id="colfilter"
              >

                <option disabled={false} value="">Select Customer</option>
                {collection &&
                  collection.map((opt_collection, index) => (
                    <option key={index} value={opt_collection._id}>{opt_collection.company_name}</option>
                  ))
                }
              </Input>

            </div>

          </Col>
          <Col xs="24" sm="12" md="6" lg="4" xl="3">

            <button type="button" className="btn btn-custom-color btn-hover-shine"
              onClick={() => searchCustomers()}
            >Search</button>
            <button type="button"
              className="btn btn-custom-color btn-hover-shine ms-1"
              onClick={() => resetFilters()}
            >Reset</button>
            <button
              type="button"
              className="btn btn-custom-color btn-hover-shine ms-1"
              onClick={handleExportClick}
              onMouseEnter={handleHover}
              onMouseLeave={handleHover}
            >
              {t("Export")}
            </button>
            <CSVLink
              headers={headers}
              data={ExportData}

              ref={csvLinkRef}
              filename={"payments.csv"}
              style={{ display: 'none' }}
            />

          </Col>
        </Row>
      </LoadingOverlay>
    </>

  );
};

export default CollectionFilter;
