import React, { useEffect, useState, Fragment } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from "react-router-dom";
import { Col, Row, Label, FormGroup, Input, Card, CardBody, Button, CardHeader, CardTitle, Collapse, } from "reactstrap";
import { Form, Field } from "react-final-form";

import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import "../../assets/custom css/loadoverlay.scss";

import Alerts from "../../components/Alerts/alerts";

import ConfigurationService from "./configuration.service";
import AuthService from "../Authentication/auth.service";

const TabEmailConfig = () => {

  const { t, i18n } = useTranslation();
  const [emailconfigs, setConfigEmail] = useState([]);

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    getSystemConfig();
  }, []);

  function getSystemConfig() {
    ConfigurationService.getConfiguration().then((response) => {        
      console.log("API Response:-", response);
      if(response.data.status){
        setConfigEmail(response.data.data[0].email_service_credentials);
        console.log("Set Data:-", emailconfigs);
        // setFormGroups(emailconfigs);
      }
      else {
        if(response.data.message === "Unauthorized") {
          AuthService.logout();
          navigate("/login");
          window.location.reload();
        }
        else {
          alert(t("Unable to retreive configurations at the moment."))
        }
      }
    },
    (error) => {
      alert(t("Unable to retreive configurations at the moment."))
    });
  }

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [accordion0, setaccordion0] = useState(false);
  const [accordion1, setaccordion1] = useState(false);
  const [accordion2, setaccordion2] = useState(false);

  const [activeId, setActiveId] = useState('0');

  function toggleAccordion(id) {
    console.log("clicked acc:-", id)
    if(activeId === id) {
      setActiveId(null);
    }
    else {
      setActiveId(id);
    }
  };

  // function setFormGroups(emailconfigs) {
  //   for(var i=0; i < emailconfigs.length; i++) {
  //     console.log("i :-", emailconfigs[i])
  //     console.log("name :-", Object.keys(emailconfigs[i]))
      
  //     return (
  //       <FormGroup>
  //         <Field name>
  //           {({ input, meta }) => (
  //             <div>
  //               <Label for="temp_subject">Project ID</Label>
  //               <Input {...input} type="text" 
  //                 invalid={meta.error && meta.touched} 
  //                 placeholder="Subject here"
  //               />
  //               {meta.error && meta.touched && 
  //               <span className="text-danger">{meta.error}</span>}
  //             </div>
  //           )}
  //         </Field>
  //     </FormGroup>
  //     )
  //   }
  // }

  function onSubmit(values) {
    console.log("Form Values:-", values);
    var payloadData = {
      functionName: "SetConfigurations",
      postData: values,
    }
    ConfigurationService.setConfiguration(payloadData).then((response) => {        
      console.log("API Response:-", response);
      setLoading(false);
      if(response.data.status){
        if(response.data.message !== '') {
          Alerts.swalSuccessAlert(response.data.message)
        }
        else {
          Alerts.swalSuccessAlert(t("Configuration updated successfully."));
        }
        getSystemConfig();
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
      else {
        if(response.data.message !== '') {
          if(response.data.message === "Unauthorized") {
            AuthService.logout();
            navigate("/login");
            window.location.reload();
          }
          else {
            Alerts.swalErrorAlert(response.data.message)
          }
        }
        else {
          Alerts.swalErrorAlert(t("Error: Can't update configuration at the moment. Please try again later."))
        }
      }
    },
    (error) => {
      setLoading(false);
      const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      console.log("Error:-", error)
      Alerts.swalErrorAlert(t("Network Operation failed."))
    });
  }

  // const validateFtn = (values) => {
  //   const errors = {};
  //   if (!values.emailprov) {
  //     errors.emailprov = "Please choose Email Provider.";
  //   }
  //   return errors;
  // };

  // function toggleAccordion(tab) {
  //   console.log("tab:-", tab)
  //   if(tab === 0) {
  //     if(accordion0) {
  //       setaccordion0(false)
  //     }
  //     else {
  //       setaccordion0(true)
  //     }
  //   }
  //   if(tab === 1) {
  //     if(accordion1) {
  //       setaccordion1(false)
  //     }
  //     else {
  //       setaccordion1(true)
  //     }
  //   }
  //   if(tab === 2) {
  //     if(accordion2) {
  //       setaccordion2(false)
  //     }
  //     else {
  //       setaccordion2(true)
  //     }
  //   }
  // };

  return (
    <Fragment>
      
        <Col md="8">
          {emailconfigs &&
            emailconfigs.map((econfig, index) => (
              <div id="accordion" className="accordion-wrapper mb-3">
                <Card>
                  <CardHeader id="headingOne">
                    <Button block color="link" className="text-start m-0 p-0" onClick={() => toggleAccordion(index)}
                      aria-expanded={true} aria-controls="collapseOne">
                      <h5 className="m-0 p-0">{econfig.service_type}</h5>
                    </Button>
                  </CardHeader>
                  <Collapse isOpen={activeId === index} data-parent="#accordion" id="collapseOne" aria-labelledby="headingOne">
                    <CardBody>
                      <Form onSubmit={onSubmit}
                        initialValues={econfig}
                        render={({ handleSubmit, values, submitting, validating, valid }) => (
                          <form onSubmit={handleSubmit}>
                            <LoadingOverlay tag="div" active={loading}
                              styles = {{overlay: (base) => ({...base})}}
                              spinner = {<DNALoader/>}>  
                              <Row form>
                                <Col md={12}>
                                  {econfig.project_id &&
                                    <FormGroup>
                                      <Field name="project_id">
                                        {({ input, meta }) => (
                                          <div>
                                            <Label for="temp_subject">{t('Project ID')}</Label>
                                            <Input {...input} type="text" 
                                              placeholder={t('Project ID')}
                                            />
                                          </div>
                                        )}
                                      </Field>
                                    </FormGroup>
                                  }
                                  {econfig.service_type &&
                                    <FormGroup>
                                      <Field name="service_type">
                                        {({ input, meta }) => (
                                          <div>
                                            <Label for="template_name">{t('Service Type')}</Label>
                                            <Input {...input} type="text" 
                                              placeholder={t('Service Type')}
                                            />
                                          </div>
                                        )}
                                      </Field>
                                    </FormGroup>
                                  }
                                  {econfig.auth_provider_x509_cert_url &&
                                    <FormGroup>
                                      <Field name="auth_provider_x509_cert_url">
                                        {({ input, meta }) => (
                                          <div>
                                            <Label for="template_name">{t('Auth Provider x509 Certificate URL')}</Label>
                                            <Input {...input} type="text" 
                                              placeholder={t('Auth Provider x509 Certificate URL')}
                                            />
                                          </div>
                                        )}
                                      </Field>
                                    </FormGroup>
                                  }
                                  {econfig.auth_uri &&
                                    <FormGroup>
                                      <Field name="auth_uri">
                                        {({ input, meta }) => (
                                          <div>
                                            <Label for="temp_subject">{t('Authentication URI')}</Label>
                                            <Input {...input} type="text" 
                                              placeholder={t('Authentication URI')}
                                            />
                                          </div>
                                        )}
                                      </Field>
                                    </FormGroup>
                                  }
                                  {econfig.token_uri &&
                                    <FormGroup>
                                      <Field name="token_uri">
                                        {({ input, meta }) => (
                                          <div>
                                            <Label for="temp_subject">{t('Token URI')}</Label>
                                            <Input {...input} type="text" 
                                              placeholder={t('Token URI')}
                                            />
                                          </div>
                                        )}
                                      </Field>
                                    </FormGroup>
                                  }
                                  {econfig.client_id &&
                                    <FormGroup>
                                        <Field name="client_id">
                                          {({ input, meta }) => (
                                            <div>
                                              <Label for="temp_subject">{t('Client ID')}</Label>
                                              <Input {...input} type="text" 
                                                placeholder={t('Client ID')}
                                              />
                                            </div>
                                          )}
                                        </Field>
                                    </FormGroup>
                                  }
                                  {econfig.client_secret &&
                                    <FormGroup>
                                      <Field name="client_secret">
                                        {({ input, meta }) => (
                                          <div>
                                            <Label for="temp_subject">{t('Client Secret')}</Label>
                                            <Input {...input} type="text" 
                                              placeholder={t('Client Secret')}
                                            />
                                          </div>
                                        )}
                                      </Field>
                                    </FormGroup>
                                  }

                                  {econfig.AWSKEY &&
                                    <FormGroup>
                                      <Field name="AWSKEY">
                                        {({ input, meta }) => (
                                          <div>
                                            <Label for="temp_subject">{t('AWS KEY')}</Label>
                                            <Input {...input} type="text" 
                                              placeholder={t('AWS KEY')}
                                            />
                                          </div>
                                        )}
                                      </Field>
                                    </FormGroup>
                                  }
                                  {econfig.AWSREGION &&
                                    <FormGroup>
                                      <Field name="AWSREGION">
                                        {({ input, meta }) => (
                                          <div>
                                            <Label for="temp_subject">{t('AWS Region')}</Label>
                                            <Input {...input} type="text" 
                                              placeholder={t('AWS Region')}
                                            />
                                          </div>
                                        )}
                                      </Field>
                                    </FormGroup>
                                  }
                                  {econfig.API_KEY &&
                                    <FormGroup>
                                      <Field name="API_KEY">
                                        {({ input, meta }) => (
                                          <div>
                                            <Label for="temp_subject">{t('API_KEY')}</Label>
                                            <Input {...input} type="text" 
                                              placeholder={t('API_KEY')}
                                            />
                                          </div>
                                        )}
                                      </Field>
                                    </FormGroup>
                                  }
                                  
                                  {econfig.AWSSENDEMIAL &&
                                    <FormGroup>
                                      <Field name="AWSSENDEMIAL">
                                        {({ input, meta }) => (
                                          <div>
                                            <Label for="temp_subject">{t('AWS Send Email')}</Label>
                                            <Input {...input} type="text" 
                                              placeholder={t('AWS Send Email')}
                                            />
                                          </div>
                                        )}
                                      </Field>
                                    </FormGroup>
                                  }

                                  
                                  
                                  <FormGroup>
                                    <div className="d-flex justify-content-center EditData">
                                      <button type="button" onClick={() => navigate(-1)} className="btn btn-outline-light btn-hover-shine me-3 mt-3">{t('Cancel')}</button>
                                      <button className="btn btn-custom-color btn-hover-shine me-3 mt-3" type="submit" >{t('Update')}</button>
                                    </div>
                                  </FormGroup>
                                </Col>
                              </Row>   
                            </LoadingOverlay>
                          </form>
                        )}
                      />
                    </CardBody>
                  </Collapse>
                </Card>
              </div>
            ))
          }
              
            </Col>

          {/* <Form onSubmit={onSubmit}
            validate={validateFtn}
            render={({ handleSubmit, values, submitting, validating, valid }) => (
              <form onSubmit={handleSubmit}>
                <LoadingOverlay tag="div" active={loading}
                  styles={{ overlay: (base) => ({ ...base }) }}
                  spinner={<DNALoader />}>
                  <Row form>
                    <Col md={12}>
                      <FormGroup>
                        <Field name="emailprov">
                          {({ input, meta }) => (
                            <div>
                              <div className="row mb-3">
                                <Label for="emailprov" className="col-sm-2 col-form-label">Email Provider</Label>
                                <div className="col-sm-10">
                                  <Input {...input} type="select"
                                    invalid={meta.error && meta.touched}
                                    placeholder="Choose Email Provider">
                                      <option disabled={true} value="">--Choose an option--</option>
                                      <option value="aws">AWS</option>
                                      <option value="sendinblue">SendInBlue</option>
                                      <option value="smpt">SMTP</option>
                                  </Input>
                                  {meta.error && meta.touched &&
                                    <span className="text-danger">{meta.error}</span>}
                                </div>
                              </div>
                            </div>
                          )}
                        </Field>
                      </FormGroup>
                      <FormGroup>
                        <div className="d-flex justify-content-center">
                          <button type="button" onClick={() => navigate(-1)} className="btn btn-outline-light btn-hover-shine me-3 mt-3">Cancel</button>
                          <button className="btn btn-custom-color btn-hover-shine me-3 mt-3" disabled={!valid}>Save</button>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                </LoadingOverlay>
              </form>
            )}
          /> */}
        
    </Fragment>
  );


};
export default TabEmailConfig;