import React, { useEffect, useState, Fragment } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import DataTable from 'react-data-table-component';
import {
  Row,
  Col,
  Card,
  CardBody
} from "reactstrap";
import Tooltip from '@mui/material/Tooltip';


import ServicesService from "../../Services Management/services.service";
import AuthService from "../../../Authentication/auth.service";
import { useTranslation } from 'react-i18next';

import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../../../components/Loaders/DnaLoader";
import "../../../../assets/custom css/loadoverlay.scss";
import Alerts from "../../../../components/Alerts/alerts";

import PricingAddCustomer from "../Customer Modals/PricingAddCustomer";
import PricingEditCustomer from "../Customer Modals/PricingEditCustomer";
import CustomerService from "../Customer.service";



const CustomerPricing = () => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const { state } = location;
  console.log("Received Object:-", state);
  const [pricings, setPricings] = useState([]);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const [totalRows, setTotalRows] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState("");


  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    fetchCustomerPricings(0, pageLimit);
  }, [pageLimit]);

  function fetchCustomerPricings(page_index, page_limit) {
    // var offset = (page_index - 1) * page_limit;
    // if (offset < 1) {
    //   offset = 0;
    // }
    var getData = {
      functionName: "GetCustomersPricing",
      postData: {
        user_id: state._id,
        limit: 100000000,
        offset: 0
      },
    }
    setLoading(true);
    CustomerService.getCustomerPricing(getData).then((response) => {
      setLoading(false);
      console.log("Get Pricings response:-", response)
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        setPricings(response.data.data);
        console.log(response.data);
        setTotalRows(response.data.count.count);
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent)
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  };

  function handleSuccess() {
    fetchCustomerPricings(currentPage, pageLimit)
  };

  function confirmDeletePricing(_id, serviceId, userId) {
    let msg = t("Once deleted, you will not be able to recover this customer pricing!");
    Alerts.swalConfirmDeleteAlert(msg).then(res => {
      console.log("Resp:-", res);
      if (res === true) {
        sendDelete(_id, serviceId, userId);
      }
    });
  }
  function sendDelete(id, service_id, user_id) {
    setLoading(true);
    CustomerService.deleteCustomerPricing(id, service_id, user_id).then((response) => {
      setLoading(false);
      console.log("Delete service details response:-", response)
      if (response.data.status) {
        fetchCustomerPricings(currentPage, pageLimit);
        if (response.data.message !== '') {
          Alerts.swalSuccessAlert(response.data.message)
        }
        else {
          Alerts.swalSuccessAlert(t("Customer Pricing Deleted Successfully."))
        }
        console.log(response.data.message)
      }
      else {
        if (response.data.message !== '') {
          if (response.data.message === "Unauthorized") {
            AuthService.logout();
            navigate("/login");
            window.location.reload();
          }
          else {
            Alerts.swalErrorAlert(response.data.message);
          }
        }
        else {
          Alerts.swalErrorAlert(t("Error: Can't delete the customer pricing at the moment."))
        }
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        Alerts.swalErrorAlert(t("Delete operation failed"))
      })
  };

  const mycolumns = [
    {
      name: t("Service"),
      selector: row => row.services[0]?.name
    },
    {
      name: t("Packaging"),
      selector: row => row.packaging[0]?.name
    },
    {
      name: t("Price Per KG"),
      selector: row => row.price
    },
    {
      name: t("Actions"),
      selector: (row) => {
        return (
          <div>
            <button className="mt-2 mb-2 me-2 btn-icon btn">
              <Tooltip placement="top" title={t("Update")} arrow>
                <i>
                  <PricingEditCustomer data={row} onSuccess={handleSuccess} />
                </i>
              </Tooltip>
              <Tooltip placement="top" title={t("Delete")} arrow>
                <i onClick={() => confirmDeletePricing(row._id, row.service_id, row.user_id)} className="pe-7s-trash text-danger btn-icon-wrapper"> </i>
              </Tooltip>
            </button>
          </div>
        );
      }
    },
  ];
  function handlePageChange(page_index) {
    console.log("Page index:-", page_index)
    setCurrentPage(page_index)
    fetchCustomerPricings(page_index, pageLimit);
  }

  async function handlePerRowsChange(newPageLimit, page_index) {
    setPageLimit(newPageLimit);
  }



  return (
    <Fragment>
      <Row>
        <Col md="12">
          <Card className="main-card mb-3">
            <CardBody>
              <LoadingOverlay tag="div" active={loading}
                styles={{ overlay: (base) => ({ ...base }) }}
                spinner={<DNALoader />}>
                <Row className="mt-2">
                  <Col md="6">
                    <h4 className="fw-bold mb-1">{t("Customer Pricing")}</h4>
                  </Col>
                  <Col md="6">
                    <PricingAddCustomer data={state._id} onSuccess={handleSuccess} />
                  </Col>
                  <Col md="12" className="pt-3">
                    {/* <DataTable
                      data={pricings}
                      columns={mycolumns}
                      pagination
                      fixedHeader
                      fixedHeaderScrollHeight="400px"
                      paginationServer
                      paginationTotalRows={totalRows}
                      onChangePage={handlePageChange}
                      onChangeRowsPerPage={handlePerRowsChange}
                      paginationResetDefaultPage={resetPaginationToggle}
                    /> */}
                    <DataTable
                      data={pricings}
                      columns={mycolumns}
                      pagination
                      fixedHeader
                      fixedHeaderScrollHeight="400px"
                    />
                  </Col>
                </Row>
              </LoadingOverlay>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );

};
export default CustomerPricing;