import React, { useState, useEffect, Fragment } from "react";
import { useTranslation } from 'react-i18next';
import { Col, Row, Button, Label, FormGroup, Input } from "reactstrap";
import { Form, Field } from "react-final-form";
import { useNavigate } from "react-router-dom";

import AuthService from "./auth.service";
import AlertService from "../../components/Alerts/alerts";

import { ToastContainer } from 'react-toastify';

import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import "../../assets/custom css/loadoverlay.scss";

import CopyRight from "../../components/CopyRight Bar/CopyRight";

import ConfigurationService from "../Configuration/configuration.service";
import FinanceService from "../Settings/configuration.service";

const Login = () => {

  useEffect(() => {
    getSetLanguage();
    setTimeout(() => {
      getFinanceConfig();
      getSystemConfig();
    }, 2000);
  }, []);

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [logo, setLogo] = useState(localStorage.getItem("system_logo"));

  function getSetLanguage() {
    if (localStorage.getItem("language")) {
      const language = localStorage.getItem("language");
      i18n.changeLanguage(language);
    }
    else {
      localStorage.setItem('language', 'en');
      i18n.changeLanguage('en');
    }
  }

  function getFinanceConfig() {
    FinanceService.getConfiguration().then((response) => {
      console.log("Finance API Response:-", response.data.data);
      if (response.data.status) {
        localStorage.setItem("payment_method", response.data.data[0].payment_method);
        localStorage.setItem("due_date", response.data.data[0].due_date);
        localStorage.setItem("default_notes", response.data.data[0].default_notes);
        localStorage.setItem("tax_percentage", response.data.data[0].tax_percentage);
        localStorage.setItem("currency", response.data.data[0].currency);
      }
      else {
        setColors('#3ac47d', 'onerror');
      }
    },
      (error) => {
        setColors('#3ac47d', 'onerror');
      });
  }
  function getSystemConfig() {
    ConfigurationService.getConfiguration().then((response) => {
      console.log("API Response:-", response);
      if (response.data.status) {
        setColors(response.data.data[0].buttons_color, '--buttons-color');
        setColors(response.data.data[0].topbar_color, '--topbar-color');
        setColors(response.data.data[0].leftbar_color, '--leftbar-color');
        setColors(response.data.data[0].buttons_text_color, '--buttons-text-color');
        setSystemLogo(response.data.data[0].system_logo);
        localStorage.setItem("google_api_key", response.data.data[0].google_api_key)
        localStorage.setItem("system_name", response.data.data[0].name)
        localStorage.setItem("auto_assign_distance", response.data.data[0].auto_assign_distance);
        localStorage.setItem("days_range", response.data.data[0].days_range);
        localStorage.setItem("whatsapp_number", response.data.data[0].whatsapp_number);

      }
      else {
        setColors('#3ac47d', 'onerror');
      }
    },
      (error) => {
        setColors('#3ac47d', 'onerror');
      });
  }

  function setColors(color, param) {
    if (param === '--buttons-color') {
      localStorage.setItem("buttons_color", color);
      document.documentElement.style.setProperty('--buttons-color', color)
    }
    if (param === '--topbar-color') {
      localStorage.setItem("topbar_color", color);
      document.documentElement.style.setProperty('--topbar-color', color)
    }
    if (param === '--leftbar-color') {
      localStorage.setItem("leftbar_color", color);
      document.documentElement.style.setProperty('--leftbar-color', color)
    }
    if (param === '--buttons-text-color') {
      localStorage.setItem("buttons_text_color", color);
      document.documentElement.style.setProperty('--buttons-text-color', color)
    }
    if (param === 'onerror') {
      localStorage.setItem("buttons_color", color);
      localStorage.setItem("topbar_color", '#fff');
      localStorage.setItem("leftbar_color", '#fff');
      localStorage.setItem("buttons_text_color", '#fff');
      document.documentElement.style.setProperty('--buttons-color', color)
      document.documentElement.style.setProperty('--topbar-color', '#fff')
      document.documentElement.style.setProperty('--leftbar-color', '#fff')
      document.documentElement.style.setProperty('--buttons-text-color', '#fff')
    }
  }

  function setSystemLogo(sys_logo) {
    setLogo(sys_logo);
    localStorage.setItem("system_logo", sys_logo);
  }

  function handlereset() {
    localStorage.setItem("reset", true)
  };

  function handleregprod() {
    localStorage.setItem("regprod", true)
  };

  const onSubmit = values => {
    console.log(values);
    setLoading(true);
    AuthService.login(values.email, values.password).then((response) => {
      console.log("Login:-", response.role_details);
      setLoading(false);
      var landingpage = response.role_details.landingpage
      // AlertService.successToast("Login Successful.")
      navigate("/" + landingpage);
      window.location.reload();
    },
      (error) => {
        setLoading(false);
        const resMessage =
          (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        if (resMessage === 'Unauthorized') {
          AlertService.swalErrorAlert(t("Invalid Credentials."))
        }
        else {
          AlertService.swalErrorAlert('Invalid Credentials')
        }
      }
    );
  };

  const validateFtn = (values) => {
    const errors = {};
    function validateEmail(email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }
    if (!values.email) {
      errors.email = t('Email Required.');
    }
    else if (!validateEmail(values.email)) {
      errors.email = t('Please enter a valid email address.');
    }
    if (!values.password) {
      errors.password = t('Password Required.');
    }
    return errors;
  };

  return (
    <Fragment>
      <div className="h-100 bg-light-gray bg-animation">
        <div className="d-flex h-100 justify-content-center align-items-center">
          <Col md="8" className="mx-auto app-login-box">
            <Form onSubmit={onSubmit}
              validate={validateFtn}
              render={({ handleSubmit, values, submitting, validating, valid }) => (
                <form onSubmit={handleSubmit}>
                  <div className="modal-dialog w-100 mx-auto shadow-sm">
                    <LoadingOverlay tag="div" active={loading}
                      styles={{ overlay: (base) => ({ ...base }) }}
                      spinner={<DNALoader />}>
                      <div className="modal-content border-light">
                        <div className="modal-body p-5">
                          <div className="h5 modal-title text-center">
                            <img src={logo} style={{ width: '40%', height: 'auto' }} className="app-logo mx-auto mb-3" />
                            <h6 className="mb-5">
                              <span>{t('Sign in to your account below.')}</span>
                            </h6>
                          </div>
                          <Row >
                            <Col md={12}>
                              <FormGroup>
                                <Field name="email">
                                  {({ input, meta }) => (
                                    <div className="mb-3">
                                      <Label for="email">{t('Email')}</Label>
                                      <Input {...input} type="email"
                                        invalid={meta.error && meta.touched}
                                        placeholder={t('Enter Email')}
                                      />
                                      {meta.error && meta.touched &&
                                        <span className="text-danger">{meta.error}</span>}
                                    </div>
                                  )}
                                </Field>
                              </FormGroup>
                            </Col>
                            <Col md={12}>
                              <FormGroup>
                                <Field name="password">
                                  {({ input, meta }) => (
                                    <div className="mb-3">
                                      <Label for="password">{t('Password')}</Label>
                                      <Input {...input} type="password"
                                        invalid={meta.error && meta.touched}
                                        placeholder="**********"
                                      />
                                      {meta.error && meta.touched &&
                                        <span className="text-danger">{meta.error}</span>}
                                    </div>
                                  )}
                                </Field>
                              </FormGroup>
                            </Col>
                          </Row>
                          <FormGroup check className="mb-3">
                            {/* <Input type="checkbox" name="check" id="exampleCheck" />
                                <Label for="exampleCheck" check>
                                  Keep me logged in
                                </Label> */}
                          </FormGroup>
                          <div>

                            <div className="d-grid mb-3">
                              <Button size="lg" className="btn-custom-color" type="submit" disabled={!valid}>
                                {t('Login')}
                              </Button>
                            </div>
                            <div className="text-center" onClick={handlereset}>
                              <a href="/forgot-password" className="btn-lg btn link-btn-custom-text-color btn-link">
                                {t('Forgot password?')}
                              </a>
                            </div>
                            {/* <div className="text-center" onClick={handleregprod}>
                                  <a href="/customer/register-product" className="btn-lg btn link-btn-custom-text-color btn-link">
                                    Register Warranty as Customer
                                  </a>
                                </div> */}
                          </div>
                        </div>
                      </div>
                    </LoadingOverlay>
                  </div>
                </form>
              )}
            />
            {/* {<CopyRight/>} */}
          </Col>
        </div>
      </div>
    </Fragment>
  );
};

export default Login;







  // return (
  //   <div className="col-md-12 login-form">
  //     <div className="container-md">
  //       <div className="row vh-100 d-flex justify-content-center">
  //         <div className="col-12 align-self-center">
  //           <div className="card-body">
  //             <div className="row">
  //               <div className="col-lg-4 mx-auto">
  //                 <div className="card">
  //                   <div className="card-body pt-4 p-0">
  //                     <div className="text-center p-3">
  //                       <a href="/" className="logo logo-admin mb-3">
  //                         <img
  //                           src="assets/images/basesystemslogo.svg"
  //                           height={50}
  //                           alt="logo"
  //                           className="auth-logo"
  //                         />
  //                       </a>
  //                       {/* <h4 className="mt-3 mb-1 fw-semibold text-white font-18">
  //                         Let's Get Started Metrica
  //                       </h4> */}
  //                       <p className="text-muted mb-0 mt-3">
  //                         Sign in to continue to Base Systems
  //                       </p>
  //                     </div>
  //                   </div>
  //                   <div className="card-body pt-0">
  //                     {/* <form className="my-4" action="index.html"> */}

  //                     <Form onSubmit={handleLogin} ref={form}>
  //                       <div className="form-group">
  //                         <label htmlFor="username">Username</label>
  //                         <Input
  //                           type="text"
  //                           className="form-control"
  //                           name="username"
  //                           value={username}
  //                           onChange={onChangeUsername}
  //                           validations={[required]}
  //                         />
  //                       </div>

  //                       <div className="form-group">
  //                         <label htmlFor="password">Password</label>
  //                         <Input
  //                           type="password"
  //                           className="form-control"
  //                           name="password"
  //                           value={password}
  //                           onChange={onChangePassword}
  //                           validations={[required]}
  //                         />
  //                       </div>

  //                       <div className="form-group mb-0 row">
  //                         <div className="col-12">
  //                           <div className="d-grid mt-3">
  //                             <button className="btn btn-danger" disabled={loading}>
  //                               {loading && (
  //                                 <span className="spinner-border spinner-border-sm"></span>
  //                               )}
  //                               Log In <i className="fas fa-sign-in-alt ms-1" />
  //                             </button>

  //                           </div>
  //                         </div>
  //                         { }
  //                       </div>

  //                       {message && (
  //                         <div className="form-group">
  //                           <div className="alert alert-danger" role="alert">
  //                             {message}
  //                           </div>
  //                         </div>
  //                       )}
  //                       <CheckButton style={{ display: "none" }} ref={checkBtn} />
  //                     </Form>
  //                     <div className="m-3 text-center text-muted" onClick={handlereset}>
  //                       <p className="mb-0">
  //                         <a
  //                           href="/forgot-password"
  //                           className="text-primary ms-2"
  //                         >
  //                           Forgot password?
  //                         </a>
  //                       </p>
  //                     </div>
  //                     {/* <hr className="hr-dashed mt-4" />
  //                     <div className="text-center mt-n5">
  //                       <h6 className="card-bg px-3 my-4 d-inline-block">
  //                         Or Login With
  //                       </h6>
  //                     </div>
  //                     <div className="d-flex justify-content-center mb-1">
  //                       <a
  //                         href
  //                         className="d-flex justify-content-center align-items-center thumb-sm bg-soft-primary rounded-circle me-2"
  //                       >
  //                         <i className="fab fa-facebook align-self-center" />
  //                       </a>
  //                       <a
  //                         href
  //                         className="d-flex justify-content-center align-items-center thumb-sm bg-soft-info rounded-circle me-2"
  //                       >
  //                         <i className="fab fa-twitter align-self-center" />
  //                       </a>
  //                       <a
  //                         href
  //                         className="d-flex justify-content-center align-items-center thumb-sm bg-soft-danger rounded-circle"
  //                       >
  //                         <i className="fab fa-google align-self-center" />
  //                       </a>
  //                     </div> */}
  //                   </div>
  //                   { }
  //                 </div>
  //                 { }
  //               </div>
  //               { }
  //             </div>
  //             { }
  //           </div>
  //           { }
  //         </div>
  //         { }
  //       </div>
  //       { }
  //     </div>
  //   </div>
  // );
