import React, { useEffect, useState, Fragment } from "react";
import { useTranslation } from 'react-i18next';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { format, parseISO, set } from 'date-fns';
import { useNavigate, useLocation, useParams, Link } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import {
  Row,
  Col,
  Input,
  Card,
  CardBody
} from "reactstrap";

import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../../components/Loaders/DnaLoader";
import "../../../assets/custom css/loadoverlay.scss";
import Alerts from "../../../components/Alerts/alerts";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faLockOpen, faLock, faKey } from "@fortawesome/free-solid-svg-icons";

import CustomerService from "./Customer.service";
import AuthService from "../../Authentication/auth.service";
import DataTable from 'react-data-table-component';

library.add(
  faLockOpen,
  faLock,
  faKey
);



const CustomersList = () => {

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState("");
  const [searchName, setsearchName] = useState('');
  const [searchRegNo, setsearchRegNo] = useState('');
  const [searchEmail, setsearchEmail] = useState('');

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    fetchCustomers(0, pageLimit);
  }, [pageLimit]);


  function fetchCustomers(page_index, page_limit) {
    var filter = {};
    if (searchName !== "") {
      console.log("Search name:-", searchName)
      filter["company_name"] = searchName
    }
    if (searchRegNo !== "") {
      console.log("Search Reg No:-", searchRegNo)
      filter["reg_no"] = searchRegNo
    }
    if (searchEmail !== "") {
      filter["email"] = searchEmail
    }

    var offset = (page_index - 1) * page_limit;
    if (offset < 1) {
      offset = 0;
    }
    var getData = {
      functionName: "GetCustomers",
      postData: {
        "sort": { _id: -1 },
        "offset": offset,
        "limit": page_limit,
        "filter": filter
      },
    }
    setLoading(true);
    CustomerService.getAllCustomers(getData).then((response) => {
      setLoading(false);
      console.log("Get Customers response:-", response)
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        setCustomers(response.data.data);
        setTotalRows(response.data.count.count);
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent)
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  };

  function handlePageChange(page_index) {
    console.log("Page index:-", page_index)
    setCurrentPage(page_index)
    fetchCustomers(page_index, pageLimit);
  }

  async function handlePerRowsChange(newPageLimit, page_index) {
    setPageLimit(newPageLimit);
  }

  function nameFilter(e) {
    console.log(e.target.value);
    setsearchName(e.target.value)
  }
  function regFilter(e) {
    console.log(e.target.value);
    setsearchRegNo(e.target.value)
  }
  function emailFilter(e) {
    console.log(e.target.value);
    setsearchEmail(e.target.value)
  }


  function resetFilters() {
    setResetPaginationToggle(!resetPaginationToggle);
    setsearchName('');
    setsearchRegNo('');
    setsearchEmail('');
    var offset = 0;
    var getData = {
      functionName: "GetCustomers",
      postData: {
        "sort": { _id: -1 },
        "offset": offset,
        "limit": pageLimit,
        "filter": {}
      },
    }
    setLoading(true);
    CustomerService.getAllCustomers(getData).then((response) => {
      setLoading(false);
      console.log("Get Customers response:-", response)
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        setCustomers(response.data.data);
        setTotalRows(response.data.count.count);
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent)
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  }

  function searchCustomers() {
    setResetPaginationToggle(!resetPaginationToggle);
    var page = 0
    handlePageChange(page++)
  }


  function confirmDeleteCustomer(_id) {
    let msg = t("Once deleted, you will not be able to recover this customer!")
    Alerts.swalConfirmDeleteAlert(msg).then(res => {
      console.log("Resp:-", res)
      if (res === true) {
        sendDelete(_id)
      }
    })
  };

  function sendDelete(id) {
    setLoading(true);
    CustomerService.deleteCustomer(id).then((response) => {
      setLoading(false);
      console.log("Delete customer response:-", response)
      if (response.data.status) {
        fetchCustomers(currentPage, pageLimit)
        if (response.data.message !== '') {
          Alerts.swalSuccessAlert(response.data.message)
        }
        else {
          Alerts.swalSuccessAlert(t("Customer Deleted Successfully."))
        }
        console.log(response.data.message)
      }
      else {
        if (response.data.message !== '') {
          if (response.data.message === "Unauthorized") {
            AuthService.logout();
            navigate("/login");
            window.location.reload();
          }
          else {
            Alerts.swalErrorAlert(response.data.message);
          }
        }
        else {
          Alerts.swalErrorAlert(t("Error: Can't delete the customer at the moment."))
        }
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        Alerts.swalErrorAlert(t("Delete operation failed"))
      })
  };

  function regeneratePassword(_id) {
    let msg = t("Are you sure you want to regenerate password for this Customer?")
    Alerts.swalConfirmGeneralAlert(msg).then(res => {
      console.log("Resp:-", res)
      if (res === true) {
        sendPassword(_id)
      }
    })
  };

  function sendPassword(id) {
    setLoading(true);
    CustomerService.sendCustomerPassword(id).then((response) => {
      console.log("Send Password response:-", response)
      setLoading(false);
      fetchCustomers(currentPage, pageLimit)
      if (response.data.status) {
        if (response.data.message !== '') {
          Alerts.swalSuccessAlert(response.data.message)
        }
        else {
          Alerts.swalSuccessAlert(t("Password emailed Successfully."))
        }
        console.log(response.data.message)
      }
      else {
        if (response.data.message !== '') {
          if (response.data.message === "Unauthorized") {
            AuthService.logout();
            navigate("/login");
            window.location.reload();
          }
          else {
            Alerts.swalErrorAlert(response.data.message);
          }
        }
        else {
          Alerts.swalErrorAlert(t("Error: Can't send the Password."))
        }
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        Alerts.swalErrorAlert(t("Operation failed"))
      })
  };

  const mycolumns = [
    {
      name: t("Company Name"),
      width: "30%",
      selector: row => row.company_name,
    },
    {
      name: t("Reg No."),
      selector: row => row.reg_no,
    }, {
      name: t("Email"),
      width: "23%",
      selector: row => row.email,
    },
    {
      name: t("PIC"),
      selector: row => row.pic_name
    },
    {
      name: t('Status'),
      width: '12%',
      selector: (row) => {
        return (
          <div>
            {row.customer_status === "Inactive" ?
              <button type="button"
                className="btn btn-danger btn-hover-shine me-3 btn">{row.customer_status}
              </button>
              :
              row.customer_status === "Active" ?
                <button type="button"
                  className="btn btn-success btn-hover-shine me-3 btn">{row.customer_status}
                </button>
                :
                ''
            }
          </div>
        );
      }
    },
    {
      name: t('Actions'),
      selector: (row) => {
        return (
          <div>
            <button className="mt-2 mb-2 me-2 btn-icon btn">
              <Tooltip placement="top" title={t("Customer Details")} arrow>
                <Link to="/customer-details" state={row}>
                  <i className="pe-7s-note2 btn-icon-wrapper text-info"> </i>
                </Link>
              </Tooltip >

              <Tooltip placement="top" title={t("Update")} arrow>
                <i>
                  <Link to="/update-customer" state={row}>
                    <i className="pe-7s-pen btn-icon-wrapper text-primary"> </i>
                  </Link>
                </i>
              </Tooltip >

              <Tooltip placement="top" title={t("Delete")} arrow>
                <i onClick={() => confirmDeleteCustomer(row._id)} className="pe-7s-trash text-danger btn-icon-wrapper"> </i>
              </Tooltip >
              {row.customer_status === "Active" &&
                <Tooltip placement="top" title={t("Send Credentials")} arrow>
                  <i onClick={() => regeneratePassword(row._id)} className="pe-7s-mail-open-file text-danger btn-icon-wrapper"> </i>
                </Tooltip >
              }
              {/* <Tooltip placement="top" title="Regenrate Password" arrow>
                <i onClick={() => regeneratePassword(row._id)} className="text-warning btn-sm px-0 btn-icon-wrapper">
                  <FontAwesomeIcon icon={["fas", "key"]} />
                </i>
              </Tooltip> */}
            </button>
          </div>
        );
      }
    },
  ];


  return (
    <Fragment>
      <div className="app-main__outer">
        <div className="app-main__inner">
          <div className=" bg-light-gray bg-animation ">
            <div className="d-flex  justify-content-center align-items-center">
              <Col md="12" className="mx-auto app-login-box">
                <div className="app-page-title">
                  <div className="page-title-wrapper">
                    <div className="page-title-heading fw-bold">
                      <div className="page-title-icon">
                        <i className="pe-7s-users icon-gradient bg-tempting-azure" />
                      </div>
                      <div>
                        {t("Customers Management")}
                        <div className="page-title-subheading">
                          {t("Explore detailed information and sub-tabs for each customer.")}
                        </div>
                      </div>
                    </div>
                    <div className="page-title-actions">
                      <button type="button" onClick={() => navigate(-1)} className="btn btn-outline-success btn-hover-shine me-3">
                        {t("Back")}
                      </button>
                    </div>
                  </div>
                </div>
                <Row>
                  <Fragment>
                    <Row>
                      <Col md="12">
                        <Card className="main-card mb-3">
                          <CardBody>
                            <Row className="mt-2">

                              <Col md="3">

                                <Input type="text" name="fname" value={searchName} placeholder={t("Search Text")} onChange={event => nameFilter(event)}></Input>
                              </Col>
                              <Col md="1">
                                <button type="button" className="btn btn-custom-color btn-hover-shine ms-1" onClick={() => searchCustomers()}>{t("Search")}</button>
                              </Col>
                              <Col md="2">
                                <button type="button" className="btn btn-custom-color btn-hover-shine" onClick={() => resetFilters()}>{t("Reset")}</button>
                              </Col>
                              <Col md="2">
                                {/* <Input type="text" name="fid" value={searchRegNo} placeholder="Reg No." onChange={event => regFilter(event)}></Input> */}
                              </Col>
                              <Col md="2">
                                {/* <Input type="text" name="femail" value={searchEmail} placeholder="Email" onChange={event => emailFilter(event)}></Input> */}
                              </Col>

                              <Col md="2">
                                <a href="/add-customer" className="btn btn-custom-color btn-hover-shine float-end">
                                  {t("Add New")}</a>
                              </Col>

                              <Col md="12" className="pt-3">
                                <LoadingOverlay tag="div" active={loading}
                                  styles={{ overlay: (base) => ({ ...base }) }}
                                  spinner={<DNALoader />}>
                                  <DataTable
                                    data={customers}
                                    columns={mycolumns}
                                    pagination
                                    fixedHeader
                                    fixedHeaderScrollHeight="400px"
                                    paginationServer
                                    paginationTotalRows={totalRows}
                                    onChangePage={handlePageChange}
                                    onChangeRowsPerPage={handlePerRowsChange}
                                    paginationResetDefaultPage={resetPaginationToggle}
                                  />
                                </LoadingOverlay>
                              </Col>
                            </Row>

                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Fragment>

                </Row>
              </Col>
            </div>
          </div>
        </div>
      </div>

    </Fragment>
  );
};

export default CustomersList;