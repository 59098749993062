import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import styled from "styled-components";
import CustomerService from "../../../pages/Edsha/Invoices/Invoices.service";
import AuthService from "../../../pages/Authentication/auth.service";
import Alerts from "../../Alerts/alerts";
import { Row, Col } from 'react-bootstrap';
import DateRangePickerComponent from './DateRangePickerComponent'; // Adjust the import path as needed

const DateInput = styled.input.attrs(props => ({
  type: "date",
  placeholder: "Select Date",
}))`
  height: 32px;
  width: 100%;
  
  border-radius: 6px;
`;

const Input = styled.select.attrs(props => ({
  type: "select",
  size: props.small ? 5 : undefined
}))`
  height: 32px;
  width: 100%;
  border-radius: 6px;
  
  background: white;
`;

const StatusInput = styled.input.attrs(props => ({
  type: "text",
  placeholder: "Search Status"
}))`
  height: 32px;
  width: 100%;
  border-radius: 6px;
  
`;

const Label = styled.label`
  display: block;
  font-weight: bold;
`;

const FilterContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  gap: 40px;
`;

const FilterRow = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

const CollectionFilter = ({ filterText1, filterText2,
  handleKeyDown, onFilter1, onFilter2, onFilterInvoice_StartDate, filterDue_EndDate,
  onFilterInvoice_EndDate, onFilterDue_StartDate, onFilterDue_EndDate,
  searchCustomers, resetFilters,
  filterDue_StartDate, filterInvoice_StartDate, filterInvoice_EndDate }) => {
  const { t, i18n } = useTranslation();
  const [collection, setCollection] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    fetchcollections();
  }, []);
  const [selectedDate, setSelectedDate] = useState(null);

  function fetchcollections() {
    var getData = {
      functionName: "GetCustomers",
      postData: {
        "sort": { _id: -1 },
        "offset": 0,
        "limit": 1000,
        "filter": {}
      },
    }
    CustomerService.getAllCustomers(getData).then((response) => {
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        console.log(response.data.data);
        if (response.data) {
          setCollection(response.data.data);
        }
      }
    },
      (error) => {
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  };

  console.log(filterText1);

  const handleDateChange = (startDateFormatted, endDateFormatted) => {
    // Call the callback functions to update the parent component's state
    onFilterDue_StartDate(startDateFormatted);
    onFilterDue_EndDate(endDateFormatted);
  };

  const handleDateChange_Invoice = (startDateFormatted, endDateFormatted) => {
    // Call the callback functions to update the parent component's state
    onFilterInvoice_StartDate(startDateFormatted);
    onFilterInvoice_EndDate(endDateFormatted);
  };
  return (
    <>
      <Row style={{ marginBottom: '20px' }}>
        <Col md="2">
        <Label>{t("Status")}</Label>
          <Input
            type="select"
            placeholder={t("Search Status")}
            value={filterText2}
            onChange={onFilter2}
            onKeyDown={handleKeyDown}
            id="colfilter"
          >

            <option disabled={false} value="">--Select Status--</option>
            <option key='generated' value='generated'>Generated</option>
            <option key='paid' value='paid'>Paid</option>
            <option key='partial paid' value='partial paid'>Partial Paid</option>
            <option key='unpaid' value='unpaid'>Unpaid</option>
            <option key='void' value='void'>Void</option>
            <option key='cancelled' value='cancelled'>Cancelled</option>
          </Input>
        </Col>
        <Col md="4">
        <Label>{t("Invoice Date")}</Label>
          <DateRangePickerComponent onDateChange={handleDateChange_Invoice} />
        </Col>

        <Col md="4">
          <Label>{t("Due Date")}</Label>
          <DateRangePickerComponent onDateChange={handleDateChange} />
        </Col>
        <Col style={{ marginTop: '20px' }} md="2">
          <button type="button" className="btn btn-custom-color btn-hover-shine ms-1"
            onClick={() => searchCustomers()}
          >Search</button>
          <button style={{ marginLeft: '10px' }} type="button"
            className="btn btn-custom-color btn-hover-shine"
            onClick={() => resetFilters()}
          >Reset</button>

        </Col>
      </Row>
    </>

  );
};

export default CollectionFilter;
