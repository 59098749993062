import React, { Fragment, useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import DataTable from 'react-data-table-component';
import Tooltip from '@mui/material/Tooltip';

import {
  Row,
  Col,
  Input,
  CardBody,
  Card
} from "reactstrap";

import FleetService from "./fleet.service";
import AuthService from "../../Authentication/auth.service";
import { useTranslation } from 'react-i18next';

import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../../components/Loaders/DnaLoader";
import "../../../assets/custom css/loadoverlay.scss";

import Alerts from "../../../components/Alerts/alerts";


const FleetList = () => {

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [models, setModels] = useState([]);
  const [brands, setBrands] = useState([]);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [fleets, setFleets] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState("");
  const [searchName, setsearchName] = useState('');
  const [searchRegNo, setsearchRegNo] = useState('');
  const [searchcolor, setsearchcolor] = useState('');
  const [searchbrand, setsearchbrand] = useState('');
  const [searchmodel, setsearchmodel] = useState('');


  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    getModels();
    getBrands();
    fetchFleets(0, pageLimit);
  }, [pageLimit]);

  function getModels() {
    setLoading(true);
    FleetService.getModels().then((response) => {
      setLoading(false);
      console.log("Get Models response:-", response)
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        setModels(response.data.data);
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent)
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  };

  function getBrands() {
    setLoading(true);
    FleetService.getBrands().then((response) => {
      setLoading(false);
      console.log("Get Brands response:-", response)
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        setBrands(response.data.data);
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent)
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  };


  function fetchFleets(page_index, page_limit) {
    var filter = {};
    if (searchName !== "") {
      console.log("Search name:-", searchName)
      filter["name"] = searchName
    }
    if (searchRegNo !== "") {
      console.log("Search Reg No:-", searchRegNo)
      filter["reg_no"] = searchRegNo
    }
    if (searchcolor !== "") {
      filter["color"] = searchcolor
    }
    if (searchbrand !== "") {
      filter["brand_id"] = searchbrand
    }
    if (searchmodel !== "") {
      filter["model_id"] = searchmodel
    }

    var offset = (page_index - 1) * page_limit;
    if (offset < 1) {
      offset = 0;
    }
    var getData = {
      functionName: "GetFleets",
      postData: {
        "sort": { _id: -1 },
        "offset": offset,
        "limit": page_limit,
        "filter": filter
      },
    }
    setLoading(true);
    FleetService.getAllFleets(getData).then((response) => {
      setLoading(false);
      console.log("Get Fleets response:-", response)
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        setFleets(response.data.data);
        setTotalRows(response.data.count.count);
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent)
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  };

  function handlePageChange(page_index) {
    console.log("Page index:-", page_index)
    setCurrentPage(page_index)
    fetchFleets(page_index, pageLimit);
  }

  async function handlePerRowsChange(newPageLimit, page_index) {
    setPageLimit(newPageLimit);
  }

  function nameFilter(e) {
    console.log(e.target.value);
    setsearchName(e.target.value)
  }
  function regFilter(e) {
    console.log(e.target.value);
    setsearchRegNo(e.target.value)
  }
  function colorFilter(e) {
    console.log(e.target.value);
    setsearchcolor(e.target.value)
  }
  function brandFilter(e) {
    console.log(e.target.value);
    setsearchbrand(e.target.value)
  }
  function modelFilter(e) {
    console.log(e.target.value);
    setsearchmodel(e.target.value)
  }


  function resetFilters() {
    setResetPaginationToggle(!resetPaginationToggle);
    setsearchName('');
    setsearchRegNo('');
    setsearchcolor('');
    setsearchbrand('');
    setsearchmodel("");
    var offset = 0;
    var getData = {
      functionName: "GetFleets",
      postData: {
        "sort": { _id: -1 },
        "offset": offset,
        "limit": pageLimit,
        "filter": {}
      },
    }
    setLoading(true);
    FleetService.getAllFleets(getData).then((response) => {
      setLoading(false);
      console.log("Get Services response:-", response)
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        setFleets(response.data.data);
        setTotalRows(response.data.count.count);
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent)
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  }

  function searchFleet() {
    setResetPaginationToggle(!resetPaginationToggle);
    var page = 0
    handlePageChange(page++)
  }


  function confirmDeleteFleet(_id) {
    let msg = t("Once deleted, you will not be able to recover this fleet!")
    Alerts.swalConfirmDeleteAlert(msg).then(res => {
      console.log("Resp:-", res)
      if (res === true) {
        sendDelete(_id)
      }
    })
  };


  function sendDelete(id) {
    setLoading(true);
    FleetService.deleteFleet(id).then((response) => {
      setLoading(false);
      console.log("Delete service response:-", response)
      if (response.data.status) {
        fetchFleets(currentPage, pageLimit)
        if (response.data.message !== '') {
          Alerts.swalSuccessAlert(response.data.message)
        }
        else {
          Alerts.swalSuccessAlert(t("Fleet Deleted Successfully."))
        }
        console.log(response.data.message)
      }
      else {
        if (response.data.message !== '') {
          if (response.data.message === "Unauthorized") {
            AuthService.logout();
            navigate("/login");
            window.location.reload();
          }
          else {
            Alerts.swalErrorAlert(response.data.message);
          }
        }
        else {
          Alerts.swalErrorAlert(t("Error: Can't delete the fleet at the moment."))
        }
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        Alerts.swalErrorAlert(t("Delete operation failed"))
      })
  };

  const mycolumns = [
    {
      name: " Name",
      selector: row => row.name,
    },
    {
      name: "Brand",
      selector: row => {
        for (let i = 0; i < brands.length; i++) {
          if (row.brand_id === brands[i]._id) {
            return (
              <div>
                {brands[i].name}
              </div>
            );
          }
        }
      }
    }
    , {
      name: "Model",
      selector: row => {
        for (let i = 0; i < models.length; i++) {
          if (row.model_id === models[i]._id) {
            return (
              <div>
                {models[i].name}
              </div>
            );
          }
        }
      }
    },
    {
      name: "Reg#",
      selector: row => row.reg_no,
    },
    {
      name: "Color",
      selector: row => row.color,
    },
    {
      name: "Driver",
      selector: row => row?.DriverData?.[0]?.name,
    },
    {
      name: 'Actions',
      selector: (row) => {
        // var data = {row: row, brands: brands, models: models}
        return (
          <div>
            <button className="mt-2 mb-2 me-2 btn-icon btn">
              <Tooltip placement="top" title="Fleet Details" arrow>
                <Link to="/fleet-details" state={row}>
                  <i className="pe-7s-note2 btn-icon-wrapper text-info"> </i>
                </Link>
              </Tooltip >
              <Tooltip placement="top" title="Delete" arrow>
                <i onClick={() => confirmDeleteFleet(row._id)} className="pe-7s-trash text-danger btn-icon-wrapper"> </i>
              </Tooltip >
              <Tooltip placement="top" title="Edit" arrow>
                <Link to="/edit-fleet" state={row}>
                  <i className="pe-7s-pen btn-icon-wrapper text-primary"> </i>
                </Link>
              </Tooltip >
            </button>
          </div>
        );
      }
    },

  ];

  return (
    <Fragment>
      <div className="app-main__outer">
        <div className="app-main__inner">
          <div className=" bg-light-gray bg-animation">
            <div className="d-flex  justify-content-center align-items-center">
              <Col md="12" className="mx-auto app-login-box">

                <div className="app-page-title">
                  <div className="page-title-wrapper">
                    <div className="page-title-heading fw-bold">
                      <div className="page-title-icon">
                        <i className="lnr-bus icon-gradient bg-tempting-azure" />
                      </div>
                      <div>
                        Fleet Management
                        <div className="page-title-subheading">
                          {t('Add, Update, Delete & View Fleets with basic details.')}
                        </div>
                      </div>
                    </div>
                    <div className="page-title-actions">
                      <button type="button" onClick={() => navigate(-1)} className="btn btn-outline-success btn-hover-shine me-3">
                        Back
                      </button>
                    </div>
                  </div>
                </div>

                <Row>
                  <Fragment>
                    <Row>
                      <Col md="12">
                        <Card className="main-card">
                          <CardBody>

                            <LoadingOverlay tag="div" active={loading}
                              styles={{ overlay: (base) => ({ ...base }) }}
                              spinner={<DNALoader />}>
                              <Row>
                                <Col md="9">
                                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Input style={{ width: '19%' }}
                                      type="text" value={searchName} placeholder="Name" onChange={event => nameFilter(event)} />
                                    <Input style={{ width: '19%' }} type="text" placeholder="Registration No" value={searchRegNo} onChange={event => regFilter(event)} >     </Input>
                                    <Input style={{ width: '19%' }} type="text" placeholder="Color" value={searchcolor} onChange={event => colorFilter(event)} >     </Input>

                                    <Input style={{ width: '19%' }} type="select" name="brand_id" value={searchbrand} onChange={event => brandFilter(event)}>
                                      <option disabled={false} value="">Brand</option>
                                      {brands &&
                                        brands.map((brand, inde) => (
                                          <option key={inde} value={brand._id}>{brand.name}</option>
                                        ))
                                      }
                                    </Input>
                                    <Input style={{ width: '19%' }} type="select" name="model_id" value={searchmodel} onChange={event => modelFilter(event)} >
                                      <option disabled={false} value="">Model</option>
                                      {models &&
                                        models.map((model, inde) => (
                                          <option key={inde} value={model._id}>{model.name}</option>
                                        ))
                                      }
                                    </Input>
                                  </div>
                                </Col>
                                <Col md="3">
                                  <button type="button" className="btn btn-custom-color btn-hover-shine" onClick={() => searchFleet()}>Search</button>
                                  <button type="button" className="btn btn-custom-color btn-hover-shine ms-3" onClick={() => resetFilters()}>Reset</button>
                                  <a href="/add-fleet" className="btn btn-custom-color btn-hover-shine ms-3">
                                    Add New </a>

                                </Col>
                              </Row>

                              <Col md="12" className="pt-3">

                                <DataTable
                                  data={fleets}
                                  columns={mycolumns}
                                  pagination
                                  fixedHeader
                                  fixedHeaderScrollHeight="400px"
                                  paginationServer
                                  paginationTotalRows={totalRows}
                                  onChangePage={handlePageChange}
                                  onChangeRowsPerPage={handlePerRowsChange}
                                  paginationResetDefaultPage={resetPaginationToggle}
                                />

                              </Col>
                            </LoadingOverlay>

                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Fragment>
                </Row>



              </Col>
            </div>

          </div>
        </div>
      </div>
    </Fragment >
  );
};


export default FleetList;
