import {Dna} from "react-loader-spinner";

const DNALoader = () => {

  return(
    <Dna 
      visible={true} 
      height="120" 
      width="120" 
      ariaLabel="dna-loading" 
      wrapperClass="dna-wrapper"
    />
  );
  
};
export default DNALoader;