import axios from "axios";

const API_URL = process.env.REACT_APP_LOGINURL;

const getAllCustomers = (payload) => {
    const headers = {
      'Content-Type': 'application/json',
      'token': localStorage.getItem("session-id")
    }
    return axios.post(API_URL + 'customerRoutes/GetCustomers', payload, { headers });
  };

function getAllServices(payload) {
    const headers = {
        'Content-Type': 'application/json',
        'token': localStorage.getItem("session-id")
    };
    return axios.post(API_URL + 'ServicesRoutes/' + payload.functionName, payload, { headers });
}


const addService = (payload) => {
    const headers = {
        'Content-Type': 'application/json',
        'token': localStorage.getItem("session-id")
    }
    return axios.post(API_URL + 'ServicesRoutes/'+payload.functionName, payload, { headers });
};

const updateService = (payload) => {
    const headers = {
        'Content-Type': 'application/json',
        'token': localStorage.getItem("session-id")
    }
    return axios.post(API_URL + 'ServicesRoutes/'+payload.functionName, payload, { headers });
};


const deleteService = (id) => {
    const headers = {
        'Content-Type': 'application/json',
        'token': localStorage.getItem("session-id")
    }
    var payload = {
        functionName: "DeleteService",
        postData: {
            _id: id,
        },
    }
    return axios.post(API_URL + 'ServicesRoutes/'+payload.functionName, payload, { headers });
};


const AddServiceDetail = (payload) => {
    const headers = {
        'Content-Type': 'application/json',
        'token': localStorage.getItem("session-id")
    }
    return axios.post(API_URL + 'ServicesRoutes/'+payload.functionName, payload, { headers });
};


const updateServiceDetail = (payload) => {
    const headers = {
        'Content-Type': 'application/json',
        'token': localStorage.getItem("session-id")
    }
    return axios.post(API_URL + 'ServicesRoutes/'+payload.functionName, payload, { headers });
};


const deleteServiceDetail = (id, service_id) => {
    const headers = {
        'Content-Type': 'application/json',
        'token': localStorage.getItem("session-id")
    }
    var payload = {
        functionName: "DeleteServiceMasterPricing",
        postData: {
            "_id": id,
            "service_id" : service_id
        },
    }
    return axios.post(API_URL + 'ServicesRoutes/'+payload.functionName, payload, { headers });
};

const GetCustomerPackaging = (payload) => {
    const headers = {
        'Content-Type': 'application/json',
        'token': localStorage.getItem("session-id")
    }
    return axios.post(API_URL + 'PackagingRoutes/'+payload.functionName, payload, { headers });
};
const getPackaging = () => {
    var payload = {
        functionName: "GetPackaging",
        postData: {},
    }
    const headers = {
        'Content-Type': 'application/json',
        'token': localStorage.getItem("session-id")
    }
    return axios.post(API_URL + 'PackagingRoutes/'+payload.functionName, payload, { headers });
};


const getPackagingSizes = () => {
    var payload = {
        functionName: "GetSizes",
        postData: {},
    }
    const headers = {
        'Content-Type': 'application/json',
        'token': localStorage.getItem("session-id")
    }
    return axios.post(API_URL + 'PackagingSizeRoutes/'+payload.functionName, payload, { headers });
};



const ServicesService = {
    getAllServices,
    GetCustomerPackaging,
    addService,
    getAllCustomers,
    updateService,
    deleteService,
    AddServiceDetail,
    updateServiceDetail,
    deleteServiceDetail,
    getPackaging,
    getPackagingSizes,
};

export default ServicesService;