import React, { useEffect, useState, Fragment } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from "react-router-dom";
import { Col, Row, Label, FormGroup, Input, Card, CardBody } from "reactstrap";
import { Form, Field } from "react-final-form";

import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import "../../assets/custom css/loadoverlay.scss";
import Alerts from "../../components/Alerts/alerts";


import ConfigurationService from "./configuration.service";
import AuthService from "../Authentication/auth.service";


const TabGenralConfig = () => {

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [configs, setConfig] = useState([]);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    getSystemConfig();
  }, []);

  function getSystemConfig() {
    ConfigurationService.getConfiguration().then((response) => {
      console.log("API Response:-", response);
      if (response.data.status) {
        const [rec_data] = response.data.data;
        setConfig(rec_data);
      }
      else {
        if (response.data.message === "Unauthorized") {
          AuthService.logout();
          navigate("/login");
          window.location.reload();
        }
        else {
          alert(t("Unable to retrieve configurations at the moment."))
        }
      }
    },
      (error) => {
        alert(t("Unable to retrieve configurations at the moment."))
      });
  }




  function handleSave(values) {
    setLoading(true);
    console.log("Form Values:-", values);
    localStorage.setItem("payment_method", values.payment_method);
    localStorage.setItem("default_notes", values.default_notes);
    localStorage.setItem("due_date", values.due_date);
    localStorage.setItem("tax_percentage", values.tax_percentage);
    localStorage.setItem("currency", values.currency);
    var payloadData = {
      functionName: "Set_Finance_Config",
      postData: {
        payment_method: values.payment_method,
        default_notes: values.default_notes,
        due_date: values.due_date,
        tax_percentage: values.tax_percentage,
        currency: values.currency
        // system_logo: B64converted
      },
    }
    // if (B64converted) {
    //   payloadData.postData['system_logo'] = B64converted;
    // }
    ConfigurationService.setConfiguration(payloadData).then((response) => {
      console.log("API Response:-", response);
      setLoading(false);
      if (response.data.status) {
        if (response.data.message !== '') {
          Alerts.swalSuccessAlert(response.data.message)
        }
        else {
          Alerts.swalSuccessAlert(t("Configuration updated successfully."));
        }
        getSystemConfig();
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
      else {
        if (response.data.message !== '') {
          if (response.data.message === "Unauthorized") {
            AuthService.logout();
            navigate("/login");
            window.location.reload();
          }
          else {
            Alerts.swalErrorAlert(response.data.message);
          }
        }
        else {
          Alerts.swalErrorAlert(t("Error: Can't update configuration at the moment. Please try again later."))
        }
      }
    },
      (error) => {
        setLoading(false);
        const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        console.log("Error:-", error)
        Alerts.swalErrorAlert(t("Network Operation failed."))
      });
  }

  const validateFtn = (values) => {
    const errors = {};
    if (!values.payment_method) {
      errors.payment_method = t("Payment method Required.");
    }
    if (!values.default_notes) {
      errors.default_notes = t("Default Notes Required.");
    }
    if (!values.due_date) {
      errors.due_date = t("Due Date Required.");
    }
    if (!values.tax_percentage) {
      errors.tax_percentage = t("Tax Percentage Required.");
    }
    if (!values.currency) {
      errors.currency = t("Currency Required.");
    }
    return errors;
  };

  return (
    <Fragment>
      <Card className="main-card mb-3">
        <CardBody className="col-md-8">
          <Form
            initialValues={configs}
            validate={validateFtn}
            onSubmit={handleSave}
            FormState
            render={({ handleSubmit, values, submitting, validating, valid, form: { getState } }) => (
              <form onSubmit={handleSubmit}>
                <LoadingOverlay tag="div" active={loading}
                  styles={{ overlay: (base) => ({ ...base }) }}
                  spinner={<DNALoader />}>
                  <Row>
                    <Col md={12}>
                      <FormGroup>
                        <Field name="payment_method">
                          {({ input, meta }) => (
                            <div>
                              <div className="row mb-3">
                                <Label for="payment_method" className="col-sm-2 col-form-label">{t('Payment Method')}</Label>
                                <div className="col-sm-10">
                                  <Input {...input} type="text"
                                    invalid={meta.error && meta.touched}
                                    placeholder={t('Method use for Payments')}
                                  />
                                  {meta.error && meta.touched &&
                                    <span className="text-danger">{meta.error}</span>}
                                </div>
                              </div>
                            </div>
                          )}
                        </Field>
                      </FormGroup>
                      <FormGroup>
                        <Field name="default_notes">
                          {({ input, meta }) => (
                            <div>
                              <div className="row mb-3">
                                <Label for="default_notes" className="col-sm-2 col-form-label">{t('Default Notes')}</Label>
                                <div className="col-sm-10">
                                  <Input {...input} type="text"
                                    invalid={meta.error && meta.touched}
                                    placeholder={t('Default Notes for Invoice')}
                                  />
                                  {meta.error && meta.touched &&
                                    <span className="text-danger">{meta.error}</span>}
                                </div>
                              </div>
                            </div>
                          )}
                        </Field>
                      </FormGroup>
                      <FormGroup>
                        <Field name="due_date">
                          {({ input, meta }) => (
                            <div>
                              <div className="row mb-3">
                                <Label for="due_date" className="col-sm-2 col-form-label">{t('Due Date')}</Label>
                                <div className="col-sm-10">
                                  <Input {...input} type="number"
                                    invalid={meta.error && meta.touched}
                                    placeholder={t('Due Date range for Invoice Dues')}
                                  />
                                  {meta.error && meta.touched &&
                                    <span className="text-danger">{meta.error}</span>}
                                </div>
                              </div>
                            </div>
                          )}
                        </Field>
                      </FormGroup>
                      <FormGroup>
                        <Field name="tax_percentage">
                          {({ input, meta }) => (
                            <div>
                              <div className="row mb-3">
                                <Label for="tax_percentage" className="col-sm-2 col-form-label">{t('Tax %')}</Label>
                                <div className="col-sm-10">
                                  <Input {...input} type="text"
                                    invalid={meta.error && meta.touched}
                                    placeholder={t('Default % for tax')}
                                  />
                                  {meta.error && meta.touched &&
                                    <span className="text-danger">{meta.error}</span>}
                                </div>
                              </div>
                            </div>
                          )}
                        </Field>
                      </FormGroup>
                      <FormGroup>
                        <Field name="currency">
                          {({ input, meta }) => (
                            <div>
                              <div className="row mb-3">
                                <Label for="currency" className="col-sm-2 col-form-label">{t('Currency')}</Label>
                                <div className="col-sm-10">
                                  <Input {...input} type="text"
                                    invalid={meta.error && meta.touched}
                                    placeholder={t('Currency')}
                                  />
                                  {meta.error && meta.touched &&
                                    <span className="text-danger">{meta.error}</span>}
                                </div>
                              </div>
                            </div>
                          )}
                        </Field>
                      </FormGroup>
                      <FormGroup>
                        <div className="d-flex justify-content-center EditData">
                          <button type="button" onClick={() => navigate(-1)} className="btn btn-outline-light btn-hover-shine me-3 mt-3">{t('Cancel')}</button>
                          <button className="btn btn-custom-color btn-hover-shine me-3 mt-3" type="submit" disabled={!valid}>{t('Save')}</button>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                </LoadingOverlay>
              </form>

            )}

          />

        </CardBody>
      </Card>
    </Fragment>
  );

};
export default TabGenralConfig; 