import React from "react";
import { Translation } from "react-i18next";
import { Navigate } from "react-router-dom";
import { Row, Col, FormGroup, Input, Button, Label, Modal, ModalHeader, ModalBody, ModalFooter, Card, CardBody } from "reactstrap";
import { Form, Field } from "react-final-form";
import ServicesService from "../../Services Management/services.service";
import AuthService from "../../../Authentication/auth.service";

import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../../../components/Loaders/DnaLoader";
import "../../../../assets/custom css/loadoverlay.scss";

import Alerts from "../../../../components/Alerts/alerts"; import CustomerService from "../Customer.service";
;


class PricingEditCustomer extends React.Component {
  componentDidMount() {
    this.getServices();
    this.getPackaging();
    // this.getPackagingSizes();
  }
  constructor(props) {
    super(props);
    console.log("Received Prop:-", props);
    this.state = {
      modal: false,
      backdrop: true,
      loading: false,
      loginNavigate: false,
      services: [],
      packagings: [],
      // packagingSizes: []
    };
    this.toggle = this.toggle.bind(this);
    this.validateFtn = this.validateFtn.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.getServices = this.getServices.bind(this);
    this.getPackaging = this.getPackaging.bind(this);
    // this.getPackagingSizes = this.getPackagingSizes.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }

  getServices() {
    var getData = {
      functionName: "GetServices",
      postData: {},
    }
    ServicesService.getAllServices(getData).then((response) => {
      console.log("Get Services response:-", response);
      this.setState({
        services: response.data.data,
      });
    },
      (error) => {
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent)
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert("Server Error, Please try again later.")
        }
      })
  }

  getPackaging() {
    ServicesService.getPackaging().then((response) => {
      console.log("Get Packagings response:-", response);
      this.setState({
        packagings: response.data.data,
      });
    },
      (error) => {
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent)
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert("Server Error, Please try again later.")
        }
      })
  }

  // getPackagingSizes() {
  //   ServicesService.getPackagingSizes().then((response) => {
  //     console.log("Get PackagingSizes response:-", response)
  //     this.setState({
  //       packagingSizes: response.data.data,
  //     });
  //   },
  //     (error) => {
  //       const errContent =
  //         (error.response && error.response.data) || error.message || error.toString();
  //       console.log("Net:-", errContent)
  //       if (errContent) {
  //         Alerts.swalErrorAlert(errContent)
  //       }
  //       else {
  //         Alerts.swalErrorAlert("Server Error, Please try again later.")
  //       }
  //     })
  // }

  validateFtn = values => {
    const errors = {};
    if (!values.service_id) {
      errors.service_id = "Service Name Required.";
    }
    if (!values.packaging_id) {
      errors.packaging_id = "Packaging Name Required.";
    }
    // if (!values.packaging_size_id) {
    //   errors.packaging_size_id = "Packaging Size Required.";
    // }
    if (!values.price) {
      errors.price = "Price Required.";
    }
    return errors;
  };

  onSubmit = values => {
    console.log(values);
    var payloadData = {
      functionName: "UpdateCustomerPricing",
      postData: {
        service_id: values.service_id,
        packaging_id: values.packaging_id,
        // packaging_size_id: values.packaging_size_id,
        price: parseFloat(values.price).toFixed(2),
        user_id: this.props.data.user_id,
        _id: this.props.data._id
      }
    }
    this.setState({
      loading: !this.state.loading,
    });
    CustomerService.updateCustomerPricing(payloadData).then((response) => {
      console.log("API Response:-", response);
      this.setState({
        loading: !this.state.loading,
      });
      if (response.data.status) {
        this.props.onSuccess();
        if (response.data.message !== '') {
          Alerts.swalSuccessAlert(response.data.message).then(res => {
            console.log("Resp:-", res)
            this.toggle();
          })
        }
        else {
          Alerts.swalSuccessAlert("Customer Pricing updated successfully.").then(res => {
            console.log("Resp:-", res)
            this.toggle();
          })
        }
      }
      else {
        if (response.data.message !== '') {
          if (response.data.message === "Unauthorized") {
            AuthService.logout();
            this.setState({ loginNavigate: true });
            window.location.reload();
          }
          else {
            Alerts.swalErrorAlert(response.data.message).then(res => {
              console.log("Resp:-", res)
            })
          }
        }
        else {
          Alerts.swalErrorAlert("Error: Can't update Customer Pricing at the moment. Please try again later.")
        }
      }
    },
      (error) => {
        this.setState({
          loading: !this.state.loading,
        });
        const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        Alerts.swalErrorAlert("Network Operation failed.")
      });
  }

  render() {
    {
      this.state.loginNavigate &&
        <Navigate to={'/login'} />
    }
    return (
      <Translation>
        {(t) => (
          <span className="d-inline-block float-end mb-2 me-2">
            <i onClick={this.toggle} className="pe-7s-pen btn-icon-wrapper text-primary"> </i>
            <Modal isOpen={this.state.modal} toggle={this.toggle} className="custom_modal_width" backdrop={true} >
              <LoadingOverlay tag="div" active={this.state.loading}
                styles={{ overlay: (base) => ({ ...base }) }}
                spinner={<DNALoader />}>
                <ModalHeader >{t('Update Customer Pricing')}</ModalHeader>
                <ModalBody>
                  <Card className="main-card mb-3">
                    <CardBody>
                      <Row>
                        <Col md="12" className="pt-3">
                          <Form onSubmit={this.onSubmit}
                            validate={this.validateFtn}
                            initialValues={this.props.data}
                            render={({ handleSubmit, values, submitting, validating, valid }) => (
                              <form onSubmit={handleSubmit}>
                                <Row>
                                  <Col md="12">
                                    <FormGroup>
                                      <Field name="service_id">
                                        {({ input, meta }) => (
                                          <div>
                                            <Input {...input} type="select" className="serviceclass"
                                              invalid={meta.error && meta.touched}
                                            >
                                              <option disabled={false} value="">{t('Service')}</option>
                                              {this.state.services &&
                                                this.state.services.map((service, index) => (
                                                  <option key={index} value={service._id}>{service.name}</option>
                                                ))
                                              }
                                            </Input>
                                            {meta.error && meta.touched &&
                                              <span className="text-danger">{t(meta.error)}</span>}
                                          </div>
                                        )}
                                      </Field>
                                    </FormGroup>
                                  </Col>
                                  <Col md="12">
                                    <FormGroup>
                                      <Field name="packaging_id">
                                        {({ input, meta }) => (
                                          <div>
                                            <Input {...input} type="select" className="packclass"
                                              invalid={meta.error && meta.touched}
                                            >
                                              <option disabled={false} value="">{t('Packaging')}</option>
                                              {this.state.packagings &&
                                                this.state.packagings.map((pack, inde) => (
                                                  <option key={inde} value={pack._id}>{pack.name}</option>
                                                ))
                                              }
                                            </Input>
                                            {meta.error && meta.touched &&
                                              <span className="text-danger">{t(meta.error)}</span>}
                                          </div>
                                        )}
                                      </Field>
                                    </FormGroup>
                                  </Col>
                                  <Col md="12">
                                    <FormGroup>
                                      <Field name="price">
                                        {({ input, meta }) => (
                                          <div>
                                            <Input {...input} type="number" className="price"
                                              invalid={meta.error && meta.touched}
                                            >
                                            </Input>
                                            {meta.error && meta.touched &&
                                              <span className="text-danger">{t(meta.error)}</span>}
                                          </div>
                                        )}
                                      </Field>
                                    </FormGroup>
                                  </Col>

                                  <div className="text-center">
                                    <FormGroup>
                                      <div className="text-center">
                                        <button type="submit" className="btn btn-secondary btn-lg btn-hover-shine me-3">
                                          {t("Update")} </button>
                                        <button type="button" className="btn btn-outline-success btn-lg btn-hover-shine me-3" onClick={this.toggle}>
                                          {t("Cancel")}
                                        </button>
                                      </div>
                                    </FormGroup>
                                  </div>
                                </Row>
                              </form>
                            )}
                          />
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </ModalBody>
              </LoadingOverlay>
            </Modal>
          </span>
        )}
      </Translation>
    );
  }
}

export default PricingEditCustomer;