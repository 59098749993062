import React, { Fragment, useState, useEffect } from "react";
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Row, Col, Card, CardHeader, CardBody, CardTitle } from "reactstrap";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import moment from 'moment'
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import DateRangePickerComponent  from "./DatePicker";
import AlertService from "../../components/Alerts/alerts";
// import PageTitle from "./Examples/PageTitle";
import AssignOrderManual from "../Edsha/Customers/Customer Modals/Customer Order Modals/AssignOrderManual.js";
import Tooltips from '@mui/material/Tooltip';

import {
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  BarChart,
  Bar,
} from "recharts";
// Examples
import CalendarBasic from "./Examples/Basic";
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import "../../assets/custom css/loadoverlay.scss";
import { Pie } from "react-chartjs-2";

import Alerts from "../../components/Alerts/alerts";

import DataTable from 'react-data-table-component';
import DashboardService from "./dashboard.service";
import AuthService from "../Authentication/auth.service";
import { useSelector, useDispatch } from 'react-redux';




const Home = () => {

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);
  const [StartDate, setStartDate] = useState(moment().startOf('year'));
  const [EndDate, setEndDate] = useState(moment().endOf('year'));
  const user = useSelector((state) => state);
  const [drivers, setDrivers] = useState([]);
  const [param, setParam] = useState("allorders");

  const [services, setServices] = useState([]);
  const [fleets, setFleets] = useState([]);
  const [orders, setOrders] = useState([]);
  const [OrderStatus, setOrderStatus] = useState([]);
  const [DashboardData, setDashboardData] = useState([]);
  const [InvoicePayments, setInvoicePayments] = useState([]);
  const [Calender, setCalender] = useState([]);
  const [labels, setLabels] = useState();
  const [values, setValues] = useState();


  useEffect(() => {
    const interval = setInterval(() => {
      fetchStates();
      fetchInvoicePayments();
      console.log('I am Fetching InvoicePayments Again ...');
    }, 15000); // 15 seconds in milliseconds
    return () => clearInterval(interval);
  }, []);



  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    setViewFtn('allorders', "reload")
    // getPackaging();
    fetchStates();
    fetchInvoicePayments();
    GetOrders_Count();
    getDrivers();
    fetchCustomerOrders();
    getFleets();
    getServices();
    setOrderDetails([]);
  }, []);
  const [singleOrderdata, setSingleOrderData] = useState("");
  const [orderDetails, setOrderDetails] = useState([]);


  const handleDetails = (event) => {
    console.log(event);
    navigate("/order-details", { state: { event } });
  }



  function setViewFtn(param, data) {
    setParam(param);
    if (param === 'allorders') {
      setSingleOrderData("");
      setOrderDetails([]);
      if (data === "reload") {
        fetchCustomerOrders(0, pageLimit);
      }
    }
    if (param === 'orderdetails') {
      setSingleOrderData(data)
    }
    else {
      setSingleOrderData("")
    }
   }


  useEffect(() => {
    GetOrdersDetails();
  }, []);



  useEffect(() => {
    GetOrders_Count();
  }, [StartDate]);

 
  function getDrivers() {
    var getData = {
      functionName: "GetDrivers",
      postData: {
      },
    }
    setLoading(true);
    DashboardService.getAllDrivers(getData).then((response) => {
      setLoading(false);
      console.log("Get Drivers response:-", response)
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        setDrivers(response.data.data);
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent)
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  };


  const datapolar = {
    labels: labels ? labels : '',
    datasets: [
      {
        data: values ? values : 0 ,
        backgroundColor: ["#8dace7", "#4BC0C0", "#ef869e", "#E7E9ED", "#71deb9", '#32a852', '#295099'],
      },
    ],
  };
  
  const options = {
    plugins: {
      datalabels: {
        display: true,
        color: "#fff",
        backgroundColor: (context) => context.dataset.backgroundColor,
        borderRadius: 5,
        font: {
          weight: "bold",
        },
        formatter: (value, context) => `${value}%`,
      },
    },
  };
  
  
  
  function GetOrdersDetails() {
    var getData = {
      functionName: "GetOrdersDetails",
      postData: {
      },
    }
    setLoading(true);
    DashboardService.GetOrdersDetails(getData).then((response) => {
      setLoading(false);
      console.log("Get Orders response:-", response)
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        console.log(response.data)
        setCalender(response.data.data);
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent)
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  };
  
  function fetchCustomerOrders(page_index, page_limit) {
    var offset = (page_index -1 ) * page_limit;
    if(offset < 1 ) {
      offset=0;
    }
    var getData = {
      functionName: "GetOrdersByFilter",
      postData: {
        "sort": { _id: -1 },
        "offset": offset,
        "limit": page_limit,
        "filter": {}
      },
    }
    setLoading(true);
    DashboardService.getCustomerOrders(getData).then((response) => {
      setLoading(false);
      console.log("Get Customer Orders response:-", response)
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        setOrders(response.data.data);
        // setservice(response.data.data[0].services);
        // setpackaging(response.data.data[0].packaging);
        // setpackagingsize(response.data.data[0].packaging_size_details)
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent)
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  };
  
  function getFleets() {
    var getData = {
      functionName: "GetFleets",
      postData: {
      },
    }
    setLoading(true);
    DashboardService.getAllFleets(getData).then((response) => {
      setLoading(false);
      console.log("Get Fleets response:-", response)
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        setFleets(response.data.data);
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent)
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  };

  function fetchStates() {
    var getData = {
      functionName: "GetDashboardData",
      postData: {},
    }
    DashboardService.GetDashboardData(getData).then((response) => {
      console.log("Get Dash States response:-", response)
      if(response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
          setDashboardData(response.data)
          console.log(response.data)
      }
    },
    (error) => {
      setLoading(false);
      const errContent =
      (error.response && error.response.data) || error.message || error.toString();
      console.log("Net:-", errContent)
      if(errContent) {
        Alerts.swalErrorAlert(errContent)
      }
      else {
        Alerts.swalErrorAlert(t("Server Error, Please try again later."))
      }
    })
  };
  function GetOrders_Count() {
    var getData = {
      functionName: "GetOrders_Count",
      postData: {
          startDate:StartDate,
          endDate:EndDate,
      },
    }
    DashboardService.GetOrders_Count(getData).then((response) => {
      console.log("Get Dash States response:-", response)
      if(response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        setOrderStatus(response.data)
         setLabels(Object.keys(response.data.data));
         setValues(Object.values(response.data.data))
      }
    },
    (error) => {
      setLoading(false);
      const errContent =
      (error.response && error.response.data) || error.message || error.toString();
      console.log("Net:-", errContent)
      if(errContent) {
        Alerts.swalErrorAlert(errContent)
      }
      else {
        Alerts.swalErrorAlert(t("Server Error, Please try again later."))
      }
    })
  };


  const handleDateChange_Invoice = (startDateFormatted, endDateFormatted) => {
    // Call the callback functions to update the parent component's state
    setStartDate(startDateFormatted);
    setEndDate(endDateFormatted);
  };



  function fetchInvoicePayments() {
    var getData = {
      functionName: "GetInvoicePayments",
      postData: {
      },
    }
    DashboardService.GetInvoicePayments(getData).then((response) => {
      console.log("Get Dash States response:-", response)
      if(response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
            setInvoicePayments(response.data)
          console.log(response.data)
      }
    },
    (error) => {
      setLoading(false);
      const errContent =
      (error.response && error.response.data) || error.message || error.toString();
      console.log("Net:-", errContent)
      if(errContent) {
        Alerts.swalErrorAlert(errContent)
      }
      else {
        Alerts.swalErrorAlert(t("Server Error, Please try again later."))
      }
    })
  };
  function getServices() {
    var getData = {
      functionName: "GetServices",
      postData: {},
    }
    DashboardService.getAllServices(getData).then((response) => {
      console.log("Get Services response:-", response);
      setServices(response.data.data)
    },
      (error) => {
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent)
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert("Server Error, Please try again later.")
        }
      })
  }
    

    async function handlePerRowsChange(newPageLimit, page_index) {
      setPageLimit(newPageLimit);
    }

    const AddInvoice = (row) => {
      if (row.invoice_status === 'true') {
        AlertService.swalErrorAlert("Invoice Already Exists.");
      } else {
        const modifiedRow = { ...row, check: '1' };
        navigate('/add-invoice', { state: modifiedRow });
      }
    }

  
    const handleSuccess = () => {
      fetchCustomerOrders();
    };
  
  const mycolumns = [
    {
      name: t("Customer Name"),
      selector: (row) => {
        if (row.userDetails[0]) {
          return (
            <div>
              {row.userDetails[0].company_name}
            </div>
          )
        }
      }
    },
    {
      name: t("Order date"),
      selector: row => row.created_at ? moment(row.created_at).format("DD-MM-YYYY") : ''
    },
    {
      name: t("Order No."),    
        width:'10%',
      selector: "order_no",
    },
    {
      name: t("Driver/Vehicle"),
      selector: (row) => {
        if (row.fleetDetails.length !== 0) {
          return (
            <div>
              {`${row.driverDetails[0]?.name}/${row.fleetDetails[0]?.name}`}
            </div>
          )
        } else {
          return (
            <div>
              Not Assigned
            </div>
          )
        }
      }
    },
    {
      name: t('Status'),
      width:'15%',
      selector: (row) => {
        return (
          <div>
            {row.order_status === "Assigned" ?
              <button type="button" style={{ borderColor: '#F39026', backgroundColor: '#F39026' }}
                className="btn btn-success btn-hover-shine me-3 btn">{row.order_status}
              </button>
              :
              row.order_status === "Cancelled" ?
                <button type="button"
                  className="btn btn-danger btn-hover-shine me-3 btn">{row.order_status}
                </button>
                :
                row.order_status === "Postponed" ?
                  <button type="button" style={{ color: 'white', borderColor: '#6056d6', backgroundColor: '#6056d6' }}
                    className="btn btn-c btn-hover-shine me-3 btn">{row.order_status}
                  </button>
                :
                row.order_status === "Quoted" ?
                  <button type="button" style={{ borderColor: '#1BD35A', backgroundColor: '#1BD35A' }}
                    className="btn btn-c btn-hover-shine me-3 btn">{row.order_status}
                  </button>
                  :
                  row.order_status === "Completed" ?
                    <button type="button"
                      className="btn btn-success btn-hover-shine me-3 btn">{row.order_status}
                    </button>

                    :
                    row.order_status === "Confirmed" ?
                      <button type="button" style={{ borderColor: '#609FFC', backgroundColor: '#609FFC' }}
                        className="btn btn-info btn-hover-shine me-3 btn">{row.order_status}
                      </button>
                      :
                      row.order_status === "Invoiced" ?
                      <button type="button" style={{ borderColor: '#ebb734', backgroundColor: '#ebb734' }}
                        className="btn btn-info btn-hover-shine me-3 btn">{row.order_status}
                      </button>
                      :
                      <button type="button" style={{ borderColor: '#42b6f5', backgroundColor: '#42b6f5' }}
                        className="btn btn-info btn-hover-shine me-3 btn">{row.order_status}
                      </button>            }
          </div>
        );
      }
    },
    {
      name: t("Pickup Date"),
      selector: row => row.pick_datetime ? moment(row.pick_datetime).format("DD-MM-YYYY HH:mm A") : 'Not Assigned',
    },

    {
      name: t('Actions'),
      width:'15%',
      selector: (row) => {
        return (
          <div>
            <button className="mt-2 mb-2 me-2 btn-icon btn">
              <Tooltips placement="top" title={t("Add Invoice")} arrow>
                <i
                  className="pe-7s-news-paper text-info btn-icon-wrapper fa fa-file-invoice"
                  onClick={() => AddInvoice(row)}
                ></i>
              </Tooltips>
              <Tooltips placement="top" title={t("Order Details")} arrow>
                <i className="pe-7s-note2 text-info btn-icon-wrapper" 
                onClick={() => handleDetails(row) }
                ></i>
              </Tooltips>
              {/* <Tooltip placement="top" title="View PDF" arrow>
                <i onClick={() => handleViewPDF(row._id)} className="pe-7s-look text-primary btn-icon-wrapper"> </i>
              </Tooltip> */}
              <Tooltips placement="top" title={t("Download PDF")} arrow>
                <i onClick={() => handlePDF(row)} className="pe-7s-download text-success btn-icon-wrapper text-info"> </i>
              </Tooltips>

              <Tooltips placement="top" title={t("Send Email")} arrow>
                <i onClick={() => handleMail(row)} className="pe-7s-mail text-primary btn-icon-wrapper"></i>
              </Tooltips>
              <Tooltips placement="top" title={t("Delete")} arrow>
                < i onClick={() => confirmDeleteOrder(row._id, row.user_id)} className="pe-7s-trash text-danger btn-icon-wrapper"> </i>
              </Tooltips>
              <AssignOrderManual data={row} onSuccess={handleSuccess} drivers={drivers} fleets={fleets} />
            </button>
          </div >
        );
      }
    },
  ];
  function confirmDeleteOrder(_id, userId) {
    let msg = t("Once deleted, you will not be able to recover this customer order!")
    Alerts.swalConfirmDeleteAlert(msg).then(res => {
      console.log("Resp:-", res)
      if (res === true) {
        sendDelete(_id, userId)
      }
    })
  };

  function sendDelete(id, user_id) {
    setLoading(true);
    DashboardService.deleteCustomerOrder(id, user_id).then((response) => {
      setLoading(false);
      console.log("Delete Customer order details response:-", response)
      if (response.data.status) {
        fetchCustomerOrders();
        if (response.data.message !== '') {
          Alerts.swalSuccessAlert(response.data.message)
        }
        else {
          Alerts.swalSuccessAlert(t("Customer Order Deleted Successfully."))
        }
        console.log(response.data.message)
      }
      else {
        if (response.data.message !== '') {
          if (response.data.message === "Unauthorized") {
            AuthService.logout();
            navigate("/login");
            window.location.reload();
          }
          else {
            Alerts.swalErrorAlert(response.data.message);
          }
        }
        else {
          Alerts.swalErrorAlert(t("Error: Can't delete the customer pricing at the moment."))
        }
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        Alerts.swalErrorAlert(t("Delete operation failed"))
      })
  };




  function handleMail(row) {
    let msg = t("Are you sure you want to send Order in Email?")
    Alerts.swalConfirmGeneralAlert(msg).then(res => {
      console.log("Resp:-", res)
      if (res === true) {
        sendMail(row)
      }
    })
  };

  function sendMail(row) {
    setLoading(true);
    let tax_percentage = localStorage.getItem("tax_percentage");
    DashboardService.sendOrder_PDF_Mail(row, tax_percentage).then((response) => {
      console.log("Send mail to:-", response)
      setLoading(false);
      if (response.data.status) {
        if (response.data.message !== '') {
          Alerts.swalSuccessAlert(response.data.message)
        }
        else {
          Alerts.swalSuccessAlert(t("Order emailed Successfully."))
        }
        console.log(response.data.message)
      }
      else {
        if (response.data.message !== '') {
          if (response.data.message === "Unauthorized") {
            AuthService.logout();
            navigate("/login");
            window.location.reload();
          }
          else {
            Alerts.swalErrorAlert(response.data.message);
          }
        }
        else {
          Alerts.swalErrorAlert(t("Error: Can't send the Password."))
        }
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        Alerts.swalErrorAlert(t("Operation failed"))
      })
  };
  const downloadPDF = (response) => {
    console.log("PDF Data Length: ", response.data.length);
    console.log("Partial PDF Data: ", response.data.slice(0, 100));
    const pdfBlob = new Blob([response.data], { type: 'application/pdf' });

    // Create a download link and trigger the download
    const url = window.URL.createObjectURL(pdfBlob);
    const downloadLink = document.createElement('a');
    downloadLink.href = url;
    downloadLink.download = 'order.pdf'; // Set the desired file name here.
    downloadLink.style.display = 'none';

    document.body.appendChild(downloadLink);
    downloadLink.click();

    // Clean up by removing the link and revoking the URL
    document.body.removeChild(downloadLink);
    window.URL.revokeObjectURL(url);
  };
  const handlePDF = (row) => {
    var payload = {
      functionName: "Get_PDF_Order",
      postData: {
        "tax_percentage": localStorage.getItem("tax_percentage"),
        "row": row,
      },
    }
    setLoading(true);
    DashboardService.getOrder_PDF(payload).then((response) => {
      console.log(response);
      const pre = document.createElement('pre');
      pre.textContent = response.data;
      document.body.appendChild(pre);
      setLoading(false);
      if (response.status === 200) {

        downloadPDF(response);
      } else if (response.status === 401) {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      } else {
        // Handle other error cases, if necessary
        console.log("Response status: " + response.status);
        // Display an error message or perform other actions as needed.
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent);
        if (errContent) {
          Alerts.swalErrorAlert(errContent);
        } else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."));
        }
      })
  }


  return (
    <Fragment>
      <div className="app-main__outer">
        <div className="app-main__inner">
          <TransitionGroup>
            <CSSTransition component="div" classNames="TabsAnimation"
              appear={true} timeout={0} enter={false} exit={false}>
              <div>
                <div className="app-page-title">
                  <div className="page-title-wrapper">
                    <div className="page-title-heading fw-bold">
                      <div className="page-title-icon">
                        <i className="pe-7s-graph2 icon-gradient bg-tempting-azure" />
                      </div>
                      <div>
                        {t('Dashboard')}
                      </div>
                    </div>
                    <div className="page-title-actions">
                      {/* <a href="/add-email-template" className="AddData">
                        <button type="button" className="btn btn-custom-color btn-hover-shine me-3 btn">{t('Add New')}</button>
                      </a> */}
                    </div>
                  </div>
                </div>
                <Row>
                  <Col md="12">
                    <Card className="mb-3">
                      {/* <CardHeader className="card-header-tab z-index-6">
                        <div className="card-header-title font-size-lg text-capitalize fw-normal">
                          <i className="header-icon lnr-chart-bars icon-gradient bg-happy-green"> {" "} </i>
                          {t('Analytics')}
                        </div>
                      </CardHeader> */}
                      <Row className="g-0">
                        <Col sm="6" md="4" xl="4">
                          <div className="card no-shadow rm-border bg-transparent widget-chart text-start">
                            <div className="icon-wrapper rounded-circle">
                              <div className="icon-wrapper-bg opacity-10 bg-warning" />
                              <i className="lnr-users text-dark opacity-8" />
                            </div>
                            <div className="widget-chart-content">
                              <div className="widget-subheading">{t('Total Customers')}</div>
                              <div className="widget-numbers">{DashboardData.data ? DashboardData.data.count_Customers : ''}</div>
                            </div>
                          </div>
                          <div className="divider m-0 d-md-none d-sm-block" />
                        </Col>
                        <Col sm="6" md="4" xl="4">
                          <div className="card no-shadow rm-border bg-transparent widget-chart text-start">
                            <div className="icon-wrapper rounded-circle">
                              <div className="icon-wrapper-bg opacity-9 bg-danger" />
                              <i className="lnr-gift text-white" />
                            </div>
                            <div className="widget-chart-content">
                              <div className="widget-subheading">{t('Completed Orders')}</div>
                              <div className="widget-numbers">{DashboardData.data ? DashboardData.data.count_Complete_Orders : ''}</div>
                            </div>
                          </div>
                          <div className="divider m-0 d-md-none d-sm-block" />
                        </Col>
                        <Col sm="12" md="4" xl="4">
                          <div className="card no-shadow rm-border bg-transparent widget-chart text-start">
                            <div className="icon-wrapper rounded-circle">
                              <div className="icon-wrapper-bg opacity-9 bg-success" />
                              <i className="lnr-history text-white" />
                            </div>
                            <div className="widget-chart-content">
                              <div className="widget-subheading">{t('Pending Orders')}</div>
                              <div className="widget-numbers">{DashboardData.data ? DashboardData.data.count_Pending_Orders : ''}</div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>



                <Row>
                  <Col md="4">
                  <Card className="main-card mb-3">
                    <CardBody>
                    <Row>
                    <Col md="4">
                    <CardTitle style={{marginTop:'10px', marginBottom:'20px', fontSize:"20px", fontWeight:'700' }} >{t("ORDERS")}</CardTitle>
                    </Col>
                    <Col md="8">
                    <DateRangePickerComponent onDateChange={handleDateChange_Invoice} />
                    </Col >
                    </Row>

                      <Pie data={datapolar} width={667} options={options}  height={270} />
                    </CardBody>
                  </Card>
       </Col>
                  <Col md="8">
                  <Card className="main-card mb-2">
                    <CardBody>
                      <CardTitle style={{marginTop:'10px', marginBottom:'20px', fontSize:"20px", fontWeight:'700' }} >{t('INVOICE & PAYMENTS')}</CardTitle>
                      <ResponsiveContainer width="100%" aspect={4.0 / 2.0}>
                        <BarChart data={InvoicePayments?.data?.monthlyCounts }>
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="month" />
                          <YAxis />
                          <Tooltip />
                          <Legend />
                          <Bar dataKey="Invoices" fill="#545CD7" />
                          <Bar dataKey="Payments" fill="#3BC37D" />
                        </BarChart>
                      </ResponsiveContainer>
                    </CardBody>
                  </Card>
                  </Col>
                  <Col md="12">
                      <Fragment>
                      <CalendarBasic check={true}  Calender={Calender} />
                      </Fragment>
                  </Col>
                  <Col md="12">
                      <Card className="mb-3">
                        <CardHeader className="card-header-tab">
                          <div className="card-header-title font-size-lg text-capitalize fw-normal">
                            {t('Latest Orders')}
                          </div>
                          </CardHeader>
                          <CardBody>
                            <LoadingOverlay tag="div" active={loading}
                                styles = {{overlay: (base) => ({...base})}}
                                spinner = {<DNALoader/>}>
                                <DataTable
                                    columns={mycolumns}
                                    data={orders}
                                    selectableRowDisabled={true}
                                    persistTableHead
                                    pagination
                                    paginationServer
                                    paginationTotalRows={totalRows}
                                    onChangeRowsPerPage={handlePerRowsChange}
                                    // paginationResetDefaultPage={resetPaginationToggle}
                                />
                            </LoadingOverlay>
                          </CardBody>
                      </Card>
                  </Col>
                    </Row>
              </div> 
            </CSSTransition>
          </TransitionGroup>
        </div>
      </div>
    </Fragment> 
  );
};

export default Home;








    
  // return (
  //   <>

  //     <div className="container-fluid">

  //       <div className="row">
  //         <div className="col-sm-12">
  //           <div className="page-title-box">
  //             <div className="float-end">
  //               <ol className="breadcrumb">
  //                 <li className="breadcrumb-item">
  //                   <a href="/">Metrica</a>
  //                 </li>

  //                 <li className="breadcrumb-item">
  //                   <a href="/">Project</a>
  //                 </li>

  //                 <li className="breadcrumb-item active">Dashboard</li>
  //               </ol>
  //             </div>
  //             <h4 className="page-title">Dashboard</h4>
  //           </div>

  //         </div>

  //       </div>

  //       <div className="row">
  //         <div className="col-md-6">
  //           <div className="row justify-content-center">
  //             <div className="col-md-6 col-lg-6">
  //               <div className="card report-card">
  //                 <div className="card-body">
  //                   <div className="row d-flex justify-content-center">
  //                     <div className="col">
  //                       <p className="text-dark mb-1 fw-semibold">
  //                         Projects
  //                       </p>
  //                       <h4 className="my-1">77</h4>
  //                       <p className="mb-0 text-truncate text-muted">
  //                         <span className="text-success">
  //                           <i className="mdi mdi-checkbox-marked-circle-outline me-1" />
  //                         </span>
  //                         26 Project Complete
  //                       </p>
  //                     </div>
  //                     <div className="col-auto align-self-center">
  //                       <div className="bg-light-alt d-flex justify-content-center align-items-center thumb-md  rounded-circle">
  //                         <i
  //                           data-feather="layers"
  //                           className="align-self-center text-muted icon-sm"
  //                         />
  //                       </div>
  //                     </div>
  //                   </div>

  //                 </div>

  //               </div>

  //             </div>

  //             <div className="col-md-6 col-lg-6">
  //               <div className="card report-card">
  //                 <div className="card-body">
  //                   <div className="row d-flex justify-content-center">
  //                     <div className="col">
  //                       <p className="text-dark mb-1 fw-semibold">Tasks</p>
  //                       <h4 className="my-1">41</h4>
  //                       <p className="mb-0 text-truncate text-muted">
  //                         <span className="badge badge-soft-success">
  //                           Active
  //                         </span>{" "}
  //                         Weekly Avg.Sessions
  //                       </p>
  //                     </div>
  //                     <div className="col-auto align-self-center">
  //                       <div className="bg-light-alt d-flex justify-content-center align-items-center thumb-md  rounded-circle">
  //                         <i
  //                           data-feather="check-square"
  //                           className="align-self-center text-muted icon-sm"
  //                         />
  //                       </div>
  //                     </div>
  //                   </div>
  //                 </div>

  //               </div>

  //             </div>

  //           </div>

  //           <div className="row">
  //             <div className="col">
  //               <div className="card">
  //                 <div className="card-header">
  //                   <div className="row align-items-center">
  //                     <div className="col">
  //                       <h4 className="card-title">Today's Tasks</h4>
  //                     </div>

  //                   </div>

  //                 </div>

  //                 <div className="card-body">
  //                   <header className="jumbotron">
                      
  //   <div>
  //     {console.log("content")}
  //     {console.log(content)}
  //   </div>;
  //                   </header>
  //                 </div>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //         <div className="col-md-6">
  //           <div className="card">
  //             <div className="card-body">

  //               <ul className="nav nav-tabs" role="tablist">
  //                 <li className="nav-item">
  //                   <a
  //                     className="nav-link fw-semibold pt-0"
  //                     data-bs-toggle="tab"
  //                     href="#Project1_Tab"
  //                     role="tab"
  //                   >
  //                     Project1
  //                   </a>
  //                 </li>
  //                 <li className="nav-item">
  //                   <a
  //                     className="nav-link active fw-semibold pt-0"
  //                     data-bs-toggle="tab"
  //                     href="#Project2_Tab"
  //                     role="tab"
  //                   >
  //                     Project2
  //                   </a>
  //                 </li>
  //                 <li className="nav-item">
  //                   <a
  //                     className="nav-link fw-semibold pt-0"
  //                     data-bs-toggle="tab"
  //                     href="#Project3_Tab"
  //                     role="tab"
  //                   >
  //                     Project3
  //                   </a>
  //                 </li>
  //               </ul>
  //             </div>

  //             <div className="card-body pt-0">

  //               <div className="tab-content">
  //                 <div
  //                   className="tab-pane"
  //                   id="Project1_Tab"
  //                   role="tabpanel"
  //                 >
  //                   <div className="row">
  //                     <div className="col-md-6">
  //                       <div className="media mb-3">
  //                         <img
  //                           src="assets/images/small/project-3.jpg"
  //                           alt="test"
  //                           className="thumb-md rounded-circle"
  //                         />
  //                         <div className="media-body align-self-center text-truncate ms-3">
  //                           <h4 className="m-0 fw-semibold text-dark font-16">
  //                             Payment App
  //                           </h4>
  //                           <p className="text-muted mb-0 font-13">
  //                             <span className="badge badge-soft-pink fw-semibold">
  //                               <i className="far fa-fw fa-clock" /> 35 days
  //                               left
  //                             </span>
  //                           </p>
  //                         </div>

  //                       </div>
  //                     </div>

  //                     <div className="col-md-6 text-lg-end  mb-2 mb-lg-0">
  //                       <h6 className="fw-semibold m-0">
  //                         Start :{" "}
  //                         <span className="text-muted fw-normal">
  //                           02 June 2021
  //                         </span>
  //                       </h6>
  //                       <h6 className="fw-semibold  mb-0 mt-2">
  //                         Deadline :{" "}
  //                         <span className="text-muted fw-normal">
  //                           {" "}
  //                           31 Oct 2021
  //                         </span>
  //                       </h6>
  //                     </div>

  //                   </div>

  //                   <div className="holder">
  //                     <ul className="steppedprogress pt-1">
  //                       <li className="complete">
  //                         <span>Planing</span>
  //                       </li>
  //                       <li className="complete">
  //                         <span>Design</span>
  //                       </li>
  //                       <li className="complete continuous">
  //                         <span>Development</span>
  //                       </li>
  //                       <li >
  //                         <span>Testing</span>
  //                       </li>
  //                     </ul>
  //                   </div>
  //                   <div className="task-box">
  //                     <div className="task-priority-icon mb-3">
  //                       <i className="fas fa-circle text-success" />
  //                     </div>
  //                     <p className="text-muted mb-1">
  //                       There are many variations of passages of Lorem Ipsum
  //                       available, but the majority have suffered alteration
  //                       in some form.
  //                     </p>
  //                     <p className="text-muted text-end mb-1">
  //                       34% Complete
  //                     </p>
  //                     <div
  //                       className="progress mb-3"
  //                       style={{
  //                         height: "4px"
  //                       }}
  //                     >
  //                       <div
  //                         className="progress-bar bg-warning"
  //                         role="progressbar"
  //                         style={{
  //                           width: "34%"
  //                         }}
  //                         aria-valuenow={34}
  //                         aria-valuemin={0}
  //                         aria-valuemax={100}
  //                       />
  //                     </div>
  //                     <div className="d-flex justify-content-between">
  //                       <div className="img-group">
  //                         <a className="user-avatar" href="/">
  //                           <img
  //                             src="assets/images/users/user-8.jpg"
  //                             alt="user"
  //                             className="thumb-xs rounded-circle"
  //                           />
  //                         </a>
  //                         <a className="user-avatar ms-n3" href="/">
  //                           <img
  //                             src="assets/images/users/user-5.jpg"
  //                             alt="user"
  //                             className="thumb-xs rounded-circle"
  //                           />
  //                         </a>
  //                         <a className="user-avatar ms-n3" href="/">
  //                           <img
  //                             src="assets/images/users/user-4.jpg"
  //                             alt="user"
  //                             className="thumb-xs rounded-circle"
  //                           />
  //                         </a>
  //                         <a className="user-avatar ms-n3" href="/">
  //                           <img
  //                             src="assets/images/users/user-6.jpg"
  //                             alt="user"
  //                             className="thumb-xs rounded-circle"
  //                           />
  //                         </a>
  //                         <a
  //                           href="/"
  //                           className="btn btn-soft-primary btn-icon-circle btn-icon-circle-sm"
  //                         >
  //                           <i className="las la-plus" />6
  //                         </a>
  //                       </div>

  //                       <ul className="list-inline mb-0 align-self-center">
  //                         <li className="list-item d-inline-block me-2">
  //                           <a href="/">
  //                             <i className="mdi mdi-format-list-bulleted text-success font-15" />
  //                             <span className="text-muted fw-bold">
  //                               34/100
  //                             </span>
  //                           </a>
  //                         </li>
  //                         <li className="list-item d-inline-block">
  //                           <a href="/">
  //                             <i className="mdi mdi-comment-outline text-primary font-15" />
  //                             <span className="text-muted fw-bold">3</span>
  //                           </a>
  //                         </li>
  //                         <li className="list-item d-inline-block">
  //                           <a className="ms-2" href="/">
  //                             <i className="mdi mdi-pencil-outline text-muted font-18" />
  //                           </a>
  //                         </li>
  //                         <li className="list-item d-inline-block">
  //                           <a href="/">
  //                             <i className="mdi mdi-trash-can-outline text-muted font-18" />
  //                           </a>
  //                         </li>
  //                       </ul>
  //                     </div>
  //                   </div>

  //                 </div>

  //                 <div
  //                   className="tab-pane active"
  //                   id="Project2_Tab"
  //                   role="tabpanel"
  //                 >
  //                   <div className="row">
  //                     <div className="col-md-6">
  //                       <div className="media mb-3">
  //                         <img
  //                           src="assets/images/small/project-2.jpg"
  //                           alt="test"
  //                           className="thumb-md rounded-circle"
  //                         />
  //                         <div className="media-body align-self-center text-truncate ms-3">
  //                           <h4 className="m-0 fw-semibold text-dark font-16">
  //                             Banking
  //                           </h4>
  //                           <p className="text-muted  mb-0 font-13">
  //                             <span className="badge badge-soft-pink fw-semibold ">
  //                               <i className="far fa-fw fa-clock" /> 35 days
  //                               left
  //                             </span>
  //                           </p>
  //                         </div>

  //                       </div>
  //                     </div>

  //                     <div className="col-md-6 text-lg-end mb-2 mb-lg-0">
  //                       <h6 className="fw-semibold m-0">
  //                         Start :{" "}
  //                         <span className="text-muted fw-normal">
  //                           15 Nov 2021
  //                         </span>
  //                       </h6>
  //                       <h6 className="fw-semibold mb-0 mt-2">
  //                         Deadline :{" "}
  //                         <span className="text-muted fw-normal">
  //                           {" "}
  //                           28 Fab 2021
  //                         </span>
  //                       </h6>
  //                     </div>

  //                   </div>

  //                   <div className="holder">
  //                     <ul className="steppedprogress pt-1">
  //                       <li className="complete">
  //                         <span>Planing</span>
  //                       </li>
  //                       <li className="complete continuous">
  //                         <span>Design</span>
  //                       </li>
  //                       <li >
  //                         <span>Development</span>
  //                       </li>
  //                       <li >
  //                         <span>Testing</span>
  //                       </li>
  //                     </ul>
  //                   </div>
  //                   <div className="task-box">
  //                     <div className="task-priority-icon mb-3">
  //                       <i className="fas fa-circle text-success" />
  //                     </div>
  //                     <p className="text-muted mb-1">
  //                       There are many variations of passages of Lorem Ipsum
  //                       available, but the majority have suffered alteration
  //                       in some form.
  //                     </p>
  //                     <p className="text-muted text-end mb-1">
  //                       15% Complete
  //                     </p>
  //                     <div
  //                       className="progress mb-3"
  //                       style={{
  //                         height: "4px"
  //                       }}
  //                     >
  //                       <div
  //                         className="progress-bar bg-purple"
  //                         role="progressbar"
  //                         style={{
  //                           width: "15%"
  //                         }}
  //                         aria-valuenow={15}
  //                         aria-valuemin={0}
  //                         aria-valuemax={100}
  //                       />
  //                     </div>
  //                     <div className="d-flex justify-content-between">
  //                       <div className="img-group">
  //                         <a className="user-avatar" href="/">
  //                           <img
  //                             src="assets/images/users/user-8.jpg"
  //                             alt="user"
  //                             className="thumb-xs rounded-circle"
  //                           />
  //                         </a>
  //                         <a className="user-avatar ms-n3" href="/">
  //                           <img
  //                             src="assets/images/users/user-5.jpg"
  //                             alt="user"
  //                             className="thumb-xs rounded-circle"
  //                           />
  //                         </a>
  //                         <a className="user-avatar ms-n3" href="/">
  //                           <img
  //                             src="assets/images/users/user-4.jpg"
  //                             alt="user"
  //                             className="thumb-xs rounded-circle"
  //                           />
  //                         </a>
  //                         <a className="user-avatar ms-n3" href="/">
  //                           <img
  //                             src="assets/images/users/user-6.jpg"
  //                             alt="user"
  //                             className="thumb-xs rounded-circle"
  //                           />
  //                         </a>
  //                         <a
  //                           href="/"
  //                           className="btn btn-soft-primary btn-icon-circle btn-icon-circle-sm"
  //                         >
  //                           <i className="las la-plus" />4
  //                         </a>
  //                       </div>

  //                       <ul className="list-inline mb-0 align-self-center">
  //                         <li className="list-item d-inline-block me-2">
  //                           <a href="/">
  //                             <i className="mdi mdi-format-list-bulleted text-success font-15" />
  //                             <span className="text-muted fw-bold">
  //                               15/100
  //                             </span>
  //                           </a>
  //                         </li>
  //                         <li className="list-item d-inline-block">
  //                           <a href="/">
  //                             <i className="mdi mdi-comment-outline text-primary font-15" />
  //                             <span className="text-muted fw-bold">3</span>
  //                           </a>
  //                         </li>
  //                         <li className="list-item d-inline-block">
  //                           <a className="ms-2" href="/">
  //                             <i className="mdi mdi-pencil-outline text-muted font-18" />
  //                           </a>
  //                         </li>
  //                         <li className="list-item d-inline-block">
  //                           <a href="/">
  //                             <i className="mdi mdi-trash-can-outline text-muted font-18" />
  //                           </a>
  //                         </li>
  //                       </ul>
  //                     </div>
  //                   </div>

  //                 </div>

  //                 <div
  //                   className="tab-pane"
  //                   id="Project3_Tab"
  //                   role="tabpanel"
  //                 >
  //                   <div className="row">
  //                     <div className="col-md-6">
  //                       <div className="media mb-3">
  //                         <img
  //                           src="assets/images/small/project-1.jpg"
  //                           alt="test"
  //                           className="thumb-md rounded-circle"
  //                         />
  //                         <div className="media-body align-self-center text-truncate ms-3">
  //                           <h4 className="m-0 fw-semibold text-dark font-16">
  //                             Transfer Money
  //                           </h4>
  //                           <p className="text-muted  mb-0 font-13">
  //                             <span className="badge badge-soft-pink fw-semibold">
  //                               <i className="far fa-fw fa-clock" /> 2 days
  //                               left
  //                             </span>
  //                           </p>
  //                         </div>

  //                       </div>
  //                     </div>

  //                     <div className="col-md-6 text-lg-end  mb-2 mb-lg-0">
  //                       <h6 className="fw-semibold m-0">
  //                         Start :{" "}
  //                         <span className="text-muted fw-normal">
  //                           01 Jan 2021
  //                         </span>
  //                       </h6>
  //                       <h6 className="fw-semibold mb-0 mt-2">
  //                         Deadline :{" "}
  //                         <span className="text-muted fw-normal">
  //                           {" "}
  //                           15 Mar 2021
  //                         </span>
  //                       </h6>
  //                     </div>

  //                   </div>

  //                   <div className="holder">
  //                     <ul className="steppedprogress pt-1">
  //                       <li className="complete">
  //                         <span>Planing</span>
  //                       </li>
  //                       <li className="complete">
  //                         <span>Design</span>
  //                       </li>
  //                       <li className="complete">
  //                         <span>Development</span>
  //                       </li>
  //                       <li className="complete finish">
  //                         <span>Testing</span>
  //                       </li>
  //                     </ul>
  //                   </div>
  //                   <div className="task-box">
  //                     <div className="task-priority-icon mb-3">
  //                       <i className="fas fa-check text-danger" />
  //                     </div>
  //                     <p className="text-muted mb-1">
  //                       There are many variations of passages of Lorem Ipsum
  //                       available, but the majority have suffered alteration
  //                       in some form.
  //                     </p>
  //                     <p className="text-muted text-end mb-1">
  //                       100% Complete
  //                     </p>
  //                     <div
  //                       className="progress mb-3"
  //                       style={{
  //                         height: "4px"
  //                       }}
  //                     >
  //                       <div
  //                         className="progress-bar bg-danger"
  //                         role="progressbar"
  //                         style={{
  //                           width: "100%"
  //                         }}
  //                         aria-valuenow={100}
  //                         aria-valuemin={0}
  //                         aria-valuemax={100}
  //                       />
  //                     </div>
  //                     <div className="d-flex justify-content-between">
  //                       <div className="img-group">
  //                         <a className="user-avatar" href="/">
  //                           <img
  //                             src="assets/images/users/user-8.jpg"
  //                             alt="user"
  //                             className="thumb-xs rounded-circle"
  //                           />
  //                         </a>
  //                         <a className="user-avatar ms-n3" href="/">
  //                           <img
  //                             src="assets/images/users/user-5.jpg"
  //                             alt="user"
  //                             className="thumb-xs rounded-circle"
  //                           />
  //                         </a>
  //                         <a className="user-avatar ms-n3" href="/">
  //                           <img
  //                             src="assets/images/users/user-4.jpg"
  //                             alt="user"
  //                             className="thumb-xs rounded-circle"
  //                           />
  //                         </a>
  //                         <a className="user-avatar ms-n3" href="/">
  //                           <img
  //                             src="assets/images/users/user-6.jpg"
  //                             alt="user"
  //                             className="thumb-xs rounded-circle"
  //                           />
  //                         </a>
  //                         <a
  //                           href="/"
  //                           className="btn btn-soft-primary btn-icon-circle btn-icon-circle-sm"
  //                         >
  //                           <i className="las la-plus" />2
  //                         </a>
  //                       </div>

  //                       <ul className="list-inline mb-0 align-self-center">
  //                         <li className="list-item d-inline-block me-2">
  //                           <a href="/">
  //                             <i className="mdi mdi-format-list-bulleted text-success font-15" />
  //                             <span className="text-muted fw-bold">
  //                               100/100
  //                             </span>
  //                           </a>
  //                         </li>
  //                         <li className="list-item d-inline-block">
  //                           <a href="/">
  //                             <i className="mdi mdi-comment-outline text-primary font-15" />
  //                             <span className="text-muted fw-bold">3</span>
  //                           </a>
  //                         </li>
  //                         <li className="list-item d-inline-block">
  //                           <a className="ms-2" href="/">
  //                             <i className="mdi mdi-pencil-outline text-muted font-18" />
  //                           </a>
  //                         </li>
  //                         <li className="list-item d-inline-block">
  //                           <a href="/">
  //                             <i className="mdi mdi-trash-can-outline text-muted font-18" />
  //                           </a>
  //                         </li>
  //                       </ul>
  //                     </div>
  //                   </div>

  //                 </div>

  //               </div>
  //             </div>

  //           </div>

  //         </div>
  //       </div>
  //       <div className="row">
  //         <div className="col-lg-8">
  //           <div className="card">
  //             <div className="card-header">
  //               <div className="row align-items-center">
  //                 <div className="col">
  //                   <h4 className="card-title">Assigned Tasks</h4>
  //                 </div>

  //                 <div className="col-auto">
  //                   <a href="/" className="text-primary">
  //                     View All
  //                   </a>
  //                 </div>

  //               </div>

  //             </div>

  //             <div className="card-body">
  //               <div className="table-responsive">
  //                 <table className="table table-hover mb-0">
  //                   <thead className="thead-light">
  //                     <tr>
  //                       <th>Task Name</th>
  //                       <th>Name</th>
  //                       <th>Start Date</th>
  //                       <th>Deadline</th>
  //                       <th>Status</th>
  //                     </tr>
  //                   </thead>
  //                   <tbody>
  //                     <tr>
  //                       <td>Product Devlopment</td>
  //                       <td>
  //                         <img
  //                           src="assets/images/users/user-2.jpg"
  //                           alt="test"
  //                           className="thumb-sm rounded me-2"
  //                         />
  //                         Kevin J. Heal
  //                       </td>
  //                       <td>20/3/2021</td>
  //                       <td>5/5/2021</td>
  //                       <td>
  //                         <span className="badge badge-md badge-boxed  badge-soft-success">
  //                           Active
  //                         </span>
  //                       </td>
  //                     </tr>
  //                     <tr>
  //                       <td>New Office Building</td>
  //                       <td>
  //                         <img
  //                           src="assets/images/users/user-3.jpg"
  //                           alt="test"
  //                           className="thumb-sm rounded me-2"
  //                         />
  //                         Frank M. Lyons
  //                       </td>
  //                       <td>11/6/2021</td>
  //                       <td>15/7/2021</td>
  //                       <td>
  //                         <span className="badge badge-md badge-boxed  badge-soft-warning">
  //                           Panding
  //                         </span>
  //                       </td>
  //                     </tr>
  //                     <tr>
  //                       <td>Website & Blog</td>
  //                       <td>
  //                         <img
  //                           src="assets/images/users/user-4.jpg"
  //                           alt="test"
  //                           className="thumb-sm rounded me-2"
  //                         />
  //                         Hyman M. Cross
  //                       </td>
  //                       <td>21/6/2021</td>
  //                       <td>3/7/2021</td>
  //                       <td>
  //                         <span className="badge badge-md badge-boxed  badge-soft-warning">
  //                           Panding
  //                         </span>
  //                       </td>
  //                     </tr>
  //                     <tr>
  //                       <td>Market Research</td>
  //                       <td>
  //                         <img
  //                           src="assets/images/users/user-5.jpg"
  //                           alt="test"
  //                           className="thumb-sm rounded me-2"
  //                         />
  //                         Angelo E. Butler
  //                       </td>
  //                       <td>30/4/2021</td>
  //                       <td>1/6/2021</td>
  //                       <td>
  //                         <span className="badge badge-md badge-boxed  badge-soft-success">
  //                           Active
  //                         </span>
  //                       </td>
  //                     </tr>
  //                     <tr>
  //                       <td>Export Marketing</td>
  //                       <td>
  //                         <img
  //                           src="assets/images/users/user-6.jpg"
  //                           alt="test"
  //                           className="thumb-sm rounded me-2"
  //                         />
  //                         Robert C. Golding
  //                       </td>
  //                       <td>20/3/2021</td>
  //                       <td>5/5/2021</td>
  //                       <td>
  //                         <span className="badge badge-md badge-boxed  badge-soft-success">
  //                           Active
  //                         </span>
  //                       </td>
  //                     </tr>
  //                   </tbody>
  //                 </table>
  //               </div>

  //             </div>

  //           </div>

  //         </div>

  //         <div className="col-lg-4">
  //           <div className="card">
  //             <div className="card-header">
  //               <div className="row align-items-center">
  //                 <div className="col">
  //                   <h4 className="card-title">Tasks Performance</h4>
  //                 </div>

  //                 <div className="col-auto">
  //                   <div className="dropdown">
  //                     <a
  //                       href="#"
  //                       className="btn btn-sm btn-outline-light dropdown-toggle"
  //                       data-bs-toggle="dropdown"
  //                       aria-haspopup="true"
  //                       aria-expanded="false"
  //                     >
  //                       <i className="mdi mdi-dots-horizontal text-muted" />
  //                     </a>
  //                     <div className="dropdown-menu dropdown-menu-end">
  //                       <a className="dropdown-item" href="/">
  //                         Purchases
  //                       </a>
  //                       <a className="dropdown-item" href="/">
  //                         Emails
  //                       </a>
  //                     </div>
  //                   </div>
  //                 </div>

  //               </div>

  //             </div>

  //             <div className="card-body">
  //               <div className="text-center">
  //                 <div id="task_status" className="apex-charts" />
  //                 <h6 className="text-primary bg-soft-primary p-3 mb-0">
  //                   <i
  //                     data-feather="calendar"
  //                     className="align-self-center icon-xs me-1"
  //                   />
  //                   01 January 2021 to 31 June 2021
  //                 </h6>
  //               </div>
  //             </div>

  //           </div>

  //         </div>

  //       </div>

  //       <div className="row">
  //         <div className="col-lg-4">
  //           <div className="card">
  //             <div className="card-header">
  //               <div className="row align-items-center">
  //                 <div className="col">
  //                   <h4 className="card-title">Activity</h4>
  //                 </div>

  //                 <div className="col-auto">
  //                   <div className="dropdown">
  //                     <a
  //                       href="#"
  //                       className="btn btn-sm btn-outline-light dropdown-toggle"
  //                       data-bs-toggle="dropdown"
  //                       aria-haspopup="true"
  //                       aria-expanded="false"
  //                     >
  //                       All
  //                       <i className="las la-angle-down ms-1" />
  //                     </a>
  //                     <div className="dropdown-menu dropdown-menu-end">
  //                       <a className="dropdown-item" href="/">
  //                         Purchases
  //                       </a>
  //                       <a className="dropdown-item" href="/">
  //                         Emails
  //                       </a>
  //                     </div>
  //                   </div>
  //                 </div>

  //               </div>

  //             </div>

  //             <div className="card-body p-0">
  //               <div
  //                 className="p-3"
  //                 style={{
  //                   height: "420px"
  //                 }}
  //                 data-simplebar
  //               >
  //                 <div className="activity">
  //                   <div className="activity-info">
  //                     <div className="icon-info-activity">
  //                       <i className="las la-user-clock bg-soft-primary" />
  //                     </div>
  //                     <div className="activity-info-text">
  //                       <div className="d-flex justify-content-between align-items-center">
  //                         <p className="text-muted mb-0 font-13 w-75">
  //                           <span>Donald</span>
  //                           updated the status of <a href="/">
  //                             Refund #1234
  //                           </a>{" "}
  //                           to awaiting customer response
  //                         </p>
  //                         <small className="text-muted">10 Min ago</small>
  //                       </div>
  //                     </div>
  //                   </div>
  //                   <div className="activity-info">
  //                     <div className="icon-info-activity">
  //                       <i className="mdi mdi-timer-off bg-soft-primary" />
  //                     </div>
  //                     <div className="activity-info-text">
  //                       <div className="d-flex justify-content-between align-items-center">
  //                         <p className="text-muted mb-0 font-13 w-75">
  //                           <span>Lucy Peterson</span>
  //                           was added to the group, group name is{" "}
  //                           <a href="/">Overtake</a>
  //                         </p>
  //                         <small className="text-muted">50 Min ago</small>
  //                       </div>
  //                     </div>
  //                   </div>
  //                   <div className="activity-info">
  //                     <div className="icon-info-activity">
  //                       <img
  //                         src="assets/images/users/user-5.jpg"
  //                         alt="test"
  //                         className="rounded-circle thumb-sm"
  //                       />
  //                     </div>
  //                     <div className="activity-info-text">
  //                       <div className="d-flex justify-content-between align-items-center">
  //                         <p className="text-muted mb-0 font-13 w-75">
  //                           <span>Joseph Rust</span>
  //                           opened new showcase <a href="/">
  //                             Mannat #112233
  //                           </a>{" "}
  //                           with theme market
  //                         </p>
  //                         <small className="text-muted">10 hours ago</small>
  //                       </div>
  //                     </div>
  //                   </div>
  //                   <div className="activity-info">
  //                     <div className="icon-info-activity">
  //                       <i className="mdi mdi-clock-outline bg-soft-primary" />
  //                     </div>
  //                     <div className="activity-info-text">
  //                       <div className="d-flex justify-content-between align-items-center">
  //                         <p className="text-muted mb-0 font-13 w-75">
  //                           <span>Donald</span>
  //                           updated the status of <a href="/">
  //                             Refund #1234
  //                           </a>{" "}
  //                           to awaiting customer response
  //                         </p>
  //                         <small className="text-muted">Yesterday</small>
  //                       </div>
  //                     </div>
  //                   </div>
  //                   <div className="activity-info">
  //                     <div className="icon-info-activity">
  //                       <i className="mdi mdi-alert-outline bg-soft-primary" />
  //                     </div>
  //                     <div className="activity-info-text">
  //                       <div className="d-flex justify-content-between align-items-center">
  //                         <p className="text-muted mb-0 font-13 w-75">
  //                           <span>Lucy Peterson</span>
  //                           was added to the group, group name is{" "}
  //                           <a href="/">Overtake</a>
  //                         </p>
  //                         <small className="text-muted">14 Nov 2021</small>
  //                       </div>
  //                     </div>
  //                   </div>
  //                   <div className="activity-info">
  //                     <div className="icon-info-activity">
  //                       <img
  //                         src="assets/images/users/user-4.jpg"
  //                         alt="test"
  //                         className="rounded-circle thumb-sm"
  //                       />
  //                     </div>
  //                     <div className="activity-info-text">
  //                       <div className="d-flex justify-content-between align-items-center">
  //                         <p className="text-muted mb-0 font-13 w-75">
  //                           <span>Joseph Rust</span>
  //                           opened new showcase <a href="/">
  //                             Mannat #112233
  //                           </a>{" "}
  //                           with theme market
  //                         </p>
  //                         <small className="text-muted">15 Nov 2021</small>
  //                       </div>
  //                     </div>
  //                   </div>
  //                 </div>

  //               </div>

  //             </div>

  //           </div>

  //         </div>

  //         <div className="col-lg-8">
  //           <div className="card">
  //             <div className="card-header">
  //               <div className="row align-items-center">
  //                 <div className="col">
  //                   <h4 className="card-title">All Projects</h4>
  //                 </div>

  //                 <div className="col-auto">
  //                   <a href="/" className="text-primary">
  //                     View All
  //                   </a>
  //                 </div>

  //               </div>

  //             </div>

  //             <div className="card-body">
  //               <div className="table-responsive">
  //                 <table className="table table-hover mb-0">
  //                   <thead className="thead-light">
  //                     <tr>
  //                       <th>Project Name</th>
  //                       <th>Start Date</th>
  //                       <th>Deadline</th>
  //                       <th>Status</th>
  //                       <th>Progress</th>
  //                     </tr>
  //                   </thead>
  //                   <tbody>
  //                     <tr>
  //                       <td>Product Devlopment</td>
  //                       <td>20/3/2021</td>
  //                       <td>5/5/2021</td>
  //                       <td>
  //                         <span className="badge badge-md badge-boxed  badge-soft-success">
  //                           Active
  //                         </span>
  //                       </td>
  //                       <td>
  //                         <small className="float-end ms-2 pt-1 font-10">
  //                           92%
  //                         </small>
  //                         <div
  //                           className="progress mt-2"
  //                           style={{
  //                             height: "3px"
  //                           }}
  //                         >
  //                           <div
  //                             className="progress-bar bg-success"
  //                             role="progressbar"
  //                             style={{
  //                               width: "92%"
  //                             }}
  //                             aria-valuenow={92}
  //                             aria-valuemin={0}
  //                             aria-valuemax={100}
  //                           />
  //                         </div>
  //                       </td>
  //                     </tr>
  //                     <tr>
  //                       <td>New Office Building</td>
  //                       <td>11/6/2021</td>
  //                       <td>15/7/2021</td>
  //                       <td>
  //                         <span className="badge badge-md badge-boxed  badge-soft-warning">
  //                           Panding
  //                         </span>
  //                       </td>
  //                       <td>
  //                         <small className="float-end ms-2 pt-1 font-10">
  //                           0%
  //                         </small>
  //                         <div
  //                           className="progress mt-2"
  //                           style={{
  //                             height: "3px"
  //                           }}
  //                         >
  //                           <div
  //                             className="progress-bar  bg-success"
  //                             role="progressbar"
  //                             style={{
  //                               width: "0%"
  //                             }}
  //                             aria-valuenow={0}
  //                             aria-valuemin={0}
  //                             aria-valuemax={100}
  //                           />
  //                         </div>
  //                       </td>
  //                     </tr>
  //                     <tr>
  //                       <td>Website & Blog</td>
  //                       <td>21/6/2021</td>
  //                       <td>3/7/2021</td>
  //                       <td>
  //                         <span className="badge badge-md badge-boxed  badge-soft-warning">
  //                           Panding
  //                         </span>
  //                       </td>
  //                       <td>
  //                         <small className="float-end ms-2 pt-1 font-10">
  //                           0%
  //                         </small>
  //                         <div
  //                           className="progress mt-2"
  //                           style={{
  //                             height: "3px"
  //                           }}
  //                         >
  //                           <div
  //                             className="progress-bar  bg-success"
  //                             role="progressbar"
  //                             style={{
  //                               width: "0%"
  //                             }}
  //                             aria-valuenow={0}
  //                             aria-valuemin={0}
  //                             aria-valuemax={100}
  //                           />
  //                         </div>

  //                       </td>
  //                     </tr>
  //                     <tr>
  //                       <td>Market Research</td>
  //                       <td>30/4/2021</td>
  //                       <td>1/6/2021</td>
  //                       <td>
  //                         <span className="badge badge-md badge-boxed  badge-soft-success">
  //                           Active
  //                         </span>
  //                       </td>
  //                       <td>
  //                         <small className="float-end ms-2 pt-1 font-10">
  //                           78%
  //                         </small>
  //                         <div
  //                           className="progress mt-2"
  //                           style={{
  //                             height: "3px"
  //                           }}

  //                         >
  //                           <div
  //                             className="progress-bar  bg-success"
  //                             role="progressbar"
  //                             style={{
  //                               width: "78%"
  //                             }}
  //                             aria-valuenow={78}
  //                             aria-valuemin={0}
  //                             aria-valuemax={100}
  //                           />
  //                         </div>
  //                       </td>
  //                     </tr>
  //                     <tr>
  //                       <td>Export Marketing</td>
  //                       <td>20/3/2021</td>
  //                       <td>5/5/2021</td>
  //                       <td>
  //                         <span className="badge badge-md badge-boxed  badge-soft-success">
  //                           Active
  //                         </span>
  //                       </td>
  //                       <td>
  //                         <small className="float-end ms-2 pt-1 font-10">
  //                           45%
  //                         </small>
  //                         <div
  //                           className="progress mt-2"
  //                           style={{
  //                             height: "3px"
  //                           }}
  //                         >
  //                           <div
  //                             className="progress-bar  bg-success"
  //                             role="progressbar"
  //                             style={{
  //                               width: "45%"
  //                             }}
  //                             aria-valuenow={45}
  //                             aria-valuemin={0}
  //                             aria-valuemax={100}
  //                           />
  //                         </div>
  //                       </td>
  //                     </tr>
  //                     <tr>
  //                       <td>Website & Blog</td>
  //                       <td>8/4/2021</td>
  //                       <td>2/6/2021</td>
  //                       <td>
  //                         <span className="badge badge-md badge-boxed  badge-soft-danger">
  //                           Complete
  //                         </span>
  //                       </td>
  //                       <td>
  //                         <small className="float-end ms-2 pt-1 font-10">
  //                           100%
  //                         </small>
  //                         <div
  //                           className="progress mt-2"
  //                           style={{
  //                             height: "3px"
  //                           }}
  //                         >
  //                           <div
  //                             className="progress-bar  bg-pink"
  //                             role="progressbar"
  //                             style={{
  //                               width: "100%"
  //                             }}
  //                             aria-valuenow={100}
  //                             aria-valuemin={0}
  //                             aria-valuemax={100}
  //                           />
  //                         </div>

  //                       </td>
  //                     </tr>
  //                   </tbody>
  //                 </table>
  //               </div>

  //             </div>

  //           </div>

  //         </div>

  //       </div>
  //   </div>
  //   </>
  // );
