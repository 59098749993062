import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import styled from "styled-components";
import CustomerService from "../../../pages/Edsha/Invoices/Invoices.service";
import AuthService from "../../../pages/Authentication/auth.service";
import Alerts from "../../Alerts/alerts";
import { Row, Col } from 'react-bootstrap';
import { Input } from 'reactstrap';

import DateRangePickerComponent from './DateRangePickerComponent'; // Adjust the import path as needed
import { CSVLink } from 'react-csv';
import moment from "moment";
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../../components/Loaders/DnaLoader";
import "../../../assets/custom css/loadoverlay.scss";


const DateInput = styled.input.attrs(props => ({
  type: "date",
  placeholder: "Select Date",
}))`
  height: 32px;
  width: 100%;
  border-radius: 6px;
`;

const Inputs = styled.select.attrs(props => ({
  type: "select",
  size: props.small ? 5 : undefined
}))`
  height: 32px;
  width: 100%;
  border-radius: 6px;
  border-color:#ccc; 
  background: white;
`;

const StatusInput = styled.input.attrs(props => ({
  type: "text",
  placeholder: "Search Status"
}))`
  height: 32px;
  width: 100%;
  border-radius: 6px;
  
`;

const Label = styled.label`
  display: block;
  font-weight: bold;
`;

const FilterContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  gap: 40px;
`;

const FilterRow = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

const CollectionFilter = ({ search_text, onSearch_text, filterText1, filterText2,
  handleKeyDown, onFilter1, onFilter2, onFilterInvoice_StartDate, filterDue_EndDate,
  onFilterInvoice_EndDate, onFilterDue_StartDate, onFilterDue_EndDate,
  searchCustomers, resetFilters,
  filterDue_StartDate, filterInvoice_StartDate, filterInvoice_EndDate }) => {
  const { t, i18n } = useTranslation();
  const [collection, setCollection] = useState([]);
  const csvLinkRef = useRef();
  const [ExportData, setExportData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  let count = 1

  const navigate = useNavigate();
  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    fetchcollections();
  }, []);
  const [selectedDate, setSelectedDate] = useState(null);

  function fetchcollections() {
    var getData = {
      functionName: "GetCustomers",
      postData: {
        "sort": { _id: -1 },
        "offset": 0,
        "limit": 1000,
        "filter": {}
      },
    }
    CustomerService.getAllCustomers(getData).then((response) => {
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        console.log(response.data.data);
        if (response.data) {
          setCollection(response.data.data);
        }
      }
    },
      (error) => {
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  };

  console.log(filterText1);

  const handleDateChange = (startDateFormatted, endDateFormatted) => {
    // Call the callback functions to update the parent component's state
    onFilterDue_StartDate(startDateFormatted);
    onFilterDue_EndDate(endDateFormatted);
  };

  const handleDateChange_Invoice = (startDateFormatted, endDateFormatted) => {
    // Call the callback functions to update the parent component's state
    onFilterInvoice_StartDate(startDateFormatted);
    onFilterInvoice_EndDate(endDateFormatted);
  };

  const fetchRecords = (page_index, page_limit, filterText1, filterText2,
    filterInvoice_StartDate, filterInvoice_EndDate,
    filterDue_StartDate, filterDue_EndDate, search_text) => {
    console.log('fetchCustomers')
    const filter = {};
    if (filterText1) filter["customer_id"] = filterText1;
    if (search_text) filter["search_text"] = search_text;
    if (filterText2) filter["status"] = filterText2;
    if (filterDue_StartDate) filter["filterDue_StartDate"] = filterDue_StartDate;
    if (filterDue_EndDate) filter["filterDue_EndDate"] = filterDue_EndDate;
    if (filterInvoice_StartDate) filter["filterInvoice_StartDate"] = filterInvoice_StartDate;
    if (filterInvoice_EndDate) filter["filterInvoice_EndDate"] = filterInvoice_EndDate;

    const offset = (page_index - 1) * page_limit;
    setLoading(true);

    CustomerService.getAllInvoices(offset, page_limit, filterText1, filterText2,
      filterInvoice_StartDate, filterInvoice_EndDate, filterDue_StartDate, filterDue_EndDate, search_text).then((response) => {
        setLoading(false);
        if (response.data.message === "Unauthorized") {
          AuthService.logout();
          navigate("/login");
          window.location.reload();
        } else {
          console.log("Response", response)
          const CSV_Filtered_Customers = prepareCustomerData(response.data.data);
          setExportData(CSV_Filtered_Customers);
          csvLinkRef.current.link.click();
        }
      }, (error) => {
        setLoading(false);
        const errContent = (error.response && error.response.data) || error.message || error.toString();
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        } else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      });
  };

  const handleExportClick = () => {
    console.log("Clicked")
    fetchRecords(1, 1000, filterText1, filterText2, filterInvoice_StartDate, filterInvoice_EndDate, filterDue_StartDate, filterDue_EndDate, search_text);
  };

  // const prepareCustomerData = (originalData) => {

  //   return originalData.map(row => ({
  //     row,
  //     customer_name: row.user_details ? row.user_details[localStorage.getItem('invoice_to_field')] : ''
  //   }));
  // };

  const prepareCustomerData = (originalData) => {
    return originalData.flatMap(row => {
      // Extract the customer name based on the invoice_to_field
      const customer_name = row.user_details.company_name ? row.user_details.company_name : '';
      const TERMS =  `${localStorage.getItem("due_date")} Days`;
      
      // row.FromSeqNo = count++;
      row.invoice_date = row.invoice_date ? moment(row.invoice_date).format('DD/MM/YYYY') : '';
      row.due_date = row.invoice_date ? moment(row.due_date).format('DD/MM/YYYY') : '';
      let seq = 1;
      // If there are no invoice items, return the row with customer_name
      if (!row.item || row.item.length === 0) {
        return [{
          ...row,
          customer_name: customer_name,
        }];
      }

      // Map over the invoice items and create a new row for each item
      return row.item.map(item => ({
        ...row,
        customer_name: customer_name,
        item_name: item.item_name,
        Description_DTL: item.package,
        Qty: item.quantity,
        UnitPrice: item.rate,
        item_price: item.item_price,
        TaxAmt: item.tax,
        Amount: item.amount,
        item: item,
        TERMS:TERMS,
        SEQ:seq++,
        Project_HDR:'P12W1',
        UOM:'PKG',
        FromSeqNo:count++,
      }));
    });
  };



  const sortedCollection = collection.slice().sort((a, b) => {
    const nameA = a[localStorage.getItem('invoice_to_field')]?.toUpperCase();
    const nameB = b[localStorage.getItem('invoice_to_field')]?.toUpperCase();
    return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
  });

  const handleHover = () => {
    setIsHovered(!isHovered);
  };

  const headers = [
    { label: "DocDate", key: "invoice_date" },
    { label: "DocNo(20)", key: "invoice_number" },
    { label: "Code(10)", key: "booking_code" },
    { label: "CompanyName(100)", key: "customer_name" },
    { label: "Agent(10)", key: "" },
    { label: "TERMS(10)", key: "TERMS" },
    { label: "Description_HDR", key: "" },
    { label: "Project_HDR(20)", key: "Project_HDR" },
    { label: "CC(200)", key: "" },
    { label: "SEQ", key: "SEQ" },
    { label: "ACCOUNT(10)", key: "" },
    { label: "ItemCode(30)", key: "item_name" }, //SHOULD BE ITEM CODE
    { label: "Description_DTL(200)", key: "Description_DTL" },
    { label: "Qty", key: "Qty" },
    { label: "UOM(10)", key: "UOM" },
    { label: "UnitPrice", key: "UnitPrice" },
    { label: "DISC(20)", key: "discount_amount" },
    { label: "Tax(10)", key: "" },
    { label: "TaxInclusive", key: "" },
    { label: "TaxAmt", key: "TaxAmt" },
    { label: "Amount", key: "Amount" },
    { label: "Location(20)", key: "" },
    { label: "Batch(30)", key: "" },
    { label: "Project_DTL(20)", key: "" },
    { label: "Remarks1(200)", key: "remarks" },
    { label: "Remarks2(200)", key: "remarks" },
    { label: "FromDocType(2)", key: "" },
    { label: "FromDocNo(20)", key: "" },    
    // { label: "Status", key: "status" },
    { label: "FromSeqNo", key: "FromSeqNo" },
  ];

  return (
    <>
      <LoadingOverlay tag="div" active={loading}
        styles={{ overlay: (base) => ({ ...base }) }}
        spinner={<DNALoader />}>
        <Row style={{ marginLeft: '10px', marginTop: '20px', marginBottom: '20px' }}>
          <Col xs="24" sm="12" md="6" lg="4" xl="3">
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Input style={{ width: "48%" }} type="text" name="fname" value={search_text} placeholder={t("Search Text")} onChange={onSearch_text}></Input>

              <Input
                type="select"
                style={{ borderColor: '#ccc', width: "48%" }}
                placeholder={t("Search Status")}
                value={filterText2}
                onChange={onFilter2}
                onKeyDown={handleKeyDown}
                id="colfilter"
              >

                <option disabled={false} value="">Select Status</option>
                <option key='generated' value='generated'>Generated</option>
                <option key='paid' value='paid'>Paid</option>
                <option key='partial paid' value='partial paid'>Partial Paid</option>
                <option key='unpaid' value='unpaid'>Unpaid</option>
                <option key='void' value='void'>Void</option>
                <option key='cancelled' value='cancelled'>Cancelled</option>
              </Input>
            </div>

          </Col>

          <Col xs="24" sm="12" md="12" lg="8" xl="6">
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{
                background: '#F9F9FB',
                height: '35px',
                width: '48%',
                border: '1px solid #ccc',
                borderRadius: '6px',
                marginBottom: '20px',
                display: 'flex',
                alignItems: 'center'
              }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Label style={{ background: '#F9F9FB', paddingRight: '10px' }}>
                    {t('Invoice')}
                  </Label>
                  <div className="d-flex justify-content-end align-items-center h-100 pr-2">
                    <div style={{ borderRight: '2px solid #ccc', height: '4%' }}></div>
                  </div>
                  {/* DateRangePickerComponent for Invoice Date */}
                  <DateRangePickerComponent onDateChange={handleDateChange_Invoice} />
                </div>
              </div>

              <div style={{
                background: '#F9F9FB',
                height: '35px',
                width: '48%',
                border: '1px solid #ccc',
                borderRadius: '6px',
                marginBottom: '20px',
                display: 'flex',
                alignItems: 'center'
              }} className="mb-2">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Label style={{ background: '#F9F9FB', paddingRight: '5px', paddingLeft: '5px' }}>
                    {t(` Due `)}
                  </Label>
                  <div className="d-flex justify-content-end align-items-center h-100 pr-2">
                    <div style={{ borderRight: '2px solid #ccc', height: '4%' }}></div>
                  </div>
                  {/* DateRangePickerComponent for Order Date */}
                  <DateRangePickerComponent onDateChange={handleDateChange} />
                </div>
              </div>
            </div>

          </Col>


          <Col xs="12" sm="6" md="6" lg="4" xl="3" className="mb-2">
            <button type="button" className="btn btn-custom-color btn-hover-shine " onClick={() => searchCustomers()}>Search</button>
            <button type="button" className="btn btn-custom-color btn-hover-shine ms-2" onClick={() => resetFilters()}>Reset</button>
            <button type="button" className="btn btn-custom-color btn-hover-shine ms-2" onClick={() => navigate('/add-invoice')}>Add</button>
            <button
              type="button"
              className="btn btn-custom-color btn-hover-shine ms-2"
              onClick={handleExportClick}
              onMouseEnter={handleHover}
              onMouseLeave={handleHover}
            >
              {t("Export")}
            </button>
            <CSVLink
              headers={headers}
              data={ExportData}

              ref={csvLinkRef}
              filename={"Invoices.csv"}
              style={{ display: 'none' }}
            />

          </Col>
          <Col xs="24" sm="12" md="6" lg="4" xl="3">
            <Input
              type="select"
              placeholder={t("Search Customer")}
              value={filterText1}
              onChange={onFilter1}
              onKeyDown={handleKeyDown}
              id="colfilter"
            >
              <option disabled={false} value="">--{t("Select Customer")}--</option>
              {collection &&
                  collection.map((opt_collection, index) => (
                    <option key={index} value={opt_collection._id}>{opt_collection.company_name}</option>
                  ))
                }
            </Input>
          </Col>
        </Row>
      </LoadingOverlay>
    </>

  );
};

export default CollectionFilter;


////////////////////////////////////////////////////////////////////////
// import React, { useEffect, useState, useRef } from "react";
// import { useNavigate } from "react-router-dom";
// import { useTranslation } from 'react-i18next';
// import styled from "styled-components";
// import CustomerService from "../../../pages/Edsha/Invoices/Invoices.service";
// import AuthService from "../../../pages/Authentication/auth.service";
// import Alerts from "../../Alerts/alerts";
// import { Row, Col } from 'react-bootstrap';
// import { Input } from 'reactstrap';
// import LoadingOverlay from "react-loading-overlay-ts";
// import DNALoader from "../../../components/Loaders/DnaLoader";
// import DateRangePickerComponent from './DateRangePickerComponent';
// import moment from 'moment';
// import { saveAs } from 'file-saver';
// import * as XLSX from 'xlsx';

// const DateInput = styled.input.attrs(props => ({
//     type: "date",
//     placeholder: "Select Date",
// }))`
//     height: 32px;
//     width: 100%;
//     border-radius: 6px;
// `;

// const Inputs = styled.select.attrs(props => ({
//     type: "select",
//     size: props.small ? 5 : undefined
// }))`
//     height: 32px;
//     width: 100%;
//     border-radius: 6px;
//     border-color: #ccc;
//     background: white;
// `;

// const StatusInput = styled.input.attrs(props => ({
//     type: "text",
//     placeholder: "Search Status"
// }))`
//     height: 32px;
//     width: 100%;
//     border-radius: 6px;
// `;

// const Label = styled.label`
//     display: block;
//     font-weight: bold;
// `;

// const FilterContainer = styled.div`
//     display: flex;
//     flex-wrap: wrap;
//     margin-top: 10px;
//     gap: 40px;
// `;

// const FilterRow = styled.div`
//     flex: 1;
//     display: flex;
//     align-items: center;
// `;

// const CollectionFilter = ({ search_text, onSearch_text, filterText1, filterText2,
//     handleKeyDown, onFilter1, onFilter2, onFilterInvoice_StartDate, filterDue_EndDate,
//     onFilterInvoice_EndDate, onFilterDue_StartDate, onFilterDue_EndDate,
//     searchCustomers, resetFilters,
//     filterDue_StartDate, filterInvoice_StartDate, filterInvoice_EndDate }) => {
//     const { t, i18n } = useTranslation();
//     const [collection, setCollection] = useState([]);
//     const csvLinkRef = useRef();
//     const [ExportData, setExportData] = useState([]);
//     const [loading, setLoading] = useState(false);
//     const [isHovered, setIsHovered] = useState(false);

//     const navigate = useNavigate();

//     useEffect(() => {
//         i18n.changeLanguage(localStorage.getItem('language'));
//         fetchcollections();
//     }, []);

//     function fetchcollections() {
//         var getData = {
//             functionName: "GetCustomers",
//             postData: {
//                 "sort": { _id: -1 },
//                 "offset": 0,
//                 "limit": 1000,
//                 "filter": {}
//             },
//         }
//         CustomerService.getAllCustomers(getData).then((response) => {
//             if (response.data.message === "Unauthorized") {
//                 AuthService.logout();
//                 navigate("/login");
//                 window.location.reload();
//             }
//             else {
//                 console.log(response.data.data);
//                 if (response.data) {
//                     setCollection(response.data.data);
//                 }
//             }
//         },
//             (error) => {
//                 const errContent =
//                     (error.response && error.response.data) || error.message || error.toString();
//                 if (errContent) {
//                     Alerts.swalErrorAlert(errContent)
//                 }
//                 else {
//                     Alerts.swalErrorAlert(t("Server Error, Please try again later."))
//                 }
//             })
//     };

//     const handleDateChange = (startDateFormatted, endDateFormatted) => {
//         onFilterDue_StartDate(startDateFormatted);
//         onFilterDue_EndDate(endDateFormatted);
//     };

//     const handleDateChange_Invoice = (startDateFormatted, endDateFormatted) => {
//         onFilterInvoice_StartDate(startDateFormatted);
//         onFilterInvoice_EndDate(endDateFormatted);
//     };

//     const fetchRecords = (page_index, page_limit, filterText1, filterText2,
//         filterInvoice_StartDate, filterInvoice_EndDate,
//         filterDue_StartDate, filterDue_EndDate, search_text) => {
//         const filter = {};
//         if (filterText1) filter["customer_id"] = filterText1;
//         if (search_text) filter["search_text"] = search_text;
//         if (filterText2) filter["status"] = filterText2;
//         if (filterDue_StartDate) filter["filterDue_StartDate"] = filterDue_StartDate;
//         if (filterDue_EndDate) filter["filterDue_EndDate"] = filterDue_EndDate;
//         if (filterInvoice_StartDate) filter["filterInvoice_StartDate"] = filterInvoice_StartDate;
//         if (filterInvoice_EndDate) filter["filterInvoice_EndDate"] = filterInvoice_EndDate;

//         const offset = (page_index - 1) * page_limit;
//         setLoading(true);

//         CustomerService.getAllInvoices(offset, page_limit, filterText1, filterText2,
//             filterInvoice_StartDate, filterInvoice_EndDate, filterDue_StartDate, filterDue_EndDate, search_text).then((response) => {
//                 setLoading(false);
//                 if (response.data.message === "Unauthorized") {
//                     AuthService.logout();
//                     navigate("/login");
//                     window.location.reload();
//                 } else {
//                     console.log("Response", response)
//                     const XLSX_Filtered_Customers = prepareCustomerData(response.data.data);
//                     setExportData(XLSX_Filtered_Customers);
//                     // csvLinkRef.current.link.click();
//                     ExportClick(XLSX_Filtered_Customers); // Directly trigger export as XLSX
//                 }
//             }, (error) => {
//                 setLoading(false);
//                 const errContent = (error.response && error.response.data) || error.message || error.toString();
//                 if (errContent) {
//                     Alerts.swalErrorAlert(errContent)
//                 } else {
//                     Alerts.swalErrorAlert(t("Server Error, Please try again later."))
//                 }
//             });
//     };

//     const handleExportClick = () => {
//     fetchRecords(1, 99999, filterText1, filterText2, filterInvoice_StartDate, filterInvoice_EndDate, filterDue_StartDate, filterDue_EndDate, search_text);
       
//     };


//     const ExportClick = (XLSX_Filtered_Customers) => {
//         const worksheet = XLSX.utils.json_to_sheet(XLSX_Filtered_Customers);
//         const workbook = XLSX.utils.book_new();
//         XLSX.utils.book_append_sheet(workbook, worksheet, "Invoices");
//         const xlsxBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
//         const blob = new Blob([xlsxBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
//         saveAs(blob, 'Invoices.xlsx');
//     };

//     const prepareCustomerData = (originalData) => {
//         return originalData.flatMap(row => {
//             const customer_name = row.user_details.company_name ? row.user_details.company_name : '';
//             let count = 0;
//             row.FromSeqNo = count++;
//             row.invoice_date = row.invoice_date ? moment(row.invoice_date).format('DD-MM-YYYY') : '';
//             row.due_date = row.invoice_date ? moment(row.due_date).format('DD-MM-YYYY') : '';

//             if (!row.item || row.item.length === 0) {
//                 return [{
//                     ...row,
//                     customer_name: customer_name,
//                 }];
//             }

//             return row.item.map(item => ({
//                 ...row,
//                 customer_name: customer_name,
//                 item_name: item.item_name,
//                 Description_DTL: item.package,
//                 Qty: item.quantity,
//                 UnitPrice: item.rate,
//                 item_price: item.item_price,
//                 TaxAmt: item.tax,
//                 Amount: item.amount,
//                 item: item,
//             }));
//         });
//     };

//     const sortedCollection = collection.slice().sort((a, b) => {
//         const nameA = a[localStorage.getItem('invoice_to_field')]?.toUpperCase();
//         const nameB = b[localStorage.getItem('invoice_to_field')]?.toUpperCase();
//         return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
//     });

//     const handleHover = () => {
//         setIsHovered(!isHovered);
//     };

//     const headers = [
//         { label: "DocDate", key: "invoice_date" },
//         { label: "DocNo", key: "invoice_number" },
//         { label: "Code", key: "booking_code" },
//         { label: "CompanyName", key: "customer_name" },
//         { label: "TERMS", key: "comments" },
//         { label: "ItemCode", key: "item_name" },
//         { label: "Description_DTL", key: "Description_DTL" },
//         { label: "Qty", key: "Qty" },
//         { label: "UnitPrice", key: "UnitPrice" },
//         { label: "TaxAmt", key: "TaxAmt" },
//         { label: "Amount", key: "Amount" },
//         { label: "Remarks", key: "remarks" },
//         { label: "Currency", key: "currency" },
//         { label: "DueDate", key: "due_date" },
//         { label: "Status", key: "status" },
//         { label: "FromSeqNo", key: "FromSeqNo" },
//     ];

//     return (
//         <>
//             <LoadingOverlay tag="div" active={loading}
//                 styles={{ overlay: (base) => ({ ...base }) }}
//                 spinner={<DNALoader />}>
//                 <Row style={{ marginLeft: '10px', marginTop: '20px', marginBottom: '20px' }}>
//                     <Col xs="24" sm="12" md="6" lg="4" xl="3">
//                         <div style={{ display: 'flex', justifyContent: 'space-between' }}>
//                             <Input style={{ width: "48%" }} type="text" name="fname" value={search_text} placeholder={t("Search Text")} onChange={onSearch_text}></Input>

//                             <Input
//                                 type="select"
//                                 style={{ borderColor: '#ccc', width: "48%" }}
//                                 placeholder={t("Search Status")}
//                                 value={filterText2}
//                                 onChange={onFilter2}
//                                 onKeyDown={handleKeyDown}
//                                 id="colfilter"
//                             >
//                                 <option disabled={false} value="">Select Status</option>
//                                 <option key='generated' value='generated'>Generated</option>
//                                 <option key='paid' value='paid'>Paid</option>
//                                 <option key='partial paid' value='partial paid'>Partial Paid</option>
//                                 <option key='unpaid' value='unpaid'>Unpaid</option>
//                                 <option key='void' value='void'>Void</option>
//                                 <option key='cancelled' value='cancelled'>Cancelled</option>
//                             </Input>
//                         </div>
//                     </Col>

//                     <Col xs="24" sm="12" md="12" lg="8" xl="6">
//                         <div style={{ display: 'flex', justifyContent: 'space-between' }}>
//                             <div style={{
//                                 background: '#F9F9FB',
//                                 height: '35px',
//                                 width: '48%',
//                                 border: '1px solid #ccc',
//                                 borderRadius: '6px',
//                                 marginBottom: '20px',
//                                 display: 'flex',
//                                 alignItems: 'center'
//                             }}>
//                                 <div style={{ display: 'flex', alignItems: 'center' }}>
//                                     <Label style={{ background: '#F9F9FB', paddingRight: '10px' }}>
//                                         {t('Invoice')}
//                                     </Label>
//                                     <div className="d-flex justify-content-end align-items-center h-100 pr-2">
//                                         <div style={{ borderRight: '2px solid #ccc', height: '4%' }}></div>
//                                     </div>
//                                     <DateRangePickerComponent onDateChange={handleDateChange_Invoice} />
//                                 </div>
//                             </div>

//                             <div style={{
//                                 background: '#F9F9FB',
//                                 height: '35px',
//                                 width: '48%',
//                                 border: '1px solid #ccc',
//                                 borderRadius: '6px',
//                                 marginBottom: '20px',
//                                 display: 'flex',
//                                 alignItems: 'center'
//                             }} className="mb-2">
//                                 <div style={{ display: 'flex', alignItems: 'center' }}>
//                                     <Label style={{ background: '#F9F9FB', paddingRight: '5px', paddingLeft: '5px' }}>
//                                         {t(` Due `)}
//                                     </Label>
//                                     <div className="d-flex justify-content-end align-items-center h-100 pr-2">
//                                         <div style={{ borderRight: '2px solid #ccc', height: '4%' }}></div>
//                                     </div>
//                                     <DateRangePickerComponent onDateChange={handleDateChange} />
//                                 </div>
//                             </div>
//                         </div>
//                     </Col>

//                     <Col xs="12" sm="6" md="6" lg="4" xl="3" className="mb-2">
//                         <button type="button" className="btn btn-custom-color btn-hover-shine " onClick={() => searchCustomers()}>Search</button>
//                         <button type="button" className="btn btn-custom-color btn-hover-shine ms-2" onClick={() => resetFilters()}>Reset</button>
//                         <button type="button" className="btn btn-custom-color btn-hover-shine ms-2" onClick={() => navigate('/add-invoice')}>Add</button>
//                         <button
//                             type="button"
//                             className="btn btn-custom-color btn-hover-shine ms-2"
//                             onClick={handleExportClick}
//                             onMouseEnter={handleHover}
//                             onMouseLeave={handleHover}
//                         >
//                             {t("Export")}
//                         </button>
//                     </Col>

//                     <Col xs="24" sm="12" md="6" lg="4" xl="3">
//                         <Input
//                             type="select"
//                             placeholder={t("Search Customer")}
//                             value={filterText1}
//                             onChange={onFilter1}
//                             onKeyDown={handleKeyDown}
//                             id="colfilter"
//                         >
//                             <option disabled={false} value="">--{t("Select Customer")}--</option>
//                             {collection &&
//                                 sortedCollection.map((opt_collection, index) => (
//                                     <option key={index} value={opt_collection._id}>{opt_collection[localStorage.getItem('invoice_to_field')]}</option>
//                                 ))
//                             }
//                         </Input>
//                     </Col>
//                 </Row>
//             </LoadingOverlay>
//         </>
//     );
// };

// export default CollectionFilter;