import { combineReducers } from '@reduxjs/toolkit';
import userReducer from './userReducer';
import ThemeOptions from "./ThemeOptions";


const reducer = combineReducers({
  user: userReducer,
  ThemeOptions:ThemeOptions
});


export default reducer;
