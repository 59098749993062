import React, {useEffect} from "react";
import { useTranslation } from 'react-i18next';
import styled from "styled-components";

const Input = styled.input.attrs(props => ({
  type: "text",
  size: props.small ? 5 : undefined
}))`
  height: 32px;
  width: 16%;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  
  padding: 0 8px 0 8px;
  margin-bottom: 12px;
  justify-content: flex-start;
`;

const ClearButton = styled.button`
  border:none;
  height: 32px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  margin-bottom: 12px;
  border-radius: 0 5px 5px 0;
  color: white;
  background: var(
`;

const ModuleFilter = ({ filterText2, handleKeyDown, onFilter2, onClear }) => {

  const { t, i18n } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
  }, []);
  
  return(
    <>
      <Input
        type="text"
        placeholder={t("Filter by module")}
        value={filterText2}
        onChange={onFilter2}
        onKeyDown={handleKeyDown}
        id="modfilter"
      />
      <ClearButton onClick={onClear}>X</ClearButton>
    </>
      
  );
  
};

export default ModuleFilter;


