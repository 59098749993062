import React, { useEffect, useState, Fragment, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import DataTable from 'react-data-table-component';
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Input,
  Modal,
  ModalHeader,
  Button,
  ModalBody,
  Label,
} from "reactstrap";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

// import Form from "react-validation/build/form";
import { Form, Field } from "react-final-form";
import moment from 'moment';
import Tooltip from '@mui/material/Tooltip';

import MultiStep from "../Customer Wizards/Wizard";

import ServicesService from "../../Services Management/services.service";
import AuthService from "../../../Authentication/auth.service";
import { useTranslation } from 'react-i18next';

import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../../../components/Loaders/DnaLoader";
import "../../../../assets/custom css/loadoverlay.scss";
import Alerts from "../../../../components/Alerts/alerts";

import CustomerService from "../Customer.service";

import OrderItems from "./Customer Order Details/OrderItems";
import OrderDetails from "./Customer Order Details/OrderDetails";
import OrderMedia from "./Customer Order Details/OrderMedia";
import AssignOrderManual from "../Customer Modals/Customer Order Modals/AssignOrderManual";
import FleetService from "../../Fleet Management/fleet.service";
import DriverService from "../../Drivers/Driver.service";
import AlertService from "../../../../components/Alerts/alerts";



const CustomerOrders = () => {

  const navigate = useNavigate();
  const form = useRef();
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const { state } = location;
  console.log("Received Object:-", state);
  const [orders, setOrders] = useState([]);
  const [rows, setrows] = useState([]);
  const [services, setServices] = useState([]);
  const [fleets, setFleets] = useState([]);
  const [packagings, setPackagings] = useState([]);
  // const [packagingSizes, setPackagingSizes] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [param, setParam] = useState("allorders");
  const [singleOrderdata, setSingleOrderData] = useState("");
  const [modelopen, setModelopen] = useState(false);
  const [updateParam, setupdateParam] = useState("false");
  const [switchtoggle, setSwitchtoggle] = useState(false);
  const [myindex, setMyIndex] = useState([]);
  const [orderDetails, setOrderDetails] = useState([]);
  const [Remarks, SetRemarks] = useState("");
  const [Contacts, setContacts] = useState([]);
  const [EmailRow, setEmailRow] = useState();
  const [modal1, setModal1] = useState(false);
  const [consignment, SetConsignment] = useState();
  const [po_no, setPo_no] = useState();

  const [fservice_id, setfservice_id] = useState("");
  const [fpackaging_id, setfpackaging_id] = useState("");
  // const [fpackaging_size_id, setfpackaging_size_id] = useState("");
  const [myDataPopObj, setmyDataPopObj] = useState([]);
  const [SinglePriceObj, setSinglePriceObj] = useState([]);
  const [fquantity, setfquantity] = useState("");
  const [fweight, setfweight] = useState("");

  const [priceidx, setpriceidx] = useState(0);


  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    setViewFtn('allorders', "reload")
    getPackaging();
    // getPackagingSizes();
    getFleets();
    getServices();
    getDrivers();
    setOrderDetails([]);
  }, []);

  useEffect(() => {
    if (orderDetails[priceidx]) {
      if (orderDetails[priceidx].packaging
        // && orderDetails[priceidx].packagingsize
        && orderDetails[priceidx].services) {
        SearchPrice();
      }

    }
  }, [fservice_id, fpackaging_id
    // , fpackaging_size_id
  ]);


  function setViewFtn(param, data) {
    setParam(param);
    if (param === 'allorders') {
      setSingleOrderData("");
      setOrderDetails([]);
      if (data === "reload") {
        fetchCustomerOrders();
      }
    }
    if (param === 'orderdetails') {
      setSingleOrderData(data)
    }
    else {
      setSingleOrderData("")
    }
    setmyDataPopObj([]);
    setSinglePriceObj([]);
    setfquantity("");
    setfweight("");
    setfservice_id("");
    setfpackaging_id("");
    // setfpackaging_size_id("");
    setrows([]);
    addRow1();
  }

  function SearchPrice() {
    var getData = {
      functionName: "GetPricing",
      postData: {
        "user_id": state._id,
        "service_id": orderDetails[priceidx].services,
        "packaging_id": orderDetails[priceidx].packaging,
        // "packaging_size_id": orderDetails[priceidx].packagingsize
      },
    }
    setLoading(true);
    CustomerService.getCustomerPricing(getData).then((response) => {
      setLoading(false);
      console.log("Get customer pricing response:-", response)
      if (response.data.status) {
        var updatedPopObj = [...myDataPopObj];
        var singlePriceObj = [...SinglePriceObj];
        if (response.data.data.length !== 0) {
          // var popDataObj = {
          //   [`price${ind}`]: response.data.data[0]?.price
          // };
          singlePriceObj[priceidx] = parseFloat(response.data.data[0]?.price).toFixed(2);
          updatedPopObj[priceidx] = (parseFloat((isNaN(orderDetails[priceidx].quantity) ? 1 : orderDetails[priceidx].quantity) * (isNaN(orderDetails[priceidx].weight) ? 1 : orderDetails[priceidx].weight) * (response.data.data[0]?.price)).toFixed(2));
          setmyDataPopObj(updatedPopObj);
          setSinglePriceObj(singlePriceObj);

          // setfservice_id("");
          // setfpackaging_id("");
          // setfpackaging_size_id("");
          console.log("Final Obj:-", updatedPopObj)
          console.log("single Price Obj:-", singlePriceObj)
          // setmyDataPopObj("");
        }
        else {
          // setfservice_id("");
          // setfpackaging_id("");
          // setfpackaging_size_id("");
          singlePriceObj[priceidx] = ("");
          updatedPopObj[priceidx] = ("");
          setSinglePriceObj(singlePriceObj);
          setmyDataPopObj(updatedPopObj);
        }

      }
      else {
        if (response.data.message === "Unauthorized") {
          AuthService.logout();
          navigate("/login");
          window.location.reload();
        }
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent)
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  }


  function toggleModal() {
    setModelopen(!modelopen)
    setSwitchtoggle(false)
  }

  function addRow1() {
    setrows(1);
  };

  function getServices() {
    var getData = {
      functionName: "getCustomerServices",
      postData: {
        "sort": { _id: -1 },
        "offset": 0,
        "limit": 1000,
        "filter": {},
        customer_id: state._id

      },
    }
    ServicesService.getAllServices(getData).then((response) => {
      console.log("Get Services response:-", response);
      setServices(response.data.data)
    },
      (error) => {
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent)
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert("Server Error, Please try again later.")
        }
      })
  }

  function getPackaging() {
    setLoading(true);
    var getData = {
      functionName: "GetCustomerPackaging",
      postData: {
        "sort": { _id: -1 },
        "offset": 0,
        "limit": 1000,
        "filter": {},
        customer_id: state._id

      },
    }
    ServicesService.GetCustomerPackaging(getData).then((response) => {
      setLoading(false);
      console.log("Get Packagings response:-", response)
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        setPackagings(response.data.data);
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent)
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  };

  // function getPackagingSizes() {
  //   setLoading(true);
  //   ServicesService.getPackagingSizes().then((response) => {
  //     setLoading(false);
  //     console.log("Get PackagingSizes response:-", response)
  //     if (response.data.message === "Unauthorized") {
  //       AuthService.logout();
  //       navigate("/login");
  //       window.location.reload();
  //     }
  //     else {
  //       setPackagingSizes(response.data.data);
  //     }
  //   },
  //     (error) => {
  //       setLoading(false);
  //       const errContent =
  //         (error.response && error.response.data) || error.message || error.toString();
  //       console.log("Net:-", errContent)
  //       if (errContent) {
  //         Alerts.swalErrorAlert(errContent)
  //       }
  //       else {
  //         Alerts.swalErrorAlert(t("Server Error, Please try again later."))
  //       }
  //     })
  // };

  function getFleets() {
    var getData = {
      functionName: "GetFleets",
      postData: {
      },
    }
    setLoading(true);
    FleetService.getAllFleets(getData).then((response) => {
      setLoading(false);
      console.log("Get Fleets response:-", response)
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        setFleets(response.data.data);
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent)
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  };

  function getDrivers() {
    var getData = {
      functionName: "GetDrivers",
      postData: {
      },
    }
    setLoading(true);
    DriverService.getAllDrivers(getData).then((response) => {
      setLoading(false);
      console.log("Get Drivers response:-", response)
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        setDrivers(response.data.data);
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent)
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  };

  function addRow() {
    if (orderDetails[priceidx] === undefined) {
      AlertService.swalErrorAlert("Please Fill Existing Rows First")
    } else if (orderDetails[priceidx]) {
      if (!orderDetails[priceidx].packaging
        // || !orderDetails[priceidx].packagingsize 
        || !orderDetails[priceidx].services || !orderDetails[priceidx].quantity) {
        AlertService.swalErrorAlert("Please Fill existing Rows First")
      } else {
        var saddRow = rows;
        saddRow = saddRow + 1;
        setrows(saddRow);

      }
    } else {
      var saddRow = rows;
      saddRow = saddRow + 1;
      setrows(saddRow);
    }
  }

  function remove_option(index) {
    if (index !== 0) {
      document.getElementById('parent' + index).remove();
    }
  }

  const AddInvoice = (row) => {
    if (row.invoice_status === 'true') {
      AlertService.swalErrorAlert("Invoice Already Exists.");
    } else {
      const modifiedRow = { ...row, check: '4' };
      navigate('/add-invoice', { state: modifiedRow });
    }
  }

  const handleSubmit = (values) => {
    var formvalid0 = 'false';
    var formvalid1 = 'false';
    // var formvalid2 = 'false';
    var formvalid3 = 'false';
    var formvalid4 = 'false';
    var formvalid5 = 'false';
    var formvalid6 = 'false';
    const elements0 = Array.from(
      document.getElementsByClassName('serviceclass')
    );
    var services_ids = [];
    for (let i = 0; i < elements0.length; i++) {
      if (elements0[i].value) {
        services_ids.push(elements0[i].value)
        formvalid0 = 'true'
      }
      else {
        formvalid0 = 'false'
        break;
      }
    }

    const elements1 = Array.from(
      document.getElementsByClassName('packclass')
    );
    var pack = [];
    for (let i = 0; i < elements1.length; i++) {
      if (elements1[i].value) {
        pack.push(elements1[i].value)
        formvalid1 = 'true'
      }
      else {
        formvalid1 = 'false'
        break;
      }
    }


    // const elements2 = Array.from(
    //   document.getElementsByClassName('sizeclass')
    // );
    // var sizes = [];
    // for (let i = 0; i < elements2.length; i++) {
    //   if (elements2[i].value) {
    //     sizes.push(elements2[i].value)
    //     formvalid2 = 'true'
    //   }
    //   else {
    //     formvalid2 = 'false'
    //     break;
    //   }
    // }

    const elements3 = Array.from(
      document.getElementsByClassName('qtyclass')
    );
    var quantities = [];
    for (let i = 0; i < elements3.length; i++) {
      if (elements3[i].value) {
        quantities.push(elements3[i].value)
        formvalid3 = 'true'
      }
      else {
        formvalid3 = 'false'
        break;
      }
    }

    const elements4 = Array.from(
      document.getElementsByClassName('weightclass')
    );
    var weights = [];
    for (let i = 0; i < elements4.length; i++) {
      if (elements4[i].value) {
        weights.push(elements4[i].value)
        formvalid4 = 'true'
      }
      else {
        formvalid4 = 'false'
        break;
      }
    }

    const elements5 = Array.from(
      document.getElementsByClassName('priceclass')
    );
    var prices = [];
    for (let i = 0; i < elements5.length; i++) {
      if (elements5[i].value) {
        prices.push(parseFloat(elements5[i].value).toFixed(2))
        formvalid5 = 'true'
      }
      else {
        formvalid5 = 'false'
        break;
      }
    }
    const elements6 = Array.from(
      document.getElementsByClassName('totalpriceclass')
    );
    var total_prices = [];
    for (let i = 0; i < elements6.length; i++) {
      if (elements6[i].value) {
        total_prices.push(parseFloat(elements6[i].value).toFixed(2))
        formvalid6 = 'true'
      }
      else {
        formvalid6 = 'false'
        break;
      }
    }
    if (formvalid0 === 'false' || formvalid1 === 'false'
      // || formvalid2 === 'false' 
      || formvalid3 === 'false' || formvalid4 === 'false' || formvalid5 === 'false' || formvalid6 === 'false') {
      Alerts.swalErrorAlert("Please fill all the fields.")
    }
    else {
      var payloadData = {
        functionName: "AddOrder",
        postData: {
          user_id: state._id,
          tax_percentage: localStorage.getItem("tax_percentage"),
          service_id: services_ids,
          packaging_id: pack,
          remarks: Remarks,
          quantity: quantities,
          weight: weights,
          price: prices,
          total_price: total_prices,
          order_status: 'Quoted',
          consignment: consignment,
          po_no: po_no

        }
      }
      console.log('postData : ', payloadData)
      setLoading(true);
      CustomerService.addCustomerOrder(payloadData).then((response) => {
        console.log("API Response:-", response);
        setLoading(false);
        if (response.data.status) {
          setViewFtn('allorders', "reload");
          if (response.data.message !== '') {
            Alerts.swalSuccessAlert(response.data.message)
          }
          else {
            Alerts.swalSuccessAlert(t("Customer order added successfully."));
          }
        }
        else {
          if (response.data.message !== '') {
            if (response.data.message === "Unauthorized") {
              AuthService.logout();
              navigate("/login");
              window.location.reload();
            }
            else {
              Alerts.swalErrorAlert(response.data.message);
            }
          }
          else {
            Alerts.swalErrorAlert(t("Error: Can't add Customer order at the moment. Please try again later."))
          }
        }
      },
        (error) => {
          setLoading(false);
          const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          Alerts.swalErrorAlert(t("Network Operation failed."))
        });
    }
  }

  function fetchCustomerOrders() {
    var getData = {
      functionName: "GetCustomerOrders",
      postData: {
        "sort": { _id: -1 },
        "offset": 0,
        "limit": 10000000,
        "filter": {
          customer_id: state._id
        }
      },
    }
    setLoading(true);
    CustomerService.getCustomerOrders(getData).then((response) => {
      setLoading(false);
      console.log("Get Customer Orders response:-", response)
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        setOrders(response.data.data);
        // setservice(response.data.data[0].services);
        // setpackaging(response.data.data[0].packaging);
        // setpackagingsize(response.data.data[0].packaging_size_details)
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent)
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  };

  function confirmDeleteOrder(_id, userId) {
    let msg = t("Once deleted, you will not be able to recover this customer order!")
    Alerts.swalConfirmDeleteAlert(msg).then(res => {
      console.log("Resp:-", res)
      if (res === true) {
        sendDelete(_id, userId)
      }
    })
  };


  const downloadPDF = (response) => {
    console.log("PDF Data Length: ", response.data.length);
    console.log("Partial PDF Data: ", response.data.slice(0, 100));
    const pdfBlob = new Blob([response.data], { type: 'application/pdf' });

    // Create a download link and trigger the download
    const url = window.URL.createObjectURL(pdfBlob);
    const downloadLink = document.createElement('a');
    downloadLink.href = url;
    downloadLink.download = 'order.pdf'; // Set the desired file name here.
    downloadLink.style.display = 'none';

    document.body.appendChild(downloadLink);
    downloadLink.click();

    // Clean up by removing the link and revoking the URL
    document.body.removeChild(downloadLink);
    window.URL.revokeObjectURL(url);
  };
  const handlePDF = (row) => {
    var payload = {
      functionName: "Get_PDF_Order",
      postData: {
        tax_percentage: localStorage.getItem("tax_percentage"),
        "row": row,
      },
    }
    setLoading(true);
    CustomerService.getOrder_PDF(payload).then((response) => {
      console.log(response);
      const pre = document.createElement('pre');
      pre.textContent = response.data;
      document.body.appendChild(pre);
      setLoading(false);
      if (response.status === 200) {

        downloadPDF(response);
      } else if (response.status === 401) {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      } else {
        // Handle other error cases, if necessary
        console.log("Response status: " + response.status);
        // Display an error message or perform other actions as needed.
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent);
        if (errContent) {
          Alerts.swalErrorAlert(errContent);
        } else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."));
        }
      })
  }

  function sendDelete(id, user_id) {
    setLoading(true);
    CustomerService.deleteCustomerOrder(id, user_id).then((response) => {
      setLoading(false);
      console.log("Delete Customer order details response:-", response)
      if (response.data.status) {
        fetchCustomerOrders();
        if (response.data.message !== '') {
          Alerts.swalSuccessAlert(response.data.message)
        }
        else {
          Alerts.swalSuccessAlert(t("Customer Order Deleted Successfully."))
        }
        console.log(response.data.message)
      }
      else {
        if (response.data.message !== '') {
          if (response.data.message === "Unauthorized") {
            AuthService.logout();
            navigate("/login");
            window.location.reload();
          }
          else {
            Alerts.swalErrorAlert(response.data.message);
          }
        }
        else {
          Alerts.swalErrorAlert(t("Error: Can't delete the customer pricing at the moment."))
        }
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        Alerts.swalErrorAlert(t("Delete operation failed"))
      })
  };

  // function handleSwitch(e) {
  //   console.log('Switch event:-', e)
  //   if (e === true) {
  //     setSwitchtoggle(true)
  //     setModelopen(!modelopen)
  //   }
  //   else {
  //     setSwitchtoggle(false)
  //     setModelopen(!modelopen)
  //   }
  // }

  // const confirmAssign = values => {

  // }

  // const validateAssingFtn = values => {
  //   const errors = {};
  //   if (!values.assigndate) {
  //     errors.assigndate = "Date Time Required.";
  //   }
  //   return errors;
  // };
  const mycolumns = [
    {
      name: t('Customer Name'),
      selector: (row) => {
        if (row.userDetails[0]) {
          return (
            <div>
              {row.userDetails[0].company_name}
            </div>
          )
        }
      }
    },
    {
      name: t('Order date'),
      selector: row => row.created_at && moment(row.created_at).format("DD-MM-YYYY")
    },
    {
      name: t('Order No.'),
      selector: "order_no",
      width: '10%'
    },
    // {
    //   name: "Service",
    //   selector: row => {
    //     for (let i = 0; i < services.length; i++) {
    //       if (row.orderDetails[0]?.service_id === services[i]._id) {
    //         return (
    //           <div>
    //             {services[i].name}
    //           </div>
    //         );
    //       }
    //     }
    //   }
    // },
    {
      name: t("Driver/Vehicle"),
      selector: (row) => {
        if (row.fleetDetails.length !== 0) {
          return (
            <div>
              {`${row.driverDetails[0]?.name}/${row.fleetDetails[0]?.name}`}
            </div>
          )
        } else {
          return (
            <div>
              Not Assigned
            </div>
          )
        }
      }
    },
    {
      name: t('Status'),
      width: '12%',
      selector: (row) => {
        return (
          <div>
            {row.order_status === "Assigned" ?
              <button type="button" style={{ borderColor: '#F39026', backgroundColor: '#F39026' }}
                className="btn btn-success btn-hover-shine me-3 btn">{row.order_status}
              </button>
              :
              row.order_status === "Cancelled" ?
                <button type="button"
                  className="btn btn-danger btn-hover-shine me-3 btn">{row.order_status}
                </button>
                :
                row.order_status === "Quoted" ?
                  <button type="button" style={{ borderColor: '#1BD35A', backgroundColor: '#1BD35A' }}
                    className="btn btn-c btn-hover-shine me-3 btn">{row.order_status}
                  </button>
                  :
                  row.order_status === "Postponed" ?
                    <button type="button" style={{ color: 'white', borderColor: '#6056d6', backgroundColor: '#6056d6' }}
                      className="btn btn-c btn-hover-shine me-3 btn">{row.order_status}
                    </button>
                    :
                    row.order_status === "Completed" ?
                      <button type="button"
                        className="btn btn-success btn-hover-shine me-3 btn">{row.order_status}
                      </button>

                      :
                      row.order_status === "Confirmed" ?
                        <button type="button" style={{ borderColor: '#609FFC', backgroundColor: '#609FFC' }}
                          className="btn btn-info btn-hover-shine me-3 btn">{row.order_status}
                        </button>
                        :
                        row.order_status === "Invoiced" ?
                        <button type="button" style={{ borderColor: '#ebb734', backgroundColor: '#ebb734' }}
                        className="btn btn-info btn-hover-shine me-3 btn">{row.order_status}
                          </button>
                          :
                          <button type="button" style={{ borderColor: '#42b6f5', backgroundColor: '#42b6f5' }}
                            className="btn btn-info btn-hover-shine me-3 btn">{row.order_status}
                          </button>
            }
          </div>
        );
      }
    },
    {
      name: t("Pickup Date"),
      selector: row => row.pick_datetime ? moment(row.pick_datetime).format("DD-MM-YYYY HH:mm A") : 'Not Assigned',
    },
    {
      name: t('Actions'),
      width: '15%',
      selector: (row) => {
        return (
          <div>
            <button className="mt-2 mb-2 me-2 btn-icon btn">
              <Tooltip placement="top" title={t('Add Invoice')} arrow>
                <i
                  className="pe-7s-news-paper text-info btn-icon-wrapper fa fa-file-invoice"
                  onClick={() => {
                    if (row.invoice_status === 'false') {
                      AddInvoice(row);
                    } else {
                      AlertService.swalErrorAlert(t('Invoice Already Exists'));
                    }
                  }}
                ></i>
              </Tooltip>
              <Tooltip placement="top" title={t('Order Details')} arrow>
                <i className="pe-7s-note2 text-info btn-icon-wrapper" onClick={() => { getServices(); setViewFtn('orderdetails', row) }}></i>
              </Tooltip>
              <Tooltip placement="top" title={t('Send Email')} arrow>
                <i onClick={() => handleMail(row)} className="pe-7s-mail text-primary btn-icon-wrapper"></i>
              </Tooltip>
              <Tooltip placement="top" title={t('Download PDF')} arrow>
                <i onClick={() => handlePDF(row)} className="pe-7s-download text-success btn-icon-wrapper text-info"> </i>
              </Tooltip>
              <Tooltip placement="top" title={t('Delete')} arrow>
                <i onClick={() => confirmDeleteOrder(row._id, row.user_id)} className="pe-7s-trash text-danger btn-icon-wrapper"> </i>
              </Tooltip>
              <AssignOrderManual data={row} onSuccess={handleSuccess} drivers={drivers} fleets={fleets} />
            </button>
          </div >
        );
      }
    },


  ];

  function handleMail(row) {
    setEmailRow('')
    setSelectedEmails('');
    setEmailRow(row)
    var getData = {
      functionName: "GetUserContacts",
      postData: {
        user_id: row.customer_id
      },
    }
    setLoading(true);
    CustomerService.GetUserContacts(getData).then((response) => {
      setLoading(false);
      console.log("Get Customer Contact response:-", response.data.data)
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        setContacts(response.data.data)
        toggle1();
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent)
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  };


  function sendMail(row) {
    setLoading(true);
    let tax_percentage = localStorage.getItem("tax_percentage");
    CustomerService.sendOrder_PDF_Mail(row, tax_percentage).then((response) => {
      console.log("Send mail to:-", response)
      setLoading(false);
      if (response.data.status) {
        if (response.data.message !== '') {
          Alerts.swalSuccessAlert(response.data.message)
        }
        else {
          Alerts.swalSuccessAlert(t("Order emailed Successfully."))
        }
        console.log(response.data.message)
      }
      else {
        if (response.data.message !== '') {
          if (response.data.message === "Unauthorized") {
            AuthService.logout();
            navigate("/login");
            window.location.reload();
          }
          else {
            Alerts.swalErrorAlert(response.data.message);
          }
        }
        else {
          Alerts.swalErrorAlert(t("Error: Can't send the Password."))
        }
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        Alerts.swalErrorAlert(t("Operation failed"))
      })
  };

  const handleBack = () => {
    setViewFtn("allorders", "")
  };

  const handleEdit = (editparam) => {
    console.log("param:-", editparam)
    // if(editparam === "true") {
    //   if(updateParam === "false") {
    //     setupdateParam(editparam)
    //   }
    //   else {
    //     setViewFtn("allorders", "reload")
    //   }
    setViewFtn("allorders", "reload")

    // }
    // else {
    //   setViewFtn("allorders", "")
    // }
  }

  // const handleDetailsEditing = (editparam) => {
  //   console.log("param:-", editparam)
  //   if(editparam === "true") {
  //     setViewFtn("allorders", "reload")
  //   }
  //   else {
  //     setViewFtn("allorders", "")
  //   }
  // }

  // const handleItemsEditing = (editparam) => {
  //   console.log("param:-", editparam)
  //   if(editparam === "true") {
  //     setViewFtn("allorders", "reload")
  //   }
  //   else {
  //     setViewFtn("allorders", "")
  //   }
  // }

  // const handleMediaEditing = (editparam) => {
  //   console.log("param:-", editparam)
  //   if(editparam === "true") {
  //     setViewFtn("allorders", "reload")
  //   }
  //   else {
  //     setViewFtn("allorders", "")
  //   }
  // }

  const handleSuccess = () => {
    setViewFtn("allorders", "reload")
  };

  // const [filter , setFilter] = useState({
  //   service_id:'',
  //   packaging_id:'',
  //   packaging_size_id:'',
  //   index:''
  // })

  function selectChanged(type, ind, ev) {
    console.log("type:-", type)
    console.log("index:-", ind)
    console.log("value:-", ev.target.value)
    setpriceidx(ind)
    const updatedOrderDetails = [...orderDetails];
    const newValue = { [type]: ev.target.value };
    updatedOrderDetails[ind] = {
      ...updatedOrderDetails[ind],
      ...newValue,
    };
    if (type === "price") {
      console.log("price", ev.target.value)
      if (ev.target.value) {
        var updatedPopObj = [...myDataPopObj];
        var singlePriceObj = [...SinglePriceObj];
        singlePriceObj[ind] = parseFloat(ev.target.value);
        updatedPopObj[ind] = (parseFloat((isNaN(orderDetails[ind].quantity) ? 1 : orderDetails[ind].quantity) * (isNaN(orderDetails[ind].weight) ? 1 : orderDetails[ind].weight) * (ev.target.value)).toFixed(2));
        setmyDataPopObj(updatedPopObj);
        setSinglePriceObj(singlePriceObj);
      }
    }

    setOrderDetails(updatedOrderDetails);
    if (type === "services") {
      setfservice_id(ev.target.value)
      if (orderDetails[ind]) {
        if (orderDetails[ind].packaging
          //  && orderDetails[priceidx].packagingsize 
          && orderDetails[ind].services
        ) {
          SearchPrice();
        }

      }
    }
    if (type === "packaging") {
      setfpackaging_id(ev.target.value)
      if (orderDetails[ind]) {
        if (orderDetails[ind].packaging
          //  && orderDetails[priceidx].packagingsize 
          && orderDetails[ind].services
        ) {
          SearchPrice();
        }

      }
    }
    // if (type === "packagingsize") {
    //   setfpackaging_size_id(ev.target.value)
    // }
    if (type === "weight") {
      // setfweight(ev.target.value)

      var updatedPop = [...myDataPopObj];
      console.log("updatedPopObj[priceidx]", updatedPop[ind])

      // setfquantity(ev.target.value)
      updatedPop[ind] = (parseFloat((parseFloat(ev.target.value)) * (isNaN(orderDetails[ind].quantity) ? 1 :
        parseFloat(orderDetails[ind].quantity)) *
        parseFloat(SinglePriceObj[ind])).toFixed(2));
      console.log("updatedPop[ind]", updatedPop[ind])
      setmyDataPopObj(updatedPop);
    }
    if (type === "quantity") {
      console.log("ev.target.value", ev.target.value)
      console.log("orderDetails[ind].weight", orderDetails[ind].weight)
      console.log("isNAN", isNaN(orderDetails[ind].weight))
      console.log("SinglePriceObj[ind].price", SinglePriceObj[ind])
      var updatedPo = [...myDataPopObj];
      console.log("updatedPopObj[priceidx]", updatedPo[ind])

      // setfquantity(ev.target.value)
      updatedPo[ind] = (parseFloat((parseFloat(ev.target.value)) * (isNaN(orderDetails[ind].weight) ? 1 :
        parseFloat(orderDetails[ind].weight)) *
        parseFloat(SinglePriceObj[ind])).toFixed(2));
      console.log("updatedPop[ind]", updatedPo[ind])
      setmyDataPopObj(updatedPo);

    }
  }

  const steps = [
    { name: t('Order Details'), component: <OrderDetails data={singleOrderdata} drivers={drivers} fleets={fleets} OnEditDetails={handleEdit} onBack={handleBack} onSuccess={handleSuccess} /> },
    {
      name: t('Order Items'), component: <OrderItems data={singleOrderdata} services={services} packagings={packagings}
        //  packagingSizes={packagingSizes} 
        OnEditItems={handleEdit} onBack={handleBack} onSuccess={handleSuccess} />
    },
    { name: t('Order Media'), component: <OrderMedia data={singleOrderdata} OnEditMedia={handleEdit} onBack={handleBack} onSuccess={handleSuccess} /> }
  ];


  const toggle1 = () => {
    setModal1(!modal1);
  };
  const handleSubmit1 = (values) => {
    toggle1();
    console.log('Selected emails:', selectedEmails);
    console.log('EmailRow:', EmailRow);
    const updatedRow = { ...EmailRow, selectedEmails }; // Combine existing rows with newly selected emails
    console.log('Updated row:', updatedRow);

    const confirmationMessage = t("Are you sure you want to send the invoice via email?");

    Alerts.swalConfirmGeneralAlert(confirmationMessage).then(res => {
      console.log("Response:", res);
      if (res === true) {
        sendMail(updatedRow); // Call the function to send email with the updated row
      }
    });
  }
  function onCancel() {
    setModal1(!modal1);
  }
  const [selectedEmails, setSelectedEmails] = useState([]);

  const handleCheckboxChange = (e) => {
    const email = e.target.value;
    const isChecked = e.target.checked;
    if (isChecked) {
      setSelectedEmails([...selectedEmails, email]);
    } else {
      setSelectedEmails(selectedEmails.filter(item => item !== email));
    }
  };


  return (
    <>
      <Modal isOpen={modal1} toggle={toggle1} className="custom_modal_width" backdrop={true}>
        <Card className="main-card mb-3">
          <CardBody>
            <h4 className="text-center fw-bold mb-4">Send Email</h4>
            <Form
              onSubmit={handleSubmit1}
              render={({ handleSubmit, submitting }) => (
                <form onSubmit={handleSubmit}>
                  <FormGroup>
                    <legend className="mb-3">Select Recipients</legend>
                    <div className="mb-3">
                      <Field name="email">
                        {({ input }) => (
                          <div className="form-check mb-2">
                            <Label check style={{ cursor: 'pointer' }}>
                              <Input
                                {...input}
                                type="checkbox"
                                id={EmailRow?.userDetails?.[0]?.email}
                                value={EmailRow?.userDetails?.[0]?.email}
                                onChange={handleCheckboxChange}
                                className="form-check-input"
                                style={{ cursor: 'pointer' }}
                              />
                              {EmailRow?.userDetails?.[0]?.email}
                            </Label>
                          </div>
                        )}
                      </Field>
                      {Contacts.map(email => (
                        <div key={email._id}>
                          <Field name="emails">
                            {({ input }) => (
                              <div className="form-check mb-2">
                                <Label check style={{ cursor: 'pointer' }}>
                                  <Input
                                    {...input}
                                    type="checkbox"
                                    id={email._id}
                                    value={email.email}
                                    onChange={handleCheckboxChange}
                                    className="form-check-input"
                                    style={{ cursor: 'pointer' }}
                                  />
                                  {email.email}
                                </Label>
                              </div>
                            )}
                          </Field>
                        </div>
                      ))}
                    </div>
                  </FormGroup>
                  <Row className="mt-4 justify-content-center">
                    <div className="col-auto">
                      <Button
                        type="submit"
                        color="primary"
                        disabled={selectedEmails.length === 0}
                      >
                        Send
                      </Button>
                    </div>
                    <div className="col-auto">
                      <Button
                        type="button"
                        onClick={onCancel}
                        color="secondary"
                      >
                        Cancel
                      </Button>
                    </div>
                  </Row>
                </form>
              )}
            />
          </CardBody>
        </Card>
      </Modal>
      {param === 'allorders' ?
        <Fragment>
          <Row>
            <Col md="12">
              <Card className="main-card mb-3">
                <CardBody>
                  <LoadingOverlay tag="div" active={loading}
                    styles={{ overlay: (base) => ({ ...base }) }}
                    spinner={<DNALoader />}>
                    <Row className="mt-2">
                      <Col md="6">
                        <h4 className="fw-bold mb-1">{t('Orders')}</h4>
                      </Col>
                      <Col md="6">
                        <a onClick={() => { getPackaging(); getServices(); setViewFtn('addneworder', ""); }} className="btn btn-custom-color btn-hover-shine ms-1 float-end">
                          {t('Add New')}
                        </a>
                      </Col>
                      <Col md="12" className="pt-3">
                        <DataTable
                          data={orders}
                          columns={mycolumns}
                          pagination
                          fixedHeader
                          fixedHeaderScrollHeight="400px"
                        />
                      </Col>
                    </Row>
                  </LoadingOverlay>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Fragment>
        : param === 'addneworder' ?
          <Fragment>
            <Row>
              <Col md="12">
                <Card className="main-card mb-3">
                  <CardBody>
                    <LoadingOverlay tag="div" active={loading}
                      styles={{ overlay: (base) => ({ ...base }) }}
                      spinner={<DNALoader />}>
                      <Form
                        onSubmit={handleSubmit}
                        render={({ handleSubmit, submitting }) => (
                          <form onSubmit={handleSubmit}>
                            <Row>
                              <Col md="12">
                                <h4 className="fw-bold mb-1">{t('Add New Order')}</h4>
                              </Col>
                              <Col style={{ marginTop: '10px' }} md="4">
                                <Input className="fw-bold mb-1" type="text" placeholder="Remarks"
                                  onChange={(e) => { SetRemarks(e.target.value) }}
                                  name="remarks"
                                />
                              </Col>
                              <Col style={{ marginTop: '10px' }} md="4">
                                <Input type="text" placeholder="Consignment No."
                                  onChange={(e) => { SetConsignment(e.target.value) }}
                                  value={consignment}
                                  className="consignment" name="consignment"
                                />
                              </Col>
                              <Col style={{ marginTop: '10px' }} md="4">
                                <Input type="text" placeholder="PO No."
                                  onChange={(e) => { setPo_no(e.target.value) }}
                                  value={po_no}
                                  className="po_no" name="po_no"
                                />
                              </Col>
                            </Row>
                            {Array.from({ length: rows }, (_, index) => {
                              var preprice = "";
                              var singleprice = "";
                              if (index < myDataPopObj.length) {
                                preprice = myDataPopObj[index];
                                singleprice = SinglePriceObj[index];
                              }
                              return (
                                <div>
                                  <Row id={"parent" + index} className="mt-4">
                                    <Col md="2">
                                      <div className="form-group">
                                        <select type="select" className="form-select serviceclass" name="service_id" onChange={(ev) => {
                                          selectChanged("services", index, ev)
                                        }}
                                        >
                                          <option disabled={false} value="">{t('Service')}</option>
                                          {services &&
                                            services.map((serv, indexx) => (
                                              <option key={indexx} value={serv._id}>{serv.name}</option>
                                            ))
                                          }
                                        </select>
                                      </div>
                                    </Col>
                                    <Col md="2">
                                      <div className="form-group">
                                        <select type="select" className="form-select packclass" name="packaging_id" onChange={(ev) => {
                                          selectChanged("packaging", index, ev)
                                        }}
                                        >
                                          <option disabled={false} value="">{t('Packaging')}</option>
                                          {packagings &&
                                            packagings.map((pack, inde) => (
                                              <option key={inde} value={pack._id}>{pack.name}</option>
                                            ))
                                          }
                                        </select>
                                      </div>
                                    </Col>
                                    {/* <Col md="1">
                                <div className="form-group">
                                  <select type="select" className="form-select sizeclass" name="packaging_size_id" onChange={(ev) => {
                                    selectChanged("packagingsize", index, ev)
                                  }}
                                  >
                                    <option disabled={false} value="">{t('Size')}</option>
                                    {packagingSizes &&
                                      packagingSizes.map((size, ind) => (
                                        <option key={ind} value={size._id}>{size.name}</option>
                                      ))
                                    }
                                  </select>
                                </div>
                              </Col > */}
                                    <Col md="2">
                                      <div className="form-group">
                                        <Input type="text" placeholder={t('Quantity')} onChange={(ev) => {
                                          selectChanged("quantity", index, ev)
                                        }}
                                          className="form-control qtyclass" name="quantity"
                                        />
                                      </div>
                                    </Col>
                                    <Col md="1">
                                      <div className="form-group">
                                        <Input type="number" placeholder={t('Weight')} onChange={(ev) => {
                                          selectChanged("weight", index, ev)
                                        }}
                                          className="form-control weightclass" name="weight"
                                        />
                                      </div>
                                    </Col>
                                    <Col md="2" >
                                      <div className="form-group">
                                        <Input type="number" onChange={(ev) => {
                                          selectChanged("price", index, ev)
                                        }}
                                          placeholder={t('Price')} className="form-control priceclass" value={singleprice} name="price"
                                        />
                                      </div>
                                    </Col>
                                    <Col md="2" >
                                      <div className="form-group">
                                        <Input type="number" disabled placeholder={t('Total Price')} className="form-control totalpriceclass" value={preprice} name="totalprice"
                                        />
                                      </div>
                                    </Col>
                                    <Col md="1" className="pt-1">
                                      <i onClick={() => addRow()} className="lnr-plus-circle h3 fw-bold"> </i>
                                      {rows !== 1 &&
                                        <i onClick={() => remove_option(index)} className="lnr-cross-circle h3 fw-bold"> </i>
                                      }
                                    </Col>
                                  </Row>
                                </div>
                              )
                            })}

                            <Row className="mt-5">
                              <div className="text-center form-group">
                                <button type="submit" className="btn btn-secondary btn-lg btn-hover-shine me-3">
                                  {t('Save')} </button>
                                <button type="button" onClick={() => setViewFtn('allorders', "")} className="btn btn-outline-success btn-lg btn-hover-shine me-3">
                                  {t('Cancel')}
                                </button>
                              </div>

                            </Row>
                          </form>
                        )}
                      />
                    </LoadingOverlay>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Fragment>
          : param === 'orderdetails' ?
            <Fragment>
              <Row>
                <Col md="12">
                  <Card className="main-card mb-3">
                    <CardBody>
                      <div className="forms-wizard-vertical">
                        <MultiStep showNavigation={true} steps={steps} />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Fragment>
            : ""}
    </>

  )
};
export default CustomerOrders;