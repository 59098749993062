import React from "react";
import { Translation } from "react-i18next";
import { Navigate } from "react-router-dom";
import { Row, Col, FormGroup, Input, Button, Label, Modal, ModalHeader, ModalBody, ModalFooter, Card, CardBody } from "reactstrap";
import { Form, Field } from "react-final-form";
import AuthService from "../../../../Authentication/auth.service";

import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../../../../components/Loaders/DnaLoader";
import "../../../../../assets/custom css/loadoverlay.scss";

import Alerts from "../../../../../components/Alerts/alerts";
import CustomerService from "../../Customer.service";



class AddOrderMedia extends React.Component {

  constructor(props) {
    super(props);
    console.log("Received Prop:-", props);
    this.state = {
      modal: false,
      backdrop: true,
      loading: false,
      loginNavigate: false,
      upfile: ''
    };
    this.toggle = this.toggle.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }

  onSubmit = values => {
    if (!this.state.upfile) {
      Alerts.swalErrorAlert("Please select a file.")
    } else {
      console.log(values);
      var payloadData = {
        functionName: "AddOrderMedia",
        postData: {
          file: this.state.upfile,
          order_id: this.props.data._id,
          user_id: this.props.data.customer_id,
        }
      }
      this.setState({
        loading: !this.state.loading,
      });
      CustomerService.AddSingleorderMedia(payloadData).then((response) => {
        console.log("API Response:-", response);
        this.setState({
          loading: !this.state.loading,
        });
        if (response.data.status) {
          this.props.onSuccess();
          this.setState({
            upfile: "",
          });
          if (response.data.message !== '') {
            Alerts.swalSuccessAlert(response.data.message).then(res => {
              console.log("Resp:-", res)
              this.toggle();
            })
          }
          else {
            Alerts.swalSuccessAlert("Media item Added successfully.").then(res => {
              console.log("Resp:-", res)
              this.toggle();
            })
          }
        }
        else {
          if (response.data.message !== '') {
            if (response.data.message === "Unauthorized") {
              AuthService.logout();
              this.setState({ loginNavigate: true });
              window.location.reload();
            }
            else {
              Alerts.swalErrorAlert(response.data.message).then(res => {
                console.log("Resp:-", res)
              })
            }
          }
          else {
            Alerts.swalErrorAlert("Error: Can't Add Media at the moment. Please try again later.")
          }
        }
      },
        (error) => {
          this.setState({
            loading: !this.state.loading,
          });
          const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          Alerts.swalErrorAlert("Network Operation failed.")
        });
    }

  }

  onFileChange = e => {
    console.log(e.target.files)
    var filename = e.target.files[0].name
    console.log("File Name", filename)
    var fileReader = new FileReader();
    fileReader.readAsDataURL(e.target.files[0])
    fileReader.onload = () => {
      const convertedPDF = fileReader.result
      console.log("Converted PDF File:-", convertedPDF)
      this.setState({
        upfile: convertedPDF
      });
    }
  }

  render() {
    {
      this.state.loginNavigate &&
        <Navigate to={'/login'} />
    }
    return (
      <Translation>
        {(t) => (
          <span className="d-inline-block mb-2">
            <a onClick={this.toggle} className="btn btn-custom-color btn-hover-shine ms-1">
              Add New
            </a>
            <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className} backdrop={true}>
              <LoadingOverlay tag="div" active={this.state.loading}
                styles={{ overlay: (base) => ({ ...base }) }}
                spinner={<DNALoader />}>
                <ModalHeader >Upload Media</ModalHeader>
                <Card className="main-card mb-3">
                  <CardBody>
                    <Row>
                      <Col md="12" className="pt-3">
                        <Form onSubmit={this.onSubmit}
                          validate={this.validateFtn}
                          render={({ handleSubmit, values, submitting, validating, valid }) => (
                            <form onSubmit={handleSubmit}>
                              <Row>
                                <Col md="12" >
                                  <FormGroup>
                                    <div className="mb-12">
                                      <Label className="mb-2">File</Label>
                                      <Input name="file" type='file' onChange={this.onFileChange}
                                      />
                                    </div>
                                  </FormGroup>
                                </Col>
                                <div className="text-center">
                                  <FormGroup>
                                    <div className="text-center">
                                      <button type="submit" className="btn btn-secondary btn-lg btn-hover-shine me-3">
                                        Add </button>
                                      <button type="button" className="btn btn-outline-success btn-lg btn-hover-shine me-3" onClick={this.toggle}>
                                        Cancel
                                      </button>
                                    </div>
                                  </FormGroup>
                                </div>
                              </Row>
                            </form>
                          )}
                        />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </LoadingOverlay>
            </Modal>
          </span>
        )}
      </Translation>
    );
  };


};

export default AddOrderMedia;