import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from "react-router-dom";

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import axios from 'axios';
// import RoleService from "../../services/role.service";
// import UserService from "../../services/user.service";

import {Card, CardBody } from "reactstrap";
import AttributesService from "./attributes.service";
import FormsService from "../DynamicUserForms/UserForms.service";
import AuthService from "../Authentication/auth.service";

import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import "../../assets/custom css/loadoverlay.scss";

import Alerts from "../../components/Alerts/alerts";


const UpdateAttribute = () => {
  
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const { state } = location;
  const [dataObj, setdataObj] = useState("");
  // setdataObj(state);
  const [dataObjoptions, setdataObjoptions] = useState("");

  const [fieldtype, setfieldType] = useState("");
  const [crudid, setcrudid] = useState("");
  const [fieldparent, setfieldparent] = useState("");
  const [parentytype, setparentytype] = useState("");
  const [iseditable, setiseditable] = useState("");
  const [datatable, setdatatable] = useState("");
  const [isrequired, setisrequired] = useState("");
  const [isunique, setisunique] = useState("");
  const [isfilterable, setisfilterable] = useState("");
  const [options_collection, setoptions_collection] = useState("");
  const [options_collection_arr, setoptions_collection_arr] = useState([]);
  
  
  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    fetchEntities();
    fetchAttributes();
    fetchcollections();
    setData();
  }, []); 


  function fetchEntities() {
    FormsService.getAll().then((response) => {
      console.log("Get Entities response:-", response)
      if(response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        setcruds(response.data.data);
      }
    },
    (error) => {
      const errContent =
      (error.response && error.response.data) || error.message || error.toString();
      if(errContent) {
        Alerts.swalErrorAlert(errContent)
      }
      else {
        Alerts.swalErrorAlert(t("Server Error, Please try again later."))
      }
    })
  };
  function fetchcollections() {
    FormsService.fetchcollections().then((response) => {
      if(response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        setoptions_collection_arr(response.data.data);
      }
    },
    (error) => {
      const errContent =
      (error.response && error.response.data) || error.message || error.toString();
      if(errContent) {
        Alerts.swalErrorAlert(errContent)
      }
      else {
        Alerts.swalErrorAlert(t("Server Error, Please try again later."))
      }
    })
  };
  function fetchAttributes() {
    AttributesService.getAll().then((response) => {
      console.log("Get Attributes response:-", response)
      setparents(response.data.data);
    },
    (error) => {
      const errContent =
      (error.response && error.response.data) || error.message || error.toString();
      if(errContent) {
        Alerts.swalErrorAlert(errContent)
      }
      else {
        Alerts.swalErrorAlert(t("Server Error, Please try again later."))
      }
    })
  };

    
  function setData() {
    console.log("State values received:-", state)
    setfieldvalues();
    setdataObj(state);
    if(!state.options){
      state.options = [];
    }
    setdataObjoptions(state.options);
    var pre_options = state.options;
    if(pre_options.length){
      setrows(pre_options.length);
    }
    else {
      setrows(1);
    }
  }

  function setfieldvalues() {
    setfieldType(state.field_type);
    setcrudid(state.crud_id);
    setfieldparent(state.field_parent);
    setiseditable(state.is_editable);
    setdatatable(state.datatable);
    setisrequired(state.is_required);
    setisunique(state.is_unique);
    setisfilterable(state.is_filterable);
    setoptions_collection(state.options_collection);
  }
  
  
  const navigate = useNavigate();
  const form = useRef();
  const checkBtn = useRef();
  const [cruds, setcruds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rows, setrows] = useState([]);
  const [parents, setparents] = useState([]);
  const [successful, setSuccessful] = useState(false);
  const [message, setMessage] = useState("");
  var formdata ={};


 
  const handleSubmit = (e) => {
    
    e.preventDefault();
    setMessage("");
    setSuccessful(false);
    let data = new FormData(e.target);
    let formdata = {};
    for (let [key, value] of data) {
      if (formdata[key] !== undefined) {
        if (!Array.isArray(formdata[key])) {
          formdata[key] = [formdata[key]];
        }
        formdata[key].push(value);
      } else {
        formdata[key] = value;
      }
    }
    
    var labelss = formdata['label[]'];
    var valuesss = formdata['value[]'];
    
    let options=[];
    if(Array.isArray(labelss)){
      for(var i=0; i < labelss.length ;i++){
        if(labelss[i] && valuesss[i])
        options.push({'label':labelss[i],'value':valuesss[i]});
      }
    }else{
      if(labelss && valuesss )
      options.push({'label':labelss,'value':valuesss});
    }
    
    formdata.options = options;
    
    formdata._id = dataObj._id;
    
    var payloadData = {
      functionName: "UpdateFormFields",
      postData:  formdata 
    }
    setLoading(true);
      console.log("Attribute payload:-", payloadData)
      AttributesService.updateAttribute(payloadData).then((response) => {  
        setLoading(false);
        if(response.data.status){
          navigate("/userform-attributes-list",{ state: {"_id":state.crud_id } })
          if(response.data.message !== '') {
            Alerts.swalSuccessAlert(response.data.message)
          }
          else {
            Alerts.swalSuccessAlert(t("Attribute updated successfully."));
          }
        }
        else {
          if(response.data.message !== '') {
            if(response.data.message === "Unauthorized") {
              AuthService.logout();
              navigate("/login");
              window.location.reload();
            }
            else {
              Alerts.swalErrorAlert(response.data.message);
            }
          }
          else {
            Alerts.swalErrorAlert(t("Error: Can't update attribute at the moment. Please try again later."))
          }
        }
      },
      (error) => {
        setLoading(false);
        const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        Alerts.swalErrorAlert(t("Network Operation failed."))
      });
  };

  const handleFunctionChange = (e) => {
    console.log("change:-", e.target.name)
    if(e.target.name === 'field_type') {
      const fieldtype = e.target.value;
      setfieldType(fieldtype);
    }
    else if(e.target.name === 'crud_id') {
      const crudid = e.target.value;
      setcrudid(crudid);
    }
    
    else if(e.target.name === 'is_editable') {
      const iseditable = e.target.value;
      setiseditable(iseditable);
    }
    else if(e.target.name === 'datatable') {
      const datatable = e.target.value;
      setdatatable(datatable);
    }
    else if(e.target.name === 'is_required') {
      const isrequired = e.target.value;
      setisrequired(isrequired);
    }
    else if(e.target.name === 'is_unique') {
      const isunique = e.target.value;
      setisunique(isunique);
    }
    else if(e.target.name === 'is_filterable') {
      const isfilterable = e.target.value;
      setisfilterable(isfilterable);
    }
    else if(e.target.name === 'options_collection') {
      const options_collection = e.target.value;
      setoptions_collection(options_collection);
    }
  } 
  
  function addRow(){
    var saddRow = rows;
    saddRow = saddRow + 1;
    setrows(saddRow);
  }

  function remove_option(index){
    document.getElementById('parent'+index).remove();
  }


  return (
    <>
      <div className="app-main__outer">
        <div className="app-main__inner">
          <div className="app-page-title">
            <div className="page-title-wrapper justify-content-left">
              <div className="page-title-heading fw-bold">
                <div className="page-title-icon">
                  <i className="pe-7s-pen icon-gradient bg-happy-green" />
                </div>
                <div>{t('Update Attribute')}
                  <div className="page-title-subheading">
                  {t('Edit the form below to update an Attribute.')}
                  </div>
                </div>
              </div>
            </div>
          </div>
        
          <div className="d-flex justify-content-center">
            <div className="col-md-12">
              <Card className="main-card mb-3">
                <CardBody>
                  <LoadingOverlay tag="div" active={loading}
                    styles = {{overlay: (base) => ({...base})}}
                    spinner = {<DNALoader/>}>  
                    <Form onSubmit={handleSubmit} ref={form} default>
                      {!successful && (
                        <div>
                          <div className="row" >
                            <div className="col-md-6 col-xl-6" >
                              <div className="form-group">
                                <label htmlFor="name">{t('Field Label')}</label>
                                <Input type="text" className="form-control" placeholder={t('Attribute Field Label')} value = {dataObj.field_lable}  name="field_lable" id="field_lable"    />
                              </div>
                            </div> 
                            <div className="col-md-6 col-xl-6" >
                              <div className="form-group">
                                <label htmlFor="page_title">{t('Field Type')}</label>
                                <select className="form-select" name="field_type" value={fieldtype} onChange={handleFunctionChange}  >
                                  <option disabled={true} value="">{t('--Choose an option--')}</option>
                                  <option  value ="text">{t('Text Input')}</option>
                                  <option  value ="number">{t('Number Input')}</option>
                                  <option  value ="email">{t('Email Input')}</option>
                                  <option  value ="password">{t('Password Input')}</option>
                                  <option  value ="dropdown">{t('Drop Down')}</option>
                                  <option  value ="checkbox">{t('CheckBox')}</option>
                                  <option  value ="radio">{t('Radio Button')}</option>
                                  <option  value ="file">{t('File')}</option>
                                </select>
                              </div>
                            </div>
                          </div>                      
                          <br/>
                          <div className="row">
                            <div className="col-md-6 col-xl-6">
                              <div className="form-group " >
                                <label htmlFor="menu_label">{t('Select Form')}</label>
                                <select className="form-select" name="crud_id"  value={crudid}     onChange={handleFunctionChange}   >
                                  <option disabled={false} value="">{t('--Choose an option--')}</option>
                                  {cruds &&
                                    cruds.map((crud, inde) => (
                                    <option key = {inde} value={crud._id}>{crud.name}</option>
                                    ))
                                  }
                                </select>
                              </div>
                            </div>

                            <div className="col-md-6 col-xl-6">    
                              <div className="form-group">
                                <label htmlFor="referal_name">{t('Referal Name')}</label>
                                <Input type="text" className="form-control" placeholder={t('Referal Name')}  value = {dataObj.referal_name} name="referal_name" id="referal_name"    />
                              </div>
                            </div>
                            
                          </div>
                          <br/>
                          <div className="row">
                           
                            <div className="col-md-6 col-xl-6">
                              <div className="form-group" >
                                <label htmlFor="referal_name">{t('Is Editable')}</label>
                                <select className="form-select" name="is_editable"  value={iseditable}   onChange={handleFunctionChange}    >
                                  <option disabled={true}  value="">{t('--Choose an option--')}</option>
                                  <option key="1" value ="1">{t('Yes')}</option>
                                  <option key="0" value ="0">{t('No')}</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-md-6 col-xl-6">
                              <div className="form-group" >
                                <label htmlFor="referal_name">{t('Show in DataTable')}</label>
                                <select className="form-select" name="datatable" value = {datatable}   onChange={handleFunctionChange}  >
                                  <option disabled={true}  value="">{t('--Choose an option--')}</option>
                                  <option key="1" value ="1">{t('Yes')}</option>
                                  <option key="0" value ="0">{t('No')}</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <br/>
                          <div className="row">
                            
                            <div className="col-md-6 col-xl-6">
                              <div className="form-group" >
                                <label htmlFor="referal_name">{t('Is Required')}</label>
                                <select className="form-select" name="is_required" value={isrequired}   onChange={handleFunctionChange}   >
                                  <option disabled={true}  value="">{t('--Choose an option--')}</option>
                                  <option key="1" value ="1">{t('Yes')}</option>
                                  <option key="0" value ="0">{t('No')}</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-md-6 col-xl-6">
                              <div className="form-group" >
                                <label htmlFor="referal_name">{t('Is Unique')}</label>
                                <select className="form-select" name="is_unique" value={isunique}   onChange={handleFunctionChange}   >
                                  <option disabled={true}  value="">{t('--Choose an option--')}</option>
                                  <option key="1" value ="1">{t('Yes')}</option>
                                  <option key="0" value ="0">{t('No')}</option>
                                </select>
                              </div>
                            </div>
                          </div> 
                          <br/>
                          <div className="row">
                           
                            
                            <div className="col-md-6 col-xl-6">
                              <div className="form-group" >
                                <label htmlFor="referal_name">{t('Is Filterable')}</label>
                                <select className="form-select" name="is_filterable" value={isfilterable}   onChange={handleFunctionChange}   >
                                  <option disabled={true}  value="">{t('--Choose an option--')}</option>
                                  <option key="1" value ="1">{t('Yes')}</option>
                                  <option key="0" value ="0">{t('No')}</option>
                                </select>
                              </div>
                            </div>
                          </div> 
                          <br/>
                          <div className="row">
                                
                            <div className="col-md-6 col-xl-6">
                                <div className="form-group" >
                                <label htmlFor="referal_name">{t('Step No')}</label>
                                <Input type="number" className="form-control" placeholder={t('Step No')} value = {dataObj.step}  name="step" id="step"    />
                                </div>
                            </div>
                            <div className="col-md-6 col-xl-6">
                                <div className="form-group" >
                                <label htmlFor="referal_name">{t('Sort No')}</label>
                                <Input type="number" className="form-control" placeholder={t('Sort No')}  value = {dataObj.sort} name="sort" id="sort"    />
                                </div>
                            </div>
                        </div>
                        <br/>
                          
                          <div className="row">
                            <div className="col-md-6 col-xl-6">
                                  <div className="form-group" >
                                  <label htmlFor="referal_name">{t('Get Options From')}</label>
                                  <select className="form-select" name="options_collection" value={options_collection}   onChange={handleFunctionChange}  id="options_collection"      >
                                      <option disabled={false} value="">{t('--Choose an option--')}</option>
                                      {options_collection_arr &&
                                        options_collection_arr.map((opt__collection_arr, inde) => (
                                        <option key = {inde} value={opt__collection_arr.name}>{opt__collection_arr.name}</option>
                                        ))
                                      }
                                  </select>
                                  </div>
                              </div>
                              <div className="col-md-6 col-xl-6">
                                  <div className="form-group" >
                                    <label htmlFor="referal_name">{t('Options Field Name')}</label>
                                    <Input type="text" className="form-control" placeholder={t('Options Field Name')} value = {dataObj.Options_Field_Name}  name="Options_Field_Name" id="Options_Field_Name"    />
                                  </div>
                              </div>
                          </div>
                            <br/>
                          <div className="row">
                            <div className="col-md-6 col-xl-6">
                              <h5>{t('Add Attribute Options (if any)')}</h5>
                            </div>
                          </div>   
                          <br/>
                          
                          {Array.from({ length: rows }, (_, index) => {
                            var optionlabel = "";
                            var optionvalue ="";
                            console.log(dataObjoptions.length);
                            if( index < dataObjoptions.length ){
                              optionlabel = dataObjoptions[index].label;
                              optionvalue = dataObjoptions[index].value;
                            }
                            return (
                              <div className="row " id={"parent"+index}>
                                <div className="col-md-6 col-xl-6" >
                                  <div className="form-group">
                                    <label htmlFor="name">{t('Option Label')}</label>
                                    <Input type="text" className="form-control" placeholder={t('Attribute Option Label')} id={"option_lable"+index} value={optionlabel} name="label[]"     />
                                  </div>
                                </div> 
                                <div className="col-md-5 col-xl-5" >
                                  <div className="form-group">
                                    <label htmlFor="name">{t('Option Value')}</label>
                                    <Input type="text" className="form-control" placeholder={t('Attribute Option Value')} id={"option_value"+index} value={optionvalue}  name="value[]"     />
                                  </div>
                                </div> 
                                <div className="col-md-1 col-xl-1" >
                                  <div className="form-group">
                                    <br/>
                                    <a onClick={() =>remove_option(index)} style={{ cursor: "pointer" }}>
                                      <button className="btn-icon btn-icon-only btn-shadow btn-outline-2x btn btn-outline-danger">
                                        <i className="pe-7s-close-circle btn-icon-wrapper"> </i>
                                      </button>
                                    </a>
                                  </div>
                                </div> 
                              </div>
                            )
                          })}
                          
                          <br/> 
                          <div className="row">
                            <div className="col-md-2 col-xl-2" >
                              <a  onClick={() => addRow()} className="btn btn-custom-color btn-hover-shine">{t('Add More')}</a>
                            </div>
                          </div> 
                          <br/>
                          <div className="form-group">
                            <div className="col-md-12 col-xl-12 ">
                              <center>
                                <button type="button" onClick={() => navigate(-1)} className="btn btn-outline-light btn-hover-shine me-3 mt-3">{t('Cancel')}</button>
                                <button type="submit" className="btn btn-custom-color btn-hover-shine me-3 mt-3" >{t('Update Attribute')}</button>
                              </center>
                            </div>
                          </div>
                        </div>
                      )}

                      {message && (
                        <div className="form-group">
                          <br/>
                          <div
                            className={
                              successful ? "alert alert-success" : "alert alert-danger"
                            }
                            role="alert"
                          >
                            {message}
                          </div>
                        </div>
                      )}
                      <CheckButton style={{ display: "none" }} ref={checkBtn} />
                    </Form>
                  </LoadingOverlay>
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
      </div>
    
    </>
  );


}
export default UpdateAttribute;