import React, { useEffect, useState, useRef, Fragment } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Row,
  Col,
  Card,
  CardBody,
  Input
} from "reactstrap";
import DateRangePickerComponent from './DateRangePickerComponent';
import styled from "styled-components";

import ServicesService from "./../Services Management/services.service.js";
import CustomerService from "./../Customers/Customer.service.js";
import moment from 'moment';
import { CSVLink } from 'react-csv';

import FleetService from "../Fleet Management/fleet.service";
import DriverService from "../Drivers/Driver.service";

import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../../components/Loaders/DnaLoader";
import "../../../assets/custom css/loadoverlay.scss";
import Alerts from "../../../components/Alerts/alerts";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faLockOpen, faLock, faKey } from "@fortawesome/free-solid-svg-icons";

// import CustomerService from "./Customer.service";
import AuthService from "../../Authentication/auth.service";
import DataTable from 'react-data-table-component';

library.add(
  faLockOpen,
  faLock,
  faKey
);



const CustomerOrders = () => {
  const [isHovered, setIsHovered] = useState(false);

  const handleHover = () => {
    setIsHovered(!isHovered);
  };

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [rows, setrows] = useState([]);
  const [csvData, setCSVData] = useState([]);
  const [services, setServices] = useState([]);
  const [fleets, setFleets] = useState([]);
  const [packagings, setPackagings] = useState([]);
  const [packagingSizes, setPackagingSizes] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [currentPage, setCurrentPage] = useState("");
  const [totalRows, setTotalRows] = useState(0);

  const [param, setParam] = useState("allorders");
  const [singleOrderdata, setSingleOrderData] = useState("");
  const [orderDetails, setOrderDetails] = useState([]);

  const [fservice_id, setfservice_id] = useState("");
  const [fpackaging_id, setfpackaging_id] = useState("");
  const [fpackaging_size_id, setfpackaging_size_id] = useState("");
  const [myDataPopObj, setmyDataPopObj] = useState([]);
  const [searchStartDate, setsearchStartDate] = useState('');
  const [searchEndDate, setsearchEndDate] = useState('');
  const [searchStartDateOrder, setsearchStartDateOrder] = useState('');
  const [searchEndDateOrder, setsearchEndDateOrder] = useState('');

  const [searchName, setsearchName] = useState('');
  const [searchRegNo, setsearchRegNo] = useState('');
  const [searchEmail, setsearchEmail] = useState('');
  const [searchPhone, setsearchPhone] = useState('');
  const [pageLimit, setPageLimit] = useState(10);

  const [searchStatus, setsearchStatus] = useState('');

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    setViewFtn('allorders', "reload")
    getPackaging();
    getPackagingSizes();
    getFleets();
    getServices();
    getDrivers();
    setOrderDetails([]);
  }, [pageLimit]);


  function setViewFtn(param, data) {
    setParam(param);
    if (param === 'allorders') {
      setSingleOrderData("");
      setOrderDetails([]);
      if (data === "reload") {
        fetchCustomerOrders(0, pageLimit);
      }
    }
    if (param === 'orderdetails') {
      setSingleOrderData(data)
    }
    else {
      setSingleOrderData("")
    }
    setmyDataPopObj([]);
    setfservice_id("");
    setfpackaging_id("");
    setfpackaging_size_id("");
    setrows([]);
    addRow1();
  }


  function addRow1() {
    setrows(1);
  };

  function getServices() {
    var getData = {
      functionName: "GetServices",
      postData: {},
    }
    ServicesService.getAllServices(getData).then((response) => {
      console.log("Get Services response:-", response);
      setServices(response.data.data)
    },
      (error) => {
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent)
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert("Server Error, Please try again later.")
        }
      })
  }

  function getPackaging() {
    setLoading(true);
    ServicesService.getPackaging().then((response) => {
      setLoading(false);
      console.log("Get Packagings response:-", response)
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        setPackagings(response.data.data);
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent)
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  };
  const Label = styled.label`
  font-weight: bold;
`;

  function getPackagingSizes() {
    setLoading(true);
    ServicesService.getPackagingSizes().then((response) => {
      setLoading(false);
      console.log("Get PackagingSizes response:-", response)
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        setPackagingSizes(response.data.data);
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent)
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  };

  function getFleets() {
    var getData = {
      functionName: "GetFleets",
      postData: {
      },
    }
    setLoading(true);
    FleetService.getAllFleets(getData).then((response) => {
      setLoading(false);
      console.log("Get Fleets response:-", response)
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        setFleets(response.data.data);
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent)
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  };

  function getDrivers() {
    var getData = {
      functionName: "GetDrivers",
      postData: {
      },
    }
    setLoading(true);
    DriverService.getAllDrivers(getData).then((response) => {
      setLoading(false);
      console.log("Get Drivers response:-", response)
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        setDrivers(response.data.data);
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent)
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  };


  async function handlePerRowsChange(newPageLimit, page_index) {
    setPageLimit(newPageLimit);
  }

  function fetchCustomerOrders(page_index, page_limit) {
    var filter = {};
    if (searchName !== "") {
      console.log("Search name:-", searchName)
      filter["searchName"] = searchName
    }
    if (searchRegNo !== "") {
      console.log("Search Reg No:-", searchRegNo)
      filter["reg_no"] = searchRegNo
    }
    if (searchEmail !== "") {
      filter["email"] = searchEmail
    }
    if (searchPhone !== "") {
      filter["phone"] = searchPhone
    }
    if (searchStatus !== "") {
      filter["order_status"] = searchStatus
    }
    if (searchStartDate !== "" && searchEndDate !== "") {
      console.log("Search Reg No:-", searchStartDate)
      filter["startDate"] = searchStartDate
      filter["endDate"] = searchEndDate
    }
    if (searchStartDateOrder !== "" && searchEndDateOrder !== "") {
      filter["startDate_order"] = searchStartDateOrder
      filter["endDate_order"] = searchEndDateOrder
    }
    var offset = (page_index - 1) * page_limit;
    if (offset < 1) {
      offset = 0;
    }
    var getData = {
      functionName: "GetOrdersByFilter",
      postData: {
        "sort": { _id: -1 },
        "offset": offset,
        "limit": page_limit,
        "filter": filter
      },
    }
    setLoading(true);
    CustomerService.getCustomerOrders(getData).then((response) => {
      setLoading(false);
      console.log("Get Customer Orders response:-", response)
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        setOrders(response?.data?.data);
        console.log("response", response)
        setTotalRows(response?.data?.count?.count);

        const mappedData = response?.data?.data?.map(order => ({
          company_name: order.userDetails?.[0]?.company_name ?? '',
          order_date: moment(order.created_at).format(`DD-MM-YYYY`),
          order_no: order.order_no,
          vehicle: order.fleetDetails?.[0]?.name ?? '',
          driver: order.driverDetails?.[0]?.name ?? '',
          order_status: order.order_status,
          pick_datetime: moment(order.pick_datetime).format(`DD-MM-YYYY`),
          invoice_status: order.invoice_status,
        }));
        setCSVData(mappedData)
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent)
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  };
  const mycolumns = [
    {
      name: "Customer Name",
      selector: (row) => {
        if (row.userDetails[0]) {
          return (
            <div>
              {row.userDetails[0].company_name}
            </div>
          )
        }
      }
    },
    {
      name: "Order date",
      selector: row => moment(row.created_at).format("DD-MM-YYYY")
    },
    {
      name: "Order No.",
      selector: "order_no",
    },
    // {
    //   name: "Service",
    //   selector: row => {
    //     for (let i = 0; i < services.length; i++) {
    //       if (row.orderDetails[0]?.service_id === services[i]._id) {
    //         return (
    //           <div>
    //             {services[i].name}
    //           </div>
    //         );
    //       }
    //     }
    //   }
    // },
    {
      name: t("Driver/Vehicle"),
      selector: (row) => {
        if (row.fleetDetails.length !== 0) {
          return (
            <div>
              {`${row.driverDetails[0]?.name}/${row.fleetDetails[0]?.name}`}
            </div>
          )
        } else {
          return (
            <div>
              Not Assigned
            </div>
          )
        }
      }
    },
    {
      name: t('Status'),
      width: '15%',
      selector: (row) => {
        return (
          <div>
            {row.order_status === "Assigned" ?
              <button type="button" style={{ borderColor: '#F39026', backgroundColor: '#F39026' }}
                className="btn btn-success btn-hover-shine me-3 btn">{row.order_status}
              </button>
              :
              row.order_status === "Cancelled" ?
                <button type="button"
                  className="btn btn-danger btn-hover-shine me-3 btn">{row.order_status}
                </button>
                :
                row.order_status === "Postponed" ?
                  <button type="button" style={{ color: 'white', borderColor: '#6056d6', backgroundColor: '#6056d6' }}
                    className="btn btn-c btn-hover-shine me-3 btn">{row.order_status}
                  </button>
                  :
                  row.order_status === "Quoted" ?
                    <button type="button" style={{ borderColor: '#1BD35A', backgroundColor: '#1BD35A' }}
                      className="btn btn-c btn-hover-shine me-3 btn">{row.order_status}
                    </button>
                    :
                    row.order_status === "Completed" ?
                      <button type="button"
                        className="btn btn-success btn-hover-shine me-3 btn">{row.order_status}
                      </button>

                      :
                      row.order_status === "Confirmed" ?
                        <button type="button" style={{ borderColor: '#609FFC', backgroundColor: '#609FFC' }}
                          className="btn btn-info btn-hover-shine me-3 btn">{row.order_status}
                        </button>
                        :
                        row.order_status === "Invoiced" ?
                          <button type="button" style={{ borderColor: '#ebb734', backgroundColor: '#ebb734' }}
                            className="btn btn-info btn-hover-shine me-3 btn">{row.order_status}
                          </button>
                          :
                          <button type="button" style={{ borderColor: '#42b6f5', backgroundColor: '#42b6f5' }}
                            className="btn btn-info btn-hover-shine me-3 btn">{row.order_status}
                          </button>
            }
          </div>
        );
      }
    },
    {
      name: t("Pickup Date"),
      selector: row => row.pick_datetime ? moment(row.pick_datetime).format("DD-MM-YYYY HH:mm A") : 'Not Assigned',
    },

  ];

  function resetFilters() {
    setResetPaginationToggle(!resetPaginationToggle);
    setsearchName('');
    setsearchRegNo('');
    setsearchEmail('');
    setsearchPhone('');
    setsearchStatus('');
    setsearchStartDate('');
    setsearchEndDate('');
    setsearchStartDateOrder('');
    setsearchEndDateOrder('');
    var offset = 0;
    var getData = {
      functionName: "GetOrdersByFilter",
      postData: {
        "sort": { _id: -1 },
        "offset": offset,
        "limit": pageLimit,
        "filter": {}
      },
    }
    setLoading(true);
    CustomerService.getCustomerOrders(getData).then((response) => {
      setLoading(false);
      console.log("Get Customers response:-", response)
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        setOrders(response.data.data);
        setTotalRows(response.data.count.count);
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent)
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  }
  function searchCustomers() {
    setResetPaginationToggle(!resetPaginationToggle);
    var page = 0
    handlePageChange(page++)
  }
  const downloadCSV = () => {
    var getData = {
      functionName: "GetOrders",
      postData: {
        // user_id: state._id
      },

    }
    setLoading(true);
    CustomerService.getCustomerOrders(getData).then((response) => {
      setLoading(false);
      if (response.status === 200) {
        // Extract column headers from the first data row
        const data = response.data.data;
        if (data.length > 0) {
          // Get all available column names from the first row
          const header = Object.keys(data[0]);

          // Define the columns to exclude
          const columnsToExclude = ['_id', "created_by"];

          // Filter the header to exclude unwanted columns
          const filteredHeader = header.filter(column => !columnsToExclude.includes(column));

          // Filter the data to exclude corresponding properties
          const filteredData = data.map(row => {
            const filteredRow = {};
            for (const key in row) {
              if (!columnsToExclude.includes(key)) {
                filteredRow[key] = row[key];
              }
            }
            return filteredRow;
          });

          // Create a CSV content with filtered headers and data
          const csv = [filteredHeader.join(','), ...filteredData.map(row => filteredHeader.map(fieldName => row[fieldName]).join(','))].join('\n');

          // Create a Blob with the CSV data
          const blob = new Blob([csv], { type: 'text/csv' });

          // Create a download link
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = 'data.csv';

          // Trigger the download
          a.click();

          // Clean up
          window.URL.revokeObjectURL(url);
        } else {
          console.log("No data to download.");
        }
      } else if (response.status === 401) {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      } else {
        console.log("Response status: " + response.status);
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent);
        if (errContent) {
          Alerts.swalErrorAlert(errContent);
        } else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."));
        }
      })
  }

  function handlePageChange(page_index) {
    console.log("Page index:-", page_index)
    setCurrentPage(page_index)
    fetchCustomerOrders(page_index, pageLimit);
  }
  const handleDateChange = (startDateFormatted, endDateFormatted) => {
    setsearchStartDate(startDateFormatted);
    setsearchEndDate(endDateFormatted);
  };
  const handleDateChangeOrder = (startDateFormatted, endDateFormatted) => {
    setsearchStartDateOrder(startDateFormatted);
    setsearchEndDateOrder(endDateFormatted);
  };

  const headers = [
    { label: "Company Name", key: "company_name", style: { fontWeight: "bold", backgroundColor: "lightgray", color: "blue" } },
    { label: "Order Date", key: "order_date", style: { fontWeight: "bold", backgroundColor: "lightgray", color: "blue" } },
    { label: "Order Number", key: "order_no", style: { fontWeight: "bold", backgroundColor: "lightgray", color: "blue" } },
    { label: "Order No.", key: "order_no", style: { fontWeight: "bold", backgroundColor: "lightgray", color: "blue" } },
    { label: "Vehicle", key: "vehicle", style: { fontWeight: "bold", backgroundColor: "lightgray", color: "blue" } },
    { label: "Driver", key: "driver", style: { fontWeight: "bold", backgroundColor: "lightgray", color: "blue" } },
    { label: "Order Status", key: "order_status", style: { fontWeight: "bold", backgroundColor: "lightgray", color: "blue" } },
    { label: "Pickup Date", key: "pick_datetime", style: { fontWeight: "bold", backgroundColor: "lightgray", color: "blue" } },
    // { label: "invoice Status", key: "invoice_status", style: { fontWeight: "bold", backgroundColor: "lightgray", color: "blue" } },
  ];

  const linkStyle = {
    textDecoration: 'none',
    color: isHovered ? 'black' : 'white',
  };

  return (
    <Fragment>
      <div className="app-main__outer">
        <div className="app-main__inner">
          <div className=" bg-light-gray bg-animation ">
            <div className="d-flex  justify-content-center align-items-center">
              <Col md="12" className="mx-auto app-login-box">
                <div className="app-page-title">
                  <div className="page-title-wrapper">
                    <div className="page-title-heading fw-bold">
                      <div className="page-title-icon">
                        <i className="pe-7s-cart icon-gradient bg-tempting-azure" />
                      </div>
                      <div>
                        {t("Order Reports")}
                        <div className="page-title-subheading">
                          {t('View & Download Reports of Orders.')}
                        </div>
                      </div>

                    </div>
                    <div className="page-title-actions">
                      <button type="button" onClick={() => navigate(-1)} className="btn btn-outline-success btn-hover-shine me-3">
                        Back
                      </button>
                    </div>
                  </div>
                </div>
                <Row>



                  <Fragment>
                    <Row>
                      <Col>
                        <Card className="main-card mb-3">
                          <CardBody>
                            <LoadingOverlay tag="div" active={loading}
                              styles={{ overlay: (base) => ({ ...base }) }}
                              spinner={<DNALoader />}>
                              <Row className="mt-2">
                                <Col xs="24" sm="12" md="6" lg="4" xl="3">
                                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Input style={{
                                      width: "49%",
                                    }}
                                      type="text" name="fname" value={searchName} placeholder={t("Search Text")} onChange={event => setsearchName(event.target.value)} />
                                    <Input
                                      type="select"
                                      placeholder={t("Search Status")}
                                      value={searchStatus}
                                      style={{
                                        width: "49%",
                                        border: '1px solid #ccc',
                                      }}
                                      onChange={(event) => setsearchStatus(event.target.value)}
                                      // onKeyDown={handleKeyDown}
                                      id="colfilter"
                                    >

                                      <option disabled={false} value="">Select Status</option>
                                      <option value='Quoted'>Quoted</option>
                                      <option value='Assigned'>Assigned</option>
                                      <option value='Invoiced'>Invoiced</option>
                                      <option value="Postponed">{t('Postponed')}</option>
                                      <option value='Cancelled'>Cancelled</option>
                                      <option value='Completed'>Completed</option>
                                      <option value='Confirmed'>Confirmed</option>

                                    </Input>

                                  </div>
                                </Col>

                                <Col xs="24" sm="12" md="12" lg="8" xl="6">
                                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div style={{
                                      background: '#F9F9FB',
                                      height: '35px',
                                      width: '49%',
                                      border: '1px solid #ccc',
                                      borderRadius: '6px',
                                      marginBottom: '20px',
                                      display: 'flex',
                                      alignItems: 'center'
                                    }}>
                                      <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Label style={{ background: '#F9F9FB', paddingRight: '10px' }}>
                                          {t('Pickup')}
                                        </Label>
                                        <div className="d-flex justify-content-end align-items-center h-100 pr-2">
                                          <div style={{ borderRight: '2px solid #ccc', height: '4%' }}></div>
                                        </div>
                                        {/* DateRangePickerComponent for Pickup Date */}
                                        <DateRangePickerComponent onDateChange={handleDateChange} />
                                      </div>
                                    </div>

                                    <div style={{
                                      background: '#F9F9FB',
                                      height: '35px',
                                      width: '49%',
                                      border: '1px solid #ccc',
                                      borderRadius: '6px',
                                      marginBottom: '20px',
                                      display: 'flex',
                                      alignItems: 'center'
                                    }} className="mb-2">
                                      <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Label style={{ background: '#F9F9FB', paddingRight: '10px' }}>
                                          {t('Order')}
                                        </Label>
                                        <div className="d-flex justify-content-end align-items-center h-100 pr-2">
                                          <div style={{ borderRight: '2px solid #ccc', height: '4%' }}></div>
                                        </div>
                                        {/* DateRangePickerComponent for Order Date */}
                                        <DateRangePickerComponent style={{}} onDateChange={handleDateChangeOrder} />
                                      </div>
                                    </div>
                                  </div>

                                </Col>


                                <Col xs="12" sm="6" md="6" lg="4" xl="3" className="mb-2">
                                  <button type="button" className="btn btn-custom-color btn-hover-shine" onClick={() => searchCustomers()}>Search</button>
                                  <button type="button" className="btn btn-custom-color btn-hover-shine ms-2" onClick={() => resetFilters()}>Reset</button>
                                  <CSVLink
                                    headers={headers}
                                    data={csvData}
                                    onMouseEnter={handleHover}
                                    onMouseLeave={handleHover}
                                    filename={"OrderReports.csv"}
                                    style={linkStyle}
                                    className="btn btn-custom-color btn-hover-shine ms-2"
                                  >
                                    Download
                                  </CSVLink>
                                </Col>

                                <Col md="12" className="pt-3">
                                  <DataTable
                                    data={orders}
                                    columns={mycolumns}
                                    pagination
                                    fixedHeader
                                    fixedHeaderScrollHeight="400px"
                                    paginationServer
                                    paginationTotalRows={totalRows}
                                    onChangePage={handlePageChange}
                                    onChangeRowsPerPage={handlePerRowsChange}
                                    paginationResetDefaultPage={resetPaginationToggle}
                                  />
                                </Col>
                              </Row>


                            </LoadingOverlay>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Fragment>

                </Row>
              </Col>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
};
export default CustomerOrders;