import React, { useEffect, useState, Fragment } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import DataTable from 'react-data-table-component';
import {
  Row,
  Col
} from "reactstrap";

import { useTranslation } from 'react-i18next';

import Tabs, { TabPane } from "rc-tabs";
import TabContent from "rc-tabs/lib/SwipeableTabContent";
import ScrollableInkTabBar from "rc-tabs/lib/ScrollableInkTabBar";
import BasicInformation from "./Driver Components/BasicInformation";
import DriverDocuments from "./Driver Components/DriverDocuments";


const DriverDetails = () => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const { state } = location;
  console.log("Received Object:-", state);
  

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
  }, []);


  

  return (
    <Fragment>
      <div className="app-main__outer">
        <div className="app-main__inner">
          <div className="bg-light-gray bg-animation">
            <div className="d-flex justify-content-center align-items-center">
              <Col md="12" className="mx-auto app-login-box">
                <div className="app-page-title mb-1">
                  <div className="page-title-wrapper">
                    <div className="page-title-heading fw-bold">
                      <div className="page-title-icon">
                        <i className="pe-7s-note2 icon-gradient bg-tempting-azure" />
                      </div>
                        <div>
                          Driver Details
                          <div className="page-title-subheading">
                           View Basic Information and uploaded Documents of Driver.
                          </div>
                        </div>
                    </div>
                    <div className="page-title-actions">
                      <button type="button" onClick={() => navigate(-1)} className="btn btn-outline-success btn-hover-shine me-3">
                        Back
                      </button>
                    </div>
                  </div>
                </div>

                <Row>
                  <Tabs defaultActiveKey="1" renderTabBar={() => <ScrollableInkTabBar />} renderTabContent={() => <TabContent />}>
                    <TabPane tab="Basic Information" key="1">
                      <BasicInformation />
                    </TabPane>
                    <TabPane tab="Documents" key="2">
                      <DriverDocuments />
                    </TabPane>
                  </Tabs>
                </Row>

                {/* <Row className="mt-5">
                                    <Col md="6">
                                        <h4 className="fw-bold ">Driver Documents</h4>
                                    </Col>
                                    <Col md="6">
                                        <AddDriverDocuments data={state._id} />
                                    </Col>
                                    <Col md="12" className="pt-3">
                                        <LoadingOverlay tag="div" active={loading}
                                            styles={{ overlay: (base) => ({ ...base }) }}
                                            spinner={<DNALoader />}>
                                            <DataTable
                                                data={documents}
                                                columns={mycolumns}
                                                pagination
                                                fixedHeader
                                                fixedHeaderScrollHeight="400px"
                                            />
                                        </LoadingOverlay>
                                    </Col>
                                </Row> */}
              </Col>
            </div>

          </div>
        </div>
      </div>
    </Fragment>
  );
};


export default DriverDetails;

