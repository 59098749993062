import React, { Fragment, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  Input,
  FormGroup,
  Label,
} from "reactstrap";
import { Form, Field } from "react-final-form";

import ServicesService from "./services.service";
import AuthService from "../../Authentication/auth.service";
import { useTranslation } from 'react-i18next';

import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../../components/Loaders/DnaLoader";
import "../../../assets/custom css/loadoverlay.scss";

import Alerts from "../../../components/Alerts/alerts";


const AddService = () => {

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [rows, setrows] = useState([]);
  const [packagings, setPackagings] = useState([]);
  // const [packagingSizes, setPackagingSizes] = useState([]);

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    addRow1();
    getPackaging();
    // getPackagingSizes();
  }, []);

  function addRow1() {
    setrows(1);
  };

  function getPackaging() {
    setLoading(true);
    ServicesService.getPackaging().then((response) => {
      setLoading(false);
      console.log("Get Packagings response:-", response)
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        setPackagings(response.data.data);
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent)
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  };

  // function getPackagingSizes() {
  //   setLoading(true);
  //   ServicesService.getPackagingSizes().then((response) => {
  //     setLoading(false);
  //     console.log("Get PackagingSizes response:-", response)
  //     if (response.data.message === "Unauthorized") {
  //       AuthService.logout();
  //       navigate("/login");
  //       window.location.reload();
  //     }
  //     else {
  //       setPackagingSizes(response.data.data);
  //     }
  //   },
  //     (error) => {
  //       setLoading(false);
  //       const errContent =
  //         (error.response && error.response.data) || error.message || error.toString();
  //       console.log("Net:-", errContent)
  //       if (errContent) {
  //         Alerts.swalErrorAlert(errContent)
  //       }
  //       else {
  //         Alerts.swalErrorAlert(t("Server Error, Please try again later."))
  //       }
  //     })
  // };


  function addRow() {
    var saddRow = rows;
    saddRow = saddRow + 1;
    setrows(saddRow);
  }

  function remove_option(index) {
    if (index !== 0) {
      document.getElementById('parent' + index).remove();
    }
  }


  const onSubmit = values => {
    const elements1 = Array.from(
      document.getElementsByClassName('packclass')
    );
    var pack = []
    elements1.forEach(element => {
      console.log(element.value);
      pack.push(element.value)
    });

    // const elements2 = Array.from(
    //   document.getElementsByClassName('sizeclass')
    // );
    // var sizes = []
    // elements2.forEach(element => {
    //   console.log(element.value);
    //   sizes.push(element.value)
    // });

    const elements3 = Array.from(
      document.getElementsByClassName('priceclass')
    );
    var prices = []
    elements3.forEach(element => {
      console.log(element.value);
      prices.push(element.value)
    });

    console.log(values);
    var payloadData = {
      functionName: "AddService",
      postData: {
        name: values.service_name,
        code: values.service_code,
        description: values.service_desc,
        packaging_id: pack,
        // packaging_size_id: sizes,
        price: prices
      }
    }
    setLoading(true);
    ServicesService.addService(payloadData).then((response) => {
      console.log("API Response:-", response);
      setLoading(false);
      if (response.data.status) {
        navigate("/services-list")
        if (response.data.message !== '') {
          Alerts.swalSuccessAlert(response.data.message)
        }
        else {
          Alerts.swalSuccessAlert(t("Service added successfully."));
        }
      }
      else {
        if (response.data.message !== '') {
          if (response.data.message === "Unauthorized") {
            AuthService.logout();
            navigate("/login");
            window.location.reload();
          }
          else {
            Alerts.swalErrorAlert(response.data.message);
          }
        }
        else {
          Alerts.swalErrorAlert(t("Error: Can't add Service at the moment. Please try again later."))
        }
      }
    },
      (error) => {
        setLoading(false);
        const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        Alerts.swalErrorAlert(t("Network Operation failed."))
      });
  }

  const validateFtn = (values) => {
    const errors = {};
    if (!values.service_name) {
      errors.service_name = t("Service Name Required.");
    }
    if (!values.service_code) {
      errors.service_code = t("Service Code Required.");
    }
    if (!values.service_desc) {
      errors.service_desc = "Service Description Required.";
    }
    return errors;
  };

  return (
    <Fragment>
      <div className="app-main__outer">
        <div className="app-main__inner">
          <div className="bg-light-gray bg-animation">
            <div className="d-flex  justify-content-center align-items-center">
              <Col md="12" className="mx-auto app-login-box">
                <div className="app-page-title">
                  <div className="page-title-wrapper">
                    <div className="page-title-heading fw-bold">
                      <div className="page-title-icon">
                        <i className="lnr-cog icon-gradient bg-tempting-azure" />
                      </div>
                      <div>
                        Add Service
                      </div>
                    </div>
                    <div className="page-title-actions">
                      <button type="button" onClick={() => navigate(-1)} className="btn btn-outline-success btn-hover-shine me-3">
                        Back
                      </button>
                    </div>
                  </div>
                </div>



                <Row>
                  <Col md="12" className="pt-3">
                    <Card className="main-card mb-3">
                      <CardBody>
                        <LoadingOverlay tag="div" active={loading}
                          styles={{ overlay: (base) => ({ ...base }) }}
                          spinner={<DNALoader />}>
                          <Form onSubmit={onSubmit}
                            validate={validateFtn}
                            render={({ handleSubmit, values, submitting, validating, valid }) => (
                              <form onSubmit={handleSubmit}>
                                <Row >
                                  <h4 className="fw-bold ">Basic Information</h4>
                                  <Col md="6">
                                    <Row className="align-items-center">
                                      <Col md="4"><Label className="pb-1">Name</Label></Col>
                                      <Col md="8">
                                        <FormGroup>
                                          <Field name="service_name">
                                            {({ input, meta }) => (
                                              <div>
                                                <Input {...input} type="text" placeholder="Service Name"
                                                  invalid={meta.error && meta.touched}
                                                />
                                                {meta.error && meta.touched &&
                                                  <span className="text-danger">{meta.error}</span>}
                                              </div>
                                            )}
                                          </Field>
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col md="6">
                                    <Row className="align-items-center">
                                      <Col md="4"><Label className="pb-1">Code</Label></Col>
                                      <Col md="8">
                                        <FormGroup>
                                          <Field name="service_code">
                                            {({ input, meta }) => (
                                              <div>
                                                <Input {...input} type="text" placeholder="Service Code"
                                                  invalid={meta.error && meta.touched}
                                                />
                                                {meta.error && meta.touched &&
                                                  <span className="text-danger">{meta.error}</span>}
                                              </div>
                                            )}
                                          </Field>
                                        </FormGroup>
                                      </Col>
                                    </Row>

                                  </Col >
                                  <Col md="12" >
                                    <Row className="align-items-center">
                                      <Col md="2"><Label className="pb-1">Description</Label></Col>
                                      <Col md="10">
                                        <FormGroup>
                                          <Field name="service_desc">
                                            {({ input, meta }) => (
                                              <div>
                                                <Input {...input} type="text" placeholder="Service Description"
                                                  invalid={meta.error && meta.touched}
                                                />
                                                {meta.error && meta.touched &&
                                                  <span className="text-danger">{meta.error}</span>}
                                              </div>
                                            )}
                                          </Field>
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                                <Row>
                                  <h4 className="fw-bold ">Master Pricing</h4>
                                </Row>
                                {Array.from({ length: rows }, (_, index) => (
                                  <Row id={"parent" + index}>
                                    <Col md="5">
                                      <FormGroup>
                                        <Field name={"packaging" + index} id={"packaging" + index}>
                                          {({ input, meta }) => (
                                            <div>
                                              <Input {...input} type="select" className="packclass"
                                                invalid={meta.error && meta.touched}
                                              >
                                                <option disabled={false} value="">Packaging</option>
                                                {packagings &&
                                                  packagings.map((pack, inde) => (
                                                    <option key={inde} value={pack._id}>{pack.name}</option>
                                                  ))
                                                }
                                              </Input>
                                              {meta.error && meta.touched &&
                                                <span className="text-danger">{meta.error}</span>}
                                            </div>
                                          )}
                                        </Field>
                                      </FormGroup>

                                    </Col>
                                    {/* <Col md="3">
                                      <FormGroup>
                                        <Field name={"packsize" + index} id={"packsize" + index}>
                                          {({ input, meta }) => (
                                            <div>
                                              <Input {...input} type="select" className="sizeclass"
                                                invalid={meta.error && meta.touched}
                                              >
                                                <option disabled={false} value="">Size</option>
                                                {packagingSizes &&
                                                  packagingSizes.map((size, ind) => (
                                                    <option key={ind} value={size._id}>{size.name}</option>
                                                  ))
                                                }
                                              </Input>
                                              {meta.error && meta.touched &&
                                                <span className="text-danger">{meta.error}</span>}
                                            </div>
                                          )}
                                        </Field>
                                      </FormGroup>


                                    </Col > */}
                                    <Col md="5" >
                                      <FormGroup>
                                        <Field name={"price" + index} id={"price" + index} >
                                          {({ input, meta }) => (
                                            <div>
                                              <Input {...input} type="text" placeholder="Price" className="priceclass"
                                                invalid={meta.error && meta.touched}
                                              />
                                              {meta.error && meta.touched &&
                                                <span className="text-danger">{meta.error}</span>}
                                            </div>
                                          )}
                                        </Field>
                                      </FormGroup>
                                    </Col>
                                    <Col md="2" className="pt-1">
                                      <i onClick={() => addRow()} class="lnr-plus-circle h3 fw-bold"> </i>
                                      {rows !== 1 &&
                                        <i onClick={() => remove_option(index)} class="lnr-cross-circle h3 fw-bold"> </i>
                                      }
                                    </Col>

                                  </Row>
                                ))}
                                <Row>
                                  <FormGroup>
                                    <div className="text-center">
                                      <button type="submit" className="btn btn-secondary btn-lg btn-hover-shine me-3">
                                        Save </button>
                                      <button type="button" onClick={() => navigate(-1)} className="btn btn-outline-success btn-lg btn-hover-shine me-3">
                                        Cancel
                                      </button>
                                    </div>
                                  </FormGroup>
                                </Row>
                              </form>
                            )}
                          />
                        </LoadingOverlay>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </div>
          </div>
        </div>
      </div >
    </Fragment >
  );
};


export default AddService;
