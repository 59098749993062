import React, { useEffect, useState, Fragment } from "react";
import { useTranslation } from 'react-i18next';
import { format, parseISO } from 'date-fns';
import { useNavigate, Link } from 'react-router-dom';
import {
  Row,
  Col, Modal, Card, CardBody

} from "reactstrap";
import $ from 'jquery';
import moment from 'moment';
import './EditableTable.css';
import { CSVLink } from 'react-csv';

import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../../components/Loaders/DnaLoader";
import "../../../assets/custom css/loadoverlay.scss";

import Alerts from "../../../components/Alerts/alerts";

import CustomerService from "./Customer.service";
import AuthService from "../../Authentication/auth.service";

import DataTable from 'react-data-table-component';
import { Tooltip } from "@mui/material";

import PaymentFilter from "../../../components/Table Filters/InvoiceFilter/PaymentFilter";
import AlertService from "../../../components/Alerts/alerts";



const CustomersList = () => {
  const [isHovered, setIsHovered] = useState(false);

  const handleHover = () => {
    setIsHovered(!isHovered);
  };

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [csvData, setCSVData] = useState([]);
  const [pdfModalIsOpen, setPDFModalIsOpen] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");
  const [filterInvoice_StartDate, setFilterInvoice_StartDate] = useState('');
  const [filterInvoice_EndDate, setFilterInvoice_EndDate] = useState('');
  const [filterDue_StartDate, setFilterDue_StartDate] = useState('');
  const [filterDue_EndDate, setFilterDue_EndDate] = useState('');
  const [search_text, setSearchText] = useState('');

  const [loading, setLoading] = useState(false);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState("");
  const [searchName, setsearchName] = useState('');
  const [searchRegNo, setsearchRegNo] = useState('');
  const [searchEmail, setsearchEmail] = useState('');
  const [filterText1, setFilterText1] = useState('');
  const [filterText2, setFilterText2] = useState('');
  const [filterText3, setFilterText3] = useState('');

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    fetchCustomers(0, pageLimit);
  }, [pageLimit]);
  function setFilterTextFtn(itemrec, param) {
    console.log(itemrec)
    console.log('Value of key1:', itemrec);
    if (param === 'users') {
      setFilterText1(itemrec);
    }
    if (param === 'orders') {
      setFilterText2(itemrec);
    }
  };
  const [value, onChange] = useState(new Date());



  function checkenterkey(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      setResetPaginationToggle(!resetPaginationToggle);
      var page = 0
      handlePageChange(page++)
    }
  };




  function fetchCustomers( page_index, page_limit, filterText1, filterText2,
    filterInvoice_StartDate, filterInvoice_EndDate,
    filterDue_StartDate, filterDue_EndDate,search_text) {
    var filter = {};
    console.log(filterText1);
    if (filterText1) {
      console.log("filterText1");
      filter["customer_id"] = filterText1
    }
    if (search_text) {
      console.log("search_text");
      filter["search_text"] = search_text
    }
    if (filterText2) {
      console.log("filterText1");
      filter["status"] = filterText2
    }
    if (filterDue_StartDate) {
      console.log("filterDue_StartDate");
      filter["filterDue_StartDate"] = filterDue_StartDate
    } if (filterDue_EndDate) {
      console.log("filterDue_EndDate");
      filter["filterDue_EndDate"] = filterDue_EndDate
    } if (filterInvoice_StartDate) {
      console.log("filterInvoice_StartDate");
      filter["filterInvoice_StartDate"] = filterInvoice_StartDate
    } if (filterInvoice_EndDate) {
      filter["filterInvoice_EndDate"] = filterInvoice_EndDate
    }
    var offset = (page_index - 1) * page_limit;
    if (offset < 1) {
      offset = 0;
    }

    setLoading(true);

    CustomerService.getAllPayments(offset, page_limit, filterText1,
      filterText2, filterInvoice_StartDate, filterInvoice_EndDate, filterDue_StartDate, filterDue_EndDate, search_text).then((response) => {
        setLoading(false);
        console.log("Get Customers response:-", response)
        if (response.data.message === "Unauthorized") {
          AuthService.logout();
          navigate("/login");
          window.location.reload();
        }
        else {
          console.log(response.data);
          setCustomers(response.data.data);
          setTotalRows(response.data.count.count);
          const mappedData = response.data.data.map(payment => ({
            payment_date: moment(payment.payment_date).format(`DD-MM-YYYY`),
            payment_id: payment.Payment_ID,
            customer_name: payment.user_details?.company_name ?? '',
            invoice_no: payment.invoice_number,
            payment_method: payment.payment_method,
            // transaction_id: payment.transaction_id,
            payment_status: payment.status,
            amount: payment.amount,
            total_amount: payment.total_amount
          }));
          setCSVData(mappedData)
        }
      },
        (error) => {
          setLoading(false);
          const errContent =
            (error.response && error.response.data) || error.message || error.toString();
          console.log("Net:-", errContent)
          if (errContent) {
            Alerts.swalErrorAlert(errContent)
          }
          else {
            Alerts.swalErrorAlert(t("Server Error, Please try again later."))
          }
        })
  };
  function handleClear(item) {
    if (item === 'users') {
      $("#colfilter").val('');
      setFilterText1('');
      setFilterText2('');
      setResetPaginationToggle(!resetPaginationToggle);
      var page = 0
      handlePageChange(page++)
    }
    if (item === 'action') {
      // $("#modfilter").val('');
      $("#actfilter").val('');
      setFilterText2('');
      setResetPaginationToggle(!resetPaginationToggle);
      var page = 0
      handlePageChange(page++)
    }
    if (item === 'function') {
      $("#funcfilter").val('');
      setFilterText3('');
      setResetPaginationToggle(!resetPaginationToggle);
      var page = 0
      handlePageChange(page++)
    }
  };

  function handlePageChange(page_index) {
    console.log("Page index:-", page_index)
    setCurrentPage(page_index)
    fetchCustomers(page_index, pageLimit);
  }

  async function handlePerRowsChange(newPageLimit, page_index) {
    setPageLimit(newPageLimit);
  }

  function resetFilters() {
    setResetPaginationToggle(!resetPaginationToggle);
    setFilterText1('');
    setFilterInvoice_StartDate('');
    setFilterInvoice_EndDate('');
    setFilterDue_StartDate('');
    setFilterDue_EndDate('');
    setFilterText2('');
    fetchCustomers(0, pageLimit);

  }

  function searchCustomers() {
    setResetPaginationToggle(!resetPaginationToggle);
    const page = 0;

    // Filter values
    const filteredText1 = filterText1 || "";
    const filteredText2 = filterText2 || "";
    const FilterInvoice_StartDate1 = filterInvoice_StartDate || "";
    const FilterInvoice_EndDate2 = filterInvoice_EndDate || "";
    const FilterDue_StartDate3 = filterDue_StartDate || "";
    const FilterDue_EndDate4 = filterDue_EndDate || "";
    const Search_text = search_text || "";
    console.log(FilterDue_StartDate3)
    console.log(FilterDue_EndDate4)
    fetchCustomers(0, pageLimit, filteredText1, filteredText2, FilterInvoice_StartDate1,
      FilterInvoice_EndDate2, FilterDue_StartDate3, FilterDue_EndDate4, Search_text);
  }



  const downloadCSV = () => {
    var getData = {
      functionName: "GetPayments",
      postData: {
        "sort": { _id: -1 },
      },
    }
    setLoading(true);
    CustomerService.getPayments(getData).then((response) => {
      setLoading(false);
      console.log(response)
      if (response.status === 200) {
        // Extract column headers from the first data row
        const data = response.data.data;
        if (data.length > 0) {
          // Get all available column names from the first row
          const header = Object.keys(data[0]);

          // Define the columns to exclude
          const columnsToExclude = ['_id', 'functionName', "invoice_id", "customer_id", 'datetime_modified', 'doctypeData', "added_by", "modified_by", "last_login", "last_login_ip", 'lastLogin_datetime'];

          // Filter the header to exclude unwanted columns
          const filteredHeader = header.filter(column => !columnsToExclude.includes(column));

          // Filter the data to exclude corresponding properties
          const filteredData = data.map(row => {
            const filteredRow = {};
            for (const key in row) {
              if (!columnsToExclude.includes(key)) {
                filteredRow[key] = row[key];
              }
            }
            return filteredRow;
          });

          // Create a CSV content with filtered headers and data
          const csv = [filteredHeader.join(','), ...filteredData.map(row => filteredHeader.map(fieldName => row[fieldName]).join(','))].join('\n');

          // Create a Blob with the CSV data
          const blob = new Blob([csv], { type: 'text/csv' });

          // Create a download link
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = 'data.csv';

          // Trigger the download
          a.click();

          // Clean up
          window.URL.revokeObjectURL(url);
        } else {
          console.log("No data to download.");
        }
      } else if (response.status === 401) {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      } else {
        console.log("Response status: " + response.status);
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent);
        if (errContent) {
          Alerts.swalErrorAlert(errContent);
        } else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."));
        }
      })
  }



  const mycolumns = [
    {
      name: "Payment Date",
      selector: "payment_date",
      sortable: true,
      cell: (row) => format(parseISO(row.payment_date), 'dd-MM-yyyy'),
    },
    {
      name: "Payment ID",
      selector: "Payment_ID",
      sortable: true,
    },
    {
      name: "Customer Name",
      selector: (row) => {
        if (row.user_details) {
          return <div >{row.user_details.company_name}</div>
        } else {
          return;
        }
      },
      // selector: "pic_name",
      sortable: true,
    },
    // {
    //   name: "Invoice No.",
    //   selector: "invoice_number",
    //   sortable: true,
    // },
    // {
    //   name: "Transaction Id",
    //   selector: "transaction_id",
    //   sortable: true,
    // },
    {
      name: "Payment Method",
      selector: "payment_method",
      sortable: true,
    },
    {
      name: t('Status'),
      width: '15%',
      selector: (row) => {
        return (
          <div>
            {row.status === "paid" ?
              <button type="button"
                className="btn btn-success btn-hover-shine me-3 btn">{row.status}
              </button>
              :
              row.status === "unpaid" ?
                <button type="button"
                  className="btn btn-danger btn-hover-shine me-3 btn">{row.status}
                </button>
                :
                <button type="button"
                  className="btn btn-primary btn-hover-shine me-3 btn">{row.status}
                </button>
            }
          </div>
        );
      }
    },
    {
      name: "Paid Amount",
      // width: "30%",
      sortable: true,
      selector: (row) => (
        <div >{`${row.currency}${parseFloat(row.amount).toFixed(2)}`} </div>
      )
    },
    {
      name: "Total Amount",
      // width: "30%",
      sortable: true,
      selector: (row) => (
        <div >{`${row.currency}${parseFloat(row.total_amount).toFixed(2)}`} </div>
      )
    },
  ];

  const headers = [
    { label: "Payment Date", key: "payment_date", style: { fontWeight: "bold", backgroundColor: "lightgray", color: "blue" } },
    { label: "payment ID", key: "payment_id", style: { fontWeight: "bold", backgroundColor: "lightgray", color: "blue" } },
    { label: "Customer Name", key: "customer_name", style: { fontWeight: "bold", backgroundColor: "lightgray", color: "blue" } },
    { label: "Invoice No.", key: "invoice_no", style: { fontWeight: "bold", backgroundColor: "lightgray", color: "blue" } },
    // { label: "Transaction ID", key: "transaction_id", style: { fontWeight: "bold", backgroundColor: "lightgray", color: "blue" } },
    { label: "Payment Method", key: "payment_method", style: { fontWeight: "bold", backgroundColor: "lightgray", color: "blue" } },
    { label: "Payment Status", key: "payment_status", style: { fontWeight: "bold", backgroundColor: "lightgray", color: "blue" } },
    { label: "Paid Amount", key: "amount", style: { fontWeight: "bold", backgroundColor: "lightgray", color: "blue" } },
    { label: "Total Amount", key: "total_amount", style: { fontWeight: "bold", backgroundColor: "lightgray", color: "blue" } },
  ];

  const linkStyle = {
    textDecoration: 'none',
    color: isHovered ? 'black' : 'white',
  };
  return (
    <>
      <Fragment>
        <div className="app-main__outer">
          <div className="app-main__inner">
            <div className=" bg-light-gray bg-animation ">
              <div className="d-flex  justify-content-center align-items-center">
                <Col md="12" className="mx-auto app-login-box">
                  <div className="app-page-title">
                    <div className="page-title-wrapper">
                      <div className="page-title-heading fw-bold">
                        <div className="page-title-icon">
                          <i className="pe-7s-credit icon-gradient bg-tempting-azure" />
                        </div>
                        <div>
                          {t("Payments Reports")}
                          <div className="page-title-subheading">
                            {t('View & Download Reports of Payments.')}
                          </div>

                        </div>
                      </div>
                      <div className="page-title-actions">
                        <button type="button" onClick={() => navigate(-1)} className="btn btn-outline-success btn-hover-shine me-3">
                          Back
                        </button>
                      </div>
                    </div>
                  </div>
                  <Row>


                    <Fragment>
                      <Row>
                        <Col md="12">
                          <Card className="main-card mb-3">
                            <CardBody>
                              <LoadingOverlay tag="div" active={loading}
                                styles={{ overlay: (base) => ({ ...base }) }}
                                spinner={<DNALoader />}>
                                <PaymentFilter
                                  headers={headers}
                                  handleHover={handleHover}
                                  linkStyle={linkStyle}
                                  csvData={csvData}
                                  onFilterInvoice_StartDate={setFilterInvoice_StartDate}
                                  onFilterInvoice_EndDate={setFilterInvoice_EndDate}
                                  search_text={search_text}
                                  onSearch_text={(e) => {
                                    setSearchText(e.target.value);
                                  }}
                                  onFilterDue_StartDate={setFilterDue_StartDate}
                                  onFilterDue_EndDate={setFilterDue_EndDate}

                                  searchCustomers={searchCustomers}
                                  resetFilters={resetFilters}
                                  onFilter1={(e) => {
                                    console.log(e.target.value);
                                    setFilterText1(e.target.value, 'users');
                                  }}
                                  onFilter2={(e) => {
                                    console.log(e.target.value);
                                    setFilterText2(e.target.value, 'users');
                                  }}
                                  handleKeyDown={(event) => checkenterkey(event)}
                                  filterText1={filterText1}
                                  filterText2={filterText2}
                                  filterInvoice_StartDate={filterInvoice_StartDate}
                                  filterInvoice_EndDate={filterInvoice_EndDate}
                                  filterDue_StartDate={filterDue_StartDate}
                                  filterDue_EndDate={filterDue_EndDate}

                                  onClear={() => handleClear('users')}
                                />

                                <DataTable
                                  data={customers}
                                  columns={mycolumns}
                                  pagination
                                  fixedHeader
                                  fixedHeaderScrollHeight="400px"
                                  paginationServer
                                  paginationTotalRows={totalRows}
                                  onChangePage={handlePageChange}
                                  onChangeRowsPerPage={handlePerRowsChange}
                                  paginationResetDefaultPage={resetPaginationToggle}
                                />
                              </LoadingOverlay>

                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </Fragment>
                  </Row>
                </Col>
              </div>
            </div>
          </div>
        </div>

      </Fragment>
    </>

  );
};

export default CustomersList;