import React from "react";
import { Translation } from "react-i18next";
import { Navigate } from "react-router-dom";
import { Row, Col, FormGroup, Input, Button, Label, Modal, ModalHeader, ModalBody, ModalFooter, Card, CardBody } from "reactstrap";
import { Form, Field } from "react-final-form";
import DriverService from "../Driver.service";
import AuthService from "../../../Authentication/auth.service";

import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../../../components/Loaders/DnaLoader";
import "../../../../assets/custom css/loadoverlay.scss";

import Alerts from "../../../../components/Alerts/alerts";;


class UpdateDriverDocuments extends React.Component {
  componentDidMount() {
    this.getDocTypes();
  }
  constructor(props) {
    super(props);
    console.log("Received Prop:-", props);
    this.state = {
      modal: false,
      backdrop: true,
      loading: false,
      loginNavigate: false,
      docTypes: [],
      upfile: ''
    };
    this.toggle = this.toggle.bind(this);
    this.validateFtn = this.validateFtn.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.getDocTypes = this.getDocTypes.bind(this);
    this.onFileChange = this.onFileChange.bind(this);

  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }

  getDocTypes() {
    DriverService.getDocTypes().then((response) => {
      console.log("Get DocTypes response:-", response);
      this.setState({
        docTypes: response.data.data,
      });
    },
      (error) => {
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent)
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert("Server Error, Please try again later.")
        }
      })
  }


  validateFtn = values => {
    const errors = {};
    if (!values.doc_type_id) {
      errors.doc_type_id = "Document Type Required.";
    }
    return errors;
  };

  onSubmit = values => {
    console.log(values);
    var payloadData = {
      functionName: "UpdateDocument",
      postData: {
        doc_type_id: values.doc_type_id,
        file: this.state.upfile,
        user_id: this.props.data.user_id,
        _id: this.props.data._id
      }
    }
    this.setState({
      loading: !this.state.loading,
    });
    DriverService.UpdateDriverDocument(payloadData).then((response) => {
      console.log("API Response:-", response);
      this.setState({
        loading: !this.state.loading,
      });
      if (response.data.status) {
        // this.toggle();
        // window,location.reload();
        if (response.data.message !== '') {
          Alerts.swalSuccessAlert(response.data.message).then(res => {
            console.log("Resp:-", res)
            this.toggle();
            window.location.reload();
          })
        }
        else {
          Alerts.swalSuccessAlert("Document Updated successfully.").then(res => {
            console.log("Resp:-", res)
            this.toggle();
            window.location.reload();
          })
        }
      }
      else {
        if (response.data.message !== '') {
          if (response.data.message === "Unauthorized") {
            AuthService.logout();
            this.setState({ loginNavigate: true });
            window.location.reload();
          }
          else {
            Alerts.swalErrorAlert(response.data.message).then(res => {
              console.log("Resp:-", res)
              this.toggle();
              window.location.reload();
            })
          }
        }
        else {
          Alerts.swalErrorAlert("Error: Can't Update Document at the moment. Please try again later.")
        }
      }
    },
      (error) => {
        this.setState({
          loading: !this.state.loading,
        });
        const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        Alerts.swalErrorAlert("Network Operation failed.")
      });

  }

  onFileChange = e => {
    console.log(e.target.files)
    var filename = e.target.files[0].name
    console.log("File Name", filename)
    var fileReader = new FileReader();
    fileReader.readAsDataURL(e.target.files[0])
    fileReader.onload = () => {
      const convertedPDF = fileReader.result
      console.log("Converted PDF File:-", convertedPDF)
      this.setState({
        upfile: convertedPDF
      });
    }
  }

  render() {
    {
      this.state.loginNavigate &&
        <Navigate to={'/login'} />
    }
    return (
      <Translation>
        {(t) => (
          <span className="d-inline-block mb-2">
            <i onClick={this.toggle} className="pe-7s-pen btn-icon-wrapper text-primary"> </i>
            <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className} backdrop={true}>
              <LoadingOverlay tag="div" active={this.state.loading}
                styles={{ overlay: (base) => ({ ...base }) }}
                spinner={<DNALoader />}>
                <ModalHeader >Update Document</ModalHeader>
                <ModalBody>
                  <Card className="main-card mb-3">
                    <CardBody>
                      <Row>
                        <Col md="12" className="pt-3">
                          <Form onSubmit={this.onSubmit}
                            validate={this.validateFtn}
                            initialValues={this.props.data}
                            render={({ handleSubmit, values, submitting, validating, valid }) => (
                              <form onSubmit={handleSubmit}>
                                <Row>
                                  <Col md="12">
                                    <FormGroup>
                                      <Field name="doc_type_id">
                                        {({ input, meta }) => (
                                          <div>
                                            <Input {...input} type="select"
                                              invalid={meta.error && meta.touched}
                                            >
                                              <option disabled={false} value="">Document Type</option>
                                              {this.state.docTypes &&
                                                this.state.docTypes.map((doctyp, ind) => (
                                                  <option key={ind} value={doctyp._id}>{doctyp.name}</option>
                                                ))
                                              }
                                            </Input>
                                            {meta.error && meta.touched &&
                                              <span className="text-danger">{meta.error}</span>}
                                          </div>
                                        )}
                                      </Field>
                                    </FormGroup>
                                  </Col>

                                  <Col md="12" >
                                    <FormGroup>
                                      <div className="mb-12">
                                        <Label className="mb-2">File</Label>
                                        <Input name="file" type='file' onChange={this.onFileChange}
                                        />
                                      </div>
                                    </FormGroup>
                                  </Col>
                                  <div className="text-center">
                                    <FormGroup>
                                      <div className="text-center">
                                        <button type="submit" className="btn btn-secondary btn-lg btn-hover-shine me-3">
                                          Save </button>
                                        <button type="button" className="btn btn-outline-success btn-lg btn-hover-shine me-3" onClick={this.toggle}>
                                          Cancel
                                        </button>
                                      </div>
                                    </FormGroup>
                                  </div>
                                </Row>
                              </form>
                            )}
                          />
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </ModalBody>
              </LoadingOverlay>
            </Modal>
          </span>
        )}
      </Translation>
    );
  };


};

export default UpdateDriverDocuments;