import React, { useEffect, useState, Fragment } from "react";
import { useTranslation } from 'react-i18next';
import { Row, Col, Card, CardBody } from "reactstrap";
import DataTable from 'react-data-table-component';

import { useNavigate, useLocation } from "react-router-dom";

import DriverService from "../Driver.service";
import AuthService from "../../../Authentication/auth.service";
import UserService from "../../../Users Management/users.service";

import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../../../components/Loaders/DnaLoader";
import "../../../../assets/custom css/loadoverlay.scss";

import Alerts from "../../../../components/Alerts/alerts";

import AddDriverDocuments from "../Driver Modals/AddDriverDocuments";
import UpdateDriverDocuments from "../Driver Modals/UpdateDriverDocuments";
import DocImage from "../Driver Modals/DocImage";



const DriverDocuments = () => {

    const [documents, setdocuments] = useState([]);
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    const location = useLocation();
    const { state } = location;
    console.log("Received Object:-", state);

    useEffect(() => {
        i18n.changeLanguage(localStorage.getItem('language'));
        fetchSingleDriverDocs();
    }, []);

    function fetchSingleDriverDocs() {
        var getData = {
            functionName: "GetUserDocuments",
            postData: {
                user_id: state._id
            },
        }
        setLoading(true);
        DriverService.getDriverDocs(getData).then((response) => {
            setLoading(false);
            console.log("Get Driver response:-", response)
            if (response.data.message === "Unauthorized") {
                AuthService.logout();
                navigate("/login");
                window.location.reload();
            }
            else {
                setdocuments(response.data.data)
                getUsers();
            }
        },
            (error) => {
                setLoading(false);
                const errContent =
                    (error.response && error.response.data) || error.message || error.toString();
                console.log("Net:-", errContent)
                if (errContent) {
                    Alerts.swalErrorAlert(errContent)
                }
                else {
                    Alerts.swalErrorAlert(t("Server Error, Please try again later."))
                }
            })
    };

    function getUsers() {
        setLoading(true);
        UserService.getAllUsers().then((response) => {
            setLoading(false);
            console.log("Get Users response:-", response)
            if (response.data.message === "Unauthorized") {
                AuthService.logout();
                navigate("/login");
                window.location.reload();
            }
            else {
                setUsers(response.data.data);
            }
        },
            (error) => {
                setLoading(false);
                const errContent =
                    (error.response && error.response.data) || error.message || error.toString();
                if (errContent) {
                    Alerts.swalErrorAlert(errContent)
                }
                else {
                    Alerts.swalErrorAlert(t("Server Error, Please try again later."))
                }
            },
        )
    };

    function confirmDeleteDocument(_id) {
        let msg = t("Once deleted, you will not be able to recover this Document!")
        Alerts.swalConfirmDeleteAlert(msg).then(res => {
            console.log("Resp:-", res)
            if (res === true) {
                sendDelete(_id)
            }
        })
    };

    function sendDelete(id) {
        setLoading(true);
        DriverService.deleteDocument(id).then((response) => {
            setLoading(false);
            console.log("Delete document details response:-", response)
            if (response.data.status) {
                fetchSingleDriverDocs()
                if (response.data.message !== '') {
                    Alerts.swalSuccessAlert(response.data.message)
                }
                else {
                    Alerts.swalSuccessAlert(t("Document Deleted Successfully."))
                }
                console.log(response.data.message)
            }
            else {
                if (response.data.message !== '') {
                    if (response.data.message === "Unauthorized") {
                        AuthService.logout();
                        navigate("/login");
                        window.location.reload();
                    }
                    else {
                        Alerts.swalErrorAlert(response.data.message);
                    }
                }
                else {
                    Alerts.swalErrorAlert(t("Error: Can't delete the Document at the moment."))
                }
            }
        },
            (error) => {
                setLoading(false);
                const errContent =
                    (error.response && error.response.data) || error.message || error.toString();
                Alerts.swalErrorAlert(t("Delete operation failed"))
            })
    };


    const mycolumns = [
        {
            name: "Document Type",
            selector: row => row?.documentData[0]?.name
        },
        {
            name: "File",
            selector: row => {
                return (
                    <div>
                        <DocImage data={row?.file} />
                    </div>
                )
            }
        },
        {
            name: "Uploaded At",
            selector: row => new Date(row?.created_at).toDateString().split(' ').slice(1).join(' ')
        },
        {
            name: "Uploaded By",
            selector: row => row?.usersInfo?.name
        },
        {
            name: 'Actions',
            selector: (row) => {
                return (
                    <div>
                        <button className="mt-2 mb-2 btn-icon btn">
                            <i>
                                <UpdateDriverDocuments data={row} />
                            </i>
                            <i onClick={() => confirmDeleteDocument(row?._id)} className="pe-7s-trash text-danger btn-icon-wrapper"> </i>
                        </button>
                    </div>
                );
            }
        },
    ];



    return (
        <Fragment>
            <Row>
                <Col md="12">
                    <Card className="main-card mb-3">
                        <CardBody>
                            <LoadingOverlay tag="div" active={loading}
                                styles={{ overlay: (base) => ({ ...base }) }}
                                spinner={<DNALoader />}>
                                <Row className="mt-2">
                                    <Col md="6">
                                        <h4 className="fw-bold mb-1">Driver Documents</h4>
                                    </Col>
                                    <Col md="6">
                                        <AddDriverDocuments data={state._id} />
                                    </Col>
                                    <Col md="12" className="pt-3">
                                        <DataTable
                                            data={documents}
                                            columns={mycolumns}
                                            pagination
                                            fixedHeader
                                            fixedHeaderScrollHeight="400px"
                                        />
                                    </Col>
                                </Row>
                            </LoadingOverlay>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    );

};
export default DriverDocuments;