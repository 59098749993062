import React from "react";
import { Translation } from "react-i18next";

import { Row, Col, FormGroup, Input, Button, Label, Modal, ModalHeader, ModalBody, ModalFooter, Card, CardBody } from "reactstrap";



class ViewOrderMedia extends React.Component {

  constructor(props) {
    super(props);
    console.log("Received Prop:-", props);
    this.state = {
      modal: false,
      backdrop: true,
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }


  render() {

    return (
      <Translation>
        {(t) => (
          <span >
            <a onClick={this.toggle} style={{ cursor: "pointer" }}>
              <button className="btn-icon btn-icon-only btn-outline-2x btn btn-outline-success">
                <i className="pe-7s-photo btn-icon-wrapper"> </i>
              </button>
            </a>
            <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className} backdrop={true}>
              <ModalBody>
                {this.props.data.includes('pdf') ?
                  <iframe src={this.props.data} ></iframe>
                  :
                  <img src={this.props.data}></img>
                }
                <div className="modal-footer justify-content-center">
                  <button onClick={this.toggle} className="btn btn-outline-success btn-hover-shine ">Close</button>
                </div>
              </ModalBody>
            </Modal>
          </span>
        )}
      </Translation>
    );
  };


};

export default ViewOrderMedia;