import React, { useEffect, useRef } from 'react';
import moment from 'moment';
import 'daterangepicker/daterangepicker.css';
import 'daterangepicker';
import $ from 'jquery';

const DateRangePickerComponent = ({ onDateChange }) => {
    const dateRangePickerRef = useRef(null);

    useEffect(() => {
        const startDate = null;
        const endDate = null;
        const updateText = (start, end) => {
            console.log(start)
            if (start !== '' && end !== '' && start?._isValid === true) {
                dateRangePickerRef.current.innerText = start.format('DD-MM-YYYY') + ' to ' + end.format('DD-MM-YYYY');
            } else {
                dateRangePickerRef.current.innerText = 'Select Date Range';
            }
        };

        const cb = (start, end) => {
            updateText(start, end);
            let startDateFormatted = null;
            let endDateFormatted = null;
            if (start !== '' && end !== '' && start?._isValid === true) {
                startDateFormatted = start.format('YYYY-MM-DD');
                endDateFormatted = end.format('YYYY-MM-DD');
            }
            if (!startDateFormatted && !endDateFormatted) {
                onDateChange('', '');
            } else {
                onDateChange(startDateFormatted, endDateFormatted);
            }
        };
            $(dateRangePickerRef.current).daterangepicker({
                "startDate": moment().subtract(3, 'days'),
                "endDate": moment(),
                ranges: {
                    'Today': [moment(), moment()],
                    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                    'This Month': [moment().startOf('month'), moment().endOf('month')],
                    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                    'This Year': [moment().startOf('year'), moment().endOf('year')],
                    'Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')],
                }
            }, cb);
        cb(startDate, endDate);
    }, []);

    return (
        <div id="reportrange" ref={dateRangePickerRef} style={{
            background: '#FFFFFF',
            cursor: 'pointer',
            height: '29px',
            padding: '5px 10px',
            width: '100%',
        }}>
            <i className="fa fa-calendar"></i>&nbsp;
            <span>Select Date Range</span> <i className="fa fa-caret-down"></i>
        </div>
    );
};

export default DateRangePickerComponent;
