import axios from "axios";

const API_URL  = process.env.REACT_APP_BASEURL;

  
const headers =  { 
  'Content-Type': 'application/json',
  'token': localStorage.getItem("session-id")
}

const fetchModules = (filter) => {
  var payload = {
    functionName: "GetModules",
    postData: filter,
  }
  return axios.post(API_URL, payload, {headers});
};

const fetchModuleswithfunctions = (filter) => {
  var payload = {
    functionName: "GetModuleswithfunction",
    postData: filter,
  }
  return axios.post(API_URL, payload, {headers});
};

const fetchFunctions = () => {
  var payload = {
    functionName: "GetFunctions",
    postData: {},
  }
  return axios.post(API_URL, payload, {headers});
};
const getCollections = (filter,collection) => {
  var payload = {
    functionName: "GetCollectionData",
    "postData": {
        "collection_name": collection,
        "filter":filter
    }
  }
  return axios.post(API_URL, payload, {headers});
};
const CommonService = {
    fetchModules,
    fetchFunctions,
    getCollections,
    fetchModuleswithfunctions
  };
  
export default CommonService;

