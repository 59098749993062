import React, { useEffect, useState, Fragment } from "react";
import { useTranslation } from 'react-i18next';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { format, parseISO, set } from 'date-fns';
import { useNavigate, useLocation, useParams, Link } from 'react-router-dom';
import {
  Row,
  Col,
  Input,
  CardBody,
  Card
} from "reactstrap";
import Tooltip from '@mui/material/Tooltip';

import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../../components/Loaders/DnaLoader";
import "../../../assets/custom css/loadoverlay.scss";

import Alerts from "../../../components/Alerts/alerts";

import DriverService from "./Driver.service";
import AuthService from "../../Authentication/auth.service";

import DataTable from 'react-data-table-component';



const DriversList = () => {

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [drivers, setDrivers] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState("");
  const [searchName, setsearchName] = useState('');
  const [searchId, setsearchId] = useState('');
  const [searchEmail, setsearchEmail] = useState('');

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    fetchDrivers(0, pageLimit);
  }, [pageLimit]);


  function fetchDrivers(page_index, page_limit) {
    var filter = {};
    if (searchName !== "") {
      console.log("Search name:-", searchName)
      filter["name"] = searchName
    }
    if (searchId !== "") {
      console.log("Search ID:-", searchId)
      filter["login_id"] = searchId
    }
    if (searchEmail !== "") {
      filter["email"] = searchEmail
    }

    var offset = (page_index - 1) * page_limit;
    if (offset < 1) {
      offset = 0;
    }
    var getData = {
      functionName: "GetDrivers",
      postData: {
        "sort": { _id: -1 },
        "offset": offset,
        "limit": page_limit,
        "filter": filter
      },
    }
    setLoading(true);
    DriverService.getAllDrivers(getData).then((response) => {
      setLoading(false);
      console.log("Get Drivers response:-", response)
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        setDrivers(response.data.data);
        setTotalRows(response.data.count.count);
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent)
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  };

  function handlePageChange(page_index) {
    console.log("Page index:-", page_index)
    setCurrentPage(page_index)
    fetchDrivers(page_index, pageLimit);
  }

  async function handlePerRowsChange(newPageLimit, page_index) {
    setPageLimit(newPageLimit);
  }

  function nameFilter(e) {
    console.log(e.target.value);
    setsearchName(e.target.value)
  }
  function idFilter(e) {
    console.log(e.target.value);
    setsearchId(e.target.value)
  }
  function emailFilter(e) {
    console.log(e.target.value);
    setsearchEmail(e.target.value)
  }


  function resetFilters() {
    setResetPaginationToggle(!resetPaginationToggle);
    setsearchName('');
    setsearchId('');
    setsearchEmail('');
    var offset = 0;
    var getData = {
      functionName: "GetDrivers",
      postData: {
        "sort": { _id: -1 },
        "offset": offset,
        "limit": pageLimit,
        "filter": {}
      },
    }
    setLoading(true);
    DriverService.getAllDrivers(getData).then((response) => {
      setLoading(false);
      console.log("Get Drivers response:-", response)
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        setDrivers(response.data.data);
        setTotalRows(response.data.count.count);
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent)
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  }

  function searchDrivers() {
    setResetPaginationToggle(!resetPaginationToggle);
    var page = 0
    handlePageChange(page++)
  }


  function confirmDeleteDriver(_id) {
    let msg = t("Once deleted, you will not be able to recover this driver!")
    Alerts.swalConfirmDeleteAlert(msg).then(res => {
      console.log("Resp:-", res)
      if (res === true) {
        sendDelete(_id)
      }
    })
  };


  function sendDelete(id) {
    setLoading(true);
    DriverService.deleteDriver(id).then((response) => {
      setLoading(false);
      console.log("Delete driver response:-", response)
      if (response.data.status) {
        fetchDrivers(currentPage, pageLimit)
        if (response.data.message !== '') {
          Alerts.swalSuccessAlert(response.data.message)
        }
        else {
          Alerts.swalSuccessAlert(t("Driver Deleted Successfully."))
        }
        console.log(response.data.message)
      }
      else {
        if (response.data.message !== '') {
          if (response.data.message === "Unauthorized") {
            AuthService.logout();
            navigate("/login");
            window.location.reload();
          }
          else {
            Alerts.swalErrorAlert(response.data.message);
          }
        }
        else {
          Alerts.swalErrorAlert(t("Error: Can't delete the driver at the moment."))
        }
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        Alerts.swalErrorAlert(t("Delete operation failed"))
      })
  };

  const mycolumns = [
    {
      name: "Name",
      selector: row => row.name,
    },
    {
      name: "ID",
      selector: row => row.login_id,
    }, {
      name: "Email",
      width: "30%",
      selector: row => row.email,
    },
    {
      name: "Phone Number",
      selector: row => row.dob
    },
    {
      name: "Fleet",
      selector: row => row?.fleetsData?.[0]?.name
    },
    {
      name: 'Actions',
      selector: (row) => {
        return (
          <div>
            <button className="mt-2 mb-2 me-2 btn-icon btn">
              <Tooltip placement="top" title="Driver Details" arrow>
                <Link to="/driver-details" state={row}>
                  <i className="pe-7s-note2 btn-icon-wrapper text-info"> </i>
                </Link>
              </Tooltip >
              <Tooltip placement="top" title="Delete" arrow>
                <i onClick={() => confirmDeleteDriver(row._id)} className="pe-7s-trash text-danger btn-icon-wrapper"> </i>
              </Tooltip >
              <Tooltip placement="top" title="Edit" arrow>
                <i>
                  <Link to="/update-driver" state={row}>
                    <i className="pe-7s-pen btn-icon-wrapper text-primary"> </i>
                  </Link>
                </i>
              </Tooltip >
            </button>
          </div>
        );
      }
    },
  ];


  return (
    <Fragment>
      <div className="app-main__outer">
        <div className="app-main__inner">
          <div className=" bg-light-gray bg-animation ">
            <div className="d-flex  justify-content-center align-items-center">
              <Col md="12" className="mx-auto app-login-box">
                <div className="app-page-title">
                  <div className="page-title-wrapper">
                    <div className="page-title-heading fw-bold">
                      <div className="page-title-icon">
                        <i className="pe-7s-users icon-gradient bg-tempting-azure" />
                      </div>
                      <div>
                        Drivers Management
                      </div>
                    </div>
                    <div className="page-title-actions">
                      <button type="button" onClick={() => navigate(-1)} className="btn btn-outline-success btn-hover-shine me-3">
                        Back
                      </button>
                    </div>
                  </div>
                </div>
                <Row>
                  <Fragment>
                    <Row>
                      <Col md="12">
                        <Card className="main-card mb-3">
                          <CardBody>

                            <LoadingOverlay tag="div" active={loading}
                              styles={{ overlay: (base) => ({ ...base }) }}
                              spinner={<DNALoader />}>
                              <Row>

                                <Col md="3">
                                  <Input type="text" name="fname" value={searchName} placeholder="Name" onChange={event => nameFilter(event)}></Input>
                                </Col>
                                <Col md="2">
                                  <Input type="text" name="fid" value={searchId} placeholder="ID" onChange={event => idFilter(event)}></Input>
                                </Col>
                                <Col md="3">
                                  <Input type="text" name="femail" value={searchEmail} placeholder="Email" onChange={event => emailFilter(event)}></Input>
                                </Col>
                                <Col md="1">
                                  <button type="button" className="btn btn-custom-color btn-hover-shine ms-1" onClick={() => searchDrivers()}>Search</button>
                                </Col>
                                <Col md="1">
                                  <button type="button" className="btn btn-custom-color btn-hover-shine" onClick={() => resetFilters()}>Reset</button>
                                </Col>
                                <Col md="2">
                                  <a href="/add-driver" className="btn btn-custom-color btn-hover-shine ms-1 float-end">
                                    Add New</a>
                                </Col>
                              </Row>

                              <Col md="12" className="pt-3">
                                <DataTable
                                  data={drivers}
                                  columns={mycolumns}
                                  pagination
                                  fixedHeader
                                  fixedHeaderScrollHeight="400px"
                                  paginationServer
                                  paginationTotalRows={totalRows}
                                  onChangePage={handlePageChange}
                                  onChangeRowsPerPage={handlePerRowsChange}
                                  paginationResetDefaultPage={resetPaginationToggle}
                                />
                              </Col>

                            </LoadingOverlay>

                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Fragment>
                </Row>
              </Col>
            </div>
          </div>
        </div>
      </div>

    </Fragment>
  );
};

export default DriversList;